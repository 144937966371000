import konnect from "./axios"
import { CODE } from "./type"

export const getCode = () => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/appSetting/getCode')
        return res?.data
    } catch (err) {
        console.log(err)
    }
}

export const updateCode = (data) => async (dispatch) => {
    let res = await konnect.post('/v1/admin/appSetting/addOrUpdateCode', data)
    return res.data
}
