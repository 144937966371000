import konnect from "./axios"

export const getTags = () => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/appSetting/getTags`)
        return res?.data
    } catch (err) {
        return err?.response
    }
}
export const updateTags = (tags) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/appSetting/createAndUpdateTags`, { tags })
        return res?.data
    } catch (err) {
        return err?.response
    }
}