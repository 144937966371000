import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

export default function DeleteModal({ close, visible, title, call, type, flag }) {
    return (
        <div>
            <Modal
                open={visible}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="deleteModal w-40" >
                    <div className='modal-head-icon-area'>
                        {type ? <CancelIcon color="error" sx={{ fontSize: 60 }} /> : <DeleteIcon color="error" sx={{ fontSize: 60 }} />}
                    </div>
                    <Typography className='modal-title' id="modal-modal-title" variant="h6" component="h2">
                        Are You Sure?
                    </Typography>
                    <Typography className='modal-description' id="modal-modal-description" sx={{ mt: 2 }}>
                        {title}
                    </Typography>
                    <div className='boxes-buttons-row justify-content-around'>
                        <Button
                            onClick={call}
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width"
                            color="error"
                            disableElevation
                            endIcon={!type && <DeleteIcon />}
                        >
                            {
                                flag === "influencer" ? "Yes" : type ? "Deactive" : "Delete"
                            }
                        </Button>
                        <Button
                            onClick={close}
                            variant="outlined"
                            size="medium"
                            className="btn-gen-margin btn-width grey-btn-outline"
                        >
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}