import React from "react"
import moment from "moment";
import numeral from "numeral";

const summaryColBrand = [
    {
        field: 'id', headerName: 'S.#', width: 80,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "brand_name", headerName: "Brand Name", width: 300, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 130, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Inf Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'customer_commission', headerName: "Cus Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },

]
const summaryColInfluencer = [
    {
        field: 'id', headerName: 'S.#', width: 80,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "name", headerName: "Influencer Name", width: 300, sortable: false, },
    { field: "influencer_name", headerName: "Instagram", width: 300, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
]
const detailColBrand = [
    {
        field: 'id', headerName: 'S.#', width: 60,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false, },
    { field: "campaign_name", headerName: "Campaign Name", width: 180, renderCell: ({ row }) => row.campaign_name?.length > 0 ? row.campaign_name : "BioShop", sortable: false, },
    { field: "influencer_name", headerName: "Influencer Name", width: 180, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 100, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 70, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 130, sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 110, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Inf Commission", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'customer_commission', headerName: "Cus Commission", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },

]
const detailColInfluencer = [
    {
        field: 'id', headerName: 'S.#', width: 60,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false, },
    { field: "campaign_name", headerName: "Campaign Name", width: 180, sortable: false, },
    { field: "advertiser_name", headerName: "Brand Name", width: 180, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 100, sortable: false, },
    { field: "total_qty", headerName: "Qty", width: 70, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
]
const dateColBrand = [
    {
        field: 'id', headerName: 'S.#', width: 60,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 150, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Inf Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'customer_commission', headerName: "Cus Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },

]
const dateColInfluencer = [
    {
        field: 'id', headerName: 'S.#', width: 80,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 150, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 120, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
]
const campaignColBrand = [
    {
        field: 'id', headerName: 'S.#', width: 60,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "campaign_name", headerName: "Campaign Name", width: 180, renderCell: ({ row }) => row.campaign_name?.length > 0 ? row.campaign_name : "BioShop", sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 80, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Inf Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'customer_commission', headerName: "Cus Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },

]
const campaignColInfluencer = [
    {
        field: 'id', headerName: 'S.#', width: 60,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "campaign_name", headerName: "Campaign Name", width: 200, sortable: false, },
    { field: "advertiser_name", headerName: "Brand Name", width: 200, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 120, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
]
const summaryColBrandInf = [
    {
        field: 'id', headerName: 'S.#', width: 80,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "advertiser_name", headerName: "Brand Name", width: 300, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 130, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    // { field: "discount", headerName: "Discount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
    { field: 'order_totalprice', headerName: "Net Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
]
const summaryColInfluencerBrn = [
    {
        field: 'id', headerName: 'S.#', width: 60,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "influencer_name", headerName: "Influencer Name", width: 300, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 120, sortable: false, },
    { field: "discount", headerName: "Discount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
]
const purchaseCol = [
    {
        field: 'id', headerName: 'S.#', width: 80,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 130, sortable: false, },
    { field: "brand_name", headerName: "Brand", width: 300, sortable: false, },
    { field: "sale_type", headerName: "Referral", width: 130, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 130, sortable: false, },
    { field: "amount", headerName: "Amount", width: 130, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 150, sortable: false, },
    { field: "discount", headerName: "Discount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
]
const orderCol = [
    {
        field: 'id', headerName: 'S.#', width: 80,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Order Date", width: 130, sortable: false,renderCell: ({ value }) => {
        return moment(value).format('MM-DD-YYYY')
      } },
    { field: "category", headerName: "Category", width: 150, sortable: false, },
    { field: "source", headerName: "Source", width: 150, sortable: false, },
    { field: "purchased_by", headerName: "Purchased By", width: 200, sortable: false, },
    { field: "amount", headerName: "Amount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "cashback", headerName: "Cashback", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "commission", headerName: "Commission", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "kb_fee", headerName: "KB Fee", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
]
const referralSaleCol = [
    {
        field: 'id', headerName: 'S.#', width: 50,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Order Date", width: 130, sortable: false, renderCell: ({ value }) => {
        return moment(value).format('MM-DD-YYYY')
      }},
    { field: "order_id", headerName: "Order#", width: 130, sortable: false, },
    { field: "general_category", headerName: "General Category", width: 150, sortable: false, },
    { field: "category", headerName: "Category", width: 150, sortable: false, },
    { field: "sub_category", headerName: "Sub Category", width: 150, sortable: false, },
    {
        field: "source", headerName: "Source", width: 150, sortable: false, renderCell: ({ value }) => {
            if (value === "productshare") { return "Product Share" }
            else if (value === "eventshare") { return "Event Share" }
            else if (value === "reviewshare") { return "Review Share" }
        }
    },
    { field: "referred_by", headerName: "Referred By", width: 200, sortable: false, },
    { field: "total_sale", headerName: "Amount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "total_commission", headerName: "Commission", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "kb_fee", headerName: "KB Fee", width: 130, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
]
export { referralSaleCol, orderCol, summaryColBrand, summaryColInfluencer, detailColBrand, detailColInfluencer, dateColBrand, dateColInfluencer, campaignColBrand, campaignColInfluencer, summaryColBrandInf, summaryColInfluencerBrn, purchaseCol };