import { RETURNS } from "../../actions/type";

export default function allSales(state = {}, action) {
    switch (action.type) {
        case RETURNS:
            {
                return action.payload
            }
        default:
            return state
    }
}