import konnect from "./axios"
import { GET_BANNER } from "./type"

export const addBanner = (type, data) => async (dispatch) => {
    let res;
    const url = "/v1/admin/banner/bookBanner";
    const formBody = new FormData();
    if (type === 1) {
        formBody.append('desktop', data.desktop)
        formBody.append('mobile', data.mobile)
        formBody.append('tab', data.sm_desktop)
        formBody.append('shop', data.app)
        formBody.append('row', data.row)
        formBody.append('position', data.position)
        formBody.append('brand_id', data.brand_id)
        formBody.append('year', data.date.year)
        formBody.append('month', data.date.month)
        formBody.append('type', data.type)
        formBody.append('country', data.country)
        if (data.logo) formBody.append('logo', data.logo)
        if (data.type !== "brand") {
            formBody.append('gen_category_id', data.gen_category_id)
            formBody.append('category_id', data.category_id)
            formBody.append('sub_category_id', data.sub_category_id)
        }
        res = await konnect.post(url, formBody)
        console.log("res 23", res)
        return res
    }
    if (type === "HPSP-B") {
        res = await konnect.post(url, data)
        return res
    }
    if (type === "new") {
        formBody.append('desktop', data.desktop)
        formBody.append('mobile', data.mobile)
        formBody.append('tab', data.sm_desktop)
        formBody.append('shop', data.app)
        formBody.append('row', data.row)
        formBody.append('position', data.position)
        formBody.append('brand_id', data.brand_id)
        formBody.append('title', data.title)
        formBody.append('description', data.description)
        formBody.append('year', data.date.year)
        formBody.append('month', data.date.month)
        formBody.append('type', data.type)
        formBody.append('country', data.country)
        if (data.logo) formBody.append('logo', data.logo)
        if (data.gen_category_id) {
            formBody.append('gen_category_id', data.gen_category_id)
            formBody.append('category_id', data.category_id)
            formBody.append('sub_category_id', data.sub_category_id)
        }
        res = await konnect.post(url, formBody)
        console.log("res 49", res)
        return res
    }

}

export const getBanner = (page, date, id, subId, countryCode, genId) => async (dispatch) => {
    try {
        let url = `/v1/admin/banner/getBannerBookingDetails`;
        let data = {}
        if (page === "brand") {
            data.type = page;
            data.year = date.year;
            data.month = date.month
            data.country = countryCode
        }
        if (page === "generalcategory") {
            if (subId === "all" && id === "all") {
                data.type = 'generalcategory';
                data.year = date.year;
                data.month = date.month
                data.country = countryCode
                data.gen_category_id = genId
                data.category_id = id
                data.sub_category_id = subId
            }
            if (id !== "all" && subId === "all") {
                data.type = 'category';
                data.year = date.year;
                data.month = date.month
                data.country = countryCode
                data.gen_category_id = genId
                data.category_id = id
                data.sub_category_id = subId
            }
            if (id !== "all" && subId !== "all") {
                data.type = 'subcategory';
                data.year = date.year;
                data.month = date.month
                data.country = countryCode
                data.gen_category_id = genId
                data.category_id = id
                data.sub_category_id = subId
            }
        }
        const res = await konnect.post(url, data);
        dispatch({
            type: GET_BANNER,
            payload: res.data?.data
        })
        return res.data.data
    } catch (err) {
        console.log("err", err.response)
    }
}

export const deleteBanner = (id) => async (dispatch) => {
    try {
        const res = await konnect.delete(`/v1/admin/banner/deleteBannerBooking/${id}`)
        return res
    } catch (err) {
        console.log(err.response)
    }
}

export const updateStatusBanner = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/banner/updateBookBanner`, data)
        return res.data
    } catch (err) {
        console.log("err", err.response.data)
        return err.response.data
    }
}
export const updateBanner = (type, data, brandId) => async (dispatch) => {
    try {
        let res;
        let url = `/v1/admin/banner/updateBookBanner`
        const formBody = new FormData();
        if (type === 1) {
            formBody.append('_id', data._id)
            if (typeof data.desktop !== "string") {
                formBody.append('desktop', data.desktop)
            }
            if (typeof data.mobile !== "string") {
                formBody.append('mobile', data.mobile)
            }
            if (typeof data.sm_desktop !== "string") {
                formBody.append('tab', data.sm_desktop)
            }
            if (typeof data.app !== "string") {
                formBody.append('shop', data.app)
            }
            if (data.logo) {
                if (typeof data.logo !== "string") {
                    formBody.append('logo', data.logo)
                }
            }
            formBody.append('brand_id', brandId)
            res = await konnect.post(url, formBody)
            return res.data
        }
        if (type === 3) {
            formBody.append('_id', data._id)
            if (typeof data.desktop !== "string") {
                formBody.append('desktop', data.desktop)
            }
            if (typeof data.mobile !== "string") {
                formBody.append('mobile', data.mobile)
            }
            if (typeof data.sm_desktop !== "string") {
                formBody.append('tab', data.sm_desktop)
            }
            if (typeof data.app !== "string") {
                formBody.append('shop', data.app)
            }
            if (data.logo) {
                if (typeof data.logo !== "string") {
                    formBody.append('logo', data.logo)
                }
            }
            formBody.append('title', data.title)
            formBody.append('description', data.description)
            formBody.append('brand_id', brandId)
            res = await konnect.post(url, formBody)
            return res.data
        }
        if (type === 2) {
            res = await konnect.post(url, { _id: data._id, brand_id: brandId })
            return res.data
        }
    } catch (err) {
        console.log("err", err.response.data)
        return err.response.data
    }
}

export const getBanners = (fields, info) => async (dispatch) => {
    try {
        let url = `/v1/admin/banner/getBannerBookingDetails`
        let data = {}
        if (fields.type === 'brand') {
            data = fields;
        } else if (fields.type === 'generalcategory') {
            if (info.catID === "all" && info.subID === "all") {
                data = {
                    ...fields,
                    type: 'generalcategory',
                    gen_category_id: info.genID,
                    category_id: info.catID,
                    sub_category_id: info.subID
                }
            }
            if (info.catID !== "all" && info.subID === "all") {
                data = {
                    ...fields,
                    type: 'category',
                    gen_category_id: info.genID,
                    category_id: info.catID,
                    sub_category_id: info.subID
                }
            }
            if (info.subID !== "all") {
                data = {
                    ...fields,
                    type: 'subcategory',
                    gen_category_id: info.genID,
                    category_id: info.catID,
                    sub_category_id: info.subID
                }
            }
        }
        const res = await konnect.post(url, data);
        dispatch({
            type: GET_BANNER,
            payload: res.data?.data
        })
        return res.data.data
    } catch (err) {
        console.log("err", err.response)
    }
}
export const addingBanner = (type, data) => async (dispatch) => {
    let res;
    const url = "/v1/admin/banner/bookBanner";
    const formBody = new FormData();
    if (type === 1) {
        formBody.append('desktop', data.desktop)
        formBody.append('mobile', data.mobile)
        formBody.append('tab', data.sm_desktop)
        formBody.append('shop', data.app)
        formBody.append('row', data.row)
        formBody.append('position', data.position)
        formBody.append('brand_id', data.brand_id)
        formBody.append('year', data.date.year)
        formBody.append('month', data.date.month)
        formBody.append('type', data.type)
        formBody.append('country', data.country)
        if (data.logo) formBody.append('logo', data.logo)
        if (data.category_id) formBody.append('category_id', data.category_id)
        res = await konnect.post(url, formBody)
        return res
    }
    if (type === "HPSP-B") {
        res = await konnect.post(url, data)
        return res
    }
    if (type === "new") {
        formBody.append('desktop', data.desktop)
        formBody.append('mobile', data.mobile)
        formBody.append('tab', data.sm_desktop)
        formBody.append('shop', data.app)
        formBody.append('row', data.row)
        formBody.append('position', data.position)
        formBody.append('brand_id', data.brand_id)
        formBody.append('title', data.title)
        formBody.append('description', data.description)
        formBody.append('year', data.date.year)
        formBody.append('month', data.date.month)
        formBody.append('type', data.type)
        formBody.append('country', data.country)
        if (data.logo) formBody.append('logo', data.logo)
        if (data.category_id) formBody.append('category_id', data.category_id)
        res = await konnect.post(url, formBody)
        return res
    }

}
export const updateMonthlyBanner = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/banner/updateCurrentYearAndMonthInBanner`, data)
        return res
    } catch (err) {
        console.log(err.response)
    }
}
function getBannerName(value, info) {
    let val = value.slice(-1);
    if (info.catID === "all" && info.subID === "all") {
        return `GCPSP-${val}`
    } else if (info.catID !== "all" && info.subID === "all") {
        return `CPSP-${val}`
    } else {
        return `SCPSP-${val}`
    }
}