import React, { useState, useEffect, useMemo } from "react";
import * as accountActions from "../../../store/actions/account"

import { connect } from "react-redux";
import { Row, Col, Button } from 'react-bootstrap';
import { Box, Card, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";

import * as pdfAction from "../../../store/actions/pdf.action"
import { toast } from 'react-toastify';
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PDFViewer, pdf, } from "@react-pdf/renderer";
import Agreement from "../../../assets/pdf/AgreementAgency"
import SaveModal from "../../../reUsable/SaveModal";



function AgreementAgency({ getAgencyDetail, agencyDetail, currentUser, generateAgencyPDF, nowGet }) {
    const [modal, setModal] = useState(false)
    const [fields, setFields] = useState({
        company_name: '',
        company: '',
        executed_by: '',
        website: '',
        fee: '0',
        date: moment().format('YYYY-MM-DD'),
    })
    const [view, setView] = useState(false)
    useEffect(() => {
        getAgencyDetail(currentUser)
    }, [])
    useEffect(() => {
        if (agencyDetail?.success) {
            setFields({
                ...fields,
                company_name: agencyDetail?.data?.company_name,
                company: agencyDetail?.data?.company,
                executed_by: agencyDetail?.data?.company_name,
                fee: agencyDetail?.data?.agreementDetails?.fee,
                website: agencyDetail?.data?.website_address,
                date: agencyDetail?.data?.agreementDetails ? agencyDetail?.data?.agreementDetails?.agreement_date ? agencyDetail?.data?.agreementDetails?.agreement_date : fields.date : fields.date
            })
        }
    }, [agencyDetail])
    async function generate() {
        const blob = await pdf(<Agreement data={fields} />).toBlob()
        const data = {
            user_id: currentUser,
            company_name: fields.company_name,
            executed_by: fields.executed_by,
            website_address: fields.website,
            company: fields.company,
            email: agencyDetail?.data?.email,
            blobData: blob,
            fee: fields.fee,
            date: moment(fields.date).format("YYYY-MM-DD")
        }
        generateAgencyPDF(data).then((res) => {
            if (res.success) {
                toast.success('Successfully submitted!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(res?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            getAgencyDetail(currentUser)
            nowGet()
            setModal(false)
        })

    }
    return (
        <div className='saleContainer'>
            <SaveModal
                visible={modal}
                title={agencyDetail?.data?.agreementDetails ? "Are you sure you want to re-submit this Agreement?" : `Are you sure you want to upload this Agreement?`}
                close={() => setModal(false)}
                type="status"
                call={() => generate()}
            />
            <Row className=''>
                <Col xl={5} lg={6}>
                    <Card variant="outlined" className='users-card-main' style={{ minHeight: 'initial' }} sx={{ minWidth: 275 }}>
                        <div className="agreementHeader">

                            <Typography className='title-bar-hd-main mb-1' color="primary" variant="h5" gutterBottom component="div">Agreement</Typography>

                            {agencyDetail?.data?.agreementDetails == null && <div className="agreementStatus"></div>}
                            {
                                agencyDetail?.data?.agreementDetails !== null &&
                                <div className="agreementStatus">
                                    {agencyDetail?.data?.agreement_status === "rejected" && <span style={{ color: "red" }}>Rejected</span>}
                                    {agencyDetail?.data?.agreement_status === "accepted" && <span style={{ color: "green" }}>Accepted</span>}
                                    {agencyDetail?.data?.agreement_status === "waiting" && <span >Waiting</span>}
                                </div>
                            }
                        </div>
                        <Divider className='mb-4' />

                        <TextField
                            value={fields.company_name}
                            onChange={(e) => { setFields({ ...fields, company_name: e.target.value }) }}
                            id="agencyName"
                            label="Agency Name"
                            type="text"
                            size="small"
                            className="field-style mb-3"
                            placeholder="Agency Name"
                            variant="outlined"
                        />
                        <TextField
                            value={fields.company}
                            onChange={(e) => { setFields({ ...fields, company: e.target.value }) }}
                            id="company"
                            label="Company"
                            type="text"
                            size="small"
                            className="field-style mb-3"
                            placeholder="Company"
                            variant="outlined"
                        />
                        <TextField
                            value={fields.website}
                            onChange={(e) => { setFields({ ...fields, website: e.target.value }) }}
                            id="website"
                            label="Website"
                            type="text"
                            size="small"
                            className="field-style mb-3"
                            placeholder="google.com"
                            variant="outlined"
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="Contract Date"
                                value={fields.date}
                                onChange={(newValue) => {
                                    setFields({ ...fields, date: newValue })
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style mb-3"
                                        size="small"
                                        style={{ backgroundColor: "white", }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <TextField
                            value={fields.executed_by}
                            onChange={(e) => { setFields({ ...fields, executed_by: e.target.value }) }}
                            id="playback_url"
                            label="Executed By"
                            type="text"
                            size="small"
                            className="field-style mb-4"
                            placeholder="Executed By"
                            variant="outlined"
                        />
                        <TextField
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                inputProps: {
                                    min: 1
                                }
                            }}
                            value={fields.fee}
                            onChange={(e) => { setFields({ ...fields, fee: e.target.value }) }}
                            id="website"
                            label="ORME FEE"
                            type="number"
                            size="small"
                            className="field-style mb-3"
                            placeholder="fee"
                            variant="outlined"
                        />
                        <Button
                            className="btn-width btn-gen-margin ml-0 h-100 webBTN"
                            disableElevation
                            size="medium"
                            variant="contained"
                            onClick={() => setView(true)}
                        >
                            View
                        </Button>

                        <Button
                            className="btn-width btn-gen-margin ml-0 h-100 webBTN"
                            disableElevation
                            size="medium"
                            variant="contained"
                            onClick={() => setModal(true)}
                        >
                            Submit
                        </Button>
                    </Card>
                </Col>
                {
                    view &&
                    <Col xl={7} lg={6}>
                        <Card variant="outlined" className='users-card-main' style={{ minHeight: 'initial' }} sx={{ minWidth: 275 }}>
                            <PDFViewer style={{ width: "100%", height: "80vh" }}>
                                <Agreement data={fields} />
                            </PDFViewer>
                        </Card>
                    </Col>
                }

            </Row>
        </div >
    )
}

const mapStateToProps = ({ accountDetail, agencyDetail }) => ({ accountDetail, agencyDetail })

export default connect(mapStateToProps, { ...pdfAction, ...accountActions })(AgreementAgency)
