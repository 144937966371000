import konnect from "./axios"
import { COMMENTS, BANUSERS } from "./type"


export const getComments = (data, page) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/chat/getAllChats`, data, {
            params: { page, limit: 20 }
        })
        dispatch({
            type: COMMENTS,
            payload: res?.data
        })
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
    }
}
export const deleteComment = (chat_id, type) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/chat/deleteChat`, { chat_id, type })
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
    }
}
export const banUser = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/chat/banUser`, data)
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
    }
}
export const unbanUser = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/chat/unbanUser`, data)
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
        return err.response.data
    }
}
export const getBanUsers = (data, page) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/chat/getBanUserList`, data, {
            params: { page, limit: 20 }
        })
        dispatch({
            type: BANUSERS,
            payload: res?.data
        })
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
    }
}