import { Box, Button, Divider, FormControlLabel, InputAdornment, Switch, TextField, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save';
import * as feedAction from "../../store/actions/feedAction"
import * as contentAction from "../../store/actions/contentManage.action"
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import SaveModal from "../../reUsable/SaveModal"
export const Moderation = ({ getModerationLevel, setModerationLevel }) => {
    const [load, setLoad] = useState(true)
    const [fields, setFields] = useState({
        nudity: 0,
        violence: 0,
        hate_speech: 0,
        alcohol: 0,
        gambling: 0,
        suggestive: 0,
        visually_disturbing: 0,
        rude_gestures: 0,
        drugs: 0,
        tobacco: 0,
        content_moderation: false,
        adult: 0
    })
    const [initial, setInitial] = useState({
        nudity: 0,
        violence: 0,
        hate_speech: 0,
        alcohol: 0,
        gambling: 0,
        suggestive: 0,
        visually_disturbing: 0,
        rude_gestures: 0,
        drugs: 0,
        tobacco: 0,
        content_moderation: false,
        adult: 0
    })
    const [modal, setModal] = useState(false)
    useEffect(() => {
        getData()
    }, [])

    function submit() {
        setModerationLevel(fields).then((res) => {
            if (res.success) {
                toast.success(`${res?.message}`)
            } else {
                toast.error(`${res?.message}`)
            }
            setModal(false)
            getData()
        })
    }

    function getData() {
        setLoad(true)
        getModerationLevel().then((res) => {
            if (res.success) {
                setInitial({
                    alcohol: res?.data.alcohol,
                    gambling: res?.data.gambling,
                    hate_speech: res?.data.hate_speech,
                    nudity: res?.data.nudity,
                    violence: res?.data.violence,
                    suggestive: res?.data.suggestive,
                    visually_disturbing: res?.data.visually_disturbing,
                    rude_gestures: res?.data.rude_gestures,
                    drugs: res?.data.drugs,
                    tobacco: res?.data.tobacco,
                    content_moderation: res?.data?.content_moderation ? res?.data?.content_moderation : false,
                    adult: res?.data?.adult ? res?.data?.adult : 0
                })
                setFields({
                    alcohol: res?.data.alcohol,
                    gambling: res?.data.gambling,
                    hate_speech: res?.data.hate_speech,
                    nudity: res?.data.nudity,
                    violence: res?.data.violence,
                    suggestive: res?.data.suggestive,
                    visually_disturbing: res?.data.visually_disturbing,
                    rude_gestures: res?.data.rude_gestures,
                    drugs: res?.data.drugs,
                    tobacco: res?.data.tobacco,
                    content_moderation: res?.data?.content_moderation ? res?.data?.content_moderation : false,
                    adult: res?.data?.adult ? res?.data?.adult : 0

                })
            } else {
                clean()
            }
        }).finally(() => setLoad(false))

    }

    function addValues(name, val) {
        let str = String(Number(val))
        if (val <= 100 && val >= 0) {
            setFields({ ...fields, [name]: str === "" ? 0 : str })
        }
    }

    function clean() {
        setFields(initial)
    }
    return (
        <div className="container-fluid mb-3">
            <SaveModal
                visible={modal}
                title={`Are you sure you want to save these changes?`}
                close={() => setModal(false)}
                type="status"
                call={() => submit()}
            />
            <Row className='pt-3'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Moderation Level
                    </Typography>
                </Col>
            </Row>
            <Divider className="mb-15" />
            <Row className="d-flex justify-content-between">
                <Col xl={5} lg={6} md={12} xs={12}>
                    <div className='infoSection new mb-3' style={{ background: "#FFF" }} >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Box className="addons-cart-title d-flex align-items-center">
                                <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                    Content Moderation Settings
                                </Typography>
                            </Box>
                            <div>
                                <span style={{ color: "#052977" }}>AI Moderation</span>
                                <Switch
                                    checked={fields.content_moderation}
                                    color="primary"
                                    onChange={(e, checked) => {
                                        setFields({ ...fields, content_moderation: checked })
                                    }} />
                            </div>
                        </div>
                        <Divider className='mb-20' />
                        <Row>
                            <Col xs={8}>
                                <TextField
                                    focused={false}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Nudity "}
                                />
                            </Col>
                            <Col xs={4}>
                                <TextField
                                    disabled={!fields.content_moderation}
                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='nudity'
                                    value={fields.nudity}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addValues(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <TextField
                                    focused={false}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Violence"}
                                />
                            </Col>
                            <Col xs={4}>
                                <TextField
                                    disabled={!fields.content_moderation}

                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='violence'
                                    value={fields.violence}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addValues(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <TextField
                                    focused={false}

                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Hate Speech"}
                                />
                            </Col>
                            <Col xs={4}>
                                <TextField
                                    disabled={!fields.content_moderation}

                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='hate_speech'
                                    value={fields.hate_speech}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addValues(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <TextField
                                    focused={false}

                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Alcohol"}
                                />
                            </Col>
                            <Col xs={4}>
                                <TextField
                                    disabled={!fields.content_moderation}

                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='alcohol'
                                    value={fields.alcohol}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addValues(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <TextField
                                    focused={false}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Gambling"}
                                />
                            </Col>
                            <Col xs={4}>
                                <TextField
                                    disabled={!fields.content_moderation}

                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='gambling'
                                    value={fields.gambling}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addValues(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <TextField
                                    focused={false}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Suggestive"}
                                />
                            </Col>
                            <Col xs={4}>
                                <TextField
                                    disabled={!fields.content_moderation}

                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='suggestive'
                                    value={fields.suggestive}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addValues(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <TextField
                                    focused={false}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Visually Disturbing"}
                                />
                            </Col>
                            <Col xs={4}>
                                <TextField
                                    disabled={!fields.content_moderation}

                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='visually_disturbing'
                                    value={fields.visually_disturbing}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addValues(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <TextField
                                    focused={false}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Rude Gestures"}
                                />
                            </Col>
                            <Col xs={4}>
                                <TextField
                                    disabled={!fields.content_moderation}

                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='rude_gestures'
                                    value={fields.rude_gestures}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addValues(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <TextField
                                    focused={false}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Drugs"}
                                />
                            </Col>
                            <Col xs={4}>
                                <TextField
                                    disabled={!fields.content_moderation}

                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='drugs'
                                    value={fields.drugs}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addValues(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <TextField
                                    focused={false}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Tobacco"}
                                />
                            </Col>
                            <Col xs={4}>
                                <TextField
                                    disabled={!fields.content_moderation}

                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='tobacco'
                                    value={fields.tobacco}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addValues(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <TextField
                                    focused={false}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Adult"}
                                />
                            </Col>
                            <Col xs={4}>
                                <TextField
                                    disabled={!fields.content_moderation}

                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='adult'
                                    value={fields.adult}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addValues(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Divider className='mb-3' />
                        <Row>
                            <Col className='text-right mb-2'>
                                <Button
                                    className="btn-width btn-gen-margin  mr-0 h-100"
                                    disableElevation
                                    size="medium"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    // onClick={submit}
                                    onClick={() => {
                                        setModal(true)
                                    }}
                                    disabled={load}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>

        </div>

    )
}

const mapStateToProps = ({ }) => ({})
export default connect(mapStateToProps, contentAction)(Moderation)