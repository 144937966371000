import { data } from "jquery"
import konnect from "./axios"
import { SHOPIFY_CATEGORY } from "./type"

export const getShopifyCategory = (data) => async (dispatch) => {
    let url = "/v1/admin/crm/userCategoriesDetail"
    try {
        const res = await konnect.post(url, data)
        dispatch({ type: SHOPIFY_CATEGORY, payload: res?.data?.data })

    } catch (err) {
        console.log(err.response)
    }
}

