import React, { useEffect, useState } from 'react'
// import { LicenseInfo } from '@mui/x-data-grid-pro';


import * as salesActions from "../../../store/actions/sales"
// import * as accountActions from "../../../store/actions/account"
import { Table, Modal } from "react-bootstrap";
import { connect } from 'react-redux'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import DesktopDateRangePicker from '@mui/lab/DesktopDateRangePicker';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DateAdapter from '@mui/lab/AdapterDateFns';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import numeral from "numeral";
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Divider } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import NoRows from "../../../reUsable/NoRows"
import Skeleton from '../../../reUsable/skeleton';
import Box from '@mui/material/Box';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO } from 'date-fns';

import { CSVLink } from 'react-csv';
import CSVButton from "../../../reUsable/CSVButton"

import "./sale.css"

function Sales({ currentUser, sales, getUserSales, userType }) {
    const fileName = `${userType}_record`;
    const [value, setValue] = useState([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [loading, setLoading] = useState(false)
    const [groupBy, setGroupBy] = useState('summary')
    const [search, setSearch] = useState(false)
    const [page, setPage] = useState(1);
    const [date, setDate] = useState(null)
    const [test, setTest] = useState(null)
    const [fields, setFields] = useState({
        orderBy: "-1",
        sort: "total_sale"
    })
    const [header, setHeader] = useState([])
    const [data, setData] = useState([])
    const [load, setLoad] = useState(true)
    // var userType = accountDetail?.userDetails?.account_type;

    useEffect(() => {
        setLoading(true)
        getUserSales(currentUser, userType, groupBy, page, value, fields).then((res) => {
            setLoading(false)
            getData(res.total_records)
        })
    }, [])

    useEffect(() => {
        if (search || date) {
            setLoading(true)
            getUserSales(currentUser, userType, groupBy, page, value, fields).then((res) => {
                setSearch(false)
                setDate(false)
                setTest(groupBy)
                setLoading(false)
                getData(res.total_records)
            })
        }
    }, [search, date]);
    const getData = (count) => {
        setLoad(true);
        getUserSales(currentUser, userType, groupBy, page, value, fields, count).then((res) => {
            setData(res.data.map((m, i) => ({
                ...m,
                id: i + 1,
                campaign_name: m.campaign_name ? m.campaign_name : "BioShop",
                order_totalprice: numeral(m?.order_totalprice).format("$0,0.00", Math.floor),
                kb_commission: numeral(m?.kb_commission).format("$0,0.00", Math.floor),
                influencer_commission: numeral(m?.influencer_commission).format("$0,0.00", Math.floor),
                customer_commission: numeral(m?.customer_commission).format("$0,0.00", Math.floor),
                discount: numeral(m?.discount).format("$0,0.00", Math.floor),
                total_sale: numeral(m?.total_sale).format("$0,0.00", Math.floor),
            })))
            setLoad(false)
        })
    }
    /////////
    let summaryColCustomer = [
        {
            field: 'id', headerName: 'S.#', width: 80, sortable: false,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
        },
        { field: "name", headerName: "Name", width: 200, sortable: false, },
        { field: "brand_name", headerName: "Brand", width: 200, sortable: false, },
        { field: "influencer_name", headerName: "Influencer", width: 200, sortable: false, },
        { field: "total_qty", headerName: "Quantity", width: 130, sortable: false, },
        { field: "total_sale", headerName: "Gross Sales", width: 130, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "discount", headerName: "Discount", width: 130, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'order_totalprice', headerName: "Net Sales", width: 130, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'customer_commission', headerName: "Customer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    ]
    let customerCol = [
        {
            field: 'id', headerName: 'S.#', width: 80, sortable: false,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
        },
        { field: "brand_name", headerName: "Brand", width: 200, sortable: false, },
        { field: "influencer_name", headerName: "Influencer", width: 200, sortable: false, },
        { field: "order_id", headerName: "Order No", width: 130, sortable: false, },
        { field: "promo", headerName: "Promo", width: 130, sortable: false, },
        { field: "sale_type", headerName: "Sale Type", width: 130, sortable: false, },
        { field: "total_qty", headerName: "Quantity", width: 130, sortable: false, },
        { field: "total_sale", headerName: "Gross Sales", width: 130, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "discount", headerName: "Discount", width: 130, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'order_totalprice', headerName: "Net Sales", width: 130, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'customer_commission', headerName: "Customer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    ]
    let dateColCustomer = [
        {
            field: 'id', headerName: 'S.#', width: 80, sortable: false,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
        },
        { field: "created_date", headerName: "Date", width: 150, sortable: false, },
        { field: "total_qty", headerName: "Quantity", width: 120, sortable: false, },
        { field: "total_sale", headerName: "Gross Sales", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'order_totalprice', headerName: "Net Sales", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'customer_commission', headerName: "Customer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    ]
    let brandCol = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
        },

        { field: "brand_name", headerName: "Brand", width: 180, sortable: false, },
        { field: "total_qty", headerName: "Qty", width: 70, sortable: false, },
        { field: "total_sale", headerName: "Gross Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'order_totalprice', headerName: "Net Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'customer_commission', headerName: "Customer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    ]
    let influencerCol = [
        {
            field: 'id', headerName: 'S.#', width: 80,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            }, sortable: false,
        },
        { field: "influencer_name", headerName: "Influencer", width: 200, sortable: false, },
        { field: "total_sale", headerName: "Gross Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "discount", headerName: "Discount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'order_totalprice', headerName: "Net Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'customer_commission', headerName: "Customer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    ]
    let campaignCol = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
        },
        { field: "campaign_name", headerName: "Campaign", width: 200, renderCell: (params) => params?.row?.campaign_name ? params?.row?.campaign_name : "BioShop", sortable: false, },
        { field: "total_qty", headerName: "Qty", width: 80, sortable: false, },
        { field: "total_sale", headerName: "Gross Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'order_totalprice', headerName: "Net Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: 'customer_commission', headerName: "Customer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    ]

    useEffect(() => {
        if (groupBy === "summary") {
            setHeader(summaryColCustomer.map(m => ({ label: m.headerName, key: m.field })))
        } else if (groupBy === "date") {
            setHeader(dateColCustomer.map(m => ({ label: m.headerName, key: m.field })))
        } else if (groupBy === "campaign") {
            setHeader(campaignCol.map(m => ({ label: m.headerName, key: m.field })))
        } else if (groupBy === "customer") {
            setHeader(customerCol.map(m => ({ label: m.headerName, key: m.field })))
        } else if (groupBy === "brand") {
            setHeader(brandCol.map(m => ({ label: m.headerName, key: m.field })))
        } else {
            setHeader(influencerCol.map(m => ({ label: m.headerName, key: m.field })))
        }

    }, [groupBy])

    function renderColumn() {
        switch (test) {
            case "date":
                return dateColCustomer
            case "campaign":
                return campaignCol
            case "brand":
                return brandCol
            case "customer":
                return customerCol
            case "influencer":
                return influencerCol
            case "summary":
                return summaryColCustomer
            default:
                return summaryColCustomer
        }

    }
    const handleSearch = (type) => {
        if (type === "refresh") {
            setGroupBy('summary')
        }
        setSearch(true)
    }
    const handleSelect = (e) => {
        setGroupBy(e.target.value)
    }
    function renderField() {
        switch (groupBy) {
            case "date": return dateColCustomer.map(m => {
                if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
            })
            case "campaign": return campaignCol.map(m => {
                if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
            })
            case "summary": return summaryColCustomer.map(m => {
                if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
            })
            case "brand": return brandCol.map(m => {
                if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
            })
            case "customer": return customerCol.map(m => {
                if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
            })
            case "influencer": return influencerCol.map(m => {
                if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
            })
            default: return summaryColCustomer.map(m => {
                if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
            })
        }
    }
    return (
        <div className='saleContainer'>
            <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                Commission
            </Typography>
            <div className='body'>
                <div className='buttons-sales'>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn-gen-margin btn-width ml-0 mb-2"
                        color="primary"
                        disabled={value[0] === moment().format('YYYY-MM-DD')}
                        disableElevation
                        onClick={() => {
                            let startDate = moment().format('YYYY-MM-DD')
                            setValue([startDate, startDate])
                            setDate(true);
                        }}
                    >
                        Today
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn-gen-margin btn-width ml-2 mb-2"
                        color="primary"
                        disableElevation
                        disabled={value[0] === moment().startOf('month').format('YYYY-MM-DD')}
                        onClick={() => {
                            let startDate = moment().startOf('month').format("YYYY-MM-DD")
                            setValue([startDate, moment().format('YYYY-MM-DD')])
                            setDate(true);
                        }}
                    >
                        MTD
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn-gen-margin btn-width ml-2 mb-2"
                        color="primary"
                        disabled={value[0] === moment().startOf('year').format('YYYY-MM-DD')}
                        disableElevation
                        onClick={() => {
                            let startDate = moment().startOf('year').format("YYYY-MM-DD")
                            setValue([startDate, moment().format('YYYY-MM-DD')])
                            setDate(true);
                        }}
                    >
                        YTD
                    </Button>
                </div>
                <Row className='align-items-center'>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Group By</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                defaultValue={groupBy}
                                placeholder="group by"
                                labelId="groupBy"
                                id="groupBy"
                                value={groupBy}
                                label="Group By"
                                onChange={(e) => handleSelect(e)}
                            >
                                <MenuItem value={"summary"}>Summary</MenuItem>
                                <MenuItem value={"customer"}>Detail</MenuItem>
                                <MenuItem value={"date"}>Date</MenuItem>
                                <MenuItem value={"campaign"}>Campaign</MenuItem>
                                <MenuItem value={"brand"}>Brand</MenuItem> :
                                <MenuItem value={"influencer"}>Influencer</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className='dateBox'>
                                <DatePicker
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format('YYYY-MM-DD'), value[1]]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format('YYYY-MM-DD')]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                </Row>
                <Row>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Field</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Field"
                                labelId="field"
                                id="field"
                                value={fields.sort}
                                label="Field"
                                onChange={(e) => setFields({ ...fields, sort: e.target.value })}
                            >
                                {renderField()}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Order By</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="order by"
                                labelId="orderBy"
                                id="orderBy"
                                value={fields.orderBy}
                                label="Order By"
                                onChange={(e) => setFields({ ...fields, orderBy: e.target.value })}
                            >
                                <MenuItem value={"1"}>Ascending</MenuItem>
                                <MenuItem value={"-1"}>Descending</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={5} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => { handleSearch() }}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('refresh') }}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
                <Divider className="separater-sale" />
                <div style={{ height: 460, width: '100%' }}>
                    {
                        loading ? <Skeleton /> :
                            <>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                                    <CSVLink
                                        headers={header}
                                        data={data}
                                        asyncOnClick={true}
                                        className="btn-width h-100 custom-btn"
                                        onClick={() => { }}
                                        filename={fileName}
                                    >
                                        <CSVButton load={load} />
                                    </CSVLink>
                                </div>
                                <DataGrid
                                    density='compact'
                                    components={{ NoRowsOverlay: NoRows, }}
                                    disableSelectionOnClick

                                    // sx={{ marginTop: 2 }}
                                    className="bg-white"
                                    rows={
                                        Object.keys(sales).length > 0 ? sales?.data?.map((single, index) => ({
                                            ...single,
                                            id: index + (page == 1 ? 1 : (page * 10) - 9),
                                        })) : []
                                    }
                                    columns={renderColumn()}
                                    rowCount={Object.keys(sales).length > 0 ? sales?.total_records : 0}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    paginationMode="server"
                                    onPageChange={(newPage) => {
                                        setPage(newPage + 1);
                                        getUserSales(currentUser, userType, groupBy, newPage + 1, value, fields).then(() => {
                                            setLoading(false)
                                            setTest(groupBy)
                                        })
                                    }}
                                />
                            </>

                    }
                </div>
            </div>
        </div >
    )
}

function mapStateToProps({ sales, accountDetail }) {
    return { sales, accountDetail }
}
export default connect(mapStateToProps, salesActions)(Sales)
