import React from 'react'
import numeral from "numeral";
import { useEffect } from 'react';

export default function Summary({ data, load, type }) {
    return (
        <>
            <div className='sumContainer-height-235'>
                <div className='sumContainer'>
                    <div className='sumTitle'>Summary</div>
                    <div className='sumBody'>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Orders</div>
                            <div className='sumBodyvalue'>{load ? "..." : data?.order_count ? data.order_count : 0}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Amount</div>
                            <div className='sumBodyvalue'>{load ? "..." : numeral(data?.order_totalprice).format("$0,0.00", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Return Amount</div>
                            <div className='sumBodyvalue'>{load ? "..." : numeral(data?.returned_amount).format("$0,0.00", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Balance Amount</div>
                            <div className='sumBodyvalue'>{load ? "..." : numeral(data?.balance_amount).format("$0,0.00", Math.floor)}</div>
                        </div>
                        {
                            type === "purchase" &&
                            <>
                                <div className='sumBodyRow'>
                                    <div className='sumBodyTitle'>Cashback%</div>
                                    <div className='sumBodyvalue'>{load ? "..." : numeral(data?.cashback_amount / data?.order_totalprice).format('0%')}</div>
                                </div>
                                <div className='sumBodyRow'>
                                    <div className='sumBodyTitle'>Cashback Amount</div>
                                    <div className='sumBodyvalue'>{load ? "..." : numeral(data?.cashback_amount).format("$0,0.00", Math.floor)}</div>
                                </div>
                            </>
                        }
                        {
                            type === "creator" &&
                            <>
                                <div className='sumBodyRow'>
                                    <div className='sumBodyTitle'>Fee%</div>
                                    <div className='sumBodyvalue'>{load ? "..." : numeral(data?.creator_commission / data?.order_totalprice).format('0%')}</div>
                                </div>
                                <div className='sumBodyRow'>
                                    <div className='sumBodyTitle'>Fee Earned</div>
                                    <div className='sumBodyvalue'>{load ? "..." : numeral(data?.creator_commission).format("$0,0.00", Math.floor)}</div>
                                </div>
                            </>
                        }
                        {
                            type === "referral" &&
                            <>
                                <div className='sumBodyRow'>
                                    <div className='sumBodyTitle'>Fee%</div>
                                    <div className='sumBodyvalue'>{load ? "..." : numeral(data?.customer_commission / data?.order_totalprice).format('0%')}</div>
                                </div>
                                <div className='sumBodyRow'>
                                    <div className='sumBodyTitle'>Fee Earned</div>
                                    <div className='sumBodyvalue'>{load ? "..." : numeral(data?.customer_commission).format("$0,0.00", Math.floor)}</div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
