import React, { useEffect, useMemo, useState } from 'react'
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { Row, Col } from 'react-bootstrap';
import * as shopifyActions from "../../../store/actions/shopifyCategory"
import { connect } from 'react-redux'
import Card from '@mui/material/Card';
import { Chip, Skeleton, Stack } from '@mui/material';


function CategorySetup({ currentUser, getShopifyCategory, shopifyCategory }) {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getShopifyCategory({ _id: currentUser }).then(() => { setLoading(false) })
    }, [currentUser])

    const data = useMemo(() => {
        if (Object.keys(shopifyCategory).length > 0) {
            return shopifyCategory
        } else {
            return null
        }
    }, [shopifyCategory,])
    return (
        <div className='saleContainer'>
            <Row className='align-items-center'>
                <Col xl={5} lg={6}>
                    <Card variant="outlined" className='users-card-main' style={{ minHeight: 'initial' }} sx={{ minWidth: 275 }}>
                        <Typography className='title-bar-hd-main mb-1' color="primary" variant="h5" gutterBottom component="div">
                            Category Setup
                        </Typography>
                        <Divider className='mb-4' />
                        <div className='mb-3' style={{ fontWeight: "500", marginTop: "15px" }}>
                            Departments
                        </div>
                        {
                            loading ? <Skeleton variant="rounded" width={'100%'} height={32} /> :
                                data?.gen_categories?.length > 0 ?
                                    <div className='stackDiv'>
                                        {data?.gen_categories?.map(item => <Chip key={item._id} label={item.name} />)}
                                    </div> :
                                    <div className='stackDiv'>
                                        <Chip label="No Data" />
                                    </div>
                        }
                        <Divider className='mt-2' />
                        <div className='mb-3' style={{ fontWeight: "500", marginTop: "15px" }}>
                            Categories
                        </div>
                        {
                            loading ? <Skeleton variant="rounded" width={'100%'} height={32} /> :
                                data?.categories?.length > 0 ?
                                    <div className='stackDiv'>
                                        {data?.categories?.map(item => <Chip key={item._id} label={`${item.gen_category.name[0]}-${item.category_name}`} />)}
                                    </div> :
                                    <div className='stackDiv'>
                                        <Chip label="No Data" />
                                    </div>
                        }
                        <Divider className='mt-2' />
                        <div className='mb-3' style={{ fontWeight: "500", marginTop: "15px" }}>
                            Sub Categories
                        </div>
                        {
                            loading ? <Skeleton variant="rounded" width={'100%'} height={32} /> :
                                data?.sub_categories?.length > 0 ?
                                    <div className='stackDiv'>
                                        {data?.sub_categories?.map(item => <Chip key={item._id} label={`${item.gen_category.name[0]}-${item.sub_category_name}`} />)}
                                    </div> :
                                    <div className='stackDiv'>
                                        <Chip label="No Data" />
                                    </div>
                        }


                    </Card>
                </Col>
            </Row>
        </div >
    )
}

function mapStateToProps({ shopifyCategory, }) {
    return { shopifyCategory, }
}
export default connect(mapStateToProps, { ...shopifyActions, })(CategorySetup)