import konnect from "./axios"
import { HELP } from "./type"

export const getHelp = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/help/getHelpsData`, data)
        // dispatch({
        //     type: HELP,
        //     payload: res?.data?.data
        // })
        return res?.data
    } catch (err) {
        return err?.response
    }
}
export const update = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/help/saveAndUpdate`, data)
        return res?.data
    } catch (err) {
        return err?.response
    }
}