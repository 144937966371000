import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import { Button, Divider } from "@mui/material";
import TextField from "@mui/material/TextField";
import DataTable from "./table";
import SkeletonLoader from "reUsable/skeleton";
import * as notificationAct from "../../store/actions/notificationAct";
import { connect } from "react-redux";
import NotFound from "reUsable/NotFound";

const Notification = ({
  getNotifications,
  createNotifications,
  notificationRed,
}) => {
  const [notificationData, setNotificationData] = useState({
    title: "",
    body: "",
  });
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  function btnsFunc() {
    return (
      <Col xs={12} md={6} xl={4} className="filter-clear-col mb-20">
        <Button
          className="btn-width btn-gen-margin ml-0 h-100"
          disableElevation
          size="medium"
          variant="contained"
          // disabled={true}
          onClick={() => {
            (notificationData.title !== "" && notificationData.body !== "") &&
              createNotifications(notificationData).then((t) => {
                setNotificationData({
                  title: "",
                  body: "",
                });
                setLoading(true);
                getNotifications(1).then(() => setLoading(false));
              });
          }}
        >
          Create
        </Button>
        <Button
          className="btn-width btn-gen-margin ml-0 grey-btn-outline h-100"
          disableElevation
          size="medium"
          variant="outlined"
          sx={{ color: 'gray' }}
          onClick={() =>
            setNotificationData({
              title: "",
              body: "",
            })
          }
        >
          Cancel
        </Button>
      </Col>
    );
  }

  useEffect(() => {
    setLoading(true);
    getNotifications(1).then(() => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      <div className="container-fluid mt-3 mb-3 pl-0">
        <Row className="pl-15">
          <Col xs className="mb-3 d-flex align-items-center">
            <Typography
              className="title-bar-hd-main"
              color="primary"
              variant="h5"
              gutterBottom
              component="div"
            >
              Notifications
            </Typography>
          </Col>
        </Row>
        <Row className="account-main-row pl-15">
          <Col xs={12} md={12} lg={8} xl={12} className="mb-20">
            <TextField
              size="small"
              value={notificationData.title}
              onChange={(e) =>
                setNotificationData({
                  ...notificationData,
                  title: e.target.value,
                })
              }
              id="outlined-basic"
              label="Title"
              variant="outlined"
              className="field-style"
            />
          </Col>
          <Col xs={12} md={12} lg={8} xl={12} className="mb-20">
            <TextField
              size="small"
              value={notificationData.body}
              onChange={(e) =>
                setNotificationData({
                  ...notificationData,
                  body: e.target.value,
                })
              }
              id="outlined-basic"
              label="Description"
              variant="outlined"
              className="field-style"
              multiline
              rows={4}
            />
          </Col>
          {btnsFunc()}
        </Row>
        <Divider className="ml-15" />
        {loading ? (
          <div className="pl-15" style={{ marginTop: 16 }}>
            <SkeletonLoader />
          </div>
        ) : notificationRed?.data?.length > 0 ? (
          <DataTable
            tableLoading={tableLoading}
            nowGet={(page) => getNotifications(page)}
            notificationRed={notificationRed}
          />
        ) : (
          <NotFound />
        )}
        {/* <div className="" style={{ backgroundColor: "red", height: "650px",marginLeft:"15px" }} /> */}
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = ({ notificationRed }) => {
  return { notificationRed };
};

export default connect(mapStateToProps, notificationAct)(Notification);
