import React, { useEffect, useState } from 'react'
import * as userSubActions from "../../../store/actions/subscriptions"
import * as kbAction from "../../../store/actions/kb"
import * as refundAction from "../../../store/actions/refund"

import { Col, Row } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, Divider, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NotFound from 'reUsable/NotFound';
import SkeletonLoader from "reUsable/skeleton";
import moment from 'moment';
import numeral from "numeral";
import NoRows from "../../../reUsable/NoRows";
import Tooltip from '@mui/material/Tooltip';
import { parseISO } from 'date-fns';
import { RiDeleteBinLine, RiFileTextLine, RiCloseCircleLine } from "react-icons/ri";
import DeleteModal from "../../../reUsable/DeleteModal";
import RefundModal from "./RefundModal"

function UserSubscription({ currentUser, userType, getKBEarning, kbEarning, cancelSubscription }) {
  const [value, setValue] = useState([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
  const [clear, setClear] = useState(false)
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [modalState, setModalState] = useState(false)
  const [date, setDate] = useState(null)
  const [data, setData] = useState({});
  const [subID, setSubID] = useState('');

  useEffect(() => {
    setClear(true)
    setLoading(true)
    let body = {
      startDate: value[0],
      endDate: value[1],
      user_id: currentUser,
      account_type: userType
    }
    getKBEarning(body, page).then(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (date) {
      let body = {
        startDate: value[0],
        endDate: value[1],
        user_id: currentUser,
        account_type: userType
      }
      setLoading(true)
      getKBEarning(body, page).then((res) => {
        setDate(false)
        setLoading(false)
      })
    }
  }, [date]);

  const col = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "S.no",
      maxWidth: 60,
      flex: 0.5,
      renderCell: ({ value }) => {
        return (
          <span className="d-flex justify-content-center w-100">{value}</span>
        );
      },
      filterable: false,
    },
    {
      field: 'transaction_id',
      headerClassName: 'super-app-theme--header',
      headerName: "Transaction Id",
      flex: 1,
      minWidth: 180,
      maxWidth: 300,
      filterable: false,
    },

    {
      field: 'transaction_date',
      headerClassName: 'super-app-theme--header',
      headerName: "Transaction Date",
      flex: 1,
      minWidth: 100,
      maxWidth: 180,
      renderCell: (params) => {
        return <span className='mb-0' >{moment(params.value).format("lll")}</span>
      },
      filterable: false,
    },
    {
      field: 'name',
      headerClassName: 'super-app-theme--header',
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
      renderCell: ({ value }) => value === "" ? "-" : value,
      filterable: false
    },
    {
      field: 'description',
      headerClassName: 'super-app-theme--header',
      headerName: "Description",
      flex: 1,
      minWidth: 200,
      maxWidth: 260,
      renderCell: ({ value }) => {
        let newVal = value
        if (newVal.length > 35) {
          newVal = value.substring(0, 35) + '...'
        }
        return value === "" ? "-" : <Tooltip title={value} arrow ><span>{newVal}</span></Tooltip>

      },
      filterable: false,
    },
    {
      field: 'amount',
      headerClassName: 'super-app-theme--header',
      headerName: "Amount",
      flex: 1,
      minWidth: 80,
      maxWidth: 120,
      renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
      filterable: false,
    },
    {
      field: 'is_cancelled',
      headerClassName: 'super-app-theme--header',
      headerName: "Action",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => {
        return params.row.is_cancelled ?
          <Button
            className='btn-gen-margin'
            variant="contained"
            style={{ color: "primary", borderColor: "primary" }}
            disableElevation
            onClick={() => {
              setSubID(params.row.transaction_id)
              setModalState(true)
            }}
          >
            <RiFileTextLine color={'white'} size={15} />
          </Button> :
          <>
            <Button
              className='btn-gen-margin'
              variant="contained"
              color='error'
              disableElevation
              onClick={() => {
                setVisible(true)
                setData(params.row)
              }}
            >
              <RiCloseCircleLine
                color={'white'}
                size={15}
              />
            </Button>
            <Button
              className='btn-gen-margin'
              variant="contained"
              style={{ color: "primary", borderColor: "primary" }}
              disableElevation
              onClick={() => {
                setSubID(params.row.transaction_id)
                setModalState(true)
              }}
            >
              <RiFileTextLine color={'white'} size={15} />
            </Button>
          </>
      },
      filterable: false, sortable: false
    },
  ]
  function renderClear() {
    return (
      <Col xs={12} md={6} xl={3} className="filter-clear-col mb-20">
        <Button
          className="btn-width btn-gen-margin ml-0 h-100"
          disableElevation
          size="medium"
          variant="contained"
          startIcon={<SearchIcon />}
          onClick={() => {
            searching()
          }}
        >
          Search
        </Button>
        <Button
          className="btn-width btn-gen-margin grey-btn-outline h-100"
          variant="outlined"
          sx={{ color: 'gray' }}
          startIcon={<ClearIcon />}
          onClick={() => {
            setValue([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
            setClear(false)
          }}
        >
          Clear
        </Button>
      </Col>
    )

  }
  const searching = () => {
    setClear(true)
    setLoading(true)
    let body = {
      startDate: value[0],
      endDate: value[1],
      user_id: currentUser,
      account_type: userType
    }
    getKBEarning(body, page).then(() => setLoading(false))
  }
  const onDelete = () => {
    let obj = {
      userId: data.user_id,
      subscriptionId: data.transaction_id
    }
    let body = {
      startDate: value[0],
      endDate: value[1],
      user_id: currentUser,
      account_type: userType
    }
    cancelSubscription(obj).then((res) => {
      console.log("res", res)
      getKBEarning(body, 1).then(() => setLoading(false))
    }).catch(err => {
      console.log("err", err)
      getKBEarning(body, 1).then(() => setLoading(false))
    })
    setVisible(false)
  }
  return (
    <div className="saleContainer">
      <RefundModal
        visible={modalState}
        title="Detail"
        close={() => {
          setSubID('')
          setModalState(false)
        }}
        id={subID}
      />
      <DeleteModal
        visible={visible}
        title="Are you sure you want to unsubscribe?"
        close={() => setVisible(false)}
        call={onDelete}
      />
      <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
        Subscription
      </Typography>
      <Row>
        <Col xl={12}>
          <div className='buttons-sales'>
            <Button
              variant="contained"
              size="small"
              className="btn-gen-margin btn-width ml-0 mb-2"
              color="primary"
              disabled={value[0] === moment().format('YYYY-MM-DD')}
              disableElevation
              onClick={() => {
                let startDate = moment().format('YYYY-MM-DD')
                setValue([startDate, startDate])
                setDate(true);
              }}
            >
              Today
            </Button>
            <Button
              variant="contained"
              size="small"
              className="btn-gen-margin btn-width ml-2 mb-2"
              color="primary"
              disableElevation
              disabled={value[0] === moment().startOf('month').format('YYYY-MM-DD')}
              onClick={() => {
                let startDate = moment().startOf('month').format("YYYY-MM-DD")
                setValue([startDate, moment().format('YYYY-MM-DD')])
                setDate(true);
              }}
            >
              MTD
            </Button>
            <Button
              variant="contained"
              size="small"
              className="btn-gen-margin btn-width ml-2 mb-2"
              color="primary"
              disabled={value[0] === moment().startOf('year').format('YYYY-MM-DD')}
              disableElevation
              onClick={() => {
                let startDate = moment().startOf('year').format("YYYY-MM-DD")
                setValue([startDate, moment().format('YYYY-MM-DD')])
                setDate(true);
              }}
            >
              YTD
            </Button>
          </div>
        </Col>
        <Col xs={12} md={6} lg={4} xl={4} className="mb-20">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className='dateBox'>
              <DatePicker
                allowSameDateSelection
                sx={{ color: "black" }}
                label="Start Date"
                value={parseISO(value[0])}
                onChange={(newValue) => {
                  setValue([moment(newValue).format('YYYY-MM-DD'), value[1]]);
                }}
                renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
              />
              <Box sx={{ mx: 2 }}> to </Box>
              <DatePicker
                allowSameDateSelection
                sx={{ color: "black" }}
                label="End Date"
                value={parseISO(value[1])}
                onChange={(newValue) => {
                  setValue([value[0], moment(newValue).format('YYYY-MM-DD')]);
                }}
                renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
              />
            </div>
          </LocalizationProvider>
        </Col>
        {renderClear()}
      </Row>
      <Divider />
      {
        <div style={{ height: 460, width: '100%' }}>
          <DataGrid
            density='compact'
            disableColumnFilter={true}
            disableColumnSelector
            disableColumnMenu
            disableSelectionOnClick
            loading={loading}
            components={{ NoRowsOverlay: NoRows, }}
            sx={{ marginTop: 2 }}
            rows={Object.keys(kbEarning).length > 0 ?
              kbEarning.data.map((item, i) => {
                return { ...item, id: i + (page == 1 ? 1 : (page * 10) - 9), }
              })
              : []}
            className="bg-white"
            columns={col}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10]}
            rowCount={Object.keys(kbEarning).length > 0 ? kbEarning?.count : 0}
            paginationMode="server"
            onPageChange={(newPage) => {
              setPage(newPage + 1)
              setLoading(true)
              let body = {
                startDate: value[0],
                endDate: value[1],
                user_id: currentUser,
                account_type: userType
              }
              getKBEarning(body, newPage + 1).then(() => setLoading(false))
            }}
          />
        </div>
      }
    </div>
  )
}

function mapStateToProps({ kbEarning }) {
  return { kbEarning }
}
export default connect(mapStateToProps, { ...kbAction, ...refundAction })(UserSubscription)