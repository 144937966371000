import konnect from "./axios"
import { SHIPPING } from "./type"


export const getShipping = () => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/fee/getGlobleShippingMethod',)
        dispatch({
            type: SHIPPING,
            payload: res?.data?.data,
        })
    } catch (err) {
        console.log(err.response)
    }
}

export const addShipping = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/fee/createGlobleShippingMethod', data)
        return res.data
    } catch (err) {
        return err.message
    }

}
export const deleteShipping = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/fee/deleteGlobleShippingMethod', data)
        return res.data
    } catch (err) {
        return err.message
    }

}
export const updateShipping = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/fee/updateGlobleShippingMethod', data)
        return res.data
    } catch (err) {
        return err.message
    }

}



