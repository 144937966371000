import React, { useEffect, useMemo, useState } from 'react'
import * as salesActions from "../../../store/actions/sales"
import * as categoryActions from "../../../store/actions/Categories/categories"
import * as accountAction from "../../../store/actions/account"
import { connect, } from 'react-redux'
import { InputLabel, FormControl, Select, TextField, Button, MenuItem, Typography, Divider, Box, Autocomplete } from '@mui/material'

import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import numeral from "numeral";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Col, Row } from "react-bootstrap";
import NoRows from "../../../reUsable/NoRows"
import Skeleton from '../../../reUsable/skeleton';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO } from 'date-fns';

import "./sale.css"
import OrderModal from './OrderModal'
import { useCallback } from 'react'


function ReferralSales({ currentUser, referralSales, refsales, filterCategories, getCustomer, getReferral, purchasers, referrers }) {
    const [value, setValue] = useState([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState(true)
    const [page, setPage] = useState(1);

    const [fields, setFields] = useState({
        generalCategory: "all",
        category: "all",
        subCategory: "all",
        source: "all",
        order: "",
        group_by: "all",
        purchasedBy: "",
        ref_user: ""
    })
    const [flag, setFlag] = useState('all')
    const [visible, setVisible] = useState(false)
    const [order, setOrder] = useState(null)
    const [gen, setGen] = useState([])
    const [cat, setCat] = useState([])
    const [sub, setSub] = useState([])
    // 
    const [name, setName] = useState('')
    const [nameLoad, setNameLoad] = useState(false)

    const [name2, setName2] = useState('')
    const [nameLoad2, setNameLoad2] = useState(false)

    useEffect(() => {
        filterCategories({ brand_id: currentUser, source: "genCategory" }).then((res) => {
            if (res.success) {
                setGen(res?.message)
            } else {
                setGen([])
            }
        })
    }, [])

    useEffect(() => {
        if (search) { getData() }
    }, [search]);


    const getData = () => {
        referralSales(currentUser, fields, value, page).then(() => {
            setFlag(fields.group_by)
            setLoading(false)
            setSearch(false)
        })
    }

    const handleSearch = (type) => {
        setPage(1)
        if (type === "refresh") {
            setName('')
            setName2('')
            setValue([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
            setFields({ generalCategory: "all", category: "all", subCategory: "all", source: "all", order: '', group_by: "all", purchasedBy: "", ref_user: "" })
            setCat([])
            setSub([])
        } else {
            setLoading(true)
            setSearch(true)
        }
    }


    const summary = useMemo(() => {
        if (refsales.success) {
            return refsales?.message?.summary?.[0]
        } else {
            return null
        }
    }, [refsales])
    const orderCol = [
        {
            field: 'id', headerName: 'S.#', width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            }, sortable: false,
        },
        { field: "created_date", headerName: "Order Date", width: 130, sortable: false,renderCell: ({ value }) => {
            return moment(value).format('MM-DD-YYYY')
          } },
        {
            field: "order_id", headerName: "Order#", width: 90, sortable: false, renderCell: (params) => (<div className='orderBTN' onClick={() => {
                setVisible(true)
                setOrder(params?.row)
            }}>{params?.value}</div>)
        },
        { field: "general_category", headerName: "Department", width: 150, sortable: false, renderCell: ({ value }) => value ? value : "All" },
        { field: "category", headerName: "Category", width: 150, sortable: false, renderCell: ({ value }) => value ? value : "All" },
        { field: "sub_category", headerName: "Sub Category", width: 150, sortable: false, renderCell: ({ value }) => value ? value : "All" },
        {
            field: "source", headerName: "Source", width: 120, sortable: false, renderCell: ({ value }) => {
                if (value == "eventshop" || value === "eventshare") {
                    return "Event Sales"
                }
                if (value == "reviewshare" || value === "reviewshop") {
                    return "Review Sales"
                }
            }
        },
        { field: "purchaser", headerName: "Purchaser", width: 150, sortable: false, },
        { field: "total_sale", headerName: "Amount", width: 110, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "kb_fee", headerName: "ORME Fee", width: 110, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "cashback_amount", headerName: "Cashback", width: 110, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "referred_by", headerName: "Referred By", width: 150, sortable: false, },
        { field: "customer_commission", headerName: "Referral Fee", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },

    ]
    const dateCol = [
        {
            field: 'id', headerName: 'S.#', width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            }, sortable: false,
        },
        { field: "created_date", headerName: "Order Date", width: 180, sortable: false,renderCell: ({ value }) => {
            return moment(value).format('MM-DD-YYYY')
          } },
        { field: "order_count", headerName: "Total Orders", width: 180, sortable: false, },
        { field: "total_sale", headerName: "Amount", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "kb_fee", headerName: "ORME Fee", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "cashback_amount", headerName: "Cashback", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "customer_commission", headerName: "Referral Fee", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },

    ]
    const departmentCol = [
        {
            field: 'id', headerName: 'S.#', width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            }, sortable: false,
        },
        { field: "general_category", headerName: "Department", width: 180, sortable: false, renderCell: ({ value }) => value ? value : "All" },
        { field: "order_count", headerName: "Total Orders", width: 180, sortable: false, },
        { field: "total_sale", headerName: "Amount", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "kb_fee", headerName: "ORME Fee", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "cashback_amount", headerName: "Cashback", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "customer_commission", headerName: "Referral Fee", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },

    ]
    const categoryCol = [
        {
            field: 'id', headerName: 'S.#', width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            }, sortable: false,
        },
        { field: "category", headerName: "Category", width: 180, sortable: false, renderCell: ({ value }) => value ? value : "All" },
        { field: "order_count", headerName: "Total Orders", width: 180, sortable: false, },
        { field: "total_sale", headerName: "Amount", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "kb_fee", headerName: "ORME Fee", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "cashback_amount", headerName: "Cashback", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "customer_commission", headerName: "Referral Fee", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },

    ]
    const subCategoryCol = [
        {
            field: 'id', headerName: 'S.#', width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            }, sortable: false,
        },
        { field: "sub_category", headerName: "Sub Category", width: 180, sortable: false, renderCell: ({ value }) => value ? value : "All" },
        { field: "order_count", headerName: "Total Orders", width: 180, sortable: false, },
        { field: "total_sale", headerName: "Amount", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "kb_fee", headerName: "ORME Fee", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "cashback_amount", headerName: "Cashback", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "customer_commission", headerName: "Referral Fee", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },

    ]
    const sourceCol = [
        {
            field: 'id', headerName: 'S.#', width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            }, sortable: false,
        },
        {
            field: "source", headerName: "Source", width: 180, sortable: false, renderCell: ({ value }) => {
                if (value == "eventshop" || value === "eventshare") {
                    return "Event Sales"
                }
                if (value == "reviewshare" || value === "reviewshop") {
                    return "Review Sales"
                }
            }
        },
        { field: "order_count", headerName: "Total Orders", width: 180, sortable: false, },

        { field: "total_sale", headerName: "Amount", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "kb_fee", headerName: "ORME Fee", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "cashback_amount", headerName: "Cashback", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "customer_commission", headerName: "Referral Fee", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },

    ]
    const referrerCol = [
        {
            field: 'id', headerName: 'S.#', width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            }, sortable: false,
        },
        { field: "referrer", headerName: "Referrer", width: 180, sortable: false, renderCell: (params) => { return params?.row?.affiliate?.name } },
        { field: "order_count", headerName: "Total Orders", width: 180, sortable: false, },
        { field: "total_sale", headerName: "Amount", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "kb_fee", headerName: "ORME Fee", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "cashback_amount", headerName: "Cashback", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "customer_commission", headerName: "Referral Fee", width: 180, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },

    ]

    function renderCol() {
        switch (flag) {
            case 'all': return orderCol
            case 'date': return dateCol
            case 'genCategory': return departmentCol
            case 'category': return categoryCol
            case 'subCategory': return subCategoryCol
            case 'source': return sourceCol
            case 'referrer': return referrerCol
            default: return orderCol
        }
    }
    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    const handleChange = (val, type) => {
        if (type === "purchaser") {
            if (val === "") {
                setFields({ ...fields, purchasedBy: '' })
                setNameLoad(false)
                return
            }
            getCustomer(val).then(() => setNameLoad(false))
        } else {
            if (val === "") {
                setFields({ ...fields, ref_user: '' })
                setNameLoad2(false)
                return
            }
            getReferral(val).then(() => setNameLoad2(false))
        }
    };
    const optimizedFn = useCallback(debounce(handleChange), []);

    return (
        <div className='saleContainer'>
            <OrderModal type="referral" user={currentUser} data={order} visible={visible} close={() => {
                setOrder(null)
                setVisible(false)
            }} />
            <Typography className='fw-normal' color="primary" variant="h6" gutterBottom component="div">
                Referral Sales
            </Typography>
            <Divider className='' />

            <div className='body'>
                <Row className='mt-4'>
                    <Col xl={3} lg={12} className='mb-3 '>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className='dateBox'>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            helperText: 'YYYY / MM / DD',
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format('YYYY-MM-DD'), value[1]]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            helperText: 'YYYY / MM / DD',
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format('YYYY-MM-DD')]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xl={3} lg={12} className="mb-3">
                        <TextField
                            label="Order"
                            variant="outlined"
                            size="small"
                            value={fields.order}
                            onChange={(e) => setFields({ ...fields, order: e.target.value })}
                            fullWidth
                        />
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel id="demo-simple-select-label1">Department</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="General Category"
                                labelId="generalCategory"
                                id="generalCategory"
                                value={fields.generalCategory}
                                label="Department"
                                onChange={(e) => {
                                    setFields({ ...fields, generalCategory: e.target.value, category: 'all', subCategory: 'all' })
                                    setCat([])
                                    setSub([])
                                    if (e.target.value !== "all") {
                                        filterCategories({ brand_id: currentUser, source: "category", gen_category_id: e.target.value }).then((res) => {
                                            if (res.success) {
                                                setCat(res?.message)
                                            } else {
                                                setCat([])
                                                setSub([])
                                            }
                                        })
                                    }
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {gen?.map((item) => (<MenuItem value={item._id} >{item.name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel id="demo-simple-select-label1">Category</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Category"
                                labelId="category"
                                id="category"
                                value={fields.category}
                                label="Category"
                                onChange={(e) => {
                                    setFields({ ...fields, category: e.target.value, subCategory: 'all' })
                                    setSub([])
                                    if (e.target.value !== "all") {
                                        filterCategories({ brand_id: currentUser, source: "subCategory", gen_category_id: fields.generalCategory, category_id: e.target.value }).then((res) => {
                                            if (res.success) {
                                                setSub(res?.message)
                                            } else {
                                                setSub([])
                                            }
                                        })
                                    }

                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {cat?.map((item) => (<MenuItem value={item._id} >{item.category_name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Sub Category</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Sub Category"
                                labelId="subCategory"
                                id="subCategory"
                                value={fields.subCategory}
                                label="Sub Category"
                                onChange={(e) => setFields({ ...fields, subCategory: e.target.value })}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {sub?.map((item) => (<MenuItem value={item._id} >{item.sub_category_name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Source</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Source"
                                labelId="source"
                                id="source"
                                value={fields.source}
                                label="Source"
                                onChange={(e) => setFields({ ...fields, source: e.target.value })}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"eventshare"}>Event Share</MenuItem>
                                <MenuItem value={"reviewshare"}>Review Share</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className="mb-3">
                        <Autocomplete
                            variant="outlined"
                            className="field-style"
                            size="small"
                            id="disable-clearable"
                            disableClearable
                            loading={nameLoad}
                            options={purchasers?.map(opt => ({ ...opt, label: `${opt.name} (${opt.email})` }))}
                            onChange={(e, data) => {
                                setFields({ ...fields, purchasedBy: data._id })
                                setName(data.label)
                            }}
                            value={name}
                            renderInput={(params) => <TextField {...params} label="Purchaser" onChange={(e) => {
                                setName(e.target.value)
                                setNameLoad(true)
                                optimizedFn(e.target.value, 'purchaser')
                            }} />}
                        />
                    </Col>
                    <Col xl={3} lg={12} className="mb-3">
                        <Autocomplete
                            variant="outlined"
                            className="field-style"
                            size="small"
                            id="disable-clearable"
                            disableClearable
                            loading={nameLoad2}
                            options={referrers?.map(opt => ({ ...opt, label: `${opt.name} (${opt.email})` }))}
                            onChange={(e, data) => {
                                setFields({ ...fields, ref_user: data._id })
                                setName2(data.label)
                            }}
                            value={name2}
                            renderInput={(params) => <TextField {...params} label="Referrer" onChange={(e) => {
                                setName2(e.target.value)
                                setNameLoad2(true)
                                optimizedFn(e.target.value, 'referrer')
                            }} />}
                        />
                    </Col>


                    <Col xl={3} lg={12} className="mb-3">
                        <FormControl className="w-100" size="small">
                            <InputLabel id="demo-simple-select-label1">Group By</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Group By"
                                labelId="group"
                                id="group"
                                value={fields.group_by}
                                label="Group By"
                                onChange={(e) =>
                                    setFields({ ...fields, group_by: e.target.value })
                                }
                            >
                                <MenuItem value={"all"}>Order</MenuItem>
                                <MenuItem value={"date"}>Date</MenuItem>
                                <MenuItem value={"genCategory"}>Department</MenuItem>
                                <MenuItem value={"category"}>Category</MenuItem>
                                <MenuItem value={"subCategory"}>Sub Category</MenuItem>
                                <MenuItem value={"source"}>Source</MenuItem>
                                <MenuItem value={"referrer"}>Referrer</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={5} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => { handleSearch() }}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('refresh') }}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
                <Divider className="separater-sale" />

                <div style={{ height: 460, width: '100%' }}>
                    {
                        loading ? <Skeleton /> :
                            <>
                                <DataGrid
                                    density="compact"
                                    disableColumnFilter={true}
                                    disableColumnSelector
                                    disableColumnMenu
                                    disableSelectionOnClick
                                    components={{ NoRowsOverlay: NoRows, }}
                                    className="bg-white"
                                    rows={
                                        Object.keys(refsales)?.length > 0 ? refsales?.message?.data?.map((item, index) => ({
                                            ...item,
                                            id: index + (page == 1 ? 1 : (page * 10) - 9),
                                            source: item.event,
                                            referred_by: item?.affiliate?.name,
                                            category: item?.category?.category_name,
                                            sub_category: item?.sub_category?.sub_category_name,
                                            general_category: item?.gen_category?.name,
                                            purchaser: item?.customer?.name
                                        })) : []
                                    }
                                    columns={renderCol()}
                                    rowCount={Object.keys(refsales)?.length > 0 ? refsales?.message?.total_records : 0}
                                    pagination
                                    pageSize={10}
                                    paginationMode="server"
                                    onPageChange={(newPage) => {
                                        setPage(newPage + 1);
                                        referralSales(currentUser, fields, value, newPage + 1).then(() => {
                                            setLoading(false)
                                            setSearch(false)
                                        })
                                    }}
                                />
                                <div className='sumContainer-height-235'>
                                    <div className='sumContainer'>
                                        <div className='sumTitle'>Summary</div>
                                        <div className='sumBody'>
                                            <div className='sumBodyRow'>
                                                <div className='sumBodyTitle'>Orders</div>
                                                <div className='sumBodyvalue'>{summary?.order_count}</div>
                                            </div>
                                            <div className='sumBodyRow'>
                                                <div className='sumBodyTitle'>Total Amount</div>
                                                <div className='sumBodyvalue'>{numeral(summary?.total_sale).format("$0,0.00", Math.floor)}</div>
                                            </div>
                                            <div className='sumBodyRow'>
                                                <div className='sumBodyTitle'>Total ORME Fee</div>
                                                <div className='sumBodyvalue'>{numeral(summary?.kb_fee).format("$0,0.00", Math.floor)}</div>
                                            </div>
                                            <div className='sumBodyRow'>
                                                <div className='sumBodyTitle'>Total Cashback</div>
                                                <div className='sumBodyvalue'>{numeral(summary?.cashback_amount).format("$0,0.00", Math.floor)}</div>
                                            </div>
                                            <div className='sumBodyRow' style={{ borderBottom: "none" }}>
                                                <div className='sumBodyTitle'>Total Referral Fee</div>
                                                <div className='sumBodyvalue'>{numeral(summary?.customer_commission).format("$0,0.00", Math.floor)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </div >
    )
}

function mapStateToProps({ sales, accountDetail, genderCategories, refsales, purchasers, referrers }) {
    return { sales, accountDetail, genderCategories, refsales, purchasers, referrers }
}
export default connect(mapStateToProps, { ...salesActions, ...categoryActions, ...accountAction })(ReferralSales)

