import konnect from "./axios"
import { ALL_WITHDRAW_REQUEST, CHANGE_WITHDRAW_STATUS } from "./type"

export const getALLWithdrawRequest = (data, page, date, flag) => async (dispatch) => {
    try {
        let url = flag === "agency" ? '/v1/admin/agency/withdrawList' : '/v1/withdraw/getAll'
        let body = {}
        if (date) {
            body.status = data?.status === "All" ? '' : data.status
            body.from_date = date[0]
            body.to_date = date[1]
        } else {
            body = {
                ...data
            }
        }
        const res = await konnect.post(url, body, {
            params: {
                limit: 10,
                page
            },

        })
        dispatch({
            type: ALL_WITHDRAW_REQUEST,
            payload: res.data
        })
        return res.data
    } catch (err) {
        console.log('111', err.response)
        return err.response
    }
}

export const handleWithdrawRequest = (data, flag) => async (dispatch) => {
    try {
        let url = flag === "agency" ? "/v1/admin/agency/withdrawRequest" : "/v1/withdraw/process"
        console.log("1",)
        const res = await konnect.post(url, data, {
        })
        dispatch({
            type: CHANGE_WITHDRAW_STATUS,
            payload: res.data
        })
        return res.data
    } catch (err) {
        console.log(err.response)
        return err.response
    }
}
export const payoutRelease = (message, row_id, flag) => async (dispatch) => {
    try {
        const url = flag === "agency" ? "/v1/admin/agency/payout" : "/v1/withdraw/makePayout"
        const res = await konnect.get(url, {
            params: {
                message,
                row_id
            },
        })
        return res?.data
    } catch (err) {
        // console.log(err.response)
        return err.response.data

    }
}

export const getWithdraw = (data, page = 1, type) => async (dispatch) => {
    try {
        const url = type === "agency" ? "/v1/admin/agency/singleUser" : "/v1/withdraw/single"
        const res = await konnect.post(url, data, { params: { limit: 10, page } })
        return res.data
        // dispatch({
        //     type: ALL_WITHDRAW_REQUEST,
        //     payload: res.data
        // })
        // return res.data
    } catch (err) {
        console.log(err.response)
        return err.response
    }
}