import React, { useEffect, useState } from 'react'
import * as transActions from "../../../store/actions/transactions"
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NotFound from 'reUsable/NotFound';
import SkeletonLoader from "reUsable/skeleton";
import { Button } from '@mui/material';
import moment from 'moment';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#052977",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function Transactions({ getTransactions, transactions,currentUser }) {

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getTransactions(currentUser)
      .then(() => {
        setLoading(false)
      })
  }, [])

  if(!loading){
    return (

      transactions.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.no</StyledTableCell>
                <StyledTableCell align="left">Subscription Type</StyledTableCell>
                <StyledTableCell align="left">Amount</StyledTableCell>
                <StyledTableCell align="left">Date</StyledTableCell>
                <StyledTableCell align="left">Next Payment Date</StyledTableCell>
                <StyledTableCell align="left">Recurring Type</StyledTableCell>
                <StyledTableCell align="left">Description</StyledTableCell>
                {/* <StyledTableCell align="left">Action</StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((it, i) => {
                return (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row" align="left">{i + 1}</StyledTableCell>
                    <StyledTableCell align="left">{it?.transaction_type}</StyledTableCell>
                    <StyledTableCell align="left">${it?.credit}</StyledTableCell>
                    <StyledTableCell align="left">{moment(it?.created_at).format("L")}</StyledTableCell>
                    <StyledTableCell align="left">{moment(it?.next_payment_date).format("L")}</StyledTableCell>
                    <StyledTableCell align="left">{it?.recurring_payment_type}</StyledTableCell>
                    <StyledTableCell align="left">{it?.description}</StyledTableCell>
                    {/* <StyledTableCell align="left">
                    <Button
                      onClick={() => {
                      }}
                      variant="contained"
                      color="error"
                      disableElevation
                    >
                      Cancel
                    </Button>
                    </StyledTableCell> */}
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : <NotFound />
  
    )
  }else{
    return <SkeletonLoader/>
  }
}

function mapStateToProps({ transactions }) {
  return { transactions }
}
export default connect(mapStateToProps, transActions)(Transactions)