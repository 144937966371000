import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { styled, alpha } from '@mui/material/styles';
import { Col, Row } from "react-bootstrap"
import * as countryActions from "../../store/actions/country"
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Box from "./Box"
import SkeletonLoader from "../../reUsable/skeleton"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { Button, Typography } from "@mui/material"
import { text } from '@fortawesome/fontawesome-svg-core';
import { Divider } from "@mui/material";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    //height: 55,
    height: '100%',
    width: '100%',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        //padding: theme.spacing(1, 1, 1, 0),
        padding: "7px 8px 8px 0",
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            // width: '20ch',
        },
    },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

function Country({ getCountry, countries, changeStatus }) {

    const [loading, setLoading] = useState(true)
    const [countryState, setCountryState] = useState([])
    const [fields, setFields] = useState({
        name: "",
        status: ""
    })

    useEffect(() => {
        getCountry().then(() => setLoading(false))
    }, [])

    useEffect(() => {
        const countRef = [...countries]
        const condition = new RegExp(fields.name.toLowerCase())
        const condition2 = new RegExp(fields.status == 1 ? "true" : "false")
        const newData = countRef.filter(item => {
            if (
                (
                    fields.name == "" ? true :
                        condition.test(item.name.toLowerCase())
                )
                &&
                (
                    fields.status == "" ? true :
                        condition2.test(item.is_active.toString())
                )
            ) {
                return item
            }
        })
        setCountryState(newData)
    }, [countries])
    // }, [fields.name, fields.status, countries])

    function onSearch() {
        const countRef = [...countries]
        const condition = new RegExp(fields.name.toLowerCase())
        const condition2 = new RegExp(fields.status == 1 ? "true" : "false")
        const newData = countRef.filter(item => {
            if (
                (
                    fields.name == "" ? true :
                        condition.test(item.name.toLowerCase())
                )
                &&
                (
                    fields.status == "" ? true :
                        condition2.test(item.is_active.toString())
                )
            ) {
                return item
            }
        })
        setCountryState(newData)
    }


    function renderClear() {
        // const filter=Object.values(fields).filter(it=>it!="").length
        // if(filter>0){
        return (

            // <Button
            //     className="btn-width btn-gen-margin ml-0 h-100"
            //     disableElevation
            //     size="medium"
            //     variant="contained"
            //     startIcon={<SearchIcon />}
            //     //onClick={onSearch}

            //     Search
            // </Button>
            <>
                <Button
                    className="btn-width btn-gen-margin ml-0 h-100"
                    disableElevation
                    size="medium"
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={onSearch}
                >
                    Search
                </Button>
                <Button
                    className="btn-width btn-gen-margin grey-btn-outline h-100"
                    variant="outlined"
                    sx={{ color: 'gray' }}
                    startIcon={<ClearIcon />}
                    onClick={() => {
                        setFields({
                            name: "",
                            status: ""
                        })
                        const countRef = [...countries]
                        const condition = new RegExp("")
                        const condition2 = new RegExp("false")
                        const newData = countRef.filter(item => {
                            if (
                                (
                                    fields.name == "" ? true :
                                        condition.test(item.name.toLowerCase())
                                )
                                &&
                                (
                                    fields.status == "" ? true :
                                        condition2.test(item.is_active.toString())
                                )
                            ) {
                                return item
                            }
                        })
                        setCountryState(newData)
                    }}
                >
                    Clear
                </Button>
            </>
            // <IconButton 
            // onClick={()=>setFields({
            //     name:"",
            //     status:""
            // })}
            // color="primary" aria-label="upload picture" component="span">
            //     <ClearIcon fontSize="large"/>
            // </IconButton>
        )
        // }
        // return null
    }

    if (!loading) {
        return (
            <div className='container-fluid pt-3 pb-3'>
  <Row className="align-items-center">
                    <Col xs={12} className="mb-20 d-flex align-items-center">
                        <Typography className='title-bar-hd-main mb-0' color="primary" variant="h5" gutterBottom component="div">
                            Countries
                        </Typography>
                    </Col>
                </Row>

                <Row className="align-items-center">
                    <Col xs={12} md={6} lg={6} xl={3} className=" mb-20">
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                fullWidth
                                // style={{ height: 40 }}
                                size='small'
                                value={fields.name}
                                onChange={e => {
                                    setFields({ ...fields, name: e.target.value })
                                }}
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={2} className=" mb-20">
                        <FormControl
                            className="field-style " size="small" fullWidth>
                            <InputLabel
                                id="demo-simple-select-label"
                            >Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.status}
                                label="Status"
                                onChange={(e) => {
                                    setFields({ ...fields, status: e.target.value })
                                }}
                            >
                                <MenuItem value={1}>Active</MenuItem>
                                <MenuItem value={2}>Deactive</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={3} className="filter-clear-col mb-20">
                        {renderClear()}
                    </Col>
                </Row>
                <Divider />
                {/* <Row style={{ padding: '0px 5px' }}>
                <Col md={9} sm={12}>
                </Col>
                    <Col md={9} sm={12} >
                    </Col>
                    <Col md={2} sm={10}>
                    </Col>
                    <Col md={1} sm={2} style={{display:'flex',flexDirection:'column',alignItems:'center',padding: '0px 5px'}}>
                       
                    </Col>
                </Row> */}
                <Row className='countries-listing-main'>
                    {
                        countryState.map((item) => {
                            return (
                                <Col key={item.code1} xl={2} lg={4} md={6} xs={6} className="countries-box">
                                    <Box
                                        item={item}
                                        changeStatus={changeStatus}
                                        reload={getCountry}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        )
    } else {
        return (
            <div className="container-fluid mt-3"> <SkeletonLoader /></div>
        )
    }
}

function mapStateToProps({ countries }) {
    return { countries }
}

export default connect(mapStateToProps, countryActions)(Country);