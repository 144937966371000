import React, { useEffect, useState } from "react";
import { connect, } from "react-redux";
import { CardHeader, Box, InputLabel, MenuItem, FormControl, Select, CardActions, Button, Divider, Card, CardContent, Typography, Avatar, CircularProgress, } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import * as videoActions from "../../store/actions/introVideoAction"
import SkeletonLoader from "reUsable/skeleton";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import ReactPlayer from "react-player";
import ConfirmModal from "./Modal"
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import logo from "../../assets/images/favicon.png"
import AddVideo from "./AddVideo";
function ManageReview({ getIntroVideos, deleteIntroVideo, intro, resetFrequency }) {
    const [loading, setLoading] = useState(true)
    const [fields, setFields] = useState({ key: "all" })
    const [modal, setModal] = useState(false)
    const [resetModal, setResetModal] = useState(false)
    const [id, setID] = useState(null)
    const [visible, setVisible] = useState(false)
    const [flag, setFlag] = useState(true)

    useEffect(() => { getData() }, [])

    function save() {
        setLoading(true)
        setModal(false)
        deleteIntroVideo({ _id: id }).then((res) => {
            if (res.success) {
                toast.success(res.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            } else {
                toast.error(res.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
            getData()
        })
    }

    function reset() {
        setResetModal(false)
        resetFrequency().then((res) => {
            if (res.success) {
                toast.success(res.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            } else {
                toast.error(res.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
        })
    }

    const getData = () => {
        getIntroVideos().then((res) => {
            if (res?.success) {
                const isFound = res?.data?.find(f => f.is_uploading)
                if (isFound) {
                    setFlag(true)
                } else {
                    setFlag(false)
                }
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        if (flag) {
            const interval = setInterval(() => {
                getData()
            }, 8000);

            return () => clearInterval(interval);
        }
    }, [flag]);
    return (
        <React.Fragment>
            {/* <button onClick={() => setFlag(false)}>dis</button> */}
            <AddVideo
                visible={visible}
                clear={() => {
                    setVisible(false)
                }}
                get={() => { getData() }}
            />
            <ConfirmModal
                visible={modal}
                title={`Are you sure you want to Delete this video?`}
                close={() => setModal(false)}
                call={() => save()}
            />
            <ConfirmModal
                visible={resetModal}
                title={`Are you sure you want to Reset User Frequency?`}
                close={() => setResetModal(false)}
                call={() => reset()}
            />
            <div className='container-fluid mt-3 mb-3 pl-0'>
                <Row className='pl-15'>
                    <Col xs className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            Intro Videos
                        </Typography>
                    </Col>
                    <Col xs className="mb-3 text-right">
                        <Button
                            onClick={() => {
                                setResetModal(true)
                            }}
                            className='btn-width mr-2'
                            disableElevation
                            variant="outlined">
                            Reset Frequency
                        </Button>
                        <Button
                            onClick={() => {
                                setVisible(true)
                            }}
                            className='btn-width'
                            startIcon={<AddIcon />}
                            disableElevation
                            variant="contained">
                            Add Video
                        </Button>


                    </Col>
                </Row>
                <Divider className="ml-15" />
                {loading ? (
                    <div className="pl-15" style={{ marginTop: 16 }}>
                        <SkeletonLoader />
                    </div>
                ) : (
                    <div className="pl-15 mt-3 d-flex" style={{ gap: '15px' }}>
                        {
                            intro?.data?.length < 1 ? <div className="noReviews">No Reviews</div> :
                                <>
                                    {
                                        intro?.data?.map((item, i) => {
                                            return (
                                                <>
                                                    <Card
                                                        variant="outlined"
                                                        className="reviews-list-box"
                                                    >
                                                        <CardHeader
                                                            sx={{ height: "55px", }}
                                                            avatar={<Avatar src={logo} sx={{ border: "1px solid #ebebeb" }} />}
                                                            title={
                                                                <>
                                                                    <div className="title-area">
                                                                        <div className="title-name">
                                                                            ORME
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        />
                                                        <div className="review-video">
                                                            {
                                                                item?.is_uploading ? <div className="introLoader"><CircularProgress /></div> :
                                                                    <ReactPlayer
                                                                        className={`review_react_player bg-black ${item?.url?.split(".")[item?.url?.split(".")?.length - 1] === "m3u8" && "objectFit"}`}
                                                                        width={"100%"}
                                                                        height={"250px"}
                                                                        url={item?.url}
                                                                        controls
                                                                        playsinline
                                                                    />
                                                            }

                                                        </div>
                                                        <CardContent className="review-content">

                                                            <h2 title={item?.key} className="intro-title">
                                                                {item?.key}
                                                            </h2>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {<p className="mt-2 mb-0 fw-bold intro-desc"><span style={{ fontWeight: "600" }}>Description: </span> {item?.desc}  </p>}
                                                            </Typography>
                                                        </CardContent>
                                                        <Box sx={{ width: '100%' }}>
                                                            <CardActions className='reviews-box-buttons'>
                                                                <Button
                                                                    onClick={() => {
                                                                        setID(item?._id)
                                                                        setModal(true)
                                                                    }}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    color="primary"
                                                                    disableElevation
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </CardActions>
                                                        </Box>
                                                    </Card>
                                                </>
                                            )
                                        })
                                    }
                                </>
                        }
                    </div>

                )}
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = ({ reviews, intro }) => {
    return { reviews, intro };
};

export default connect(mapStateToProps, { ...videoActions })(ManageReview);
