import konnect from "./axios"
import { GET_COUNTRY, SEARCH_COUNTRY } from "./type"


export const getCountry=()=>async(dispatch)=>{
    try{
        const res=await konnect.post('/v1/admin/filters/getallcountries')
        dispatch({
            type:GET_COUNTRY,
            payload:res.data?.data
        })
        return res.data?.data
    }catch(err){
        console.log(err.response)
    }
}

export const getOnlyActiveCountry=()=>async(dispatch)=>{
    try{
        const res=await konnect.post('/v1/admin/campaign/getCountries')
        dispatch({
            type:GET_COUNTRY,
            payload:res.data?.message
        })
    }catch(err){
        console.log(err.response)
    }
}

export const changeStatus=(code1,is_active)=>async(dispatch)=>{

        const res=await konnect.post('/v1/admin/appSetting/changestatus',
        {
            code1,
            is_active
        }
        )
        return res
}

export const getStates=(country_code)=>async(dispatch)=>{
    const res=await konnect.post('/v1/common/receive/states',
    {
        country_code
    }
    )
    return res.data
}
export const getCity=(data)=>async(dispatch)=>{

    const res=await konnect.post('/v1/common/receive/cities',data)
    return res.data
}
export const getCountries=(data)=>async(dispatch)=>{
    const res=await konnect.post('/v1/common/receive/countries',data)
    dispatch({
        type:GET_COUNTRY,
        payload:res.data.message
    })
    return res
}