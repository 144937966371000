export const columns = [
    {
        field: "sNo",
        headerName: "S.no",
    },
    {
        field: "pixel_id",
        headerName: "PID",
    },
    {
        field: "created_at",
        headerName: "Date",
    },
    {
        field: "name",
        headerName: "Name",
    },

    // {
    //     field: "status",
    //     headerName: "Status",
    // },
    // {
    //     field: "packageName",
    //     headerName: "Package",
    // },
    // {
    //     field: "email",
    //     headerName: "Email",
    // },
    {
        field: "instagram_username",
        headerName: "Instagram",
    },
    // {
    //     field: "bioshop",
    //     headerName: "Bioshop",
    // },
    // {
    //     field: "device",
    //     headerName: "Device",
    // },
    // {
    //     field: "app",
    //     headerName: "App",
    // },

    // {
    //     field: "featured",
    //     headerName: "Featured",
    // },
];
export const columnsCustomer = [
    {
        field: "pixel_id",
        headerName: "PID",
    },
    {
        field: "created_at",
        headerName: "Created On",
    },
    {
        field: "is_ai_search",
        headerName: "AI Search",
    },
    {
        field: "name",
        headerName: "Name",
    },

    {
        field: "status",
        headerName: "Status",
    },
    {
        field: "device",
        headerName: "Device",
    },
    {
        field: "device_detail",
        headerName: "Device Name",
    },
    {
        field: "device_version",
        headerName: "OS Version",
    },
    {
        field: "app",
        headerName: "Public",
    },
    {
        field: "version",
        headerName: "Version",
    },
    {
        field: "email",
        headerName: "Email",
    },
    {
        field: "phone",
        headerName: "Phone No.",
    },
    {
        field: "otp_verified",
        headerName: "OTP",
    },
    {
        field: "email_verified",
        headerName: "Email Verified",
    },
    {
        field: "paypal_email",
        headerName: "Paypal",
    },
    {
        field: "shipping_addresses",
        headerName: "Shipping",
    }

];

export const deviceDetail = [
    {
        field: "created_at",
        headerName: "Created On",
    },
    {
        field: "status",
        headerName: "Status",
    },
    {
        field: "device",
        headerName: "Device",
    },
    {
        field: "device_detail",
        headerName: "Device Name",
    },
    {
        field: "device_version",
        headerName: "OS Version",
    },
    {
        field: "otp_verified",
        headerName: "OTP",
    },
    {
        field: "email_verified",
        headerName: "Email Verified",
    },
    {
        field: "paypal_email",
        headerName: "Paypal",
    },
    {
        field: "shipping_addresses",
        headerName: "Shipping",
    }

];