import React, { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Col, Row } from 'react-bootstrap';
import { Box, Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab';


const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

export default function BanModal({ close, visible, ban }) {
    const [fields, setFields] = useState({
        reason: '',
        ban_type: 'chatban'
    })
    const [submit, setSubmit] = useState(false)
    function cancel() {
        setFields({
            reason: '',
            ban_type: 'chatban'
        })
        setSubmit(false)
        close()
    }

    function handleSubmit() {
        setSubmit(true)
        if (fields.reason === '') {
            return
        }
        ban(fields)
        cancel()
    }

    return (
        <Modal
            open={visible}
            onClose={cancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className={"deleteModal modal-generic-style modal-spacing-new"} style={{ width: "30%" }}>
                <Row className='heading-name-area-modal '>
                    <Col xl={12} lg={12} className="d-flex align-items-center justify-content-between">
                        <Box className="d-flex align-items-center">
                            <Typography className='text-center w-100 m-0' id="modal-modal-title" variant="h6" component="h2">
                                Ban User
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                className='custom-close-btn-moda'
                                onClick={cancel}
                                aria-label="delete" sx={{ color: "grey" }}>
                                <CancelIcon color="error" />
                            </IconButton>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} lg={12} className="">
                        <Divider className='mb-15' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} >
                        <FormControl
                            className="field-style mb-3" size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Ban Type</InputLabel>
                            <Select
                                value={fields.ban_type}
                                label="Ban Type"
                                name='ban_type'
                                onChange={(e) => setFields({ ...fields, ban_type: e.target.value })}
                            >
                                <MenuItem value={'chatban'}>Chat Ban</MenuItem>
                                <MenuItem value={'permaban'}>Permanent Ban</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-left" >
                        <TextField
                            error={submit && fields.reason === "" ? true : false}
                            size="small"
                            label="Reason"
                            name='reason'
                            value={fields.reason}
                            onChange={(e) => setFields({ ...fields, reason: e.target.value })}
                            variant="outlined"
                            className="field-style mb-3 "
                            multiline
                            rows={4}
                        />
                    </Col>
                </Row>
                <div className='modal-buttons-row' style={{ marginTop: "5px" }}>
                    <LoadingButton
                        onClick={handleSubmit}
                        variant="contained"
                        size="medium"
                        color="primary"
                        className="btn-gen-margin ml-0 btn-width"
                        disableElevation
                    >
                        Ban
                    </LoadingButton>
                    <Button
                        className='btn-gen-margin btn-width grey-btn-outline'
                        variant="outlined"
                        component="label"
                        size="large"
                        onClick={cancel}
                    >
                        Cancel
                    </Button>
                </div>
            </Box>
        </Modal >
    );
}