import React, { useState, useEffect, useMemo } from 'react'
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap"
import { Button, Divider, InputAdornment, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import * as boostAction from "../../../store/actions/videobost"
import { toast } from 'react-toastify';

function BoostSetting({ boostSettings, updateBoostSettings }) {
    const [initial, setInitial] = useState(null)
    const [fields, setFields] = useState({
        review_cost: 0,
        show_cost: 0
    })

    useEffect(() => {
        getData()
    }, [])

    const handleSave = () => {
        updateBoostSettings(fields).then((res) => {
            if (res.success) {
                toast.success(`${res?.message}`)
            } else {
                toast.error(`${res?.message}`)
            }
            getData()
        })
    }

    function getData() {
        boostSettings().then((res) => {
            if (res.success) {
                setInitial(res?.data?.[0])
                setFields({
                    review_cost: res?.data?.[0]?.review_cost,
                    show_cost: res?.data?.[0]?.show_cost,
                })
            } else {
                setInitial(null);
                setFields({
                    review_cost: 0,
                    show_cost: 0
                })
            }
        })
    }

    return (
        <div className="container-fluid mb-3">
            <Row className='pt-3'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Boost Setting
                    </Typography>
                </Col>
            </Row>
            <Divider className="mb-15" />
            <Row className="d-flex justify-content-between">
                <Col xl={4} lg={6} md={12}>
                    <div className='infoSection new mb-3' style={{ background: "#FFF" }} >
                        <Box className="addons-cart-title d-flex align-items-center">
                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                Promoted Videos
                            </Typography>
                        </Box>
                        <Divider className='mb-20' />
                        <TextField
                            size="small"
                            type='number'
                            value={fields.review_cost}
                            onChange={e => {
                                let val = e.target.value;
                                if (val > 0) {
                                    setFields({ ...fields, review_cost: val })
                                }
                            }}
                            id="outlined-basic"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$ </InputAdornment>,
                                endAdornment: <InputAdornment position="end">/day</InputAdornment>,
                                inputProps: {
                                    min: 1
                                }
                            }}
                            label="Review Cost"
                            variant="outlined"
                            className="field-style mb-15"
                        />
                        <TextField
                            size="small"
                            type='number'
                            value={fields.show_cost}
                            onChange={e => {
                                let val = e.target.value;
                                if (val > 0) {
                                    setFields({ ...fields, show_cost: val })
                                }
                            }}
                            id="outlined-basic"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$ </InputAdornment>,
                                endAdornment: <InputAdornment position="end">/day</InputAdornment>,
                                inputProps: {
                                    min: 1
                                }
                            }}
                            label="Show Cost"
                            variant="outlined"
                            className="field-style mb-15"
                        />
                        <Button
                            className="btn-width btn-gen-margin grey-btn-outline h-100"
                            variant="outlined"
                            sx={{ color: 'gray', marginLeft: 0 }}
                            disableElevation
                            size="medium"
                            startIcon={<RotateLeftIcon />}
                            onClick={() => {
                                setFields({
                                    review_cost: initial?.review_cost,
                                    show_cost: initial?.show_cost
                                })
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            className="btn-width btn-gen-margin ml-0 h-100"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={() => handleSave()}
                        >
                            Save
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = ({ feed }) => {
    return { feed };
};

export default connect(mapStateToProps, { ...boostAction })(BoostSetting);