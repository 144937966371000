import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Divider, Typography } from '@mui/material'
import * as discoverActions from "../../store/actions/discover.actions"
import { connect } from 'react-redux'

import Section from './Section'
function UserShop({ }) {
    return (
        <div className="container-fluid mb-3">
            <Row className='pt-3'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Discover
                    </Typography>
                </Col>
            </Row>
            <Divider className="mb-15" />
            <Row className="d-flex justify-content-betwee">
                <Section type='review' />
            </Row>

        </div>
    )
}

const mapStateToProps = ({ }) => ({})
export default connect(mapStateToProps, discoverActions)(UserShop)
