import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import { Box, Button, Divider, FormControl, IconButton, InputLabel, MenuItem, Modal, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import DataTable from "./table";
import SkeletonLoader from "reUsable/skeleton";
import * as notificationAct from "../../store/actions/notificationAct";
import { connect } from "react-redux";
import NotFound from "reUsable/NotFound";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SearchInfluencerMultiple from "reUsable/SearchInfluencerMultiple";
import SaveModal from "../../reUsable/SaveModal";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';

import SearchInfluencer from "reUsable/SearchInfluencer";
const NotificationMSG = ({
  getNotificationsMSG,
  createNotificationsMSG,
  notification,
}) => {
  const [notificationData, setNotificationData] = useState({
    title: "",
    body: "",
    image: null,
    userIds: []
  });

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [visible, setVisible] = useState(false)
  const [resent, setResent] = useState(false)
  const [fields, setFields] = useState({
    groupby: 'summary',
    is_viewed: 'all',
    user_id: '',
    notification_id: ''
  })

  const [flag, setFlag] = useState('summary')
  function save() {
    (notificationData.title !== "" && notificationData.body !== "") &&
      createNotificationsMSG(notificationData).then((t) => {
        setNotificationData({
          title: "",
          body: "",
          image: null,
          userIds: []
        });
        setResent(false)
        getData()
      });
    setModal(false)
    setVisible(false)
  }
  function btnsFunc() {
    return (
      <Col xs={12} md={6} xl={4} className="filter-clear-col mb-20">
        <Button
          disabled={loading || (notificationData.body === "" || notificationData.title === "")}
          className="btn-width btn-gen-margin ml-0 h-100"
          disableElevation
          size="medium"
          variant="contained"
          // disabled={true}
          onClick={() => {
            setModal(true)
          }}
        >
          Create
        </Button>
        <Button
          className="btn-width btn-gen-margin ml-0 grey-btn-outline h-100"
          disableElevation
          size="medium"
          variant="outlined"
          sx={{ color: 'gray' }}
          onClick={() =>
            setNotificationData({
              title: "",
              body: "",
              image: null,
              userIds: []
            })
          }
        >
          Cancel
        </Button>
      </Col>
    );
  }
  useEffect(() => {
    getData()
  }, []);

  function getData(type) {
    setLoading(true)
    if (type === 'refresh') {
      setFields({ groupby: 'summary', is_viewed: 'all', user_id: '', notification_id: '' })
      getNotificationsMSG(1, { groupby: 'summary', is_viewed: 'all', user_id: '', notification_id: '' }).then(() => {
        setFlag('summary')
        setLoading(false)
      });
    } else {
      getNotificationsMSG(1, fields).then(() => {
        setFlag(fields.groupby)
        setLoading(false)
      });
    }
  }

  function resend(data) {
    setResent(true)
    setNotificationData({
      title: data.title,
      body: data.desc,
      userIds: [],
      image: null
    })
    setVisible(true)
    // title: "",
    //   body: "",
    //     image: null,
    //       userIds: []
    console.log("data", data)
  }
  return (
    <div className="">
      <SaveModal
        visible={modal}
        title={notificationData.userIds.length === 0 ? `Are you sure you want to send this notification to all ORME users? ` : `Are you sure you want to create this notification?`}
        close={() => {
          setModal(false)
          setResent(false)
        }}
        type="status"
        call={() => {
          setLoading(true)
          save()
        }}
      />
      <CreateNotification
        setModal={setModal}
        notificationData={notificationData}
        setNotificationData={setNotificationData}
        visible={visible}
        caller={btnsFunc}
        resent={resent}
        close={() => {
          setResent(false)
          setVisible(false)
          setNotificationData({
            title: "",
            body: "",
            image: null,
            userIds: []
          })
        }}
      />
      <div className="container-fluid mt-3 mb-3 pl-0">
        <Row className="pl-15">
          <Col xs className="mb-3 d-flex align-items-center">
            <div className="w-100" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography
                className="title-bar-hd-main"
                color="primary"
                variant="h5"
                gutterBottom
                component="div"
              >
                Notification Messages
              </Typography>
              <div>
                <Button
                  className="btn-width btn-gen-margin ml-0 h-100"
                  disableElevation
                  size="medium"
                  variant="contained"
                  onClick={() => { setVisible(true) }}

                >
                  Create
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <div className="body ml-15">
          <Row className="mt-4">

            <Col xl={3} lg={12} className="mb-3 ">
              <FormControl className="w-100" size="small">
                <InputLabel id="demo-simple-select-label1">Group By</InputLabel>
                <Select
                  sx={{ color: "black", background: "#fff" }}
                  placeholder="Group By"
                  labelId="group"
                  id="group"
                  value={fields?.groupby}
                  label="Group By"
                  onChange={(e) =>
                    setFields({ ...fields, groupby: e.target.value })
                  }
                >
                  <MenuItem value={"summary"}>Summary</MenuItem>
                  <MenuItem value={"detail"}>Detail</MenuItem>
                </Select>
              </FormControl>
            </Col>
            <Col xl={3} lg={12} className="mb-3 ">
              <TextField
                size="small"
                value={fields.notification_id}
                onChange={e => setFields({ ...fields, notification_id: e.target.value })}
                id="outlined-basic"
                label="PID"
                variant="outlined"
                className="field-style"
              />
            </Col>
            <Col xl={3} lg={12} className="mb-3 ">
              <SearchInfluencer disable={fields.groupby === "summary"} setter={(value) => setFields({ ...fields, user_id: value })} brand={fields.user_id}
              // type={type} 
              />

            </Col>

            <Col xl={3} lg={12} className="mb-3 ">
              <FormControl className="w-100" size="small">
                <InputLabel id="demo-simple-select-label1">Viewed</InputLabel>
                <Select
                  disabled={fields.groupby === "summary"}
                  sx={{ color: "black", background: "#fff" }}
                  labelId="Viewed"
                  id="Viewed"
                  value={fields?.is_viewed}
                  label="Viewed"
                  onChange={(e) =>
                    setFields({ ...fields, is_viewed: e.target.value })
                  }
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={true}>Viewed</MenuItem>
                  <MenuItem value={false}>Not Viewed</MenuItem>
                </Select>
              </FormControl>
            </Col>
            <Col xl={3} lg={12} className="mb-3">
              <Button
                variant="contained"
                size="medium"
                className="btn-width btn-gen-margin laptop ml-0 "
                color="primary"
                startIcon={<SearchIcon />}
                disableElevation
                onClick={() => {
                  getData()
                  // setLoading(true)
                  // getNotificationsMSG(1, fields).then(() => setLoading(false))
                }}
              >
                Search
              </Button>
              <Button
                variant="outlined"
                size="medium"
                startIcon={<ClearIcon />}
                className="btn-width btn-gen-margin grey-btn-outline laptop"
                color="success"
                disableElevation
                onClick={() => {
                  getData('refresh')
                  // setFields({ groupby: 'summary' })
                  // setLoading(true)
                  // getNotificationsMSG(1, { groupby: 'summary' }).then(() => setLoading(false))
                }}
              >
                Clear
              </Button>
            </Col>
          </Row>
        </div>
        <Divider className="ml-15 mb-3" />
        {loading ? (
          <div className="pl-15" style={{ marginTop: 16 }}>
            <SkeletonLoader />
          </div>
        ) : notification?.result?.data?.length > 0 ? (
          <DataTable
            type={flag}
            tableLoading={tableLoading}
            nowGet={(page) => getNotificationsMSG(page, fields)}
            data={notification}
            resend={resend}
          />
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = ({ notification }) => {
  return { notification };
};

export default connect(mapStateToProps, { ...notificationAct, })(NotificationMSG);
const imageLoad = (file) => {
  return new Promise((res, rej) => {
    let img = new Image();
    img.src = URL.createObjectURL(file)
    img.onload = function () {
      var width = img.naturalWidth,
        height = img.naturalHeight;
      window.URL.revokeObjectURL(img.src);
      res({ width, height, file })
    };
  })
}

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};
function CreateNotification({ setModal, loading, visible, close, notificationData, setNotificationData, resent }) {
  return (
    <Modal
      open={visible}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className={"deleteModal modal-generic-style modal-spacing-new"} style={{ width: "40%" }}>
        <Row className='heading-name-area-modal '>
          <Col xl={12} lg={12} className="d-flex align-items-center justify-content-between">
            <Box className="d-flex align-items-center">
              <Typography className='text-center w-100 m-0' id="modal-modal-title" variant="h6" component="h2">
                {resent ? "Notification" : "Create Notification"}
              </Typography>
            </Box>
            <Box>
              <IconButton
                className='custom-close-btn-moda'
                onClick={close}
                aria-label="delete" sx={{ color: "grey" }}>
                <CancelIcon color="error" />
              </IconButton>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} className="">
            <Divider className='mb-15' />
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="text-left mb-10" >
            <TextField
              size="small"
              value={notificationData.title}
              onChange={(e) =>
                setNotificationData({
                  ...notificationData,
                  title: e.target.value,
                })
              }
              id="outlined-basic"
              label="Title"
              variant="outlined"
              className="field-style"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-left mb-10" >
            <TextField
              size="small"
              value={notificationData.body}
              onChange={(e) =>
                setNotificationData({
                  ...notificationData,
                  body: e.target.value,
                })
              }
              id="outlined-basic"
              label="Description"
              variant="outlined"
              className="field-style"
              multiline
              rows={resent ? 15 : 10}
            />
          </Col>
        </Row>
        {
          !resent &&
          <>
            <Row>
              <Col xs={12} className="text-left mb-10" >
                <SearchInfluencerMultiple setter={(value) => {
                  if (value.length > 0) {
                    setNotificationData({ ...notificationData, userIds: value })
                  } else {
                    setNotificationData({ ...notificationData, userIds: [] })
                  }
                }} users={notificationData.userIds} type={'influencer'} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="text-left mb-10" >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                  <Button
                    startIcon={<FileUploadIcon />}
                    className=' '
                    disableElevation
                    size="medium"
                    component="label"
                    variant="contained">
                    Upload Image
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        if (e.target.files[0]) {
                          imageLoad(e.target.files[0])
                            .then(({ file }) => {
                              setNotificationData({
                                ...notificationData,
                                image: file,
                              })
                            })
                        }
                      }}
                    />
                  </Button>
                  {notificationData?.image && <span style={{ fontSize: "0.8rem", color: "gray" }}>{notificationData?.image?.name}</span>}
                </div>
              </Col>
            </Row>
          </>
        }
        {
          !resent &&
          <div className='modal-buttons-row' style={{ marginTop: "5px" }}>
            <Button
              disabled={loading || (notificationData.body === "" || notificationData.title === "") || resent}
              className="btn-width btn-gen-margin ml-0 h-100"
              disableElevation
              size="medium"
              variant="contained"
              onClick={() => {
                setModal(true)
              }}
            >
              Create
            </Button>
            <Button
              className="btn-width btn-gen-margin ml-0 grey-btn-outline h-100"
              disableElevation
              size="medium"
              variant="outlined"
              sx={{ color: 'gray' }}
              onClick={() => {
                setNotificationData({
                  title: "",
                  body: "",
                  image: null,
                  userIds: []
                })
                close()
              }
              }
            >
              Cancel
            </Button>
          </div>
        }


      </Box>
    </Modal >
  )
}