import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "ionicons/dist/css/ionicons.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorker from "./serviceWorker";
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

serviceWorker.unregister();
