import React from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import { TailSpin } from 'react-loader-spinner'

export default function CSVButton({ load }) {
    return (
        <>
            {load ?
                <>
                    <div div className="mr-2" >
                        <TailSpin
                            height="15"
                            width="15"
                            color='white'
                            ariaLabel='loading'
                        />
                    </div >
                    Loading
                </> :
                <>
                    <DownloadIcon style={{ fontSize: "17px !important" }} className="mr-2" />
                    Download
                </>
            }
        </>
    )
}
