import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from "react-bootstrap";
import SkeletonLoader from "../../../reUsable/skeleton";
import { Button, Divider, Typography } from '@mui/material';
import AddModal from "./AddCategory";
import defaultImg from "../../../assets/images/default.jpg";
import Category from 'reUsable/category';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';

import * as catActions from "../../../store/actions/Categories/categories"
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
function GenderCategory({ getGenderCategories, genderCategories, sortGeneral }) {
    const [loading, setLoading] = useState(true)
    const [addModal, setAddModal] = useState(false)

    useEffect(() => { getGenderCategories().then(() => setLoading(false)) }, [])

    function renderCategories() {
        return genderCategories.map((item) => {
            return (
                <Category
                    key={item.category_id}
                    image={item.image_url ? item.image_url : defaultImg}
                    verify={item.is_active}
                    isFeatured={item.is_featured}
                    item={item}
                    reloadData={() => getGenderCategories()}
                    name={item.name}
                    type="new"
                />
            )
        })
    }
    const [arr, setArr] = useState([

    ])
    function sort() {
        let data = arr.map((item, i) => ({ position: i + 1, _id: item._id }))
        sortGeneral(data).then(() => {
            setLoading(true)
            getGenderCategories().then(() => setLoading(false))
        })
    }
    const SortableList = SortableContainer(({ items }) => {
        return (
            <div className='container-fluid '>
                <Row>
                    {
                        items?.sort((a, b) => a.position - b.position)
                            .map((value, index) => (
                                <SortableItem value={value} index={index} key={value._id} />
                            ))
                    }
                </Row>
                <Button
                    disabled={arr.length === 0}
                    onClick={sort}
                    className='btn-width'
                    startIcon={<SaveIcon />}
                    disableElevation
                    variant="contained">
                    Save</Button>
            </div>
        )
    })

    const SortableItem = SortableElement(({ value, index }) => {
        return (

            <Col index={index} xs={12} md={6} lg={4} xl={2} className={"categories-box cat-new-box"}>
                <Category
                    key={value.category_id}
                    image={value.image_url ? value.image_url : defaultImg}
                    verify={value.is_active}
                    isFeatured={value.is_featured}
                    item={value}
                    reloadData={() => getGenderCategories()}
                    name={value.name}
                    type="new"
                />
            </Col>
            // <div className="list__card" index={index}>
            //     <div className="list__card-right">
            //         <div className="list__card-right--name"> {value.name} </div>
            //     </div>
            // </div>
        )
    })

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let arr = arrayMoveImmutable(genderCategories, oldIndex, newIndex);
        for (let i = 0; i < arr.length; i++) {
            arr[i].position = i;
        }
        setArr(arr);
    };
    return (
        <React.Fragment>
            <AddModal
                visible={addModal}
                close={() => setAddModal(false)}
                reloadData={() => getGenderCategories()}
            />
            <div className="table-width container-fluid">
                <Row className='pt-3'>
                    <Col xs className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            Departments
                        </Typography>
                    </Col>
                    <Col xs className="mb-3 text-right">
                        <Button
                            onClick={() => setAddModal(true)}
                            className='btn-width'
                            startIcon={<AddIcon />}
                            disableElevation
                            variant="contained">
                            Add General Category
                        </Button>
                    </Col>
                </Row>
                <Divider className="mb-15" />
                {!loading ? (

                    <SortableList items={genderCategories} onSortEnd={onSortEnd} axis="xy" />

                ) : <SkeletonLoader />}
            </div>
        </React.Fragment>
    );
}

function mapStateToProps({ genderCategories }) {
    return { genderCategories }
}

export default connect(mapStateToProps, catActions)(GenderCategory)
