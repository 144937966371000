import React, { useMemo } from 'react'
import NoRows from "../../reUsable/NoRows"
import numeral from 'numeral'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import moment from 'moment'
import Tooltip from "@mui/material/Tooltip";

function VideoTable({ nowGet, data }) {
    const [page, setPage] = useState(1)
    const [pageLoad, setPageLoad] = useState(false)
    const feedCol = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        { field: "video_id", headerName: "Video ID", width: 140, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        {
            field: "title", headerName: "Video Title", width: 400, sortable: false, renderCell: ({ value }) => {
                return value ? <Tooltip title={value} placement="top" arrow><div>{value}</div></Tooltip> : <span style={{ color: "#bebebe" }}>Empty</span>
            }
        },
        { field: "brand", headerName: "Brand", width: 150, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "total_views", headerName: "Views", width: 120, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_likes", headerName: "Likes", width: 120, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_shares", headerName: "Shares", width: 120, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "shareRation", headerName: "Share %", width: 120, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_comments", headerName: "Comments", width: 120, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_sales", headerName: "Sales", width: 120, sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
    ]
    const [sum, setSum] = useState(false)

    const feeds = useMemo(() => {
        if (data?.success) {
            if (data?.reviewAnalytics || data?.showAnalytics) {
                setSum(false)
                setPageLoad(false)
                return data?.reviewAnalytics ? data?.reviewAnalytics?.[0] : data?.showAnalytics?.[0]
            } else {
                setSum(true)
                setPageLoad(false)
                return data
            }
        }
    }, [data])
    return (
        <DataGrid
            showCellRightBorder={true}
            rowHeight={35}
            disableColumnFilter={true}
            density="compact"
            loading={pageLoad}
            components={{ NoRowsOverlay: NoRows }}
            disableColumnSelector
            disableColumnMenu
            disableSelectionOnClick
            rows={
                feeds?.data?.map((item, index) => ({
                    ...item,
                    id: index + (page == 1 ? 1 : (page * 20) - 19),
                    brand: sum ? item?.brand_name : item?.brandData?.[0]?.name,
                    video_id: item?.id,
                    shareRation: numeral(item?.total_shares / item?.total_views).format('0.0%')
                }))
            }
            columns={feedCol}
            rowCount={Object.keys(feeds?.data).length !== 0 ? feeds?.total : 0}
            pagination
            pageSize={20}
            rowsPerPageOptions={[20]}
            paginationMode="server"
            onPageChange={(newPage) => {
                setPageLoad(true)
                setPage(newPage + 1);
                if (sum) {
                    nowGet(newPage + 1, true);
                } else {
                    nowGet(newPage + 1);
                }
            }}
        />
    )
}
export default VideoTable