import konnect from "./axios"
import { GET_PACKAGES, PACKAGE_DETAIL } from "./type"

export const getPackages = (data) => async (dispatch) => {
    try {
        const res = await konnect.get('/v1/admin/package/receiveactivepackage')
        dispatch({
            type: GET_PACKAGES,
            payload: res.data?.message
        })
    } catch (err) {
        console.log(err.response)
    }
}

export const getAllPackages = (data) => async (dispatch) => {
    try {
        const res = await konnect.get('/v1/admin/package/receive')
        dispatch({
            type: GET_PACKAGES,
            payload: res.data?.message
        })
    } catch (err) {
        console.log(err.response)
    }
}

export const addPackage = (data) => async (dispatch) => {
    let updatedData = { ...data }
    if (updatedData.fixed_yearly_discount === "") updatedData.fixed_yearly_discount = 0;
    if (updatedData.discount_type === "percentage") updatedData.fixed_yearly_discount = 0;
    if (updatedData.discount_type === "fixed") updatedData.yearly_discount = 0;
    const res = await konnect.post('/v1/admin/package/reserve', updatedData)
    return res
}

export const deletePackage = (id) => async (dispatch) => {
    const res = await konnect.delete(`/v1/admin/package/remove/${id}`)
    return res
}

export const updatePackage = (id, data) => async (dispatch) => {
    let updatedData = { ...data }
    if (updatedData.fixed_yearly_discount === "") updatedData.fixed_yearly_discount = 0;
    if (updatedData.discount_type === "percentage") updatedData.fixed_yearly_discount = 0;
    if (updatedData.discount_type === "fixed") updatedData.yearly_discount = 0;
    const res = await konnect.put(`/v1/admin/package/revise/${id}`, updatedData)
    return res
}

export const getPackageDetail = (id) => async (dispatch) => {
    try {
        const res = await konnect.get(`/v1/admin/package/getone/${id}`)
        dispatch({
            type: PACKAGE_DETAIL,
            payload: res.data?.message
        })
    } catch (err) {
        console.log(err.response)
    }
}