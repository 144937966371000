import React from 'react'
import { Col, Row } from "react-bootstrap";
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';

export default function NotFound() {
  return (
      <div className="search-here-text-main">
        <Row className='search-txt-area align-items-center'>
          <Col xl={12} lg={12} className="justify-content-center ">
            <Typography className='fw-normal pb-2' color="primary" variant="h5">
              No Data Available
            </Typography>
            <Typography className='fw-normal text-muted mb-0' color="primary" variant="p">
              Sorry but the data you are looking for does not exist.
            </Typography>
          </Col>
        </Row>
      </div>
  )
}
