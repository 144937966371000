import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

export default function CustomAutoComplete({ generalCategoryId, brandId, submit, nameLoad, page, accountList, accountByCategory, setBrandId, setName, optimizedFn, setNameLoad, name, categoryId, subCategoryId }) {
    return (
        <Autocomplete
            style={submit && brandId === "" ? { border: "1px solid red" } : {}}
            variant="outlined"
            className="field-style"
            size="small"
            id="disable-clearable"
            disableClearable
            loading={nameLoad}
            options={
                page === "brand" ?
                    accountList?.message ? accountList?.message.map(opt => ({ ...opt, label: opt.name })) : [] :
                    accountByCategory.length > 0 ?
                        accountByCategory.map(opt => ({ ...opt, label: opt.name }))
                        : []
            }
            onChange={(e, data) => {
                setBrandId(data?._id)
                setName(data.name)
            }}
            value={name}
            renderInput={(params) => <TextField {...params} label="Brand" onChange={(e) => {
                setName(e.target.value)
                setNameLoad(true)
                optimizedFn(e.target.value, page === "brand" ? true : false, generalCategoryId, categoryId, subCategoryId)
            }} />}
        />
    )
}
