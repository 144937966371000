import { REVIEWS } from "../../actions/type";
export default function getReviews(state = {}, action) {
    switch (action.type) {
        case REVIEWS:
            {
                if (action.flag) {
                    return {
                        ...action.payload,
                        message: {
                            ...action.payload.message,
                            data: [...state.message.data, ...action.payload.message.data]
                        }
                    }
                } else {

                    return action.payload;
                }
            }
        default:
            return state
    }
}