import id from "date-fns/locale/id/index"
import konnect from "./axios"
import { SUB_CAT } from "./type"


export const getSubCategories = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/categories/getallcategories')
        dispatch({
            type: SUB_CAT,
            payload: res.data.data
        })
        return res.data.data
    } catch (err) {
        console.log(err)
    }
}
export const getSubCategoriesByParent = (id) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/mobile/category/createcategories', { parent_id: id })
        dispatch({
            type: SUB_CAT,
            payload: res.data.data
        })
        return res.data.data
    } catch (err) {
        console.log(err)
    }
}

export const addSubCategory = (data, id) => async (dispatch) => {
    // const formBody = new FormData();
    // formBody.append('parent_category', id)
    // formBody.append('category_name', data.category_name)
    // formBody.append('image', data.file)
    const res = await konnect.post('/v1/admin/categories/createcategories', { ...data, gender_category_id: id })
    return res
}

export const deleteSubCategory = (id) => async (dispatch) => {
    const res = await konnect.delete(`/v1/admin/category/remove/${id}`)
    return res
}

export const updateSubCategory = (data, id, parent_category) => async (dispatch) => {
    const formBody = new FormData();
    formBody.append('category_name', data.category_name)
    formBody.append('parent_category', parent_category)
    formBody.append('image', data.file)

    const res = await konnect.put(`/v1/admin/category/updateSubCategory/${id}`, formBody)
    return res
}



export const changeSubCateStatus = (data) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/category/categorySubCategoryActiveDeactive', data)
    return res
}

export const changeSubFeatureStatus = (data) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/category/updateFeaturedAndAppSubCategory', data)
    return res
}