import React from "react"
import numeral from "numeral";
import moment from "moment"

const summaryColBrand = [
    {
        field: 'id', headerName: 'S.#', width: 80,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    // { field: "name", headerName: "Name", width: 300, sortable: false, },
    { field: "brand_name", headerName: "Brand Name", width: 300, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 130, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    // { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
]
const summaryColCustomer = [
    {
        field: 'id', headerName: 'S.#', width: 80,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "name", headerName: "Name", width: 250, sortable: false, },
    { field: "brand_name", headerName: "Brand Name", width: 250, sortable: false, },
    { field: "influencer_name", headerName: "Influencer Name", width: 250, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'customer_commission', headerName: "Customer Comm", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 130, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },

]
const summaryColInfluencer = [
    {
        field: 'id', headerName: 'S.#', width: 80,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "name", headerName: "Name", width: 300, sortable: false, },
    { field: "influencer_name", headerName: "Instagram", width: 300, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 130, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },

]


const dateColBrand = [
    {
        field: 'id', headerName: 'S.#', width: 60, sortable: false,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        },
    },
    { field: "created_date", headerName: "Date", width: 150, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
]
const dateColInfluencer = [
    {
        field: 'id', headerName: 'S.#', width: 60, sortable: false,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        },
    },
    { field: "created_date", headerName: "Date", width: 150, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 130, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },

]
const dateColCustomer = [
    {
        field: 'id', headerName: 'S.#', width: 60, sortable: false,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        },
    },
    { field: "created_date", headerName: "Date", width: 150, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 200, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'customer_commission', headerName: "Customer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 130, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },

]


const detailColBrand = [
    {
        field: 'id', headerName: 'S.#', width: 60, sortable: false,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false },
    { field: "campaign_name", headerName: "Campaign Name", width: 180, renderCell: ({ row }) => row?.campaign_name ? row.campaign_name : "BioShop", sortable: false, },
    // { field: "influencer_name", headerName: "Influencer Name", width: 180, sortable: false, },
    { field: "name", headerName: "Name", width: 180, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 100, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 70, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 130, sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    // { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    // { field: 'customer_commission', headerName: "Customer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    // { field: "total_commission", headerName: "Commission Paid", width: 140, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
]
const detailColInfluencer = [
    {
        field: 'id', headerName: 'S.#', width: 60, sortable: false,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false },
    { field: "campaign_name", headerName: "Campaign Name", width: 180, renderCell: ({ row }) => row?.campaign_name ? row.campaign_name : "BioShop", sortable: false, },
    // { field: "influencer_name", headerName: "Influencer Name", width: 180, sortable: false, },
    { field: "name", headerName: "Name", width: 180, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 100, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 70, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 130, sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 130, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },

    // { field: "total_commission", headerName: "Commission Paid", width: 140, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
]
const detailColCustomer = [
    {
        field: 'id', headerName: 'S.#', width: 60, sortable: false,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false },
    { field: "campaign_name", headerName: "Campaign Name", width: 180, renderCell: ({ row }) => row?.campaign_name ? row.campaign_name : "BioShop", sortable: false, },
    // { field: "influencer_name", headerName: "Influencer Name", width: 180, sortable: false, },
    { field: "name", headerName: "Name", width: 180, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 100, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 70, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 130, sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'customer_commission', headerName: "Customer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 130, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },

    // { field: "total_commission", headerName: "Commission Paid", width: 140, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
]


const campaignColBrand = [
    {
        field: 'id', headerName: 'S.#', width: 60,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "campaign_name", headerName: "Campaign Name", width: 180, renderCell: ({ row }) => row?.campaign_name ? row.campaign_name : "BioShop", sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 80 },
    { field: "total_sale", headerName: "Gross Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
]
const campaignColCustomer = [
    {
        field: 'id', headerName: 'S.#', width: 60,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "campaign_name", headerName: "Campaign Name", width: 180, renderCell: ({ row }) => row?.campaign_name ? row.campaign_name : "BioShop", sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 80 },
    { field: "total_sale", headerName: "Gross Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'customer_commission', headerName: "Customer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 130, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },

]
const campaignColInfluencer = [
    {
        field: 'id', headerName: 'S.#', width: 60,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "campaign_name", headerName: "Campaign Name", width: 180, renderCell: ({ row }) => row?.campaign_name ? row.campaign_name : "BioShop", sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 80 },
    { field: "total_sale", headerName: "Gross Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "discount", headerName: "Discount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 130, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },

]

const colInfluencer = [
    {
        field: 'id', headerName: 'S.#', width: 60, sortable: false,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false },
    {
        field: "user_type", headerName: "User Type", width: 120, sortable: false, renderCell: ({ value }) => {
            if (value === "customer") return "shopper"
            return value
        }
    },
    // { field: "influencer_name", headerName: "Influencer Name", width: 180, sortable: false, },
    { field: "name", headerName: "Name", width: 180, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 100, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 80, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 130, sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    // { field: "total_commission", headerName: "Commission Paid", width: 140, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
]
const colAll = [
    {
        field: 'id', headerName: 'S.#', width: 60, sortable: false,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false },
    {
        field: "user_type", headerName: "User Type", width: 120, sortable: false, renderCell: ({ value }) => {
            if (value === "customer") return "shopper"
            return value
        }
    },
    // { field: "influencer_name", headerName: "Influencer Name", width: 180, sortable: false, },
    { field: "name", headerName: "Name", width: 180, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 100, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 80, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 130, sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'customer_commission', headerName: "Shopper Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    // { field: "total_commission", headerName: "Commission Paid", width: 140, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
]
const colCustomer = [
    {
        field: 'id', headerName: 'S.#', width: 60, sortable: false,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false },
    {
        field: "user_type", headerName: "User Type", width: 120, sortable: false, renderCell: ({ value }) => {
            if (value === "customer") return "shopper"
            return value
        }
    },
    // { field: "influencer_name", headerName: "Influencer Name", width: 180, sortable: false, },
    { field: "name", headerName: "Name", width: 180, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 100, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 80, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 130, sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'customer_commission', headerName: "Shopper Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    // { field: "total_commission", headerName: "Commission Paid", width: 140, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
]
const colBrand = [
    {
        field: 'id', headerName: 'S.#', width: 20, sortable: false,
        renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false },
    {
        field: "user_type", headerName: "Source", width: 100, sortable: false,
        renderCell: ({ value }) => {
            if (value === "customer") return "Shopper"
            return value
        }
    },
    { field: "referred_by", headerName: "Referred By", width: 120, sortable: false },
    { field: "name", headerName: "Shopper", width: 140, sortable: false },
    { field: "brand_name", headerName: "Brand Name", width: 120, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 70, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 80, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 100, sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 90, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Influencer Comm", width: 130, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },
    { field: 'customer_commission', headerName: "Shopper Comm", width: 130, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 100, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },

]

const colBrandPurchase = [
    {
        field: 'id', headerName: 'S.#', width: 20, sortable: false,
        renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false },
    {
        field: "user_type", headerName: "Source", width: 100, sortable: false,
        renderCell: ({ value }) => {
            if (value === "customer") return "Shopper"
            // if (value === "Commission") return "KB"
            return value
        }
    },
    { field: "referred_by", headerName: "Referred By", width: 120, sortable: false },
    { field: "name", headerName: "Shopper", width: 140, sortable: false },
    { field: "brand_name", headerName: "Brand Name", width: 120, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 80, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 80, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 100, sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 90, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'influencer_commission', headerName: "Influencer Comm", width: 130, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },
    { field: 'customer_commission', headerName: "Shopper Comm", width: 130, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 90, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },

]
const colHouse = [
    {
        field: 'id', headerName: 'S.#', width: 60, sortable: false,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false },
    {
        field: "user_type", headerName: "User Type", width: 120, sortable: false, renderCell: ({ value }) => {
            if (value === "customer") return "shopper"
            return value
        }
    },
    // { field: "influencer_name", headerName: "Influencer Name", width: 180, sortable: false, },
    { field: "name", headerName: "Name", width: 180, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 100, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 70, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 130, sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },

    // { field: "total_commission", headerName: "Commission Paid", width: 140, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
]

const colInfluencerCashback = [
    {
        field: 'id', headerName: 'S.#', width: 60, sortable: false,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false },
    {
        field: "user_type", headerName: "User Type", width: 120, sortable: false, renderCell: ({ value }) => {
            if (value === "customer") return "shopper"
            return value
        }
    },
    // { field: "influencer_name", headerName: "Influencer Name", width: 180, sortable: false, },
    { field: "brand_name", headerName: "Brand Name", width: 180, sortable: false, },
    { field: "name", headerName: "Name", width: 180, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 100, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 70, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 130, sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    // { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    // { field: 'influencer_commission', headerName: "Influencer Commission", width: 165, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    // { field: "total_commission", headerName: "Commission Paid", width: 140, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
]
const colAllCashback = [
    {
        field: 'id', headerName: 'S.#', width: 60, sortable: false,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false },
    {
        field: "user_type", headerName: "User Type", width: 120, sortable: false, renderCell: ({ value }) => {
            if (value === "customer") return "shopper"
            return value
        }
    },
    { field: "brand_name", headerName: "Brand Name", width: 180, sortable: false, },
    { field: "name", headerName: "Name", width: 180, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 100, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 70, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 130, sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },

]
const colCustomerCashback = [
    {
        field: 'id', headerName: 'S.#', width: 60, sortable: false,
        renderCell: ({ value }) => {
            return (
                <span className="d-flex justify-content-center w-100">{value}</span>
            );
        }, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false },
    {
        field: "user_type", headerName: "User Type", width: 120, sortable: false, renderCell: ({ value }) => {
            if (value === "customer") return "shopper"
            return value
        }
    },

    { field: "brand_name", headerName: "Brand Name", width: 180, sortable: false, },
    { field: "name", headerName: "Name", width: 180, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 100, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 70, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 130, sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 120, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
]

const singleWithdrawalCol = [
    {
        field: 'id', headerName: 'S.#', width: 20, sortable: false,
        renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 120, sortable: false },
    { field: "name", headerName: "Name", width: 150, sortable: false },
    { field: "amount", headerName: "Amount", width: 120, sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "status", headerName: "Status", width: 120, sortable: false, },
]
const singleShopperPurchase = [
    {
        field: 'id', headerName: 'S.#', width: 20, sortable: false,
        renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 100, sortable: false },
    {
        field: "user_type", headerName: "Source", width: 100, sortable: false,
        renderCell: ({ value }) => {
            if (value === "customer") return "Shopper"
            // if (value === "Commission") return "KB"
            return value
        }
    },
    { field: "referred_by", headerName: "Referred By", width: 120, sortable: false },
    { field: "name", headerName: "Shopper", width: 140, sortable: false },
    { field: "brand_name", headerName: "Brand Name", width: 120, sortable: false, },
    { field: "order_id", headerName: "Order#", width: 80, sortable: false, },
    { field: "total_qty", headerName: "Quantity", width: 80, sortable: false, },
    { field: "total_sale", headerName: "Gross Sales", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "promo", headerName: "Promo", width: 100, sortable: false, },
    { field: "discount", headerName: "Discount", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: 'order_totalprice', headerName: "Net Sales", width: 90, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    // { field: 'kb_commission', headerName: "KB Fee", width: 100, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    // { field: 'influencer_commission', headerName: "Influencer Comm", width: 130, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },
    // { field: 'customer_commission', headerName: "Shopper Comm", width: 130, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },
    { field: 'cashback_amount', headerName: "Cashback", width: 100, renderCell: ({ value }) => <span className={value === 0 ? '' : 'grayColor'} > {numeral(value).format("$0,0.00", Math.floor)} </span>, sortable: false, },

]

const withdrawalCol = [
    {
        field: 'id', headerName: 'S.#', width: 20, sortable: false,
        renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 120, sortable: false },
    {
        field: "account_type", headerName: "Account Type", width: 150, sortable: false, renderCell: ({ value }) => {
            if (value === "customer") return "shopper"
            return value
        }
    },
    { field: "name", headerName: "Name", width: 150, sortable: false },
    { field: "amount", headerName: "Amount", width: 120, sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "status", headerName: "Status", width: 120, sortable: false, },
]
const withdrawCol = [
    {
        field: 'id', headerName: 'S.#', width: 20, sortable: false,
        renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
    },
    { field: "created_date", headerName: "Date", width: 200, sortable: false },
    { field: "withdraw_amount", headerName: "Amount", width: 200, sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "status", headerName: "Status", width: 200, sortable: false, },
]
const depositCol = [
    {
        field: 'id', headerName: 'S.#', width: 20, sortable: false,
        renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
    },
    { field: "transaction_date", headerName: "Date", width: 120, sortable: false, renderCell: ({ value }) => moment(value).format('YYYY-MM-DD') },
    { field: "name", headerName: "Name", width: 150, sortable: false },
    { field: "deposit_amount", headerName: "Amount", width: 120, sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    { field: "description", headerName: "Description", width: 350, sortable: false, },
]

const refundCol = [
    {
        field: 'id', headerName: 'S.#', width: 20, sortable: false,
        renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
    },
    { field: "created", headerName: "Date", width: 200, sortable: false, renderCell: ({ value }) => moment.unix(value).format("YYYY-MM-DD HH:mm:ss") },
    { field: "customer_name", headerName: "Name", width: 150, sortable: false, },
    { field: "customer_email", headerName: "Email", width: 200, sortable: false },
    { field: "amount_paid", headerName: "Amount", width: 130, sortable: false, renderCell: ({ value }) => numeral(value / 100).format("$0,0.00", Math.floor) },
    { field: "hosted_invoice_url", headerName: "Receipt", width: 100, sortable: false, renderCell: ({ value }) => <a href={value} target="_blank" >OPEN</a> },
    { field: "is_refund", headerName: "Action", width: 150, sortable: false, renderCell: ({ value }) => value ? "Available to Refund" : "Already Refunded" },
]

const orderCol = [
    {
        field: 'id', headerName: 'S.#', width: 20, sortable: false,
        renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
    },
    { field: "orderName", headerName: "Order", width: 130, sortable: false },
    { field: "date", headerName: "Date", width: 180, sortable: false },
    { field: "eventTitle", headerName: "Event", width: 150, sortable: false },
    { field: "sourceName", headerName: "Brand", width: 150, sortable: false },
    { field: "customerName", headerName: "Customer", width: 150, sortable: false },
    { field: "channel", headerName: "Channel", width: 120, sortable: false },
    { field: "status", headerName: "Status", width: 120, sortable: false },
    { field: "paymentStatus", headerName: "Payment Status", width: 150, sortable: false },
    { field: "items", headerName: "Quantity", width: 120, sortable: false },
    { field: "cancelStatus", headerName: "Cancel Status", width: 150, sortable: false },
    { field: "orderPrice", headerName: "Price", width: 150, sortable: false },
    // { field: "transaction_date", headerName: "Date", width: 120, sortable: false, renderCell: ({ value }) => moment(value).format('YYYY-MM-DD') },
    // { field: "deposit_amount", headerName: "Amount", width: 120, sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
    // { field: "description", headerName: "Description", width: 350, sortable: false, },
]

export {
    summaryColBrand, summaryColInfluencer, summaryColCustomer,
    detailColBrand, detailColInfluencer, detailColCustomer,
    dateColBrand, dateColInfluencer, dateColCustomer,
    campaignColBrand, campaignColInfluencer, campaignColCustomer, colInfluencer, colCustomer, colBrand, colAll, colHouse,
    colAllCashback, colCustomerCashback, colInfluencerCashback, colBrandPurchase, singleShopperPurchase, singleWithdrawalCol, withdrawalCol, depositCol, refundCol, orderCol, withdrawCol
};