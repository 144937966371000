import React from 'react'
import { Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';

export default function NotMatch() {
    return (
        <div className='mt-5' style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',height:'70%'}}>
            <SearchIcon
            sx={{fontSize:90,color:'gray'}}
            />
            <Typography color="gray" variant="h4">Page Not Found</Typography>
        </div>
    )
}
