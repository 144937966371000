import { Button, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import numeral from 'numeral';
import NoRows from 'reUsable/NoRows';
import React from 'react'
import { AiOutlineEdit, } from "react-icons/ai";

export default function Table({ data, setter }) {

    const priceCol = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        {
            field: "name",
            headerName: "Name",
            width: 200,
            sortable: false,
        },
        {
            field: "min",
            headerName: "Minimum",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
        },
        {
            field: "max",
            headerName: "Maximum",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
        },
        {
            field: "is_active",
            headerName: "Status",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <p className='mb-0' style={{ color: value ? "green" : "red" }}>
                        {value ? "Active" : "Deactive"}
                    </p>
                )
            }
        },
        {
            field: "action",
            headerName: "Actions",
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <div className="action-buttons-div">

                    <Tooltip title="Update" placement="left" arrow>
                        <Button
                            className="btn-gen-margin"
                            style={{ color: "primary", borderColor: "primary" }}
                            variant="contained"
                            onClick={() => {
                                setter(params.row)
                            }}
                            disableElevation
                        >
                            <AiOutlineEdit color={"#ffffff"} size={15} />
                        </Button>
                    </Tooltip>
                    {/* <Button
                                className='btn-gen-margin'
                                variant="contained"
                                color='error'
                                disableElevation
                                onClick={() => {
                                    setCurrentUser(params.value._id)
                                    setModal(true)
                                }}
                            >
                                <RiDeleteBinLine
                                    color={'white'}
                                    size={15}
                                />
                            </Button> */}


                </div >
            ),
        },

    ];
    return (
        <div style={{ height: "445px" }}>

            <DataGrid
                density="compact"
                disableColumnFilter={true}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                sx={{ marginTop: 2 }}
                className="bg-white ml-15"
                components={{ NoRowsOverlay: NoRows, }}
                rows={
                    data?.map((item, index) => ({
                        ...item,
                        id: index + 1,
                    }))
                }

                columns={priceCol}
                pagination
                pageSize={12}
                rowsPerPageOptions={[12]}
                rowCount={data?.length}
                paginationMode="server"
            />

        </div>
    )
}
