import konnect from "./axios"
import { GET_CATEGORIES, PARENT_CATEGORY } from "./type"



export const getParentCategory = () => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/mobile/category/parentCategories')
        dispatch({
            type: PARENT_CATEGORY,
            payload: res.data?.data
        })
    } catch (err) {
        console.log(err)
    }
}
export const getCategories = () => async (dispatch) => {
    try {
        let res = await konnect.post('v1/admin/gender/categories/getallgendercategories')
        dispatch({ type: GET_CATEGORIES, payload: res.data?.message })
    } catch (err) {
        console.log(err)
    }
}

export const addCategory = (data, type) => async (dispatch) => {
    // const formBody = new FormData();
    // formBody.append('category_name', data.category_name)
    // formBody.append('image', data.file)
    // let res = await konnect.post('/v1/admin/category/reserve', { ...data })
    let res = await konnect.post('/v1/admin/gender/categories/creategendercategories', { ...data })
    return res
}

export const updateCategory = (data, id, type) => async (dispatch) => {
    // const formBody = new FormData();
    // formBody.append('category_name', data.category_name)
    // formBody.append('image', data.file)
    let res = await konnect.put(`/v1/admin/category/revise/${id}`, { category_name: data.category_name })
    return res
}

export const deleteCategory = (id, type) => async (dispatch) => {
    let res = await konnect.delete(`/v1/admin/category/remove/${id}`)
    return res
}

export const changeCateStatus = (data, type) => async (dispatch) => {
    let res = await konnect.post('/v1/admin/category/activeDeactive', data)
    return res
}

export const changeFeatureStatus = (data, type) => async (dispatch) => {
    let res = await konnect.post('/v1/admin/category/updateFeaturedAndAppCategory', data)
    return res
}