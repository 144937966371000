import konnect from "./axios"

export const videoSetting = () => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/appSetting/getVideoSetting`)
        return res?.data
    } catch (err) {
        return err?.response
    }
}
export const updateVideoSetting = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/appSetting/videoSetting`, data)
        return res?.data
    } catch (err) {
        return err?.response
    }
}
export const payoutSetting = () => async (dispatch) => {
    try {
        const res = await konnect.get(`/v1/admin/fee/getLimit`)
        return res?.data
    } catch (err) {
        return err?.response
    }
}
export const updatePayoutSetting = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/fee/setLimit`, data)
        return res?.data
    } catch (err) {
        return err?.response
    }
}

export const emailSetting = () => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/appSetting/getDefaultSetting`)
        return res?.data
    } catch (err) {
        return err?.response
    }
}
export const updateEmailSetting = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/appSetting/createAndUpdateDefaultSetting`, data)
        return res?.data
    } catch (err) {
        return err?.response
    }
}