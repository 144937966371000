import React, { useState, useEffect, useMemo } from "react";
import * as accountActions from "../../../store/actions/account"

import { connect } from "react-redux";
import { Row, Col, Button } from 'react-bootstrap';
import { Box, Card, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';

import * as pdfAction from "../../../store/actions/pdf.action"
import { toast } from 'react-toastify';
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PDFViewer, pdf, } from "@react-pdf/renderer";
import TermPDF from "../../../assets/pdf/TERM"
import SaveModal from "../../../reUsable/SaveModal";



function Agreement({ getDetails, currentUser, accountDetail, generatePDF, nowGet, deleted }) {
    const [modal, setModal] = useState(false)
    const [fields, setFields] = useState({
        is_stripe_connect_user: false,
        merchant_name: '',
        brand_name: '',
        executed_by: '',
        website: '',
        fee: '0',
        date: moment(),
    })
    const [view, setView] = useState(false)
    useEffect(() => {
        getDetails(currentUser, deleted)
    }, [])
    useEffect(() => {
        if (accountDetail?.userDetails) {
            setFields({
                ...fields,
                merchant_name: accountDetail?.userDetails?.merchant_name,
                brand_name: accountDetail?.userDetails?.brand_name,
                executed_by: accountDetail?.userDetails?.merchant_name,
                fee: accountDetail?.userDetails?.contract?.fee,
                website: accountDetail?.userDetails?.website_address,
                is_stripe_connect_user: accountDetail?.userDetails?.contract?.is_stripe_connect_user ? accountDetail?.userDetails?.contract?.is_stripe_connect_user : false,
                date: accountDetail?.agreement ? accountDetail?.agreement?.updated_at : fields.date
            })
        }
    }, [accountDetail])


    async function generate() {
        const blob = await pdf(<TermPDF data={fields} />).toBlob()
        const data = {
            user_id: currentUser,
            merchant_name: fields.merchant_name,
            executed_by: fields.executed_by,
            website_address: fields.website,
            brand_name: fields.brand_name,
            email: accountDetail?.userDetails?.email,
            blobData: blob,
            fee: fields.fee,
            is_stripe_connect_user: fields.is_stripe_connect_user
        }
        generatePDF(data).then((res) => {
            if (res.success) {
                toast.success('Successfully submitted!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(res?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            getDetails(currentUser, deleted)
            nowGet()
            setModal(false)
        })

    }
    return (
        <div className='saleContainer'>
            <SaveModal
                visible={modal}
                title={accountDetail?.agreement ? "Are you sure you want to re-submit this Agreement?" : `Are you sure you want to upload this Agreement?`}
                close={() => setModal(false)}
                type="status"
                call={() => generate()}
            />
            <Row className=''>
                <Col xl={5} lg={6}>
                    <Card variant="outlined" className='users-card-main' style={{ minHeight: 'initial' }} sx={{ minWidth: 275 }}>
                        <div className="agreementHeader">

                            <Typography className='title-bar-hd-main mb-1' color="primary" variant="h5" gutterBottom component="div">Agreement</Typography>
                            {accountDetail?.agreement == null && <div className="agreementStatus"></div>}
                            {
                                accountDetail?.agreement !== null &&
                                <div className="agreementStatus">
                                    {accountDetail?.userDetails?.agreement === "rejected" && <span style={{ color: "red" }}>Rejected</span>}
                                    {accountDetail?.userDetails?.agreement === "accepted" && <span style={{ color: "green" }}>Accepted</span>}
                                    {accountDetail?.userDetails?.agreement === "waiting" && <span >Waiting</span>}
                                </div>
                            }
                        </div>
                        <Divider className='mb-4' />
                        {/* <Row>
                            <Col xs={8}>
                                <FormControl className='w-100 mb-4' size="small">
                                    <InputLabel id="demo-simple-select-helper-label">Payout Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={fields.is_stripe_connect_user}
                                        onChange={(e) => {
                                            setFields({ ...fields, is_stripe_connect_user: e.target.value })
                                        }}
                                        label="Payout Type"
                                    >
                                        <MenuItem value={false}>Stripe</MenuItem>
                                        <MenuItem value={true}>Stripe Connect</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={4}>
                                <div>Connected</div>
                            </Col>
                        </Row> */}
                        <div className='mb-4 newVideoFeed'>
                            <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                <h6 style={{ color: "#052977" }}>Payout Account </h6>
                                <div className="agreementStatus">
                                    {


                                        accountDetail?.userDetails?.contract?.is_stripe_connect_user ?
                                            accountDetail?.userDetails?.is_stripe_account_connected ?
                                                <span style={{ color: 'green', fontSize: "0.8rem" }}>Connected</span> :
                                                <span style={{ fontSize: "0.8rem", color: "red" }}>Not Connected</span> : null}
                                </div>
                            </div>
                            <Divider className='mb-20' />
                            <FormControl className='w-100 mb-4' size="small">
                                {/* <InputLabel id="demo-simple-select-helper-label">Payout Type</InputLabel> */}
                                <Select
                                    disabled={accountDetail?.userDetails?.is_stripe_account_connected}
                                    className="pid-field3"
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={fields.is_stripe_connect_user}
                                    onChange={(e) => {
                                        setFields({ ...fields, is_stripe_connect_user: e.target.value })
                                    }}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    label=' '
                                >
                                    <MenuItem value={false}>Stripe</MenuItem>
                                    <MenuItem value={true}>Stripe Connect</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <TextField
                            value={fields.merchant_name}
                            onChange={(e) => { setFields({ ...fields, merchant_name: e.target.value }) }}
                            id="website"
                            label="Merchant Name"
                            type="text"
                            size="small"
                            className="field-style mb-3"
                            placeholder="Merchant Name"
                            variant="outlined"
                        // disabled={accountDetail?.agreement !== null}
                        />
                        <TextField
                            value={fields.brand_name}
                            onChange={(e) => { setFields({ ...fields, brand_name: e.target.value }) }}
                            id="brand_name"
                            label="Brand Name"
                            type="text"
                            size="small"
                            className="field-style mb-3"
                            placeholder="Brand Name"
                            variant="outlined"
                        // disabled={accountDetail?.agreement !== null}
                        />
                        <TextField
                            // disabled={accountDetail?.agreement !== null}
                            value={fields.website}
                            onChange={(e) => { setFields({ ...fields, website: e.target.value }) }}
                            id="website"
                            label="Website"
                            type="text"
                            size="small"
                            className="field-style mb-3"
                            placeholder="google.com"
                            variant="outlined"
                        // disabled={accountDetail?.agreement !== null}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                // disabled={accountDetail?.agreement !== null}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="Contract Date"
                                value={fields.date}
                                onChange={(newValue) => {
                                    setFields({ ...fields, date: newValue })
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style mb-3"
                                        size="small"
                                        style={{ backgroundColor: "white", }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <TextField
                            // disabled={accountDetail?.agreement !== null}
                            value={fields.executed_by}
                            onChange={(e) => { setFields({ ...fields, executed_by: e.target.value }) }}
                            id="playback_url"
                            label="Executed By"
                            type="text"
                            size="small"
                            className="field-style mb-4"
                            placeholder="Executed By"
                            variant="outlined"
                        // disabled={accountDetail?.agreement !== null}
                        />
                        <TextField
                            // disabled={accountDetail?.agreement !== null}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                inputProps: {
                                    min: 1
                                }
                            }}
                            value={fields.fee}
                            onChange={(e) => { setFields({ ...fields, fee: e.target.value }) }}
                            id="website"
                            label="ORME FEE"
                            type="number"
                            size="small"
                            className="field-style mb-3"
                            placeholder="fee"
                            variant="outlined"
                        />
                        <Button
                            className=" btn-gen-margin ml-0  btn-width btn-gen-margin grey-btn-outline h-100 resetBTN"
                            disableElevation
                            size="medium"
                            variant="outlined"
                            sx={{ color: 'gray', }}
                            startIcon={<ClearIcon />}
                            onClick={() => {
                                setView(false)
                                setFields({
                                    merchant_name: accountDetail?.userDetails?.merchant_name,
                                    brand_name: accountDetail?.userDetails?.brand_name,
                                    website: accountDetail?.userDetails?.website_address,
                                    executed_by: accountDetail?.userDetails?.merchant_name,
                                    date: moment(),
                                    fee: '25',
                                    is_stripe_connect_user: false,

                                })
                            }}
                        >
                            Undo
                        </Button>
                        <Button
                            className="btn-width btn-gen-margin ml-0 h-100 webBTN"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={() => setView(true)}
                        // disabled={accountDetail?.userDetails?.agreement === "accepted"}
                        >
                            View
                        </Button>

                        <Button
                            className="btn-width btn-gen-margin ml-0 h-100 webBTN"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            // disabled={(accountDetail?.userDetails?.agreement === "accepted" || accountDetail?.userDetails?.agreement === "rejected") && accountDetail?.agreement}
                            onClick={() => setModal(true)}
                        // disabled={accountDetail?.agreement !== null}
                        >
                            Submit
                        </Button>
                    </Card>
                </Col>
                {
                    view &&
                    <Col xl={7} lg={6}>
                        <Card variant="outlined" className='users-card-main' style={{ minHeight: 'initial' }} sx={{ minWidth: 275 }}>
                            <PDFViewer style={{ width: "100%", height: "80vh" }}>
                                <TermPDF data={fields} />
                            </PDFViewer>
                        </Card>
                    </Col>
                }

            </Row>
        </div >
    )
}

const mapStateToProps = ({ accountDetail }) => ({ accountDetail })

export default connect(mapStateToProps, { ...pdfAction, ...accountActions })(Agreement)
