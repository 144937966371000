import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Avatar } from '@mui/material';
import Stack from '@mui/material/Stack';
import SaveIcon from '@mui/icons-material/Save';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import * as catActions from "../../../store/actions/Categories/categories"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

function UpdateCategory({ reloadData, close, visible, addCategory, data, updateSubCategory, catData, categories, item }) {
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [categoryId, setCategoryId] = useState("")
    const [fields, setFields] = useState({ category_name: "", })
    const [reset, setReset] = useState(false);
    const [child, setChild] = useState([]);
    const [cID, setCID] = useState('')

    useEffect(() => {
        setFields({ category_name: data.sub_category_name })
        setCategoryId(data.gen_category_id)
        setReset(false)
    }, [data, reset])

    const getValue = (k, v) => {
        setFields({ ...fields, [k]: v })
    }

    function onSubmit() {
        setSubmit(true)
        if (fields.category_name && categoryId) {
            setLoading(true)
            updateSubCategory(fields, data._id, categoryId)
                .then((res) => {
                    setLoading(false)
                    close()
                    setSubmit(false)
                    reloadData()
                    toast.success(res.data.message)
                })
                .catch((err) => {
                    setLoading(false)
                    close()
                    toast.error(err.response.data.message)
                })
        }
    }
    useEffect(() => {
        if (categoryId) {
            let arr = item?.categories?.find(f => f._id === data.category_id)
            setCID(arr.category_name)
            // setFields({category_name: data.})
        }
    }, [categoryId, data])

    return (
        <div>
            <Modal
                open={visible}
                onClose={() => {
                    setFields({ category_name: data.sub_category_name })
                    setCategoryId(data.gen_category_id)
                    close()
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="deleteModal modal-generic-style">
                    <Row className='no-gutters'>
                        <Col xl={12} lg={12} className="d-flex align-items-center justify-content-end">
                            <Box>
                                <IconButton
                                    className='custom-close-btn-moda modal-btn-position'
                                    onClick={() => {
                                        close()
                                    }}
                                    aria-label="delete" sx={{ color: "grey" }}>
                                    <CancelIcon color="error" />
                                </IconButton>
                            </Box>
                        </Col>
                    </Row>
                    <div className='add-icon-modal'>
                        <EditIcon
                            color="primary"
                            sx={{ fontSize: 40 }}
                        />
                    </div>
                    <Typography className='modal-hd' style={{ color: "primary" }} variant="h5" gutterBottom component="div">
                        Update Sub Category
                    </Typography>

                    <Row className="mt-3">
                        <Col xl lg={3} sm={12} className="mb-3">
                            <FormControl className="field-style ">
                                <InputLabel id="demo-multiple-name-label">General Category</InputLabel>
                                <Select
                                    disabled
                                    value={categoryId}
                                    onChange={(e) => { setCategoryId(e.target.value) }}
                                    error={submit && !categoryId ? true : false}
                                    helperText={submit && !categoryId ? "Please Select the Fields" : null}
                                    id="categoryId"
                                    label="General Category"
                                    size="small"
                                    style={styles.input}
                                    MenuProps={MenuProps}
                                    variant="outlined" >
                                    {catData.map((option) => (
                                        <MenuItem key={option._id} value={option._id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl >
                        </Col>
                        <Col xl lg={3} sm={12} className="mb-3">
                            <TextField
                                value={cID}
                                disabled
                                error={submit && !fields.category_name ? true : false}
                                helperText={submit && !fields.category_name ? "Please fill the Fields" : null}
                                id="name"
                                label="Category"
                                size="small"
                                style={styles.input}
                                variant="outlined" />
                        </Col>

                        <Col xl lg={3} sm={12} className="mb-3">
                            <TextField
                                value={fields.category_name}
                                onChange={e => getValue('category_name', e.target.value)}
                                error={submit && !fields.category_name ? true : false}
                                helperText={submit && !fields.category_name ? "Please fill the Fields" : null}
                                id="name"
                                label="Category"
                                size="small"
                                style={styles.input}
                                variant="outlined" />
                        </Col>
                    </Row>
                    <div className='modal-buttons-row'>
                        <LoadingButton
                            onClick={onSubmit}
                            loading={loading}
                            variant="contained"
                            size="medium"
                            color="primary"
                            className="btn-gen-margin ml-0 btn-width"
                            disableElevation
                            endIcon={<SaveIcon />}
                        >
                            Save
                        </LoadingButton>
                        <Button
                            className='btn-gen-margin btn-width grey-btn-outline'
                            variant="outlined"
                            component="label"
                            size="large"
                            onClick={() => setReset(true)}
                        >
                            Reset
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

const styles = {
    input: {
        width: '100%',
        //marginBottom:30
    }
}
const mapStateToProps = ({ categories }) => ({ categories })
export default connect(mapStateToProps, catActions)(UpdateCategory)