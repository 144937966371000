// actions.js
import { GET_STATUS } from "./type";
import micro from "./axiosMicro";

export const getStatus = () => async (dispatch) => {
  try {
    const response = await micro.get("/v1/aws/list-instances");
    console.log(response?.data?.data?.instances, "testtst");
    dispatch({
      type: GET_STATUS,
      payload: response?.data?.data?.instances,
    });
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};
