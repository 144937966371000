import { Box, CardHeader, Skeleton } from '@mui/material'
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

export default function ShippingSkeleton() {
    return (
        <div className='container-fluid ml-0 p-0'>
            <Row className='pt-3' >
                {[...new Array(6)]?.map(item => (
                    <Col md={6} lg={4} className='mb-3' >

                        <Card sx={{ display: 'flex' }} className='p-0'  >
                            <Box className=''>
                                <Skeleton className='w-100' variant="rectangular" width={210} height={160} />
                            </Box>

                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    )
}
