import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Divider, Box } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import * as accountActions from "../../store/actions/account"
import ClearIcon from '@mui/icons-material/Clear';
import DataTable from "./AgencyTable";
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { columns } from "./columns"
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";
import moment from "moment";
import DateBtn from "reUsable/DateBtn";

function Agency({ getAgency, agencies, getAgencyList, clearAccounts, getAccountList, accountList, ...rest }) {
    const history = useHistory()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [search, setSearch] = useState(true)
    const [fields, setFields] = useState({
        pixel_id: '',
        _id: '',
        email: '',
        phone: '',
        is_active: true,
        agreement_status: 'all',
        orderBy: -1,
        sort: "pixel_id",
    })


    const [submit, setSubmit] = useState(false)
    const [name, setName] = useState("")
    const [nameLoad, setNameLoad] = useState(false)
    const [value, setValue] = useState([
        moment('2023-1-1').format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
    ]);
    const [date, setDate] = useState(false);
    const [flag, setFlag] = useState(false)

    const [list, setList] = useState([])

    useEffect(() => {
        onSearch()
        setNameLoad(true)
        getAgencyList(name).then(res => {
            if (res?.success) {
                setList(res?.data?.message)
                setNameLoad(false)
            }
        })
        return () => {
            dispatch({
                type: "clearMinimize"
            })
        }
    }, [])



    useEffect(() => {
        if (date) {
            onSearch()
        }
    }, [date])

    function onSearch() {
        setSubmit(true)
        setLoading(true)
        setTableLoading(true)
        getAgency(1, fields, value)
            .then((res) => {
                if (res) {
                    setDate(false)
                    setSearch(true)
                    setFlag(false)
                    setLoading(false)
                    setTableLoading(false)
                } else {
                    setDate(false)
                    setSearch(false)
                    setFlag(false)
                    setTableLoading(false)
                    setLoading(false)
                }
            })

    }

    function renderClear() {
        return (
            <Col xs={12} md={12} lg={6} xl={3} className="filter-clear-col mb-20">
                <Button
                    className="btn-width btn-gen-margin   laptop ml-0 "
                    disableElevation
                    size="medium"
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={() => onSearch()}
                >
                    Search
                </Button>
                <Button
                    className="btn-width btn-gen-margin grey-btn-outline  laptop"
                    variant="outlined"
                    sx={{ color: 'gray' }}
                    startIcon={<ClearIcon />}
                    onClick={() => {
                        clearAccounts();
                        setNameLoad(true)
                        getAgencyList('').then(res => {
                            if (res?.success) {
                                setList(res?.data?.message)
                                setNameLoad(false)
                            }
                        })
                        const payload = {
                            _id: '',
                            is_active: true,
                            email: '',
                            phone: '',
                            agreement_status: 'all',
                            orderBy: -1,
                            sort: "pixel_id",
                            pixel_id: '',

                        }
                        setFields(payload)
                        setName("")
                        setLoading(true)
                        setValue([moment().subtract(1, 'year').startOf('year').format("YYYY-MM-DD"),
                        moment().format("YYYY-MM-DD"),])
                        const upFields = { ...payload }
                        setTableLoading(true)
                        getAgency(1, upFields, [moment().subtract(1, 'year').startOf('year').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),])
                            .then((res) => {
                                if (res) {
                                    setSearch(true)
                                    setLoading(false)
                                    setTableLoading(false)
                                } else {
                                    setSearch(false)
                                    setTableLoading(false)
                                    setLoading(false)
                                }
                            })
                    }}
                >
                    Clear
                </Button>
            </Col>
        )
    }

    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    const handleChange = (val) => {
        getAgencyList(val).then(res => {
            if (res?.success) {
                setList(res?.data?.message)
                setNameLoad(false)
            }
        })
    };
    const optimizedFn = useCallback(debounce(handleChange), []);

    useEffect(() => {
        if (flag) {
            onSearch()
        }
    }, [flag])

    return (
        <React.Fragment>
            <div className='container-fluid mb-3 pl-0 mt-3'>

                <Row className='pl-15'>
                    <Col xs className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            Agency
                        </Typography>
                    </Col>
                    {/* <Col xs className="mb-3 text-right">
                        <Button
                            onClick={() => {
                                history.push('/brand/add')
                            }}
                            className='btn-width'
                            startIcon={<AddIcon />}
                            disableElevation
                            variant="contained">
                            Add Brand
                        </Button>
                    </Col> */}
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={12} >
                        <DateBtn value={value} setValue={(data) => {
                            setValue(data)
                        }} setDate={setDate} />
                        {/* <div className="pl-15 btn-div mb-3">
                            <button
                                className={fields.status === 'active' ? "btn-dates btn-active" : 'btn-dates'}
                                onClick={() => {
                                    setFields({ ...fields, status: 'active', agreement: 'accepted', stripe_status: 'connected', ecom_status: 'connected', app: true })
                                    setFlag(true)
                                }}
                            >
                                Active
                            </button>
                            <button
                                className={fields.status === 'incomplete' ? "btn-dates btn-active" : 'btn-dates'}
                                onClick={() => {
                                    setFields({ ...fields, status: 'incomplete', agreement: 'all', stripe_status: 'all', ecom_status: 'all', app: 'all' })
                                    setFlag(true)
                                }}
                            >
                                Incomplete
                            </button>
                            <button
                                className={fields.status === 'new' ? "btn-dates btn-active" : 'btn-dates'}
                                onClick={() => {
                                    setFields({ ...fields, status: 'new', app: 'all', agreement: 'all', stripe_status: 'all', ecom_status: 'all' })
                                    setFlag(true)
                                }}
                            >
                                New
                            </button>
                            <button
                                className={fields.status === 'deactive' ? "btn-dates btn-active" : 'btn-dates'}
                                onClick={() => {
                                    setFields({ ...fields, status: 'deactive', agreement: 'all', stripe_status: 'all', ecom_status: 'all', app: 'all' })
                                    setFlag(true)
                                }}
                            >
                                Deactive
                            </button>
                        </div> */}
                    </Col>
                </Row>



                <Row className="account-main-row pl-15">
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="dateBox">
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}

                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format("YYYY-MM-DD")]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.pixel_id}
                            onChange={e => setFields({ ...fields, pixel_id: e.target.value })}
                            id="outlined-basic"
                            label="PID"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>

                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <Autocomplete
                            variant="outlined"
                            className="field-style"
                            size="small"
                            id="disable-clearable"
                            disableClearable
                            loading={nameLoad}
                            options={nameLoad ? [] : list?.map(opt => ({ ...opt, label: `${opt.pixel_id} - ${opt.company_name}` }))}
                            onChange={(e, data) => {
                                setFields({ ...fields, _id: data?._id })
                                setName(data.label)
                            }}
                            value={name}
                            renderInput={(params) => <TextField {...params} label="Name" onChange={(e) => {
                                if (e.target.value !== '') {
                                    setName(e.target.value)
                                    setFields({ ...fields, name: e.target.value })
                                    setNameLoad(true)
                                    optimizedFn(e.target.value)
                                } else {
                                    setName('')
                                    setNameLoad(true)
                                    setFields({ ...fields, _id: '', name: '' })
                                    optimizedFn(e.target.value)
                                }
                            }} />}
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.email}
                            onChange={e => setFields({ ...fields, email: e.target.value })}
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.phone}
                            onChange={e => setFields({ ...fields, phone: e.target.value })}
                            id="outlined-basic"
                            label="Phone"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.is_active}

                                label="Status"
                                onChange={(e) => {
                                    let val = e.target.value
                                    setFields({ ...fields, is_active: val })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Deactive</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Agreement </InputLabel>
                            <Select

                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.agreement_status}

                                label="Agreement"
                                onChange={(e) => {
                                    setFields({ ...fields, agreement_status: e.target.value, })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"accepted"}>Accepted</MenuItem>
                                <MenuItem value={"waiting"}>Waiting</MenuItem>
                                <MenuItem value={"new"}>Empty</MenuItem>
                                <MenuItem value={"rejected"}>Rejected</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className='field-style' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Field</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Field"
                                labelId="field"
                                id="field"
                                value={fields.sort}
                                label="Field"
                                onChange={(e) => setFields({ ...fields, sort: e.target.value })}
                            >
                                <MenuItem key={'pixel_id'} value={'pixel_id'} >PID</MenuItem>
                                <MenuItem key={'date'} value={'date'} >Date</MenuItem>
                                <MenuItem key={'company_name'} value={'company_name'} >Agency Name</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className=' field-style' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Order By</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="order by"
                                labelId="orderBy"
                                id="orderBy"
                                value={fields.orderBy}
                                label="Order By"
                                onChange={(e) => setFields({ ...fields, orderBy: e.target.value })}
                            >
                                <MenuItem value={1}>Ascending</MenuItem>
                                <MenuItem value={-1}>Descending</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    {renderClear()}
                </Row>
                <Divider className="ml-15" />
                <DataTable
                    tableLoading={tableLoading}
                    nowGet={(page) => {
                        setTableLoading(true)
                        getAgency(page, fields, value).then(() => setTableLoading(false))
                    }}
                    accounts={agencies}
                    search={search}
                    loadTable={loading}
                />
            </div>
        </React.Fragment >
    );
}

const mapStateToProps = ({ agencies, accountList }) => {
    return { agencies, accountList };
};

export default connect(mapStateToProps, { ...accountActions, })(Agency);
