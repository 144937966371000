import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card, Col, Row } from "react-bootstrap"
import { Box, CardHeader, Checkbox, Divider, TextField, Typography } from "@mui/material";
import * as accountActions from "../../../store/actions/account"
import { CheckBox } from "@mui/icons-material";
import numeral from "numeral";
import SaveModal from "../../../reUsable/SaveModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faPercent } from "@fortawesome/free-solid-svg-icons";

function Balance({ getAccountBalance, currentUser, sendEmail }) {
    const [balance, setBalance] = useState(null)
    const [modal, setModal] = useState(false)
    useEffect(() => {
        getAccountBalance(currentUser).then((res) => {
            if (res.success) {
                setBalance({
                    available: res?.data?.available?.[0],
                    pending: res?.data?.pending?.[0]
                })
            } else {
                setBalance(null)
            }
        })
    }, [])

    function save() {
        sendEmail(currentUser).then(() => setModal(false))
    }

    return (
        <div className='saleContainer'>
            <SaveModal
                visible={modal}
                title={`Are you sure you want to send email for Insufficient Funds?`}
                close={() => setModal(false)}
                type="status"
                call={() => save()}
            />
            <Row className='align-items-center'>
                <Col xl={8} lg={8}>
                    <Card variant="outlined" className='users-card-main' style={{ minHeight: 'initial' }} sx={{ minWidth: 275 }}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <Typography className='fw-normal' color="primary" variant="h6" gutterBottom component="div">
                                Stripe Setup
                            </Typography>
                            {
                                balance &&
                                <div onClick={() => {
                                    setModal(true)
                                }} className='user-status-style' style={{ backgroundColor: '#052977', cursor: 'pointer', fontSize: "0.8rem" }} >
                                    Remind Balance
                                </div>
                            }


                        </div>
                        <Divider className='mb-4' />
                        <div class='earningTitle'>Account Balance</div>
                        <div class="row">
                            <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                                <div class="dashboard-info-box">
                                    <FontAwesomeIcon
                                        className="info-icon-main"
                                        icon={faDollarSign}
                                    />
                                    <div class="value-info-hd">
                                        <div>{numeral(balance ? (balance?.available?.amount + balance?.pending?.amount) / 100 : 0).format("$0,0.00", Math.floor)}</div>
                                    </div>
                                    <div class="total-info-hd">Total Balance</div>
                                </div>
                            </div>
                            <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                                <div class="dashboard-info-box">
                                    <FontAwesomeIcon
                                        className="info-icon-main"
                                        icon={faDollarSign}
                                    />
                                    <div class="value-info-hd">
                                        <div>{numeral(balance ? balance?.available?.amount / 100 : 0).format("$0,0.00", Math.floor)}</div>
                                    </div>
                                    <div class="total-info-hd">Available Balance</div>
                                </div>
                            </div>
                            <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                                <div class="dashboard-info-box">
                                    <FontAwesomeIcon
                                        className="info-icon-main"
                                        icon={faDollarSign}
                                    />
                                    <div class="value-info-hd">
                                        <div>{numeral(balance ? balance?.pending?.amount / 100 : 0).format("$0,0.00", Math.floor)}</div>
                                    </div>
                                    <div class="total-info-hd">Pending Balance</div>
                                </div>
                            </div>


                            {/* <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                                <div class="dashboard-info-box">
                                    <FontAwesomeIcon
                                        className="info-icon-main"
                                        icon={faDollarSign}
                                    />
                                    <div class="value-info-hd">
                                        <div>{numeral(loading ? 0 : records?.lifetime?.content + records?.lifetime?.cashback + records?.lifetime?.ref).format("$0,0.00", Math.floor)}</div>
                                    </div>
                                    <div class="total-info-hd">TOTAL EARNINGS</div>
                                </div>
                            </div> */}
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = ({ }) => ({})

export default connect(mapStateToProps, { ...accountActions })(Balance)

const style = {
    rowStyle: {
        marginBottom: "10px",
        fontWeight: "500",
        lineHeight: "1.25",
        color: "#001737"
    },
    colStyle: {
        textAlign: "left"
    }

}