import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';

import { Col, Row } from "react-bootstrap";
import { Button, Typography, Divider, } from '@mui/material';
import Skeleton from '../../reUsable/skeleton';
import "../accountMain/components/sale.css"


import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import SearchBrand from "reUsable/SearchBrand";
import Table from "./Table";
// import SearchUser from "reUsable/searchUser";
import * as productActions from "../../store/actions/product.action"
import { CSVLink } from "react-csv";
import CSVButton from "reUsable/CSVButton";
import DownloadIcon from '@mui/icons-material/Download';

const header = [
    { key: 'id', label: 'S#' },
    { key: 'ProductSku', label: 'SKU' },
    // { key: 'general_category', label: 'Department' },
    // { key: 'category', label: 'Category' },
    // { key: 'sub_category', label: 'Sub Category' },
    // { key: 'mediaUrl', label: 'Image' },
    // { key: 'price', label: 'Price' },
    // { key: 'inventory', label: 'Stock' },
    { key: 'shows', label: 'Show (Ids)' },
    { key: 'reviews', label: 'Review (Ids)' },
    // { key: 'productDesc', label: 'Description' },
]


function ProductSearch({ getSoldProducts }) {
    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState({ brand_id: '' })
    const [data, setData] = useState(null)

    const [load, setLoad] = useState(true)
    const [csvData, setCSVData] = useState([])

    function handleSearch(type) {
        if (type === "refresh") {
            setLoad(true)
            setFields({ brand_id: '' })
            getData({ brand_id: '' })
        } else {
            getData(fields)
        }
    }
    useEffect(() => {
        getData()
    }, [])
    const getData = (obj) => {
        setLoading(true)
        setLoad(true)
        getSoldProducts({ ...obj }, 1,).then((res) => {
            if (res?.success) {
                setData(res?.data?.[0])
                setLoading(false)
            } else {
                setData(null)
                setLoading(false)
            }
        })
    }

    function downloadCSV() {
        getSoldProducts({ ...fields }, 1, data?.total ? data?.total : 0).then((res) => {
            setCSVData(res.data?.[0]?.data?.map((item, i) => ({
                ...item,
                // productDesc:'',
                id: i + 1,
                general_category: item?.gen_category?.name ? item?.gen_category?.name : "All",
                category: item?.category?.category_name ? item?.category?.category_name : "All",
                sub_category: item?.sub_category?.sub_category_name ? item?.sub_category?.sub_category_name : "All",
                shows: item?.events?.map(m => {
                    if (m.event_counter) {
                        return m?.event_counter?.[0] + "-" + m?.event_counter?.split('E')[1].split('-').join('').match(/.{1,3}/g).join('-')
                    } else if (m.show_counter) {
                        return m?.show_counter?.[0] + "-" + m?.show_counter?.split('S')[1].split('-').join('').match(/.{1,3}/g).join('-')
                    } else {
                        return ""
                    }
                })?.join(', '),
                reviews: item?.reviews?.map(m => m?.review_counter?.[0] + "-" + m?.review_counter?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-'))?.join(', '),
            })))
            setLoad(false)

        })
    }

    useEffect(() => {
        if (data) {
            if (data?.data?.length > 0) {
                downloadCSV()
            }
        } else {
            setLoad(true)
        }
    }, [data])

    return (
        <React.Fragment>
            <div className='container-fluid mt-3 mb-3 pl-0'>
                <Row className='pl-15'>
                    <Col xl className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            Products <span style={{ fontSize: '0.8rem' }}>Sold Out</span>
                        </Typography>

                    </Col>
                    <Col>
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                            {
                                load ?
                                    <Button
                                        disabled
                                        className='btn-width'
                                        startIcon={<DownloadIcon />}
                                        disableElevation
                                        variant="contained">
                                        Download
                                    </Button>
                                    :
                                    <CSVLink
                                        headers={header}
                                        data={csvData}
                                        asyncOnClick={true}
                                        className="btn-width h-100 custom-btn"
                                        onClick={() => { }}
                                        filename={'sold_out_products'}
                                    >
                                        <CSVButton load={load} />
                                    </CSVLink>
                            }

                        </div>
                    </Col>

                </Row>
                <Row className="account-main-row pl-15">


                    <Col xl={3} lg={12} className='mb-3 pid-field-main'>
                        <SearchBrand setter={(value) => setFields({ ...fields, brand_id: value })} brand={fields.brand_id} />
                    </Col>

                    <Col xl={3} lg={12} className='mb-3'>
                        <Button
                            disabled={fields?.brand_id === ""}
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width ml-0 laptop ml-0"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                        <Button
                            disabled={fields?.brand_id === ""}
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width laptop"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('refresh') }}
                        >
                            Reset
                        </Button>
                    </Col>
                </Row>
                <Divider className="separater-sale ml-15" />
                <div style={{ height: 670 }} className="pl-3">
                    {
                        loading ? <Skeleton /> :
                            <>
                                <Table
                                    nowGet={(page) => {
                                        getSoldProducts({ ...fields }, page).then((res) => {
                                            if (res?.success) {
                                                setData(res?.data?.[0])
                                            } else {
                                                setData(null)
                                            }
                                        })
                                    }}
                                    data={data}
                                />
                            </>
                    }
                </div>
            </div>

        </React.Fragment>
    )
}
function mapStateToProps({ }) {
    return {}
}
export default connect(mapStateToProps, productActions)(ProductSearch)