import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";

import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { Col, Row } from 'react-bootstrap';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import * as bannerAction from "../../store/actions/adManage"
import { toast } from 'react-toastify';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};
function UpdateBanner({ clear, visible, get, updateMonthlyBanner }) {

    const [value, setValue] = useState(new Date())
    const [date, setDate] = useState({
        year: moment().year(),
        month: moment().month() + 1,
    })
    function handleSubmit() {
        console.log(date)
        updateMonthlyBanner(date).then((res) => {
            if (res.success) {
                toast.success(res?.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            } else {
                toast.error(res?.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
            get()
        })
        // if (data) {
        //     updateShipping({ ...fields, _id: data?._id }).then(res => {
        //         if (res.success) {
        //             toast.success(res?.message, {
        //                 autoClose: 3000,
        //                 hideProgressBar: true,
        //             });
        //         } else {
        //             toast.error(res?.message, {
        //                 autoClose: 3000,
        //                 hideProgressBar: true,
        //             });
        //         }
        //         get()

        //     }).catch(err => {
        //         console.log("err", err)
        //     })
        //     close()
        // } 
    }

    function close() {
        setDate({ year: moment().year(), month: moment().month() + 1, })
        setValue(new Date())
        clear()
    }

    return (
        <Modal
            open={visible}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className={"deleteModal modal-generic-style modal-spacing-new"} style={{ width: "30%" }}>
                <Row className='heading-name-area-modal '>
                    <Col xl={12} lg={12} className="d-flex align-items-center justify-content-between">
                        <Box className="d-flex align-items-center">
                            <Typography className='text-center w-100 m-0' id="modal-modal-title" variant="h6" component="h2">
                                Update Banners
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                className='custom-close-btn-moda'
                                onClick={close}
                                aria-label="delete" sx={{ color: "grey" }}>
                                <CancelIcon color="error" />
                            </IconButton>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} lg={12} className="">
                        <Divider className='mb-15' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-left mb-3" >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                views={['year', 'month']}
                                label="Year and Month"
                                minDate={new Date()}
                                maxDate={new Date('2025-06-01')}
                                value={value}
                                onChange={(newValue) => {
                                    setDate({ ...date, year: moment(newValue).year(), month: moment(newValue).month() + 1 })
                                    setValue(newValue);
                                }}
                                renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '100%', }} {...params} helperText={null} />}
                            />
                        </LocalizationProvider>
                    </Col>
                </Row>
                <div className='modal-buttons-row' style={{ marginTop: "5px", }}>
                    <LoadingButton
                        onClick={handleSubmit}
                        variant="contained"
                        size="medium"
                        color="primary"
                        className="btn-gen-margin ml-0 btn-width"
                        disableElevation
                    >
                        <SaveIcon className="mr-2" />  Update
                    </LoadingButton>
                </div>
            </Box>
        </Modal >
    )
}
const mapStateToProps = ({ }) => {
    return {};
};
export default connect(mapStateToProps, { ...bannerAction })(UpdateBanner)

