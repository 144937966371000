import React, { useEffect, useMemo, useState } from 'react'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { Row, Col } from 'react-bootstrap';
import * as contractActions from "../../../store/actions/contract"
import * as accountActions from "../../../store/actions/account"
import moment from "moment";
import SaveIcon from '@mui/icons-material/Save';

import { connect } from 'react-redux'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { toast } from 'react-toastify';
import { parseISO } from 'date-fns';


function Contract({ currentUser, deleted, accountDetail, addContract, getDetails }) {
    const [load, setLoad] = useState(false)
    const [submits, setSubmits] = useState(false);
    const [value, setValue] = useState([moment().format('YYYY-MM-DD'), moment().add(1, 'y').format('YYYY-MM-DD')])
    const [date, setDate] = useState(null)
    const [fields, setFields] = useState({
        fee: 0,
        min_commission: 0,
        max_commission: 0,
    })
    const accountDetailMemo = useMemo(() => {
        if (accountDetail?.userDetails) {
            if (accountDetail.userDetails.contract) {

                // setValue([accountDetail?.userDetails?.contract?.from_date, accountDetail?.userDetails?.contract?.to_date])
                setFields({
                    fee: accountDetail?.userDetails?.contract?.fee ? accountDetail?.userDetails?.contract?.fee : 0,
                    min_commission: accountDetail?.userDetails?.contract?.min_commission ? accountDetail?.userDetails?.contract?.min_commission : 0,
                    max_commission: accountDetail?.userDetails?.contract?.max_commission ? accountDetail?.userDetails?.contract?.max_commission : 0,
                })
            }
        }
    }, [accountDetail])

    const submit = () => {
        setSubmits(true)
        // if ((!fields.fee || !fields.min_commission || !fields.max_commission)) return null
        let data = {
            user_id: currentUser,
            contract: {
                fee: fields.fee,
                min_commission: parseInt(fields.min_commission),
                max_commission: parseInt(fields.max_commission),
                from_date: value[0],
                to_date: value[1]
            }
        }
        addContract(data).then((res) => {
            getDetails(currentUser, deleted)
            setSubmits(false)
            toast.success('Successfully updated', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
            })
        }).catch(err => console.log(err));
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    return (
        <div className='saleContainer'>
            <Row className='align-items-center'>
                <Col xl={5} lg={6}>
                    <Card variant="outlined" className='users-card-main' style={{ minHeight: 'initial' }} sx={{ minWidth: 275 }}>
                        <Typography className='title-bar-hd-main mb-1' color="primary" variant="h5" gutterBottom component="div">
                            ORME Fee
                        </Typography>
                        <Divider className='mb-4' />
                        {/* <Row >
                            <Col xl={12} lg={12} className='mb-20'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <div className='dateBox'>
                                        <DatePicker
                                            allowSameDateSelection
                                            sx={{ color: "black" }}
                                            label="Start Date"
                                            // value={date === null ? value[0] : date[0]}
                                            value={parseISO(value[0])}
                                            onChange={(newValue) => {
                                                // let sDate = new Date(newValue);
                                                // let eDate = new Date(newValue)
                                                // eDate.setFullYear(eDate.getFullYear() + 1)
                                                // setDate([sDate, eDate])
                                                setValue([moment(newValue).format('YYYY-MM-DD'), moment(newValue).add(1, 'y').format('YYYY-MM-DD')]);
                                            }}
                                            renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                        />
                                        <Box sx={{ mx: 2 }}> to </Box>
                                        <DatePicker
                                            allowSameDateSelection
                                            sx={{ color: "black" }}
                                            label="End Date"
                                            // value={date === null ? value[1] : date[1]}
                                            value={parseISO(value[1])}
                                            onChange={(newValue) => {
                                                // let d = new Date(newValue)
                                                // console.log("newValue", typeof newValue)
                                                // console.log("D", d)
                                                // setDate([date[0], d])
                                                setValue([value[0], moment(newValue).format('YYYY-MM-DD')]);
                                            }}
                                            renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                        />
                                    </div>
                                </LocalizationProvider>
                            </Col>
                        </Row> */}
                        {/* <Row > */}
                        {/* <Col md={12} lg={6} xl={6} className='mb-20'> */}
                        <TextField
                            value={fields.fee}
                            onChange={(e) => { setFields({ ...fields, fee: e.target.value }) }}
                            // error={submits && !(fields.fee >= 0 && fields.fee != "" && fields.fee === 0) ? true : false}
                            // helperText={submits && !(fields.fee >= 0 && fields.fee != "" && fields.fee === 0) ? "Please fill the Field" : null}
                            id="fee"
                            label="Fee"
                            type="number"
                            size="small"
                            className="field-style mb-4"
                            disabled
                            // style={styles.input}
                            variant="outlined" />
                        {/* </Col> */}
                        {/* <Col md={12} lg={6} xl={6} className='mb-20'>
                                <TextField
                                    value={fields.min_commission}
                                    onChange={(e) => { setFields({ ...fields, min_commission: e.target.value }) }}
                                    // error={(fields.min_commission > fields.max_commission) ? true : false}
                                    // helperText={(fields.min_commission > fields.max_commission) ? "Please fill the Field" : null}
                                    id="min_commission"
                                    label="Min Commission"
                                    type="number"
                                    size="small"
                                    className="field-style"
                                    // style={styles.input}
                                    variant="outlined" />
                            </Col>
                            <Col md={12} lg={6} xl={6} className='mb-20'>
                                <TextField
                                    value={fields.max_commission}
                                    onChange={(e) => { setFields({ ...fields, max_commission: e.target.value }) }}
                                    // error={(fields.max_commission < fields.min_commission) ? true : false}
                                    // helperText={(fields.max_commission < fields.min_commission) ? "Please fill the Field" : null}
                                    id="max_commission"
                                    label="Max Commission"
                                    type="number"
                                    size="small"
                                    className="field-style"
                                    // style={styles.input}
                                    variant="outlined" />
                            </Col> */}
                        {/* <Col md={12} lg={12} xl={6} > */}
                        {/* <Button
                            // style={accountDetail?.agreement !== null ? { opacity: '0.65' } : {}}
                            className="btn-width btn-gen-margin ml-0 h-100 webBTN"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            // disabled={(accountDetail?.userDetails?.agreement === "accepted" || accountDetail?.userDetails?.agreement === "rejected") && accountDetail?.agreement}
                            // disabled={accountDetail?.agreement !== null}
                            onClick={() => submit()}
                        >
                            Submit
                        </Button> */}

                        {/* <Button
                            className="btn-width btn-gen-margin ml-0 h-100 webBTN"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}

                            
                            disabled={accountDetail?.agreement !== null}
                        >
                            Save
                        </Button> */}
                        {/* </Col> */}
                        {/* </Row> */}
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
const styles = {
    input: {
        marginBottom: 20
    }
}

function mapStateToProps({ accountDetail }) {
    return { accountDetail }
}
export default connect(mapStateToProps, { ...contractActions, ...accountActions })(Contract)