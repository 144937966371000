import konnect from "./axios"
import { FEED, ADFEED, VARIANT_FEED, FEED_STATS, FEED_USERS, VIDEO_STATS } from "./type"



export const getSoldProducts = (data, page, limit = 8) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/products/getSoldOutproducts`, data, {
            params: { page, limit }
        })
        // dispatch({
        //     type: VIDEO_STATS,
        //     payload: res?.data
        // })
        return res?.data
    } catch (err) {
        return err?.response
    }
}