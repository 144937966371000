import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CreateIcon from '@mui/icons-material/AddCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import { connect } from 'react-redux';
// import * as subCatActions from "../../../store/actions/subCategory"
import { toast } from 'react-toastify';
import { Avatar } from '@mui/material';
import Stack from '@mui/material/Stack';
import SaveIcon from '@mui/icons-material/Save';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import * as catActions from "../../../store/actions/Categories/categories"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};


function AddCategory({ reloadData, close, visible, addSubCategory, catData, data }) {
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [categoryId, setCategoryId] = useState("")
    const [subId, setSubId] = useState('')
    const [fields, setFields] = useState({
        category_name: "",
    })
    const [child, setChild] = useState([])

    const getValue = (k, v) => setFields({ ...fields, [k]: v })

    function onSubmit() {
        setSubmit(true)
        if (fields.category_name && categoryId) {
            setLoading(true)
            let data = {
                name: fields.category_name,
                gen_category_id: categoryId,
                category_id: subId
            }
            addSubCategory(data)
                .then((res) => {
                    setLoading(false)
                    setFields({
                        category_name: "",
                    })
                    setCategoryId("")
                    setSubId('')
                    setSubmit(false)
                    close()
                    reloadData()
                    toast.success(res.data.message)
                })
                .catch((err) => {
                    setLoading(false)
                    setFields({
                        category_name: "",
                    })
                    setCategoryId("")
                    setSubId('')
                    close()
                    toast.error(err.response.data.message)
                })
        }
    }

    useEffect(() => {
        if (categoryId) {
            let arr = data?.message?.find(m => m._id === categoryId)
            setChild(arr)
        }
    }, [categoryId])

    return (
        <div>
            <Modal
                open={visible}
                onClose={() => {
                    setFields({ category_name: "" })
                    setCategoryId("")
                    setSubId("")
                    setSubmit(false)
                    setChild([])
                    close()
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="deleteModal modal-generic-style">
                    <Row className='no-gutters'>
                        <Col xl={12} lg={12} className="d-flex align-items-center justify-content-end">
                            <Box>
                                <IconButton
                                    className='custom-close-btn-moda modal-btn-position'
                                    onClick={() => {
                                        setFields({ ...fields, })
                                        setCategoryId("")
                                        setSubmit(false)
                                        close()
                                    }}
                                    aria-label="delete" sx={{ color: "grey" }}>
                                    <CancelIcon color="error" />
                                </IconButton>
                            </Box>
                        </Col>
                    </Row>

                    <div className='add-icon-modal'>
                        <CreateIcon
                            color="primary"
                            sx={{ fontSize: 40 }}
                        />
                    </div>
                    <Typography className='modal-hd' style={{ color: "primary" }} variant="h5" gutterBottom component="div">
                        Add Category
                    </Typography>
                    {/* <Stack direction="row" spacing={2} className="mb-4">
                        <Avatar className='m-auto category-modal-thumb'
                            alt="Remy Sharp"
                            src={fields.file.name ? URL.createObjectURL(fields.file) : ""}
                            sx={{ width: 100, height: 100, border: submit && !fields.file?.name ? '1px solid red' : null }}
                        />
                    </Stack> */}
                    <Row className="mt-3">
                        <Col xl lg={3} sm={12} className="mb-3">
                            <FormControl className={categoryId === "" ? "field-style multiple-checkbox-style" : "field-style"} >
                                <InputLabel id="demo-multiple-name-label">General Category</InputLabel>
                                <Select
                                    // onChange={e => getValue('category_name', e.target.value)}
                                    error={submit && !categoryId ? true : false}
                                    helperText={submit && !categoryId ? "Please Select the Fields" : null}
                                    // placeholder="Category"
                                    className="field-style "
                                    id="categoryId"
                                    label="General Category"
                                    size="small"
                                    style={styles.input}
                                    value={categoryId}
                                    onChange={(e) => { setCategoryId(e.target.value); setSubId('') }}
                                    MenuProps={MenuProps}
                                    variant="outlined" >
                                    {catData.map((option) => (
                                        <MenuItem key={option._id} value={option._id} disabled={!option.is_active}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xl lg={3} sm={12} className="mb-3">
                            <FormControl className={subId === "" ? "field-style multiple-checkbox-style" : "field-style"} >
                                <InputLabel id="demo-multiple-name-label">Category</InputLabel>
                                <Select
                                    disabled={child?.length == 0}
                                    // onChange={e => getValue('category_name', e.target.value)}
                                    error={submit && !subId ? true : false}
                                    helperText={submit && !subId ? "Please Select the Fields" : null}
                                    // placeholder="Category"
                                    className="field-style "
                                    id="subId"
                                    label="Category"
                                    size="small"
                                    style={styles.input}
                                    value={subId}
                                    onChange={(e) => { setSubId(e.target.value) }}
                                    MenuProps={MenuProps}
                                    variant="outlined" >
                                    {child?.categories?.map((option) => (
                                        <MenuItem key={option._id} value={option._id} disabled={!option.is_active}>
                                            {option.category_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xl lg={3} sm={12} className="mb-3">
                            <TextField
                                onChange={e => getValue('category_name', e.target.value)}
                                error={submit && !fields.category_name ? true : false}
                                helperText={submit && !fields.category_name ? "Please fill the Fields" : null}
                                className="field-style"
                                id="name"
                                label="Sub Category"
                                size="small"
                                style={styles.input}
                                value={fields.category_name}
                                variant="outlined" />
                        </Col>
                        {/* <Col sm={12} md={6} lg={3} className="mb-3">
                            <Button
                                className='upload-reset-buttons'
                                variant="contained"
                                component="label"
                                size="large"
                                //fullWidth="true"
                                startIcon={<FileUploadIcon />}
                                disableElevation
                                style={{ color: 'primary', borderColor: 'primary' }}
                            >
                                Upload Image
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => {
                                        if (e.target.files[0]) {
                                            getValue('file', e.target.files[0])
                                        }
                                    }}
                                />
                            </Button>
                        </Col> */}
                        {/* <Col sm={12} md={6} lg={3} className="mb-3">
                         
                        </Col> */}

                    </Row>



                    <div className='modal-buttons-row'>
                        <LoadingButton
                            onClick={onSubmit}
                            loading={loading}
                            variant="contained"
                            size="medium"
                            color="primary"
                            className="btn-gen-margin ml-0 btn-width"
                            disableElevation
                            endIcon={<SaveIcon />}
                        >
                            Save
                        </LoadingButton>
                        <Button
                            // className='upload-reset-buttons  grey-btn-outline'
                            className='btn-gen-margin btn-width grey-btn-outline'
                            variant="outlined"
                            component="label"
                            size="large"
                            //fullWidth="true"
                            // style={{ color: 'primary', borderColor: 'primary' }}
                            onClick={() => {
                                setCategoryId("")
                                setChild([])
                                setFields({
                                    category_name: "",
                                })
                                setCategoryId("")
                                setSubId('')
                            }}
                        >
                            Reset

                        </Button>
                        {/* <Button
                            onClick={() => {
                                setFields({ ...fields, file: {} })
                                setCategoryId("")
                                setSubmit(false)
                                close()
                            }}
                            //style={{ color: 'primary', borderColor: 'primary' }}
                            variant="outlined"
                            size="medium"
                            className="btn-gen-margin btn-width grey-btn-outline"
                        >
                            Cancel
                        </Button> */}
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

const styles = {
    input: {
        // width:'100%',
        // marginBottom:30
    }
}

export default connect(null, catActions)(AddCategory)