import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../table.css";
import moment from "moment";
import { Avatar, Fade, Modal, Backdrop, Tooltip, Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import numeral from "numeral";
import defaultImage from "../../assets/images/default.jpg"
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};
function DataTable({ tableLoading, nowGet, data, type, resend }) {
  const [modal, setModal] = React.useState(false)
  const [image, setImage] = React.useState('')
  const columns = [
    {
      field: "sNo",
      headerClassName: "super-app-theme--header",
      headerName: "S.no",
      width: 50,
      sortable: false,
      renderCell: ({ value }) => {
        return (
          <span className="d-flex justify-content-center w-100">{value}</span>
        );
      },
      filterable: false,
    },
    {
      field: "notification_id",
      headerClassName: "super-app-theme--header",
      headerName: "PID",
      width: 120,
      sortable: false,
      renderCell: ({ value }) => {
        return (
          <span className="d-flex justify-content-center w-100">{value ? value : <span style={{ color: "#bebebe" }}>Empty</span>}</span>
        );
      },
      filterable: false,
    },
    {
      field: "title",
      headerClassName: "super-app-theme--header",
      headerName: "Title",
      sortable: false,
      width: 200,
      filterable: false,
      renderCell: ({ value }) => <Tooltip title={value} placement="top-start" arrow><div>{value}</div></Tooltip>
    },
    {
      field: "desc",
      headerClassName: "super-app-theme--header",
      headerName: "Description",
      width: 500,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => <Tooltip title={value} placement="top-start" arrow><div>{value}</div></Tooltip>
    },
    {
      field: "image_url",
      headerClassName: "super-app-theme--header",
      headerName: "Image",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) =>
        <div onClick={() => {
          setImage(value)
          setModal(true)
        }} style={{ width: "100%", display: "flex", justifyContent: "center", cursor: 'pointer' }}>
          <img src={value ? value : defaultImage} alt="product-img" style={{ width: "90px", height: '50px', objectFit: "contain", padding: "10px" }} />
          {/* <Avatar variant="square" style={{ width: '30px', height: '60px', }} alt="product-img" src={value} /> */}
        </div>
    },
    {
      field: "viewed",
      headerClassName: "super-app-theme--header",
      headerName: "Read",
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: ({ value }) => value ? <span style={{ color: "green" }}>Viewed</span> : <span style={{ color: "red" }}>Not Viewed</span>

    },
    {
      field: "user",
      headerClassName: "super-app-theme--header",
      headerName: "Received By",
      sortable: false,
      filterable: false,
      width: 350,
      renderCell: ({ value }) => <span>{value}</span>

    },
    {
      field: "created_at",
      headerClassName: "super-app-theme--header",
      headerName: "Created At",
      flex: 1.5,
      sortable: false,
      filterable: false,
      minWidth: 190,
    },

  ];
  const summaryColumns = [
    {
      field: "sNo",
      headerClassName: "super-app-theme--header",
      headerName: "S.no",
      width: 50,
      sortable: false,
      renderCell: ({ value }) => {
        return (
          <span className="d-flex justify-content-center w-100">{value}</span>
        );
      },
      filterable: false,
    },
    {
      field: "notification_id",
      headerClassName: "super-app-theme--header",
      headerName: "PID",
      width: 120,
      sortable: false,
      renderCell: ({ value }) => {
        return (
          <span className="d-flex justify-content-center w-100">{value ? value : <span style={{ color: "#bebebe" }}>Empty</span>}</span>
        );
      },
      filterable: false,
    },
    {
      field: "title",
      headerClassName: "super-app-theme--header",
      headerName: "Title",
      sortable: false,
      width: 200,
      filterable: false,
      renderCell: ({ value }) => <Tooltip title={value} placement="top-start" arrow><div>{value}</div></Tooltip>
    },
    {
      field: "desc",
      headerClassName: "super-app-theme--header",
      headerName: "Description",
      width: 500,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => <Tooltip title={value} placement="top-start" arrow><div>{value}</div></Tooltip>
    },
    {
      field: "totalSend",
      headerClassName: "super-app-theme--header",
      headerName: "Total Sends",
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => numeral(value).format('0,000,000')
    },
    {
      field: "viewedTrueCount",
      headerClassName: "super-app-theme--header",
      headerName: "Total Reads",
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => numeral(value).format('0,000,000')
    },
    {
      field: "created_at",
      headerClassName: "super-app-theme--header",
      headerName: "Created At",
      flex: 1.5,
      sortable: false,
      filterable: false,
      minWidth: 190,
    },
    {
      field: "action",
      headerClassName: "super-app-theme--header",
      headerName: "Action",
      flex: 1.5,
      sortable: false,
      filterable: false,
      minWidth: 190,
      renderCell: (params) => {
        return (
          <div style={{ color: "#15226a", cursor: 'pointer', fontWeight:"500" }} fontSize="small" onClick={() => resend(params.row)} >view</div>
        )
      }
    },
  ];
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const notificationData = React.useMemo(() => {
    if (data?.success) {
      return data?.result
    } else {
      return null
    }
  }, [data])
  return (
    <div style={{ height: 620, marginLeft: "15px" }}>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={modal} timeout={500} style={style}>
          <img
            src={image ? image : defaultImage}
            alt="notification-image"
            style={{ maxHeight: "90%", maxWidth: "90%" }}
          />
        </Fade>
        {/* <Box sx={style} className="deleteModal modal-spacing-new" style={{ width: '52rem', height: "initial" }}>
                </Box> */}
      </Modal>
      <DataGrid
        showCellRightBorder
        rowHeight={50}
        disableColumnFilter={true}
        disableColumnSelector
        disableColumnMenu
        disableSelectionOnClick
        //   sx={{ marginTop: 2 }}
        density="compact"
        rows={notificationData?.data?.map((item, i) => {
          if (type === "summary") {
            return {
              ...item,
              sNo: i + (page == 1 ? 1 : page * 15 - 14),
              id: i,
              created_at: moment(item.created_at).format("MM/DD/YYYY - hh:mm A"),
            };
          } else {
            return {
              ...item,
              sNo: i + (page == 1 ? 1 : page * 15 - 14),
              id: item._id,
              created_at: moment(item.created_at).format("MM/DD/YYYY - hh:mm A"),
              title: item.title,
              body: item.body,
              user: `${item?.userDetails?.name} - ${item?.userDetails?.email}`
            };
          }

        })}
        columns={type === "summary" ? summaryColumns : columns}
        pagination
        className="bg-white"
        pageSize={15}
        rowsPerPageOptions={[15]}
        rowCount={notificationData?.pagination?.totalRecords}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPage(newPage + 1);
          setLoading(true);
          nowGet(newPage + 1).then(() => setLoading(false));
        }}
        loading={loading || tableLoading ? true : false}
      />
    </div>
  );
}

export default DataTable;
