import React, { useEffect, useMemo } from 'react'
import NoRows from "../../reUsable/NoRows"
import numeral from 'numeral'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import moment from 'moment'
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';
import UserModal from "../../reUsable/UserModal";
import BanModal from "./BanModal";
import EditIcon from '@mui/icons-material/Edit';

function DataTable({ nowGet, data, onDelete, flag, userban }) {
    const [user, setUser] = useState(null)
    const [modal, setModal] = useState(false)
    const [page, setPage] = useState(1)
    const [pageLoad, setPageLoad] = useState(false)
    const [banModal, setBanModal] = useState(false)

    const column = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        { field: "datetime", headerName: "Date", width: 180, sortable: false, renderCell: ({ value }) => value ? moment(value).format('lll') : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "brand_name", headerName: "Brand", width: 180, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "pixel_id", headerName: "PID", width: 100, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        {
            field: "author", headerName: "User", width: 180, sortable: false, renderCell: (params) => {
                const user = params.row

                return <span
                    onClick={() => {
                        setUser({
                            id: user?.user_id,
                            name: user?.author,
                            image: user?.profile_image_url,
                            customer_id: user?.customer_id,
                            type: user?.account_type
                        })
                        setModal(true)
                    }}
                    className="account-name-hover-style"
                    style={user?.author ? {} : { color: "#bebebe" }}>
                    {user?.account_type === "brand" ? `(Brand) - ${user?.author}` : user?.author ? user?.author : "Empty"}
                </span>
            }
        },
        {
            field: "title", headerName: "Video Title", width: 350, sortable: false, renderCell: ({ value }) => {
                if (value) {
                    return (
                        <Tooltip title={value} placement="top-start" arrow>
                            <span>{value}</span>
                        </Tooltip>
                    );
                } else {
                    return <span style={{ color: "#bebebe" }}>Empty</span>
                }
            }
        },
        {
            field: "content", headerName: "Comments", width: 350, sortable: false, renderCell: ({ value }) => {
                if (value) {
                    return (
                        <Tooltip title={value} placement="top-start" arrow>
                            <span>{value}</span>
                        </Tooltip>
                    );
                } else {
                    return <span style={{ color: "#bebebe" }}>Empty</span>
                }
            }
        },
        {
            field: "action", headerName: "Action", width: 120, sortable: false, renderCell: (params) => {
                if (flag) {
                    return ""
                } else {
                    return (
                        <>
                            <DeleteIcon style={{ color: "#a11818", fontSize: '1.1rem', cursor: "pointer", }}
                                onClick={() => onDelete(params?.row)}
                            />
                            {params?.row?.account_type !== 'brand' &&
                                <EditIcon style={{ color: "#052977", fontSize: '1.1rem', cursor: "pointer", marginLeft: '5px' }}
                                    onClick={() => {
                                        setUser(params?.row)
                                        setBanModal(true)
                                    }}
                                />
                            }
                        </>
                    )
                }
            }
        },
    ]
    useEffect(() => {
        setPageLoad(false)
    }, [data])

    function banUser(data) {
        userban({ ...data, ...user })
        setUser(null)
    }

    return (
        <div style={{ height: 575 }} className="pl-3">
            <BanModal
                data={user}
                visible={banModal}
                close={() => {
                    setBanModal(false)
                }}
                ban={banUser}
            />
            <UserModal
                from={'withdraw'}
                visible={modal}
                close={() => {
                    setUser(null)
                    setModal(false);
                }}
                userImage={user?.image}
                userName={user?.name}
                deleted={false}
                currentCustomerId={user?.customer_id}
                currentUser={user?.id}
                nowGet={() => { }}
                userType={user?.type}
            />
            <DataGrid
                loading={pageLoad}
                showCellRightBorder={true}
                rowHeight={35}
                disableColumnFilter={true}
                density="compact"
                components={{ NoRowsOverlay: NoRows }}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                rows={
                    data?.data?.map((item, index) => ({
                        ...item,
                        id: index + (page == 1 ? 1 : (page * 20) - 19),
                    }))
                }
                columns={column}
                rowCount={data?.totalCount ? data?.totalCount : 0}
                pagination
                pageSize={20}
                rowsPerPageOptions={[20]}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPageLoad(true)
                    setPage(newPage + 1);
                    nowGet(newPage + 1);
                }}

            />
        </div>
    )
}
export default React.memo(DataTable)