import Axios from "axios"
import konnect from "./axios"
import { FEED, ADFEED, VARIANT_FEED, FEED_STATS, FEED_USERS, VIDEO_STATS, FEED_STATS_BRAND } from "./type"


//main Feed
export const feedSetting = (type) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/feeds/getfeedsetting', { type })
        dispatch({
            type: FEED,
            payload: res.data
        })
    } catch (err) {
        console.log(err.response)
    }
}
export const followingFeedSetting = (type) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/feeds/getFollowingFeedSetting', { type })
        return res.data
    } catch (err) {
        console.log(err.response)
    }
}

export const updateFollowingFeedSetting = (data, type) => async (dispatch) => {
    let updates = {
        type,
        date_range: data?.range,
        posts_to_read: data?.posts_to_read,
        is_category: data.is_category,
        boosted_percent: data.boosted_percent,
        sort_by: data.sort_by
    }
    if (data.id) { updates.id = data?.id }
    const res = await konnect.post(`/v1/admin/feeds/updateFollowingFeedSetting`, updates)
    return res.data
}

export const forYouFeedSetting = (type) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/feeds/getForYouFeedSetting', { type })
        return res.data
    } catch (err) {
        console.log(err.response)
    }
}

export const updateForYouFeedSetting = (data, type) => async (dispatch) => {
    let updates = {
        ...data
    }
    if (data.id) { updates.id = data?.id }
    const res = await konnect.post(`/v1/admin/feeds/updateForYouFeedSetting `, updates)
    return res.data
}

export const cleanfeed = () => async (dispatch) => {
    dispatch({
        type: FEED,
        payload: {}
    })
}

export const updateFeedSetting = (data, type) => async (dispatch) => {
    let updates = {
        type,
        date_range: data?.range,
        posts_to_read: data?.post,
        ...data
    }
    // let updates = {
    //     type,
    //     date_range: data?.range,
    //     posts_to_read: data?.post,
    //     refresh_time: data?.ttl,
    //     follower_percent: data?.follower_percent,
    //     other_percent: data?.other_percent,
    //     is_category: data?.is_category,
    // }
    const url = data?.id ? "updatefeedsetting" : "createfeedsetting"
    if (data.id) { updates.id = data?.id }
    const res = await konnect.post(`/v1/admin/feeds/${url}`, updates)
    return res.data
}


//AD FEED
export const adfeedSetting = (type) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/feeds/getadfeedsetting', { type })
        dispatch({
            type: ADFEED,
            payload: res.data
        })
    } catch (err) {
        console.log(err.response)
    }
}
export const cleanadfeed = () => async (dispatch) => {
    dispatch({
        type: ADFEED,
        payload: {}
    })
}
export const updateadFeedSetting = (data, type) => async (dispatch) => {
    let updates = { type, ad_percent: data.percent }
    const url = data?.id ? "updateadfeedsetting" : "createadfeedsetting"
    if (data.id) { updates.id = data?.id }
    const res = await konnect.post(`/v1/admin/feeds/${url}`, updates)
    return res.data
}

//Variant Feed
export const getVariantFeeds = (type) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/feeds/getvariantfeedsetting', { type })
        dispatch({
            type: VARIANT_FEED,
            payload: res.data
        })
    } catch (err) {
        console.log(err.response)
    }
}
export const cleanVariants = () => async (dispatch) => {
    dispatch({
        type: VARIANT_FEED,
        payload: {}
    })
}
export const updateVariantFeed = (data, type) => async (dispatch) => {
    const url = data?.id ? "updatevariantfeedsetting" : "createvariantfeedsetting"
    let updates = {
        type,
        most_sold_percent: Number(data.sale),
        most_viewed_percent: Number(data.views),
        most_liked_percent: Number(data.like),
        most_commented_percent: Number(data.comment),
        most_shared_percent: Number(data.share),
        date_range: Number(data.range),
        most_popular_percent: Number(data.popular),
        new_video_date_range: Number(data.newRange),
        new_video_percent: Number(data.new_video),
    }
    if (type === "review") {
        updates.ad_percent = Number(data.ad)

    }
    if (data.id) { updates.id = data?.id }
    try {

        const res = await konnect.post(`/v1/admin/feeds/${url}`, updates)
        return res.data
    } catch (err) {
        return err.response.data
    }
}


export const generateFeed = (type) => async (dispatch) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
    const res = await Axios.post(process.env.REACT_APP_MICRO_FEED_URL + 'v1/admin/feeds/createfeed', { type }, { headers })
    return res.data
}

// export const generateFeed = (type) => async (dispatch) => {
//     const res = await konnect.post('/v1/admin/feeds/createfeed', { type })
//     return res.data
// }

export const getFeedStats = (data, page) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/promotedVideos/userWiseAnalytics`, data, {
            params: { page, limit: 15 }
        })
        dispatch({
            type: FEED_STATS,
            payload: res?.data
        })
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
        // return err?.response
    }
}
export const getFeedStatsOld = (data, page = 1) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/promotedVideos/getUserAnalyticsFromTable`, data, {
            params: { page, limit: 15 }
        })
        dispatch({
            type: FEED_STATS,
            payload: res?.data
        })
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
        // return err?.response
    }
}
export const getFeedStatsSummary = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/promotedVideos/userWiseAnalyticsSummary`, data)
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
        // return err?.response
    }
}
export const getFeedStatsBrand = (data, page) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/promotedVideos/brandWiseAnalytics`, data, {
            params: { page, limit: 20 }
        })
        dispatch({
            type: FEED_STATS_BRAND,
            payload: res?.data
        })
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
        // return err?.response
    }
}

export const getFeedUsers = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/promotedVideos/getUser`, data)
        dispatch({
            type: FEED_USERS,
            payload: res?.data
        })
        return res?.data
    } catch (err) {
        return err?.response
    }
}
export const getRange = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/feeds/getfeedcount`, data)
        return res?.data
    } catch (err) {
        return err?.response
    }
}

export const getVideoFeed = (data, page, date) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/promotedVideos/getVideoanalaytics`, data, {
            params: { page, limit: 20 }
        })
        dispatch({
            type: VIDEO_STATS,
            payload: res?.data
        })
        return res?.data
    } catch (err) {
        return err?.response
    }
}
export const getVideoFeedOld = (data, page, date) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/promotedVideos/getVideoAnalyticsFromTable`, data, {
            params: { page, limit: 20 }
        })
        dispatch({
            type: VIDEO_STATS,
            payload: res?.data
        })
        return res?.data
    } catch (err) {
        return err?.response
    }
}