import React, { useEffect, useState } from 'react'
import * as accountActions from "../../../store/actions/account"
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import numeral from "numeral";
import Skeleton from '../../../reUsable/skeleton';
import { Row, Col } from "react-bootstrap"
import { Button, CardHeader, Divider, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment"
import { toast } from 'react-toastify';


function Balance({ accountBalance, currentUser, getBalance, userType, addBalance }) {
    const [load, setLoad] = useState(false);
    const [add, setAdd] = useState(false);
    const [date, setDate] = useState(new Date());
    const [fields, setFields] = useState({
        amount: "",
        ref: "",
        receipt_date: moment().format("YYYY-MM-DD"),
    })
    useEffect(() => {
        setLoad(true)
        getBalance(currentUser, userType).then(() => setLoad(false))
    }, [])

    const handleSave = () => {
        setLoad(true)
        let data = {
            user_id: currentUser,
            ...fields
        }
        addBalance(data).then((res) => {
            toast.success(`${res.data.message}`)
            getBalance(currentUser, userType).then(() => {
                setLoad(false)
                clear()
            })
        })
    }
    function clear() {
        setFields({
            amount: "",
            ref: "",
            receipt_date: moment().format("YYYY-MM-DD"),
        })
        setDate(new Date())
    }
    return (
        <div>
            <Typography className='title-bar-hd-main mb-3' color="primary" variant="h5" gutterBottom component="div">
                Balance
            </Typography>
            {

                <div className='container-fluid m-0 pl-0 '>
                    {(userType === "brand") &&
                        // <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }} >
                        <Row className="d-flex justify-content-end">
                            <Col xs={12} md={6} xl={4} className="filter-clear-col mb-20 justify-content-end ">
                                {add && <Button
                                    className="btn-width btn-gen-margin grey-btn-outline h-100"
                                    variant="outlined"
                                    sx={{ color: 'gray' }}
                                    disableElevation
                                    size="medium"

                                    // startIcon={<SaveIcon />}
                                    onClick={() => {
                                        setAdd(false)
                                        clear()
                                    }}
                                >
                                    Cancel
                                </Button>}

                                <Button
                                    className="btn-width btn-gen-margin ml-0 h-100 mr-0"
                                    disableElevation
                                    size="medium"
                                    variant="contained"
                                    // startIcon={<SaveIcon />}
                                    onClick={() => setAdd(true)}
                                >
                                    Add Balance
                                </Button>
                            </Col>
                        </Row>
                        // </div>
                    }
                    <Row className="d-flex justify-content-between">
                        <Col xl={8} lg={8} md={12}>
                            {load ? <Skeleton /> :
                                <>
                                    {userType !== "brand" &&
                                        <>
                                            <Row>
                                                <Col xl={6} lg={8} className='mb-4'>
                                                    <Card variant="outlined" className='BalanceCard addons balance-box'>
                                                        <CardHeader title="Lifetime Purchases" />
                                                        <Divider />
                                                        <div className='titleLifetime'>
                                                            <div className='head'>Lifetime Cashback <span className='cba'>{numeral(accountBalance?.cashbackAmount).format("$0,0.00", Math.floor)}</span></div>
                                                            <div className='head'>Lifetime {userType === "influencer" ? "Earnings" : "Commissions"} <span className='cba'>{numeral(accountBalance?.commissionAmount).format("$0,0.00", Math.floor)}</span></div>
                                                            <div className='head last'>Lifetime Withdrawals <span className='cba'>{numeral(accountBalance?.withdrawal).format("$0,0.00", Math.floor)}</span></div>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={6} lg={8} className='mb-4 '>
                                                    <Card variant="outlined" className='BalanceCard addons balance-box'>
                                                        <CardHeader title="Balances" />
                                                        <Divider />
                                                        <div className='titleLifetime'>
                                                            <div className='head'>Available Balance <span className='cba'>{numeral(accountBalance?.balanceAmount).format("$0,0.00", Math.floor)}</span></div>
                                                            <div className='head'>Available to Withdraw <span className='cba'>{numeral(accountBalance?.availableWithdrawAmount).format("$0,0.00", Math.floor)}</span></div>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {userType === "brand" &&
                                        <>
                                            <Row>
                                                <Col xl={6} lg={8} className='mb-4'>
                                                    <Card variant="outlined" className='BalanceCard addons balance-box'>
                                                        <CardHeader title="History" />
                                                        <Divider />
                                                        <div className='titleLifetime'>
                                                            <div className='head'>Lifetime Commissions Paid <span className='cba'>{numeral(accountBalance?.totalCommissionAmountPay).format("$0,0.00", Math.floor)}</span></div>
                                                            <div className='head'>Lifetime Cashback Paid<span className='cba'>{numeral(accountBalance?.totalCashbackPay).format("$0,0.00", Math.floor)}</span></div>
                                                            <div className='head'>Lifetime KB Paid<span className='cba'>{numeral(accountBalance?.totalKbFeeAmountPay).format("$0,0.00", Math.floor)}</span></div>
                                                            <div className='head'>Lifetime Deposit<span className='cba'>{numeral(accountBalance?.totalDepositAmount).format("$0,0.00", Math.floor)}</span></div>
                                                            <div className='head'>Total Amount Paid<span className='cba'>{numeral(accountBalance?.totalAmountPay).format("$0,0.00", Math.floor)}</span></div>
                                                            <div className='head'>Lifetime Withdrawal<span className='cba'>{numeral(accountBalance?.withdrawal).format("$0,0.00", Math.floor)}</span></div>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={6} lg={8} className='mb-4 '>
                                                    <Card variant="outlined" className='BalanceCard addons balance-box'>
                                                        <CardHeader title="Balances" />
                                                        <Divider />
                                                        <div className='titleLifetime'>
                                                            <div className='head'>Available Balance <span className='cba'>{numeral(accountBalance?.totalBalance).format("$0,0.00", Math.floor)}</span></div>
                                                            <div className='head'>Available to Withdraw <span className='cba'>{numeral(accountBalance?.totalBalance).format("$0,0.00", Math.floor)}</span></div>
                                                            {/* <div className='head'>Total Deposit <span className='cba'>{numeral(accountBalance?.totalDepositAmount).format("$0,0.00", Math.floor)}</span></div> */}
                                                        </div>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {/* {
                                        userType === "brand" &&
                                        <>
                                            <Row className=''>
                                                <Divider />
                                                {userType !== "customer" &&
                                                    <Col xl={5} lg={6} className='mb-4 text-center'>
                                                        <Card variant="outlined" className='users-card-main addons balance-box'>
                                                            <Box className="addons-cart-title">
                                                                <Typography className='fw-normal mb-0' color="grey" variant="h6" gutterBottom component="div">
                                                                    Deposit
                                                                </Typography>
                                                            </Box>
                                                            <CardContent className='pb-0 pt-0'>
                                                                <Typography className='fw-semi-bold mb-0' color="grey" variant="h5" gutterBottom component="div">
                                                                    {numeral(accountBalance?.deposit_amount).format("$0,0.00", Math.floor)}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Col>
                                                }
                                                <Col xl={5} lg={6} className='mb-4 text-center'>
                                                    <Card variant="outlined" className='users-card-main addons balance-box white-box' sx={{ minWidth: 275 }}>
                                                        <Box className="addons-cart-title">
                                                            <Typography className='fw-normal mb-0' color="white" variant="h6" gutterBottom component="div">
                                                                Balance
                                                            </Typography>
                                                        </Box>
                                                        <CardContent className='pb-0 pt-0 balance-box-amount'>
                                                            <Typography className='fw-semi-bold mb-0' color="white" variant="h5" gutterBottom component="div">
                                                                {userType === "brand" ? numeral(accountBalance?.current_balance).format("$0,0.00", Math.floor) : numeral(accountBalance?.total_balance).format("$0,0.00", Math.floor)}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Col>
                                                {userType === "customer" &&
                                                    <Col xl={5} lg={6} className='mb-4 text-center'>
                                                        <Card variant="outlined" className='users-card-main addons balance-box' sx={{ minWidth: 275 }}>
                                                            <Box className="addons-cart-title">
                                                                <Typography className='fw-normal mb-0' color="grey" variant="h6" gutterBottom component="div">
                                                                    {userType === "brand" ? "Net Sales" : "Withdrawal"}
                                                                </Typography>
                                                            </Box>
                                                            <CardContent className='pb-0 pt-0'>
                                                                <Typography className='fw-semi-bold mb-0' color="grey" variant="h5" gutterBottom component="div">
                                                                    {userType === "brand" ? numeral(accountBalance?.total_sale).format("$0,0.00", Math.floor) : numeral(accountBalance?.withdrawal).format("$0,0.00", Math.floor)}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Col>
                                                }
                                            </Row>
                                            <Row className=''>
                                                {userType !== "customer" &&
                                                    <Col xl={5} lg={6} className='mb-4 text-center'>
                                                        <Card variant="outlined" className='users-card-main addons balance-box' sx={{ minWidth: 275 }}>
                                                            <Box className="addons-cart-title">
                                                                <Typography className='fw-normal mb-0' color="grey" variant="h6" gutterBottom component="div">
                                                                    {userType === "brand" ? "Net Sales" : "Withdrawal"}
                                                                </Typography>
                                                            </Box>
                                                            <CardContent className='pb-0 pt-0'>
                                                                <Typography className='fw-semi-bold mb-0' color="grey" variant="h5" gutterBottom component="div">
                                                                    {userType === "brand" ? numeral(accountBalance?.total_sale).format("$0,0.00", Math.floor) : numeral(accountBalance?.withdrawal).format("$0,0.00", Math.floor)}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Col>
                                                }
                                                <Col xl={5} lg={6} className='mb-4 text-center'>
                                                    <Card variant="outlined" className='users-card-main addons balance-box' sx={{ minWidth: 275 }}>
                                                        <Box className="addons-cart-title">
                                                            <Typography className='fw-normal mb-0' color="grey" variant="h6" gutterBottom component="div">
                                                                Commission {userType === "brand" ? "Paid" : "Earn"}
                                                            </Typography>
                                                        </Box>
                                                        <CardContent className='pb-0 pt-0'>
                                                            <Typography className='fw-semi-bold mb-0' color="grey" variant="h5" gutterBottom component="div">
                                                                {userType === "brand" ? numeral(accountBalance?.spent_amount).format("$0,0.00", Math.floor) : numeral(accountBalance?.earning_amount).format("$0,0.00", Math.floor)}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Col>

                                            </Row>
                                        </>
                                    } */}

                                </>
                            }
                        </Col>
                        {(add && userType === "brand") &&

                            <Col xl={4} lg={4} md={12}>
                                <div className='infoSection new mb-3' >
                                    <Box className="addons-cart-title d-flex align-items-center">
                                        <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                            Deposit
                                        </Typography>
                                    </Box>
                                    <Divider className='mb-20' />

                                    <TextField
                                        size="small"
                                        value={fields.amount}
                                        onChange={e => setFields({ ...fields, amount: e.target.value })}
                                        id="outlined-basic"
                                        label="Amount"
                                        variant="outlined"
                                        type="number"
                                        className="field-style mb-15"
                                    />
                                    <TextField
                                        size="small"
                                        value={fields.ref}
                                        onChange={e => setFields({ ...fields, ref: e.target.value })}
                                        id="outlined-basic"
                                        label="Reference"
                                        variant="outlined"
                                        className="field-style mb-15"
                                    />
                                    <div className='mb-15'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                allowSameDateSelection
                                                sx={{ color: "black" }}
                                                label="Receipt Date"
                                                value={date}
                                                onChange={(newValue) => {
                                                    setDate(new Date(newValue))
                                                    setFields({ ...fields, receipt_date: moment(newValue).format("YYYY-MM-DD") })
                                                }}
                                                renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '100%', }} {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <Button
                                        className="btn-width btn-gen-margin ml-0 h-100"
                                        disableElevation
                                        size="medium"
                                        variant="contained"
                                        startIcon={<SaveIcon />}
                                        onClick={() => handleSave()}
                                        disabled={fields.amount === "" || fields.ref === ""}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Col>}

                    </Row>

                </div>
            }
        </div>
    )
}

function mapStateToProps({ accountBalance, }) {
    return { accountBalance }
}
export default connect(mapStateToProps, accountActions)(Balance)