import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';
import { endOfDay, parseISO } from "date-fns";
import { Col, Row } from 'react-bootstrap'
import { Autocomplete, Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import SkeletonLoader from "reUsable/skeleton";
import *  as discountActions from 'store/actions/discount.action'
import SearchBrand from 'reUsable/SearchBrand';
import DataTable from "./Table"
import AddIcon from '@mui/icons-material/Add';
import ConfirmModal from 'reUsable/ConfirmModal';
import SaveModal from "reUsable/SaveModal"
import DateBtn from 'reUsable/DateBtn';
import DiscountModal from "./DiscountModalAdd"
import { toast } from 'react-toastify';
export const Discounts = ({ discounts, getDiscounts, addDiscount, deleteDiscount, updateDiscountStatus }) => {
    const [loading, setLoading] = useState(true)
    const [value, setValue] = useState([moment().startOf('year').format("YYYY-MM-DD"), moment().endOf('year').format("YYYY-MM-DD")]);
    const [fields, setFields] = useState({ brand_id: '', active: true, name: '' })
    const [visible, setVisible] = useState(false)
    const [modal, setModal] = useState(false)
    const [detail, setDetail] = useState(null)
    useEffect(() => {
        getData(fields, value)
    }, [])

    function handleSearch(type) {
        if (type === "refresh") {
            setValue([moment().startOf('year').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),])
            setFields({ brand_id: '', active: true, name: '' })
            getData({ brand_id: '', active: true, name: '' }, [moment().startOf('year').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),])
        } else {
            getData(fields, value)
        }
    }

    const getData = (obj, date) => {
        setLoading(true)
        getDiscounts({ ...obj, start_date: date[0], end_date: date[1] }, 1).finally(() => setLoading(false))
    }

    function createDiscount() {
        addDiscount(detail).then((res) => {
            if (res.success) {
                toast.success(res?.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            } else {
                toast.error(res?.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
        }).finally(() => {
            setDetail(null)
            setModal(false)
            getData(fields, value)

        })
    }

    function onDelete(data) {
        deleteDiscount({ discount_id: data._id, brand_id: data.brand_id }).then((res) => {
            if (res.success) {
                toast.success(res?.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            } else {
                toast.error(res?.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
        }).finally(() => {
            getData(fields, value)
        })
    }

    function onUpdate(data) {
        updateDiscountStatus({ discount_id: data._id, brand_id: data.brand_id, active: !data.active }).then((res) => {
            if (res.success) {
                toast.success(res?.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            } else {
                toast.error(res?.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
        }).finally(() => {
            getData(fields, value)
        })
    }



    return (
        <div className='container-fluid mt-3 mb-3 pl-0'>
            <DiscountModal
                visible={visible}
                clear={() => { setVisible(false) }}
                // create={createDiscount}
                create={(data) => {
                    setDetail(data)
                    setModal(true)
                }
                }
            />
            <SaveModal
                visible={modal}
                title={detail?.brand_ids?.length === 0 ? "Are you sure you want to add this discount to all brands?" : `Are you sure you want to add this discount?`}
                close={() => {
                    setDetail(null)
                    setModal(false)
                }}
                type="status"
                call={createDiscount}
            />

            <Row className='pl-15'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Discounts
                    </Typography>
                </Col>
                <Col xs className="mb-3 text-right">
                    <Button
                        onClick={() => {
                            setVisible(true)
                        }}
                        className='btn-width'
                        startIcon={<AddIcon />}
                        disableElevation
                        variant="contained">
                        Add Discount
                    </Button>
                </Col>
            </Row>

            <Row className="account-main-row pl-15">
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="dateBox">
                            <DatePicker
                                minDate={new Date(2023, 0, 1)}
                                slotProps={{ textField: { helperText: "YYYY / MM / DD" } }}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="Start Date"
                                value={parseISO(value[0])}
                                onChange={(newValue) => { setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]) }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style"
                                        size="small"
                                        style={{ backgroundColor: "white", width: "50%" }}
                                        {...params}
                                    />
                                )}
                            />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <DatePicker
                                minDate={new Date(2023, 0, 1)}
                                slotProps={{ textField: { helperText: "YYYY / MM / DD" } }}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="End Date"
                                value={parseISO(value[1])}
                                onChange={(newValue) => { setValue([value[0], moment(newValue).format("YYYY-MM-DD")]) }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style"
                                        size="small"
                                        style={{ backgroundColor: "white", width: "50%" }}
                                        {...params}
                                    />
                                )}
                            />
                        </div>
                    </LocalizationProvider>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <TextField
                        size="small"
                        value={fields.name}
                        onChange={e => setFields({ ...fields, name: e.target.value })}
                        id="outlined-basic"
                        label="Discount Code"
                        variant="outlined"
                        className="field-style"
                    />
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <SearchBrand setter={(value) => {
                            setFields({ ...fields, brand_id: value })
                        }} brand={fields.brand_id} />
                    </FormControl>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel
                            id="demo-simple-select-label"
                        >Status</InputLabel>
                        <Select
                            sx={[{ color: "black" }]}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fields.active}
                            label="Status"
                            onChange={(e) => {
                                setFields({ ...fields, active: e.target.value, })
                            }}
                        >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Not Active</MenuItem>
                        </Select>
                    </FormControl>
                </Col>

                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <Button
                        variant="contained"
                        size="medium"
                        className="btn-gen-margin btn-width laptop ml-0"
                        color="primary"
                        startIcon={<SearchIcon />}
                        disableElevation
                        onClick={() => { handleSearch() }}
                    >
                        Search
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        startIcon={<RefreshIcon />}
                        className="btn-gen-margin btn-width laptop"
                        color="success"
                        disableElevation
                        onClick={() => { handleSearch('refresh') }}
                    >
                        Refresh
                    </Button>
                </Col>
            </Row>
            <Divider className="ml-15" />
            {loading ? (
                <div className="pl-15" style={{ marginTop: 16 }}>
                    <SkeletonLoader />
                </div>
            ) : (
                <DataTable
                    data={discounts}
                    nowGet={(page) => {
                        getDiscounts({ ...fields, start_date: value[0], end_date: value[1] }, page)
                    }}
                    onDelete={onDelete}
                    onUpdate={onUpdate}
                />
            )}
        </div>
    )
}

const mapStateToProps = ({ discounts }) => ({ discounts })
export default connect(mapStateToProps, { ...discountActions })(Discounts)