import konnect from "./axios"
import { PLAYBACK_URL } from "./type"

export const getDomain = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/crm/getDomain', data)
        dispatch({
            type: PLAYBACK_URL,
            payload: res.data.data
        })
        return res.data
    } catch (err) {
        return err.response.data
    }
}
export const addDomain = (fields) => async (dispatch) => {
    let data = { ...fields }
    const res = await konnect.post('/v1/admin/crm/createDomain', data)
    return res.data
}