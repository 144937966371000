import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActions, Divider } from '@mui/material';
import DeleteModal from 'reUsable/DeleteModal';
// import * as subCatActions from "../store/actions/subCategory"
import CardHeader from '@mui/material/CardHeader';
import { GoVerified } from "react-icons/go";
import { ImCross } from "react-icons/im";
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import UpdateCategory from '../pages/MainCategories/Category/UpdateCategory';
import Box from '@mui/material/Box';
import defaultImg from "../assets/images/default.jpg"

import * as catActions from "../store/actions/Categories/categories"


function Category({ deleteCategory, reloadData, changeStatusCategory, changeSubFeatureStatus, data, catData, item, }) {
    const [currentData, setCurrentData] = useState({})
    const [loading, setLoading] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)
    const [modal, setModal] = useState(false)

    function onDelete() {
        setLoading(true)
        deleteCategory(currentData._id, "new")
            .then((res) => {
                setLoading(false)
                reloadData()
                setModal(false)
                toast.success(res.data.message)
            })
            .catch(err => {
                setLoading(false)
                setModal(false)
                toast.error(err.response?.message)
            })
    }

    function onShowModal(item) {
        setCurrentData(item)
        setModal(true)
    }
    function onShowUpdateModal(item) {
        setCurrentData(item)
        setUpdateModal(true)
    }
    return (
        <>
            <UpdateCategory
                visible={updateModal}
                data={currentData}
                close={() => setUpdateModal(false)}
                reloadData={reloadData}
                catData={catData}
            />
            <DeleteModal
                visible={modal}
                title="Are you sure you want to delete this category?"
                close={() => setModal(false)}
                call={onDelete}
            />
            <div className='container-fluid' >
                {
                    item?.categories?.length > 0 &&
                    <Row key={item._id}>
                        <Col xl={12} className="mb-15" >
                            <Card key={item._id} variant="outlined" className='users-card-main subCategoryBox' style={{ minHeight: 'initial' }} >
                                <Box className='cus-cat-user-info-box-top'>
                                    <div>
                                        <div className='cus-cat-user-info-img-main ' style={{
                                            display: "flex", alignItems: "center", justifyContent: "center"
                                        }}>
                                            {item?.name}
                                            {/* <img
                                                className='cus-cat-user-info-img'
                                                src={item.image_url ? item.image_url : defaultImg}
                                            /> */}
                                        </div>
                                    </div>
                                    <CardContent style={{ width: '100%', paddingTop: '10px', paddingBottom: '0px' }} >
                                        <Typography gutterBottom variant="h6" className='fw-normal mb-0' color="primary" component="div">
                                            {item?.name}
                                        </Typography>
                                        <Typography gutterBottom variant="body2">
                                            General Category
                                        </Typography>
                                    </CardContent>
                                </Box>
                                <Divider className='mb-15' />
                                <Row>
                                    <Col xl={12}>
                                        <Typography className='title-bar-hd-main mb-20 fw-normal subCat-sub-title ' color="gray" variant="h6" gutterBottom component="div">
                                            Categories
                                        </Typography>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        item?.categories?.map(item => (
                                            <Col key={item._id} xs={12} md={6} lg={4} xl={2} className="categories-box cat-new-box">
                                                <Card key={item._id} sx={{ display: 'flex' }} >
                                                    <CardHeader
                                                        className='category-header'
                                                        title={
                                                            <Typography className='categories-hd ' variant="h5" component="div">
                                                                {item?.category_name}
                                                            </Typography>
                                                        }
                                                        subheader={
                                                            <SwitchComponent item={item} changeFeature={changeSubFeatureStatus} reloadData={reloadData} changeStatus={changeStatusCategory} />
                                                        }
                                                    />
                                                    {/* <div className='newCatName mb-2' >{item?.category_name.toUpperCase()}</div> */}
                                                    {/* <CardMedia
                                                        component="img"
                                                        image={item.image_url}
                                                        alt="green iguana"
                                                    /> */}
                                                    <Box sx={{ width: '100%' }}>
                                                        <CardActions className='categories-box-buttons'>
                                                            <Button
                                                                onClick={() => onShowUpdateModal(item)}
                                                                variant="contained"
                                                                size="small"
                                                                color="primary"
                                                                disableElevation
                                                                startIcon={<EditIcon />}
                                                            >
                                                                Edit
                                                            </Button>
                                                            <Button
                                                                style={{ marginLeft: 10 }}
                                                                onClick={() => onShowModal(item)}
                                                                variant="outlined"
                                                                size="small"
                                                                color="primary"
                                                                disableElevation
                                                                startIcon={<DeleteIcon />}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </CardActions>
                                                    </Box>
                                                </Card>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        </>
    )
}
export default connect(null, catActions)(Category)
const SwitchComponent = ({ item, changeFeature, reloadData, changeStatus, }) => {
    const [check, setCheck] = useState(true)
    const [isPopular, setIsPopular] = useState(false)
    useEffect(() => {
        setCheck(item.is_active)
        setIsPopular(item.is_featured)
    }, [])
    useEffect(() => {
        if (!check) setIsPopular(check)
    }, [check])
    return (
        <>

            {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}> */}
            {/* <Box>
                <p className='mb-0'>Featured</p>
                <Switch
                    disabled={!check}
                    size="small"
                    color="success"
                    checked={isPopular}
                    onChange={(e) => {
                        setIsPopular(e.target.checked)
                        changeFeature({ category_id: item._id, is_featured: e.target.checked })
                            .then(() => {
                                reloadData()
                            })
                    }}
                />
            </Box> */}
            <Box className='d-flex justify-content-between' >
                <p className='mb-0'>Status</p>
                <Switch
                    size="small"
                    color="success"
                    checked={check}
                    onChange={(e) => {
                        setCheck(e.target.checked)
                        changeStatus(item._id)
                            .then(() => {
                                reloadData()
                            })
                    }}
                />
            </Box >
            {/* </Box> */}
        </>

    )
}