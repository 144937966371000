import konnect from "./axios"
import { INTRO } from "./type"


export const getIntroVideos = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/trainingVid/getTrainingVideos', { ...data }, { params: { page: 1, limit: 10 } })
        dispatch({
            type: INTRO,
            payload: res.data,
        })
        return res.data
    } catch (err) {
        console.log(err.response)
        dispatch({
            type: INTRO,
            payload: {},
        })
    }
}

export const deleteIntroVideo = (data) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/trainingVid/deleteTrainingVideos', data)
    return res.data
}

export const resetFrequency = (data) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/trainingVid/resetUsersViews', data)
    return res.data
}

export const addVideo = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/trainingVid/addOrUpdateTrainingVideos', data)
        return res.data
    } catch (err) {
        return err.message
    }

}
