import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Card, Col, Row } from "react-bootstrap"
import { Box, CardHeader, Checkbox, Divider, TextField, Typography } from "@mui/material";
import * as accountActions from "../../../store/actions/account"
import { CheckBox } from "@mui/icons-material";
import numeral from "numeral";



function Shipping({ getDetails, accountDetail, currentUser, deleted, userType }) {
    useEffect(() => {
        getDetails(currentUser, deleted)
    }, [])

    const data = useMemo(() => {
        const { userDetails } = accountDetail
        if (userType === "brand") {
            if (userDetails?.shipping_methods.length > 0) {
                return userDetails?.shipping_methods
            } else {
                return null
            }
        } else {
            if (userDetails?.shipping_addresses?.length > 0 && !userDetails?.is_house_influencer) {
                return userDetails?.shipping_addresses
            } else {
                return []
            }
        }
    }, [accountDetail])


    return (
        <div className='saleContainer'>
            <Row className='align-items-center'>
                <Col xl={5} lg={6}>
                    {/* <Col xl={12} lg={12}> */}
                    <Card variant="outlined" className='users-card-main' style={{ minHeight: 'initial' }} sx={{ minWidth: 275 }}>
                        <Typography className='title-bar-hd-main mb-1' color="primary" variant="h5" gutterBottom component="div">Shipping {userType === "brand" ? "Methods" : "Addresses"}</Typography>
                        <Divider className='mb-4' />
                        {data?.length == 0 && <div className="fw-bold ml-2">No Data</div>}
                        <div className='container-fluid ml-0 p-0 shipping-new'>
                            <Row className='pt-3' >
                                {userType === "brand" ?
                                    <>
                                        {data?.map(item => (
                                            <Col key={item?._id} md={12} lg={12} className='mb-3' >
                                                <Card sx={{ display: 'flex' }} key={item?._id}>
                                                    <CardHeader className='category-header' />
                                                    <Box className='px-3'>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <div className='shipping-info-line'>
                                                                    <div className='ship-info-hd'>Name: </div> <div className='ship-info-hd text-right'>{item?.name}</div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <div className='shipping-info-line'>
                                                                    <div className='ship-info-hd'>Amount: </div> <div className="text-right">{numeral(item?.amount).format("$0,0.00", Math.floor)}</div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <div className='shipping-info-line no-border'>
                                                                    <div className='ship-info-hd'>Default:</div>
                                                                    <div className="text-right"> <Checkbox size="small" checked={item?.is_default} color="primary" /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Box>
                                                </Card>
                                            </Col>
                                        ))}
                                    </> :
                                    <>
                                        {
                                            data?.map((item) => (
                                                <Col key={item?._id} md={12} lg={12} className='mb-3' >
                                                    <Card sx={{ display: 'flex' }} >
                                                        <CardHeader className='category-header' />
                                                        <Box className='px-3'>
                                                            <Row>
                                                                <Col xs={12}>
                                                                    {
                                                                        item?.is_default &&
                                                                        <div className='shipping-info-line'>
                                                                            <div className='ship-info-hd' style={{ color: "gray", }}>Default </div>
                                                                        </div>
                                                                    }

                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line'>
                                                                        <div className='ship-info-hd'>Name: </div> <div className='ship-info-hd text-right'>{item?.firstName} {item?.lastName}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line'>
                                                                        <div className='ship-info-hd'>Phone: </div> <div className='ship-info-hd text-right'>{item?.phone} </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line'>
                                                                        <div className='ship-info-hd'>Address: </div> <div className='ship-info-hd text-right'>{item?.address1}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line'>
                                                                        <div className='ship-info-hd'>Zip: </div> <div className='ship-info-hd text-right'>{item?.zip}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line'>
                                                                        <div className='ship-info-hd'>City: </div> <div className='ship-info-hd text-right'>{item?.city}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line'>
                                                                        <div className='ship-info-hd'>State: </div> <div className='ship-info-hd text-right'>{item?.province}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line no-border'>
                                                                        <div className='ship-info-hd'>Country: </div> <div className='ship-info-hd text-right'>{item?.country}</div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Box>
                                                    </Card>
                                                </Col>
                                            ))
                                        }
                                    </>
                                }

                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = ({ accountDetail }) => ({ accountDetail })

export default connect(mapStateToProps, { ...accountActions })(Shipping)

const style = {
    rowStyle: {
        marginBottom: "10px",
        fontWeight: "500",
        lineHeight: "1.25",
        color: "#001737"
    },
    colStyle: {
        textAlign: "left"
    }

}