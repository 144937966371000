import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineEdit } from "react-icons/ai";
import * as accountActions from "../../store/actions/account";
import { Button, Paper, } from "@mui/material";
import "../table.css";
import * as perActions from ".../../store/actions/authPermissions";
import { connect } from "react-redux";
import UserModal from "../../reUsable/UserModal";
import * as minimizActions from "../../store/actions/minimiz";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteModal from "reUsable/DeleteModal";
import Tooltip from "@mui/material/Tooltip";
import NoRows from "../../reUsable/NoRows";
import moment from "moment";
import { useEffect } from "react";
import SkeletonLoader from "reUsable/skeleton";


function DataTable({
  user,
  updateAgency,
  accounts,
  nowGet,
  tableLoading,
  changeActive,
  makeMasterUser,
  deleteMasterUser,
  minimize,
  minimizActions,
  deleteMinimize,
  noRows,
  id,
  type,
  editUser,
  search,
  loadTable
}) {

  const columns = [
    {
      field: "sNo",
      headerClassName: "super-app-theme--header",
      headerName: "S.no",
      minWidth: 50,
      maxWidth: 50,
      flex: 0.5,
      renderCell: ({ value }) => {
        return (
          <span className="d-flex justify-content-center w-100">{value}</span>
        );
      },
      filterable: false, sortable: false
    },
    {
      field: "pixel_id",
      headerClassName: "super-app-theme--header",
      headerName: "PID",
      minWidth: 100,
      maxWidth: 100,
      flex: 0.5,
      filterable: false, sortable: false,
      renderCell: ({ value }) => {
        return <span style={{ color: '#1665D8' }}>{value}</span>
        if (process.env.REACT_APP_BASE_URL === "https://backendlive.ormelive.com/") {
          return <a target="_blank" href={`https://www.ormelive.com/${value}`}>{value}</a>
        } else {
          return <a target="_blank" href={`https://dev.ormelive.com/${value}`}>{value}</a>
        }
      }
    },
    {
      field: "created_at",
      headerClassName: "super-app-theme--header",
      headerName: "Created On",
      minWidth: 100,
      maxWidth: 100,
      flex: 0.5,
      filterable: false, sortable: false,
      renderCell: ({ value }) => {
        return moment(value).format('MM-DD-YYYY')
      }
    },
    {
      field: "company_name",
      headerClassName: "super-app-theme--header",
      headerName: "Agency Name",
      renderCell: (params) => {
        return (
          // <Link to={`/accountMain/detail/${params.value.id}`}>
          <span
            className="account-name-hover-style"
            onClick={() => {
              setImage('')
              setUserType('agency')
              setCurrentUser(params.row._id);
              setUserName(params.row.company_name);
              if (params.row.deleted) {
                setCurrentDeleted(params.row.deleted);
              }
              // setCurrentCustomerId(params.row.customer_id);
              setUserModal(true);
            }}
          >
            {params.row.company_name}
          </span>
          // </Link>
        );
      },
      minWidth: 220,
      maxWidth: 220,
      flex: 1,
      filterable: false, sortable: false
    },
    {
      field: "email",
      headerClassName: "super-app-theme--header",
      headerName: "Email",
      minWidth: 220,
      maxWidth: 220,
      flex: 1,
      filterable: false, sortable: false
    },
    {
      field: "phone",
      headerClassName: "super-app-theme--header",
      headerName: "Phone",
      minWidth: 220,
      maxWidth: 220,
      flex: 1,
      filterable: false, sortable: false
    },
    {
      field: "is_active",
      minWidth: 100,
      maxWidth: 100,
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      renderCell: ({ value }) => {
        return (<p className='mb-0' style={{ color: value ? "green" : "red" }}>
          {value ? "Active" : "Deactive"}
        </p>)
      },
      flex: 1,
      filterable: false, sortable: false
    },
    {
      field: "agreement_status",
      headerClassName: "super-app-theme--header",
      headerName: "Agreement",
      minWidth: 120,
      maxWidth: 120,
      renderCell: ({ value }) => {
        if (value === "rejected") {
          return <p className="not-connected">Rejected</p>
        } else if (value === "accepted") {
          return <p className="not-connected" style={{ color: 'green' }}>Accepted</p>
        } else if (value === "new") {
          return <span style={{ color: "#bebebe" }}>Empty</span>
        } else {
          return value
        }
      },
      flex: 1,
      filterable: false, sortable: false
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Actions",
      flex: 1.5,
      filterable: false, sortable: false,
      minWidth: 150,
      textAlign: "center",
      renderCell: (params) => {
        return (
          <div className="action-buttons-div">
            {params.value.deleted ? null : (
              <>
                <Tooltip title="Update Details" placement="left" arrow>
                  <Button
                    className="btn-gen-margin"
                    style={{ color: "primary", borderColor: "primary" }}
                    variant="contained"
                    onClick={() => {
                      setImage('')
                      setUserType('agency')
                      setCurrentUser(params.row._id);
                      setUserName(params.row.company_name);
                      if (params.row.deleted) {
                        setCurrentDeleted(params.row.deleted);
                      }
                      // setCurrentCustomerId(params.row.customer_id);
                      setUserModal(true);
                    }}
                    disableElevation
                  >
                    <AiOutlineEdit color={"#ffffff"} size={15} />
                  </Button>
                </Tooltip>
                <Button
                  className='btn-gen-margin'
                  variant="contained"
                  color='error'
                  disableElevation
                  onClick={() => {
                    setCurrentUser(params.value._id)
                    setModal(true)
                  }}
                >
                  <RiDeleteBinLine
                    color={'white'}
                    size={15}
                  />
                </Button>
              </>
            )}

          </div >
        );
      },
    },
  ];


  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [userModal, setUserModal] = React.useState(editUser ? true : false);
  const [currentUser, setCurrentUser] = React.useState("");
  const [currentDeleted, setCurrentDeleted] = React.useState(false);
  const [currentCustomerId, setCurrentCustomerId] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [userType, setUserType] = React.useState(null)
  const [image, setImage] = React.useState('')

  useEffect(() => {
    if (editUser) {
      setImage(editUser?.profile_image_url)
      setUserType(editUser?.account_type)
      setCurrentUser(editUser?._id);
      setUserName(editUser?.name);
      setCurrentCustomerId(editUser?.customer_id);
      setUserModal(true);
    }

  }, [editUser])
  function onDelete() {
    updateAgency(currentUser).then(() => {
      nowGet(page);
    });
    setModal(false);
  }


  return (
    <div style={{ height: 470 }}>
      <DeleteModal
        visible={modal}
        title="Are you sure you want to delete this Agency?"
        close={() => setModal(false)}
        call={onDelete}
      />
      <UserModal
        visible={userModal}
        close={() => {
          setUserModal(false);
        }}
        userImage={image}
        userName={userName}
        deleted={currentDeleted}
        currentCustomerId={currentCustomerId}
        currentUser={currentUser}
        nowGet={() => nowGet(page)}
        userType={'agency'}
      />
      {loadTable ? <div className="pl-15" style={{ marginTop: 16 }}><SkeletonLoader /></div>
        : (
          search ?
            <DataGrid
              density="compact"
              disableColumnFilter={true}
              disableColumnSelector
              disableColumnMenu
              disableSelectionOnClick
              sx={{ marginTop: 2 }}
              className="bg-white ml-15"
              components={{ NoRowsOverlay: NoRows, }}

              rows={
                accounts?.message?.length > 0 ?
                  accounts?.message?.map((item, i) => {
                    return {
                      ...item,
                      plan: "basic",
                      permission: { permission: item.permission, _id: item._id },
                      packageName: { packageName: item.packageName, account_type: item.account_type },
                      amount: item.amount ? "$" + item.amount : 0,
                      id: item._id,
                      payment: item,
                      actions: item,
                      bioshop: item.instagram_username,
                      status: item,
                      sNo: i + (page == 1 ? 1 : page * 10 - 9),
                    };
                  }) : []}
              columns={columns}
              pagination
              pageSize={10}
              rowsPerPageOptions={[10]}
              rowCount={accounts?.count ? accounts?.count : 0}
              paginationMode="server"
              onPageChange={(newPage) => {
                setPage(newPage + 1);
                setLoading(true);
                nowGet(newPage + 1)
              }}
              loading={tableLoading}
            /> : null
        )}
      <div className="modal-listing-min-window campaign-bottom-bar">
        <Paper variant="outline" className="d-flex min-window-sub-box">
          {minimize.map((item) => {
            return (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  deleteMinimize(item.id);
                  setCurrentUser(item.id);
                  setUserName(item.userName);
                  setUserModal(true);
                }}
                className="d-flex min-chip"
              >
                <p className="mb-0 chip-title">{item.userName}</p>
                <div
                  onClick={() => {
                    deleteMinimize(item.id);
                    setCurrentUser(item.id);
                    setUserName(item.userName);
                    setUserModal(true);
                  }}
                  className="chip-icon-window"
                >
                  <OpenInNewIcon />
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteMinimize(item.id);
                  }}
                  className="chip-icon-cancel"
                >
                  <ClearIcon />
                </div>
              </div>
            );
          })}
        </Paper>
      </div>
    </div>
  );
}

function mapStateToProps({ minimize, editUser }) {
  return { minimize, editUser };
}
export default connect(mapStateToProps, {
  ...perActions,
  ...minimizActions,
  ...accountActions,
})(DataTable);
