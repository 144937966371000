import { Box, Button, Divider, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save';
import * as feedAction from "../../store/actions/feedAction"
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const VariantFeed = ({ variantFeed, getVariantFeeds, updateVariantFeed, cleanVariants, type }) => {
    const [load, setLoad] = useState(true)
    const [range, setRange] = useState(0);
    const [newRange, setNewRange] = useState(0);
    const [fields, setFields] = useState({ like: 0, sale: 0, views: 0, comment: 0, share: 0, ad: 0, popular: 0, new_video: 0 })
    const [error, setError] = useState(false);
    const [msg, setMSG] = useState('')
    useEffect(() => {
        setLoad(true)
        getVariantFeeds(type).then(() => setLoad(false))
        return () => cleanVariants()
    }, [])

    function submit() {
        // if (maxWeight !== 100) {
        //     setMSG("Total Weight should be equal to 100")
        //     setError(true)
        //     return
        // }
        setMSG('')
        setError(false)
        let data = {
            ...fields,
            range,
            newRange,
            id: variantFeed?.message?._id
        }
        setLoad(true)
        updateVariantFeed(data, type).then((res) => {
            if (res?.success) {
                toast.success(`${res?.message}`)
                setLoad(false)
            } else {
                toast.error(`${res?.message}`)
                setLoad(false)
            }
            getVariantFeeds(type)
        })
    }

    const feedData = useMemo(() => {
        if (variantFeed?.success) {
            if (variantFeed?.message) {
                setFields({
                    like: variantFeed?.message?.most_liked_percent,
                    sale: variantFeed?.message?.most_sold_percent,
                    views: variantFeed?.message?.most_viewed_percent,
                    comment: variantFeed?.message?.most_commented_percent,
                    share: variantFeed?.message?.most_shared_percent,
                    ad: variantFeed?.message?.ad_percent ? variantFeed?.message?.ad_percent : 0,
                    popular: variantFeed?.message?.most_popular_percent ? variantFeed?.message?.most_popular_percent : 0,
                    new_video: variantFeed?.message?.new_video_percent ? variantFeed?.message?.new_video_percent : 0

                })
                setRange(variantFeed?.message?.date_range ? variantFeed?.message?.date_range : 0)
                setNewRange(variantFeed?.message?.new_video_date_range ? variantFeed?.message?.new_video_date_range : 0)
            }
        } else {
            setFields({ like: 0, sale: 0, views: 0, comment: 0, share: 0, ad: 0, popular: 0, new_video: 0 })
            setRange(0)
            setNewRange(0)
        }
    }, [variantFeed])

    const maxWeight = useMemo(() => {
        return Object.values(fields).reduce((acc, val) => acc += Number(val), 0)
    }, [fields])

    function addWeight(name, val) {
        if (val <= 100 && val >= 0) {
            setFields({ ...fields, [name]: val === "" ? 0 : val })
        }
    }

    return (
        <div className='infoSection new mb-3' style={{ background: "#FFF" }} >
            <Box className="addons-cart-title d-flex align-items-center">
                <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                    Variant Feed
                </Typography>

            </Box>
            <Divider className='mb-20' />
            {
                type === "review" &&
                <Row>
                    <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                        <TextField
                            label=" "
                            inputProps={{ readOnly: true, }}
                            InputLabelProps={{ shrink: false }}
                            size="small"
                            variant="outlined"
                            className="field-style mb-15"
                            value={"AD "}
                        />
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                        <TextField
                            label=" "
                            InputLabelProps={{ shrink: false }}
                            size="small"
                            type='number'
                            name='ad'
                            value={fields.ad}
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    max: 100
                                },
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            onChange={(e) => {
                                addWeight(e.target.name, e.target.value)
                            }}
                            variant="outlined"
                            className="field-style mb-15"
                        />
                    </Col>
                </Row>
            }

            <Row>
                
                <Col xs={12}>
                    <div className='mb-2 newVideoFeed'>
                        <h6 style={{ color: "#052977" }}>New Video Feed</h6>
                        <Divider className='mb-20' />
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <TextField
                                    disabled={load}
                                    size="small"
                                    value={newRange}
                                    onChange={e => {
                                        let val = e.target.value;
                                        if (val > 0) {
                                            setNewRange(val)
                                        }
                                    }}
                                    id="outlined-basic"
                                    type='number'
                                    label="Range"
                                    InputProps={{
                                        inputProps: {
                                            min: 1
                                        },
                                        endAdornment: <InputAdornment position="end">days</InputAdornment>,

                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <TextField
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"New Videos"}
                                />
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                <TextField
                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='new_video'
                                    value={fields.new_video}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addWeight(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                    </div>


                </Col>
            </Row>
            {/* <Row>
                <Col xs={12}>
                    <div className='mb-2 newVideoFeed'>
                        <h6 style={{ color: "#052977" }}>Variant Feed</h6>
                        <Divider className='mb-20' />
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <TextField
                                    disabled={load}
                                    size="small"
                                    value={range}
                                    onChange={e => {
                                        let val = e.target.value;
                                        if (val > 0) {
                                            setRange(val)
                                        }
                                    }}
                                    id="outlined-basic"
                                    type='number'
                                    label="Range"
                                    InputProps={{
                                        inputProps: {
                                            min: 1
                                        },
                                        endAdornment: <InputAdornment position="end">days</InputAdornment>,

                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <TextField
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Likes "}
                                />
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                <TextField
                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='like'
                                    value={fields.like}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addWeight(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <TextField
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Sales"}
                                />
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                <TextField
                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='sale'
                                    value={fields.sale}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addWeight(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <TextField
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Views "}
                                />
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                <TextField
                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='views'
                                    value={fields.views}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addWeight(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <TextField
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Comments "}
                                />
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                <TextField
                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='comment'
                                    value={fields.comment}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addWeight(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <TextField
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Share "}
                                />
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                <TextField
                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='share'
                                    value={fields.share}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addWeight(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <TextField
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Popular "}
                                />
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                <TextField
                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='popular'
                                    value={fields.popular}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addWeight(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                    </div>


                </Col>
            </Row> */}




            {/* <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.ad}
                        onChange={e => {
                            let val = e.target.value;
                            if (val >= 0 && val <= 100) {
                                setFields({ ...fields, ad: val === "" ? 0 : val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Ad %"
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,

                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.like}
                        onChange={e => {
                            let val = e.target.value;
                            if (val >= 0 && val <= 100) {
                                setFields({ ...fields, like: val === "" ? 0 : val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Like %"
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,

                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.sale}
                        onChange={e => {
                            let val = e.target.value;
                            if (val >= 0 && val <= 100) {
                                setFields({ ...fields, sale: val === "" ? 0 : val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Sale %"
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,

                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.views}
                        onChange={e => {
                            let val = e.target.value;
                            if (val >= 0 && val <= 100) {
                                setFields({ ...fields, views: val === "" ? 0 : val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Views %"
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,

                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.comment}
                        onChange={e => {
                            let val = e.target.value;
                            if (val >= 0 && val <= 100) {
                                setFields({ ...fields, comment: val === "" ? 0 : val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Comment %"
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,

                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.share}
                        onChange={e => {
                            let val = e.target.value;
                            if (val >= 0 && val <= 100) {
                                setFields({ ...fields, share: val === "" ? 0 : val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Share %"
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,

                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.popular}
                        onChange={e => {
                            let val = e.target.value;
                            if (val >= 0 && val <= 100) {
                                setFields({ ...fields, popular: val === "" ? 0 : val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Popular %"
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,

                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row> */}
            {/* <Divider className='mb-2' />
            <Row>
                <Col className='mb-2' xs={8}>
                    <div className='d-flex justify-content-between'>
                        <div>Total</div>
                        <div>{error && <span style={{ color: "#f51313", fontSize: "0.7rem", fontWeight: "600" }}>{msg}</span>}</div>
                    </div>
                </Col>
                <Col className='mb-2 text-right' xs={4}>
                    {maxWeight}%
                </Col>
            </Row>
            <Divider className='mb-3' /> */}
            <Row>
                <Col className='text-right mb-2 mt-2'>
                    {/* <Button
                        className="btn-width btn-gen-margin grey-btn-outline h-100"
                        variant="outlined"
                        sx={{ color: 'gray', marginLeft: "0px !important" }}
                        disableElevation
                        size="medium"
                        startIcon={<RotateLeftIcon />}
                        onClick={clean}
                    >
                        Reset
                    </Button> */}
                    <Button
                        className="btn-width btn-gen-margin  mr-0 ml-0 h-100 "
                        disableElevation
                        size="medium"
                        variant="contained"
                        startIcon={<SaveIcon />}
                        onClick={submit}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = ({ variantFeed }) => ({ variantFeed })
export default connect(mapStateToProps, feedAction)(VariantFeed)