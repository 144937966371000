import konnect from "./axios"
import { GET_TRANSACTION } from "./type"

export const getTransactions = (userId, type, data) => async (dispatch) => {
    try {
        if (type === "payment") {
            return "fetched"
        } else {
            const res = await konnect.post('/v1/admin/transaction/userTransactions', { userId })
            dispatch({
                type: GET_TRANSACTION,
                payload: res.data?.data
            })
        }

    } catch (err) {
        console.log(err.response)
    }
}

