import React, { useEffect, useState, useCallback } from "react";

import { connect } from "react-redux";
import * as adAction from "../../store/actions/adManage"
import * as accountActions from "../../store/actions/account"
import * as catActions from "../../store/actions/categories"
import * as subCatAction from "../../store/actions/subCategory"
import * as countryActions from "../../store/actions/country"
import * as categoryAction from "../../store/actions/Categories/categories"

import { Col, Row } from "react-bootstrap";
import { Button, Divider, Typography, TextField, MenuItem, Select, FormControl, InputLabel, Paper } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Box } from '@mui/material';
import uploadIcon from "../../assets/images/uploadIcon.jpg"
import Skeleton from "../../reUsable/skeleton"

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DeleteModal from "../../reUsable/DeleteModal";
import { toast } from 'react-toastify';

import Switch from '@mui/material/Switch';
import BannerAdd from "./CreateModal";
import BannerUpdate from "./UpdateModal";
import { changeStatus } from "store/actions/country";
import moment from "moment"
import SaveModal from "../../reUsable/SaveModal";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import AddIcon from '@mui/icons-material/Add';
import UpdateBanner from "./UpdateBanner";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 49 * 4.5 + 8,
            width: 200,
        },
    },
}

function AdManage({ updateMonthlyBanner, addBanner, getBanner, deleteBanner, getAccountList, accountList, updateStatusBanner, updateBanner, subcategories, getSubCategoriesByParent, getAccountByCategory, accountByCategory, getCountry, banners, genderCategories, getGenderCategories, categoryByID, categories, subCategoryByID
}) {
    const [value, setValue] = useState(new Date());
    const [modal, setModal] = useState(false);
    const [row, setRow] = useState(null);
    const [slot, setSlot] = useState(null);
    const [data, setData] = useState([])
    const [newData, setNewData] = useState([])
    const [loading, setLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [id, setId] = useState("");
    const [name, setName] = useState("")
    const [nameLoad, setNameLoad] = useState(false)
    const [brandId, setBrandId] = useState("");
    const [updateModal, setUpdateModal] = useState(false);
    const [load, setLoad] = useState(false)
    const [page, setPage] = useState("brand")
    const [banner, setBanner] = useState("HPSP-A")
    const [date, setDate] = useState({
        year: moment().year(),
        month: moment().month() + 1,
    })
    const [genCategoryId, setGenCategoryId] = useState('')
    const [categoryId, setCategoryId] = useState('all')
    const [subCategoryId, setSubCategoryId] = useState('all')
    const [country, setCountry] = useState([])
    const [countryCode, setCountryCode] = useState('US')
    const [search, setSearch] = useState(false)
    const [listCategory, setListCategory] = useState([])
    const [listSubCategory, setListSubCategory] = useState([])
    const [key, setKey] = useState(0);
    const [flag, setFlag] = useState(false)
    const [temp, setTemp] = useState('')
    const [visible, setVisible] = useState(false)

    function renderClear() {
        return (
            <Col xs={12} md={3} xl={3} className="filter-clear-col mb-20">
                <Button
                    className="btn-width btn-gen-margin ml-0 h-100"
                    disableElevation
                    size="medium"
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={onSearch}
                >
                    Search
                </Button>
                <Button
                    className="btn-width btn-gen-margin grey-btn-outline h-100"
                    variant="outlined"
                    sx={{ color: 'gray' }}
                    startIcon={<ClearIcon />}
                    onClick={() => {
                        setValue(new Date)
                        setDate({
                            year: moment().year(),
                            month: moment().month() + 1,
                        })
                        setBanner("HPSP-A")
                        setPage("brand")
                        setGenCategoryId("")
                        setListCategory([])
                        setListSubCategory([])
                        setCategoryId("all")
                        setSubCategoryId("all")
                        setSearch(false)
                        setLoading(true)
                        setCountryCode("US")
                        setFlag(false)
                        setTemp('')
                        getBanner("brand", {
                            year: moment().year(),
                            month: moment().month() + 1,
                        }, '', '', "US").then((res) => {
                            if (res) {
                                setData(res);
                                setNewData(res?.filter(item => item.id === 'HPSP-A'))
                                setLoading(false)
                            } else {
                                setData([])
                                setNewData([])
                                setLoading(false)
                            }
                        })
                    }}
                >
                    Clear
                </Button>
            </Col>
        )
    }
    useEffect(() => {
        if (updateModal || modal) {
            if (page === "brand") {
                getAccountList('', false, "brand")
            } else if (page === "generalcategory") {
                getAccountByCategory(1, { name: '', gen_category_id: genCategoryId, category_id: categoryId, sub_category_id: subCategoryId })
            }
        }
    }, [modal, updateModal])
    useEffect(() => {
        getCountry().then((res) => {
            if (res) {
                setCountry(res.filter(f => f.is_active === true))
            } else {
                setCountry([])
            }
        })
        getBanner(page, date, '', '', countryCode).then((res) => {
            if (res) {
                setData(res);
                const name = getBannerName(res[key].id)
                setNewData(res?.filter(item => name ? item.id === name : item.id === banner))
                setLoading(false)
            } else {
                setData([]);
                setNewData([]);
                setLoading(false)
            }
        })
    }, [])
    const onSearch = () => {
        setSearch(true)
        if (page === "generalcategory") {
            if (genCategoryId === "") {
                toast.error("Please select general category")
                return
            }
        }
        setLoading(true)
        getData();
    }
    function handleSubmit(type, val) {
        if (type === 1) {
            let data = {
                row: row.rowId,
                position: slot.id,
                desktop: val.desktop,
                sm_desktop: val.sm_desktop,
                mobile: val.mobile,
                app: val.app,
                country: countryCode,
                date,
                type: page
            }
            data.brand_id = brandId;

            if (val.logo !== "") data.logo = val.logo;
            if (page !== "brand") {
                data.gen_category_id = genCategoryId
                data.category_id = categoryId
                data.sub_category_id = subCategoryId
            }
            if (page !== "brand" && categoryId === "all" && subCategoryId === "all") data.type = "generalcategory"
            if (categoryId !== "all" && subCategoryId === "all") data.type = "category"
            if (subCategoryId !== "all") data.type = "subcategory"

            console.log('11', data)
            addBanner(type, data).then((res) => {
                if (res.data.success) {
                    setLoading(true)
                    getData();
                    toast.success("Banner Added Successfully")
                }
            }).catch(err => {
                console.log("123", err.message)
                setLoading(true)
                getData();
                toast.error("Something went wrong")
            })
        }
        if (type === "HPSP-B") {
            let data = {
                row: row.rowId,
                position: slot.id,
                brand_id: brandId,
                year: date.year,
                month: date.month,
                country: countryCode,
                type: page
            }
            if (page !== "brand") {
                data.gen_category_id = genCategoryId
                data.category_id = categoryId
                data.sub_category_id = subCategoryId
            }
            if (page !== "brand" && categoryId === "all" && subCategoryId === "all") data.type = "generalcategory"
            if (categoryId !== "all" && subCategoryId === "all") data.type = "category"
            if (subCategoryId !== "all") data.type = "subcategory"
            addBanner(type, data).then(() => {
                setLoading(true)
                getData();
                toast.success("Banner Added Successfully")
            }).catch(err => {
                console.log("err", err.message)
                setLoading(true)
                getData();
                toast.error("Something went wrong")
            })
        }

        if (type === "new") {
            let data = {
                row: row.rowId,
                position: slot.id,
                desktop: val.desktop,
                sm_desktop: val.sm_desktop,
                mobile: val.mobile,
                app: val.app,
                title: val.title,
                description: val.description,
                country: countryCode,
                date,
                type: page
            }
            data.brand_id = brandId
            if (val.logo !== "") data.logo = val.logo
            if (page !== "brand") {
                data.gen_category_id = genCategoryId
                data.category_id = categoryId
                data.sub_category_id = subCategoryId
            }
            if (page !== "brand" && categoryId === "all" && subCategoryId === "all") data.type = "generalcategory"
            if (categoryId !== "all" && subCategoryId === "all") data.type = "category"
            if (subCategoryId !== "all") data.type = "subcategory"

            addBanner(type, data).then((res) => {
                setLoading(true)
                getData();
                toast.success("Banner Added Successfully")
            }).catch(err => {
                console.log("err", err.message)
                setLoading(true)
                getData();
                toast.error("Something went wrong")
            })
        }
    }
    function handleDelete() {
        deleteBanner(id).then((res) => {
            if (res.data.success) {
                getData()
                toast.success("Banner Deleted Successfully")
                setDeleteModal(false)
            } else {
                getData()
                setDeleteModal(false)
                toast.error("Something went wrong")
            }
        })
    }
    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    const handleChange = (val, flag, gen_category_id, category_id, sub_category_id) => {
        if (flag) {
            getAccountList(val, false, "brand").then(() => setNameLoad(false))
        } else {
            getAccountByCategory(1, { name: val, gen_category_id, category_id, sub_category_id }).then(() => setNameLoad(false))

        }
    };
    const optimizedFn = useCallback(debounce(handleChange), []);
    const handleStatus = (data) => {
        updateStatusBanner(data).then((res) => {
            getData();
            toast.success(res?.message ? res?.message : "Something went wrong")
        })
    }
    const hanldeUpdate = (type, val) => {
        setLoading(true)
        setLoad(true)
        if (type === 2) {
            updateBanner(type, val, brandId).then(() => {
                setUpdateModal(false)
                getData();
                setLoad(false)
                toast.success("Banner Updated Successfully")
            })
        } else {
            updateBanner(type, val, brandId).then(() => {
                setUpdateModal(false)
                getData();
                setLoad(false)
                toast.success("Banner Updated Successfully")
            })
        }

    }
    const customBanner = [
        { key: 1, parent: "brand", name: "HPSP-A", label: "Main Banner" },
        { key: 2, parent: "brand", name: "HPSP-B", label: "Featured" },
        { key: 3, parent: "brand", name: "HPSP-C", label: "Brands you'll Love" },
        { key: 4, parent: "brand", name: "HPSP-D", label: "Hot Deals" },

        { key: 5, parent: "category", name: "CPSP-A", label: "Main Banner" },
        { key: 6, parent: "category", name: "CPSP-B", label: "Featured" },
        { key: 7, parent: "category", name: "CPSP-C", label: "Brands you'll Love" },

        { key: 8, parent: "subcategory", name: "SCPSP-A", label: "Main Banner" },
        { key: 9, parent: "subcategory", name: "SCPSP-B", label: "Featured" },
        { key: 10, parent: "subcategory", name: "SCPSP-C", label: "Brands you'll Love" },

        { key: 11, parent: "generalcategory", name: "GCPSP-A", label: "Main Banner" },
        { key: 12, parent: "generalcategory", name: "GCPSP-B", label: "Featured" },
        { key: 13, parent: "generalcategory", name: "GCPSP-C", label: "Brands you'll Love" },
    ]

    useEffect(() => {
        if (page !== "brand") {
            getGenderCategories()
        }
    }, [page])
    // useEffect(() => {
    //     if ((page === 'category' || page === "subcategory") && genCategoryId !== "") {
    //         categoryByID(genCategoryId).then((res) => {
    //             if (res?.success) {
    //                 setListCategory(res?.message)
    //             } else {
    //                 setListCategory([])
    //             }
    //         })
    //     }
    // }, [genCategoryId, page])
    // useEffect(() => {
    //     if (page === 'subcategory' && genCategoryId !== "" && categoryId !== "") {
    //         subCategoryByID(categoryId, genCategoryId).then((res) => {
    //             if (res?.success) {
    //                 setListSubCategory(res?.message)
    //             } else {
    //                 setListSubCategory([])
    //             }
    //         })
    //     }
    // }, [categoryId, page])

    useEffect(() => {
        let val = banner.slice(-1);
        if (val === "A") setKey(0)
        if (val === "B") setKey(1)
        if (val === "C") setKey(2)
        if (val === "D") setKey(3)
    }, [banner, genCategoryId, categoryId, subCategoryId])

    function getData() {
        getBanner(page, date, categoryId, subCategoryId, countryCode, genCategoryId).then((res) => {
            if (res) {
                setData(res);
                const name = getBannerName(res[key].id)
                if (name) {
                    setFlag(true)
                    if (name.includes('SCPSP')) {
                        setTemp('subcategory')
                    } else {
                        setTemp('category')
                    }
                    setBanner(name)
                }
                setNewData(res?.filter(item => name ? item.id === name : item.id === banner))
                setLoading(false)
                setSearch(false)
            } else {
                setData([])
                setNewData([])
                setLoad(false)
            }
        })
    }

    function getBannerName(value) {
        let val = value.slice(-1);
        if (categoryId !== "all" && subCategoryId === "all") {
            return `CPSP-${val}`
        } else if (subCategoryId !== "all") {
            return `SCPSP-${val}`
        } else {
            setFlag(false)
            return null
        }
    }

    function renderFields() {
        if (page === "generalcategory") {
            return (
                <>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel id="demo-simple-select-label">General Category</InputLabel>
                            <Select
                                error={search && genCategoryId === "" ? true : false}
                                placeholder="General Category"
                                className="field-style "
                                id="genCategoryId"
                                label="General Category"
                                size="small"
                                value={genCategoryId}
                                onChange={(e) => {
                                    setCategoryId('all')
                                    setSubCategoryId('all')
                                    setListCategory([])
                                    setListSubCategory([])
                                    setGenCategoryId(e.target.value)
                                    categoryByID(e.target.value).then((res) => {
                                        if (res?.success) {
                                            setListCategory(res?.message)
                                        } else {
                                            setListCategory([])
                                        }
                                    })
                                }}
                                MenuProps={MenuProps}
                                variant="outlined" >
                                {genderCategories?.map((option) => (
                                    <MenuItem disabled={!option.is_active} key={option._id} value={option._id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                            <Select
                                disabled={genCategoryId === ""}
                                placeholder="Category"
                                className="field-style "
                                id="categoryId"
                                label="Category"
                                size="small"
                                value={categoryId}
                                onChange={(e) => {
                                    setSubCategoryId('all')
                                    setListSubCategory([])
                                    setCategoryId(e.target.value)
                                    subCategoryByID(e.target.value, genCategoryId).then((res) => {
                                        if (res?.success) {
                                            setListSubCategory(res?.message)
                                        } else {
                                            setListSubCategory([])
                                        }
                                    })
                                }}
                                MenuProps={MenuProps}
                                variant="outlined" >
                                <MenuItem value={'all'} key={'all'}>All</MenuItem>
                                {listCategory?.map((option) => (
                                    <MenuItem disabled={!option.is_active} key={option._id} value={option._id}>
                                        {option.category_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel id="demo-simple-select-label">Sub Category</InputLabel>
                            <Select
                                disabled={categoryId === "" || genCategoryId === ""}
                                placeholder="Category"
                                className="field-style "
                                id="categoryId"
                                label="Category"
                                size="small"
                                value={subCategoryId}
                                onChange={(e) => {
                                    setSubCategoryId(e.target.value)
                                }}
                                MenuProps={MenuProps}
                                variant="outlined" >
                                <MenuItem value={'all'} key={'all'}>All</MenuItem>
                                {listSubCategory?.map((option) => (
                                    <MenuItem disabled={!option.is_active} key={option._id} value={option._id}>
                                        {option.sub_category_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                </>
            )
        }
    }
    function updateMonthly() {
        updateMonthlyBanner({ year: moment().year(), month: moment().month() + 1, }).then((res) => {
            if (res.success) {
                toast.success(res?.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            } else {
                toast.error(res?.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
            setVisible(false)
            getData()
        })
    }
    return (
        <React.Fragment>
            <SaveModal
                visible={visible}
                title={`Are you sure you want to update these banners?`}
                close={() => setVisible(false)}
                type="status"
                call={updateMonthly}
            />
            {/* <UpdateBanner
                visible={visible}
                clear={() => { setVisible(false) }}
                get={() => { setVisible(false); getData() }}
            /> */}
            <BannerAdd
                page={page}
                visible={modal}
                close={() => setModal(false)}
                slot={slot}
                rowData={row}
                save={(type, val) => handleSubmit(type, val)}
                name={name}
                setName={setName}
                nameLoad={nameLoad}
                setNameLoad={setNameLoad}
                optimizedFn={optimizedFn}
                accountList={accountList}
                setBrandId={setBrandId}
                brandId={brandId}
                categoryId={categoryId}
                generalCategoryId={genCategoryId}
                subCategoryId={subCategoryId}
                accountByCategory={accountByCategory}
            />

            <BannerUpdate
                page={page}
                visible={updateModal}
                slot={slot}
                rowData={row}
                close={() => setUpdateModal(false)}
                type="update"
                update={hanldeUpdate}
                load={load}
                name={name}
                setName={setName}
                nameLoad={nameLoad}
                setNameLoad={setNameLoad}
                optimizedFn={optimizedFn}
                accountList={accountList}
                setBrandId={setBrandId}
                categoryId={categoryId}
                subCategoryId={subCategoryId}
                accountByCategory={accountByCategory}
            />
            <DeleteModal
                visible={deleteModal}
                title={`Are you sure you want to delete this Banner?`}
                close={() => setDeleteModal(false)}
                call={() => handleDelete()}
            />

            <div className="container-fluid mb-3">
                <Row className='pt-3'>
                    <Col xs className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            Banners
                        </Typography>
                    </Col>
                    <Col xs className="mb-3 text-right">
                        <Button
                            onClick={() => {
                                setVisible(true)
                            }}
                            className='btn-width'
                            startIcon={<AddIcon />}
                            disableElevation
                            variant="contained">
                            Update Banners
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4} lg={4} xl={3} className="mb-20">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                views={['year', 'month']}
                                label="Year and Month"
                                minDate={new Date()}
                                maxDate={new Date('2025-06-01')}
                                value={value}
                                onChange={(newValue) => {
                                    setDate({ ...date, year: moment(newValue).year(), month: moment(newValue).month() + 1 })
                                    setValue(newValue);
                                }}
                                renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '100%', }} {...params} helperText={null} />}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Country</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={countryCode}
                                label="Country"
                                MenuProps={MenuProps}
                                onChange={(e) => {
                                    setCountryCode(e.target.value)
                                }}
                            >
                                {
                                    country.length > 0 && country.map(item => {
                                        return (
                                            <MenuItem key={item.code1} value={item.code1} >{item.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Page</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={page}
                                label="Page"
                                onChange={(e) => {
                                    setPage(e.target.value)
                                    if (page === "brand") {
                                        setListCategory([])
                                        setListSubCategory([])
                                        setGenCategoryId("")
                                        setCategoryId("all")
                                        setSubCategoryId("all")
                                    } else if (page === "generalcategory") {
                                        setListSubCategory([])
                                        setListCategory([])
                                        setCategoryId('all')
                                        setSubCategoryId('all')
                                        setGenCategoryId('')
                                    }
                                    if (e.target.value === "brand") setBanner("HPSP-A")
                                    if (e.target.value === "generalcategory") setBanner("GCPSP-A")
                                }}
                            >
                                <MenuItem key={"brand"} value={"brand"}>Brands</MenuItem>
                                <MenuItem key={"generalcategory"} value={"generalcategory"}>Category</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>

                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Section</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={banner}
                                label="Banner"
                                onChange={(e) => {
                                    setBanner(e.target.value)
                                }}
                            >
                                {/* {
                                    (page !== "home" && categoryId !== "all" && subCategoryId === "") &&
                                    <>
                                        <MenuItem key={5} value={'CPSP-A'} >Main Banner</MenuItem>
                                        <MenuItem key={6} value={'CPSP-B'} >Featured</MenuItem>
                                        <MenuItem key={7} value={'CPSP-C'} >Brands you'll Love</MenuItem>
                                    </>
                                }
                                {
                                    (page !== "home" && subCategoryId !== "") &&
                                    <>
                                        <MenuItem key={8} value={'SCPSP-A'} >Main Banner</MenuItem>
                                        <MenuItem key={9} value={'SCPSP-B'} >Featured</MenuItem>
                                        <MenuItem key={10} value={'SCPSP-C'} >Brands you'll Love</MenuItem>
                                    </>
                                } */}
                                {
                                    customBanner.map(item => {
                                        return (
                                            flag ?
                                                item.parent === temp && <MenuItem key={item.key} value={item.name} >{item.label}</MenuItem> :
                                                page === item.parent && <MenuItem key={item.key} value={item.name} >{item.label}</MenuItem>
                                        )
                                    })
                                }

                            </Select>
                        </FormControl>
                    </Col>

                    {renderFields()}
                    {renderClear()}
                </Row>
                <Divider className="mb-15" />
                {
                    loading ? <Skeleton /> :
                        <Paper variant="outlined" className='box-style-new' style={{ padding: "10px 15px 5px 15px", borderRadius: 7 }}>
                            {
                                newData.length > 0 && newData?.map((item, i) => (
                                    <div className="bannerContainer" key={i}>
                                        <div className="bannerName">
                                            <Typography className='title-bar-hd-main' color="primary" variant="h6" gutterBottom component="div"> {item.name}</Typography>
                                        </div>
                                        <Divider className="mb-15" />
                                        <Row className="bannerSlotContainer">
                                            {item?.slots.map((slot, i) => {
                                                return (
                                                    <Col key={i} xs={12} md={4} xl={4} className="bannerslots mb-15">
                                                        {slot.upload ?
                                                            <Card  >
                                                                <CardActionArea >
                                                                    <div className={slot?.data?.row === 1 ? "tableDisplayImage featuredImageFull" : "tableDisplayImage"}>
                                                                        <CardMedia
                                                                            component="img"
                                                                            image={slot?.data?.row !== 2 ? slot.data.desktop_url : slot.data.brandData.length > 0 ? slot.data.brandData[0].profile_image_url : slot.data.desktop_url}
                                                                            sx={{ width: "100", borderRadius: 1, margin: 1 }}
                                                                            alt={`Title of brand ${slot?.data?.brandData?.[0]?.name}`}
                                                                        />
                                                                    </div>
                                                                    <div className="container-fluid">
                                                                        <Row className="justify-content-center">
                                                                            <Col xl={6} lg={6} md={6} xs={6} className="mb-1" >
                                                                                <Typography className="mb-0 fw-normal cardNameContainer" gutterBottom variant="h6" component="div" >
                                                                                    SLOT - {slot?.id} {slot?.data?.brandData?.[0] && <span className="cardName"> ({slot?.data?.brandData?.[0]?.name}) </span>}
                                                                                </Typography>
                                                                            </Col>
                                                                            <Col xl={6} lg={6} md={6} xs={6} className="align-items-center d-flex justify-content-end mb-1">
                                                                                <div className='justify-content-end align-items-center  '>
                                                                                    <EditIcon onClick={() => {
                                                                                        setRow(item);
                                                                                        setSlot(slot);
                                                                                        setUpdateModal(true)
                                                                                    }} fontSize="small" className="btn-gen-margin edit-icon-btn cursor-pointer" />
                                                                                    <DeleteIcon onClick={() => {
                                                                                        setDeleteModal(true);
                                                                                        setId(slot.data._id)
                                                                                    }} fontSize="small" className="btn-gen-margin delete-icon-btn cursor-pointer" />
                                                                                    <EditBTN item={slot.data} changeStatus={handleStatus} />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>

                                                                </CardActionArea>
                                                            </Card>
                                                            :
                                                            <Card >
                                                                <CardActionArea>
                                                                    <div className="tableDisplayImage iconImage" >
                                                                        <CardMedia
                                                                            component="img"
                                                                            image={uploadIcon}
                                                                            sx={{ width: "100", borderRadius: 1, margin: 1 }}
                                                                            alt="green iguana"
                                                                        />
                                                                    </div>
                                                                    <div className="container-fluid">
                                                                        <Row className="justify-content-center">
                                                                            <Col xl={8} lg={8} md={6} className="mb-1" >
                                                                                <Typography className="mb-0 fw-normal" gutterBottom variant="h6" component="div">
                                                                                    SLOT - {slot?.id}
                                                                                </Typography>
                                                                            </Col>
                                                                            <Col xl={4} lg={4} md={6} className="align-items-center d-flex justify-content-end mb-2">
                                                                                <div className='justify-content-end align-items-center  '>
                                                                                    <Button
                                                                                        onClick={() => {
                                                                                            setRow(item);
                                                                                            setSlot(slot);
                                                                                            setModal(true)
                                                                                        }}
                                                                                        startIcon={<FileUploadIcon className="upload-icon" />}
                                                                                        className='upload-icon-btn fw-normal'
                                                                                        disableElevation
                                                                                        variant="contained">
                                                                                        Upload
                                                                                    </Button>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </CardActionArea>
                                                            </Card>
                                                        }
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                ))
                            }
                        </Paper>

                }
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = ({ banners, accountList, subcategories, accountByCategory, genderCategories, categories, }) => {
    return { banners, accountList, subcategories, accountByCategory, genderCategories, categories, };
};

export default connect(mapStateToProps, { ...adAction, ...accountActions, ...catActions, ...subCatAction, ...countryActions, ...categoryAction })(AdManage);


function EditBTN({ item, changeStatus, }) {
    const [check, setCheck] = useState(true)
    const [switchModal, setSwitchModal] = useState(false)
    useEffect(() => {
        setCheck(item?.is_active);
    }, [])
    return (
        <div className="d-inline-block">
            <DeleteModal
                visible={switchModal}
                title={`Are you sure you want to deactive this Banner?`}
                close={() => setSwitchModal(false)}
                call={() => {
                    setCheck(false)
                    changeStatus({ _id: item._id, is_active: false })
                    setSwitchModal(false)
                }}
                type="status"
            />
            <Box>
                <Switch
                    size="small"
                    color="success"
                    checked={check}
                    onChange={(e, checked) => {
                        if (!checked) {
                            setSwitchModal(true)
                        } else {
                            setCheck(checked)
                            changeStatus({ _id: item._id, is_active: checked })
                        }
                    }}
                />
            </Box>
        </div>

    )
}