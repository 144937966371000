import konnect from "./axios"
import { GET_DASHBOARD, DASHBOARD_GRAPH } from "./type"

export const getDashboard = (date, brand_id) => async (dispatch) => {
    try {
        let updated = {
            from_date: date[0],
            to_date: date[1],
            brand_id
        }
        const res = await konnect.post('/v1/admin/crm/getDashboard', updated)
        dispatch({
            type: GET_DASHBOARD,
            payload: res?.data
        })
    } catch (err) {
        console.log(err.response)
    }
}

export const getDashboardGraph = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/graphData')
        dispatch({
            type: DASHBOARD_GRAPH,
            payload: res.data?.data
        })
        return res.data.data
    } catch (err) {
        console.log(err.response)
    }
}