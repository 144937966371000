import React, { useEffect, useState, } from 'react'
import { connect } from "react-redux";

import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { Col, Row } from 'react-bootstrap';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import * as introAction from "../../store/actions/introVideoAction"
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AWS from "aws-sdk";
const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECREATACCESSKEY,
    region: "us-east-1",
});

const s3 = new AWS.S3();

function AddVideo({ clear, visible, addVideo, data, get }) {
    const [load, setLoad] = useState(false)
    const [fields, setFields] = useState({
        desc: "",
        key: "starter",
        url: "",
    })
    const [file, setFile] = useState(null)
    const [submit, setSubmit] = useState(false)
    function setter(k, v) {
        setFields({ ...fields, [k]: v })
    }

    function handleSubmit() {
        setSubmit(true)
        if (file === null || fields.desc === "") {
            return
        }
        setLoad(true)
        addVideo(fields).then(res => {
            if (res.success) {
                const fileName = res?.file_name
                const params = {
                    Bucket: process.env.REACT_APP_BUCKET,
                    Key: `uploads/${fileName}`,
                    Body: file,
                    ContentType: file.type,
                };
                console.log("params", params)
                s3.upload(params, function (err, data) {
                    if (err) {
                        console.log(err, "-----");
                        setLoad(false)
                    } else {
                        toast.success("Uploaded Successfully", {
                            autoClose: 3000,
                            hideProgressBar: true,
                        });
                        setLoad(false)
                    }
                });

            } else {
                toast.error(res?.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
            get()
        }).catch(err => {
            console.log("err", err)
        })
        setTimeout(() => { close() }, 1000)
    }

    function close() {
        setFields({
            desc: "",
            key: "starter",
            url: "",
        })
        setFile(null)
        setSubmit(false)
        setLoad(false)
        clear()
    }

    return (
        <Modal
            open={visible}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className={"deleteModal modal-generic-style modal-spacing-new"} style={{ width: "30%" }}>
                <Row className='heading-name-area-modal '>
                    <Col xl={12} lg={12} className="d-flex align-items-center justify-content-between">
                        <Box className="d-flex align-items-center">
                            <Typography className='text-center w-100 m-0' id="modal-modal-title" variant="h6" component="h2">
                                Add Intro Video
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                className='custom-close-btn-moda'
                                onClick={close}
                                aria-label="delete" sx={{ color: "grey" }}>
                                <CancelIcon color="error" />
                            </IconButton>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} lg={12} className="">
                        <Divider className='mb-15' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} >
                        <FormControl
                            className="field-style mb-3" size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Key</InputLabel>
                            <Select
                                value={fields.key}
                                label="Key"
                                name='key'
                                onChange={(e) => setter(e.target.name, e.target.value)}
                            >
                                <MenuItem value={'starter'}>Starter</MenuItem>
                                <MenuItem value={'brand'}>Brand</MenuItem>
                                <MenuItem value={'shopper'}>Shopper</MenuItem>
                                <MenuItem value={'creator'}>Creator</MenuItem>
                                <MenuItem value={'training'}>Training</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-left" >
                        <TextField
                            error={submit && fields.desc === "" ? true : false}
                            helperText={submit && fields.desc === "" ? "Please fill the " : null}
                            size="small"
                            label="Description"
                            name='desc'
                            value={fields.desc}
                            onChange={(e) => setter(e.target.name, e.target.value)}
                            variant="outlined"
                            className="field-style mb-3 "
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} >
                        <Button
                            className='w-100'
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                        >
                            Upload file
                            <VisuallyHiddenInput type="file" onChange={(e) => {
                                setFile(e.target.files[0])

                            }} />
                        </Button>
                        {
                            (submit && file === null) && <div style={{ fontSize: "0.8rem", color: "red" }}>File is required!</div>
                        }
                        {
                            file && <div style={{ fontSize: "0.8rem", color: "gray" }}>{file.name}</div>
                        }
                    </Col>
                </Row>
                <div className='modal-buttons-row' style={{ marginTop: "5px", justifyContent: "flex-end" }}>
                    <LoadingButton
                        disabled={load}
                        onClick={handleSubmit}
                        variant="contained"
                        size="medium"
                        color="primary"
                        className="btn-gen-margin ml-0 btn-width"
                        disableElevation
                    >
                        <SaveIcon className="mr-2" />  {data ? "Update" : "Save"}
                    </LoadingButton>
                </div>
            </Box>
        </Modal >
    )
}
const mapStateToProps = ({ }) => {
    return {};
};
export default connect(mapStateToProps, { ...introAction })(AddVideo)

