import konnect from "./axios"
import { FEES } from "./type"

export const getFeesSetting = () => async (dispatch) => {
    try {
        const res = await konnect.get(`/v1/admin/fee/get`)
        dispatch({
            type: FEES,
            payload: res?.data?.data
        })
        return res?.data
    } catch (err) {
        return err?.response
    }
}
export const updateFees = (data, page) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/fee/change`, data)
        return res?.data
    } catch (err) {
        return err?.response
    }
}