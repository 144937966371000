import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { Autocomplete, TextField, Typography, Box, Divider, Button, Paper, CircularProgress } from '@mui/material'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import * as accountActions from "../../store/actions/account"
import * as dashActions from "../../store/actions/dashboard"
import moment from 'moment'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO, } from "date-fns";

import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    faList,
    faPercent,
    faDollarSign,
    faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons";
import { TailSpin } from 'react-loader-spinner'
import numeral from 'numeral'


function Dashboard({ getAccountList, accountList, dashboard, getDashboard }) {
    const [load, setLoad] = useState(true)
    const [value, setValue] = useState([
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
    ]);

    const [brand, setBrand] = useState('')
    const [name, setName] = useState("")
    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    const handleChange = (val, type) => getAccountList(val, false, 'brand', type)
    const optimizedFn = useCallback(debounce(handleChange), []);

    useEffect(() => {
        getAccountList('', false, 'brand')
        getDashboard(value, brand)
    }, [])

    function getData(type) {
        setLoad(true)
        if (type === 'reset') {
            setValue([
                moment().startOf("year").format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
            ])
            setName('')
            setBrand('')
            getDashboard([
                moment().startOf("year").format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
            ], '')
        } else {
            getDashboard(value, brand)
        }
    }

    const data = useMemo(() => {
        if (dashboard?.success) {
            let dash = {
                direct: {
                    brand: { ...dashboard?.message?.content_sales?.contentSalesBrand?.[0] },
                    creator: { ...dashboard?.message?.content_sales?.contentSalesCreator?.[0] }
                },
                referral: {
                    brand: { ...dashboard?.message?.referral_sales?.referralSalesBrand?.[0] },
                    creator: { ...dashboard?.message?.referral_sales?.referralSalesCreator?.[0] }
                },
                orders: {
                    ...dashboard?.message?.orders?.[0]
                }
            }
            setLoad(false)
            return dash
        } else {
            return null
        }
    }, [dashboard])

    return (
        <div className='container-fluid mt-3 mb-3 pl-0'>
            <Row className='pl-15'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Dashboard
                    </Typography>
                </Col>
            </Row>
            <Row className="account-main-row pl-15">
                <Col xs={12} md={6} xl={3} className="mb-20 dashboard-date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="dateBox">
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        helperText: "YYYY / MM / DD",
                                    },
                                }}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="Start Date"
                                value={parseISO(value[0])}
                                onChange={(newValue) => {
                                    setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style"
                                        size="small"
                                        style={{ backgroundColor: "white", width: "50%" }}
                                        {...params}
                                    />
                                )}
                            />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        helperText: "YYYY / MM / DD",
                                    },
                                }}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="End Date"
                                value={parseISO(value[1])}
                                onChange={(newValue) => {
                                    setValue([value[0], moment(newValue).format("YYYY-MM-DD")]);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style"
                                        size="small"
                                        style={{ backgroundColor: "white", width: "50%" }}
                                        {...params}
                                    />
                                )}
                            />
                        </div>
                    </LocalizationProvider>
                </Col>
                <Col xs={12} md={6} xl={3} className="mb-20">
                    <Autocomplete
                        variant="outlined"
                        className="field-style"
                        size="small"
                        id="disable-clearable"
                        disableClearable
                        options={accountList?.message ? accountList?.message.map(opt => ({ ...opt, label: `${opt.pixel_id} - ${opt.name}` })) : []}
                        onChange={(e, data) => {
                            setBrand(data?._id)
                            setName(data.label)
                        }}
                        value={name}
                        renderInput={(params) => <TextField {...params} label="Brand" onChange={(e) => {
                            setName(e.target.value)
                            optimizedFn(e.target.value)
                        }} />}
                    />
                </Col>
                <Col xs={12} md={12} xl={5} className="mb-20 dashboard-search-buttons">
                    <Button
                        variant="contained"
                        size="medium"
                        className="btn-gen-margin btn-width"
                        color="primary"
                        startIcon={<SearchIcon />}
                        disableElevation
                        onClick={getData}
                    >
                        Search
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        startIcon={<RefreshIcon />}
                        className="btn-gen-margin btn-width"
                        color="success"
                        disableElevation
                        onClick={() => { getData('reset') }}
                    >
                        Refresh
                    </Button>
                </Col>
            </Row>
            <Divider className="separater-sale" />

            {/* directSales */}
            <div className="container-fluid pl-15 dashboard-page">
                <div className="row">
                    <div className="col-12 mb-2 title">
                        <div className="title-area pb-10">
                            <h1 className="title-hd">Direct Sales </h1>
                        </div>
                        <h5 className="mb-0">Brand</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xl col-lg-3 col-md-6 laptop-width-bo">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faList} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.brand?.number_of_orders).format("0")}
                            </div>
                            <div className="total-info-hd"># Of Orders</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faDollarSign}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.brand?.gross_amount).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Gross Sale</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faMoneyCheck} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.brand?.cashback_amount).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Cashback</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faMoneyCheck}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.brand?.customer_commission).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">REFERRAL FEE</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faMoneyCheck}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.brand?.creator_commission).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">CREATOR Fee</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faDollarSign} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.brand?.kb_fee).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">ORME Fee</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faDollarSign} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.brand?.net_sales).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Net Sales</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains icon-percent" icon={faPercent} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.brand?.net_sales / data?.orders?.net_sales).format("0%")}
                            </div>
                            <div className="total-info-hd">% Sales</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 title">
                        <div className="title-area pb-10">
                            <h5 className="mb-0 mt-0">Creator</h5>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6 col-xl col-lg-3 col-md-6 laptop-width-bo">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faList} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.creator?.number_of_orders).format("0")}
                            </div>
                            <div className="total-info-hd"># Of Orders</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faDollarSign}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.creator?.gross_amount).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Gross Sale</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faMoneyCheck} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.creator?.cashback_amount).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Cashback</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faMoneyCheck}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.creator?.customer_commission).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">REFERRAL FEE</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faMoneyCheck}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.creator?.creator_commission).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">CREATOR Fee</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faDollarSign} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.creator?.kb_fee).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">ORME Fee</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faDollarSign} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.creator?.net_sales).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Net Sales</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains icon-percent" icon={faPercent} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.direct?.creator?.net_sales / data?.orders?.net_sales).format("0%")}
                            </div>
                            <div className="total-info-hd">% Sales</div>
                        </div>
                    </div>
                </div>
                <Divider className="separater-sale" />
            </div>
            {/* refferalSales */}
            <div className="container-fluid pl-15 dashboard-page">
                <div className="row">
                    <div className="col-12 mb-2 title">
                        <div className="title-area pb-10">
                            <h1 className="title-hd">Referred Sales </h1>
                        </div>
                        <h5 className="mb-0">Brand</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xl col-lg-3 col-md-6 laptop-width-bo">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faList} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.brand?.number_of_orders).format("0")}

                            </div>
                            <div className="total-info-hd"># Of Orders</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faDollarSign}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.brand?.gross_amount).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Gross Sale</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faMoneyCheck} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.brand?.cashback_amount).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Cashback</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faMoneyCheck}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.brand?.customer_commission).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">REFERRAL FEE</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faMoneyCheck}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.brand?.creator_commission).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">CREATOR Fee</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faDollarSign} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.brand?.kb_fee).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">ORME Fee</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faDollarSign} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.brand?.net_sales).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Net Sales</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains icon-percent" icon={faPercent} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.brand?.net_sales / data?.orders?.net_sales).format("0%")}
                            </div>
                            <div className="total-info-hd">% Sales</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 title">
                        <div className="title-area pb-10">
                            <h5 className="mb-0 mt-0">Creator</h5>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6 col-xl col-lg-3 col-md-6 laptop-width-bo">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faList} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.creator?.number_of_orders).format("0")}

                            </div>
                            <div className="total-info-hd"># Of Orders</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faDollarSign}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.creator?.gross_amount).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Gross Sale</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faMoneyCheck} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.creator?.cashback_amount).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Cashback</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faMoneyCheck}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.creator?.customer_commission).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">REFERRAL FEE</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faMoneyCheck}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.creator?.creator_commission).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">CREATOR Fee</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faDollarSign} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.creator?.kb_fee).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">ORME Fee</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faDollarSign} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.creator?.net_sales).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Net Sales</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains icon-percent" icon={faPercent} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.referral?.creator?.net_sales / data?.orders?.net_sales).format("0%")}
                            </div>
                            <div className="total-info-hd">% Sales</div>
                        </div>
                    </div>
                </div>
                <Divider className="separater-sale" />
            </div>
            {/* totalSales */}
            <div className="container-fluid pl-15 dashboard-page">
                <div className="row">
                    <div className="col-12 mb-2 title">
                        <div className="title-area pb-10">
                            <h1 className="title-hd">Total Sales </h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xl col-lg-3 col-md-6 laptop-width-bo">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faList} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.orders?.number_of_orders).format("0")}

                            </div>
                            <div className="total-info-hd"># Of Orders</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faDollarSign}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.orders?.gross_amount).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Gross Sale</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faMoneyCheck} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.orders?.cashback_amount).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Cashback</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faMoneyCheck}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.orders?.customer_commission).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">REFERRAL FEE</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon
                                className="info-icon-mains"
                                icon={faMoneyCheck}
                            />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.orders?.creator_commission).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">CREATOR Fee</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faDollarSign} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.orders?.kb_fee).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">ORME Fee</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains" icon={faDollarSign} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral(data?.orders?.net_sales).format("$0,0.0'")}
                            </div>
                            <div className="total-info-hd">Net Sales</div>
                        </div>
                    </div>
                    <div className="col-6 col-xl col-lg-3 col-md-6">
                        <div className="dashboard-info-box-main">
                            <FontAwesomeIcon className="info-icon-mains icon-percent" icon={faPercent} />
                            <div className="value-info-hd">
                                {load ? <CircularProgress size={"10px"} /> : numeral((data?.orders?.net_sales / data?.orders?.net_sales)).format("0%")}
                            </div>
                            <div className="total-info-hd">% Sales</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = ({ accountList, dashboard }) => ({ accountList, dashboard })
export default connect(mapStateToProps, { ...accountActions, ...dashActions })(Dashboard)