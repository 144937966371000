import React, { useState } from 'react'
import { Autocomplete, TextField } from '@mui/material';
import { connect } from 'react-redux'
import * as accountActions from "../../store/actions/account"
import { useCallback } from 'react';
import { useEffect } from 'react';


export const SearchBrand = ({ getBrand, setter, brand }) => {
    const [nameLoad, setNameLoad] = useState(true);
    const [name, setName] = useState('')
    const [brands, setBrands] = useState([])
    function debounce(cb, delay = 250) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    const handleChange = (val) => {
        getBrand(val, false,).then((res) => {
            if (res.success) {
                setBrands(res?.data?.message)
                setNameLoad(false)
            }
        })
    };
    const optimizedFn = useCallback(debounce(handleChange), []);

    useEffect(() => {
        if (brand === "") {
            setNameLoad(false)
            setName('')
            getBrand('', false,).then((res) => {
                if (res.success) {
                    setBrands(res?.data?.message)
                    setNameLoad(false)
                }
            })
        }
    }, [brand])

    return (
        <Autocomplete
            variant="outlined"
            className="field-style"
            size="small"
            id="disable-clearable"
            disableClearable
            loading={nameLoad}
            options={nameLoad ? [] : brands?.map(opt => ({ ...opt, label: opt.name }))}
            onChange={(e, data) => {
                setName(data.label)
                setter(data?._id)
            }}
            value={name}
            renderInput={(params) => <TextField {...params} label="Brand" onChange={(e) => {
                if (e.target.value !== "") {
                    setName(e.target.value)
                    setNameLoad(true)
                    optimizedFn(e.target.value)
                } else {
                    setName(e.target.value)
                    setNameLoad(true)
                    optimizedFn(e.target.value)
                    setter('')
                }
            }} />}
        />
    )
}

const mapStateToProps = ({ accountList }) => ({ accountList })

export default connect(mapStateToProps, accountActions)(SearchBrand)