import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap"
import { Button, Divider, InputAdornment, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import * as accountAction from "../../store/actions/account"
import { toast } from 'react-toastify';
import SaveModal from "../../reUsable/SaveModal";
import SearchBrand from "reUsable/SearchBrand";
import { TextFields } from '@mui/icons-material';

const urlLive = 'https://www.manage.ormelive.com/'
const urlDev = 'https://www.managedev.ormelive.com/'

function AdminControl({ setBrandRank }) {
    const [load, setLoad] = useState(false)
    const [modal, setModal] = useState(false)
    const [fields, setFields] = useState({ brand_id: '', rank: 5 })

    const handleSave = () => {
        setBrandRank(fields).then((res) => {
            if (res.success) {
                toast.success(res.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
                // setFields({ brand_id: '', rank: 5 })
            } else {
                console.log("Err", res)
            }
        })
        setModal(false)
        setLoad(false)
    }


    return (
        <div className="container-fluid mb-3 pt-3">
            <SaveModal
                visible={modal}
                title={`Are you sure you want to change Brand Raking?`}
                close={() => {
                    setLoad(false)
                    setModal(false)
                }}
                type="status"
                call={() => handleSave()}
            />
            <Row className="d-flex justify-content-between">
                <Col xl={5} lg={5} md={12}>
                    <div className='infoSection new mb-3 ' style={{ background: "#FFF" }} >
                        <Box className="addons-cart-title d-flex align-items-center">
                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                Brand Ranking
                            </Typography>
                        </Box>
                        <Divider className='mb-20' />
                        <div className='mb-3'>
                            <SearchBrand setter={(value, val) => {
                                setFields({ ...fields, brand_id: value, rank: val.rank })
                            }} brand={fields.brand_id} />
                        </div>
                        <div className='mb-3'>
                            <TextField
                                label="Rank"
                                size="small"
                                type='number'
                                name='Rank'
                                value={fields?.brand_id ? fields?.rank : ""}
                                InputProps={{
                                    inputProps: {
                                        min: 1,
                                        max: 10
                                    },
                                }}
                                onChange={(e) => {
                                    const num = Number(e.target.value)
                                    if (num > 0 && num < 11) {
                                        setFields({ ...fields, rank: num })
                                    }
                                }}
                                variant="outlined"
                                className="field-style mb-15"
                            />
                        </div>

                        <div className='d-flex justify-content-end'>
                            <Button
                                className="btn-width btn-gen-margin grey-btn-outline  laptop ml-0 mb-3"
                                variant="outlined"
                                sx={{ color: 'gray' }}
                                // className="btn-width btn-gen-margin ml-0 h-100"
                                disableElevation
                                size="medium"
                                onClick={() => {
                                    setFields({ brand_id: '', rank: 5 })
                                }}
                            >
                                Clear
                            </Button>
                            <Button
                                disabled={load || fields.brand_id === ""}
                                className="btn-width btn-gen-margin ml-0 h-100 mb-3"
                                disableElevation
                                size="medium"
                                variant="contained"
                                onClick={() => {
                                    setLoad(true)
                                    setModal(true)
                                }}
                            >
                                Save
                            </Button>
                        </div>


                    </div>
                </Col>

            </Row>
        </div>
    )
}

const mapStateToProps = ({ }) => {
    return {};
};

export default connect(mapStateToProps, accountAction)(AdminControl);