import { Box, Button, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import numeral from 'numeral';
import NoRows from 'reUsable/NoRows';
import React, { useMemo } from 'react'
import { useState } from 'react';
// import SaveModal from "./Modal";

export default function Table({ data, load, page, nowGet, type }) {


    const successCol = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        {
            field: "created_at",
            headerName: "Date",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => moment(value).format('MM-DD-YYYY'),
        },
        {
            field: "pixel_id",
            headerName: "PID",
            width: 150,
            sortable: false,
        },
        {
            field: "name",
            headerName: "Brand",
            width: 250,
            sortable: false,
        },
        {
            field: "amount",
            headerName: "Amount",
            width: 250,
            sortable: false,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
        },
    ];
    const errorCol = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        {
            field: "created_at",
            headerName: "Date",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => moment(value).format('MM-DD-YYYY'),
        },
        {
            field: "pixel_id",
            headerName: "PID",
            width: 150,
            sortable: false,
        },
        {
            field: "name",
            headerName: "Brand",
            width: 250,
            sortable: false,
        },
        {
            field: "message",
            headerName: "Reason",
            width: 1000,
            sortable: false,
        },
    ];


    console.log('1', data)
    return (
        <div style={{ height: "528px" }}>
            <DataGrid
                density="compact"
                disableColumnFilter={true}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                className="bg-white ml-15"
                components={{ NoRowsOverlay: NoRows, }}
                loading={load}
                rows={
                    type === "success" ?
                        data?.data?.map((item, index) => ({
                            ...item,
                            id: index + (page == 1 ? 1 : page * 12 - 11),
                            amount: item?.stripe_charge_data?.confirmPaymentIntent.amount / 100,
                            name: item?.brandData?.name,
                            pixel_id: item?.brandData?.pixel_id
                        })) :
                        data?.data?.map((item, index) => ({
                            ...item,
                            id: index + (page == 1 ? 1 : page * 12 - 11),
                            name: item?.brandData?.name,
                            pixel_id: item?.brandData?.pixel_id
                        }))
                }
                columns={type === "success" ? successCol : errorCol}
                pagination
                pageSize={12}
                rowsPerPageOptions={[12]}
                rowCount={data?.totalRecords ? data?.totalRecords : 0}
                paginationMode="server"
                onPageChange={(newPage) => nowGet(newPage + 1)}
            />
            {
                type === "success" &&
                <div className='sumContainer-height-235 bg-white ml-15'>
                    <div className='sumContainer'>
                        <div className='sumTitle'>Summary</div>
                        <div className='sumBody'>
                            <div className='sumBodyRow' style={{ borderBottom: "none" }}>
                                <div className='sumBodyTitle'>Total Amount</div>
                                <div className='sumBodyvalue'>{numeral(data?.paymentSummary?.succeededTotal / 100).format("$0,0.00", Math.floor)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
