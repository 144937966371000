import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./status.css";
import * as serviceAction from "../store/actions/serviceStatus";
import SkeletonComponent from "reUsable/skeleton";

function TEST({ statusDetail, getStatus }) {

  useEffect(() => {
    const fetchData = async () => {
      await getStatus();
    };
    fetchData();
  }, [getStatus]);

  const getIcon = (status) => {
    switch (status) {
      case "running":
        return { icon: faCheckCircle, color: "#28A745" };
      case "stopped":
        return { icon: faTimesCircle, color: "#fa1313" };
      case "stopping":
        return { icon: faExclamationCircle, color: "yellow" };
      default:
        return faExclamationCircle;
    }
  };

  if (!statusDetail || statusDetail.length === 0) {
    return <SkeletonComponent />;
  }

  return (
    <div className="container ml-0">
      <div className="components-container two-columns">
        {statusDetail.map((detail) => (
          <div
            key={detail?.InstanceId}
            className="component-container border-color"
          >
            <div className="status-data-main">
              <div className="sttus-left">
                <div className="status-title-area">
                  <div className="status-title">{detail?.KeyName}</div>
                  <Tooltip placement="top" title={detail?.InstanceStatus}>
                    <div className="status-question">?</div>
                  </Tooltip>
                </div>
                <div className="status-state">{detail?.State}</div>
              </div>
              <div className="sttus-right">
                <Tooltip placement="top" title={detail?.State}>
                  <div className={`status-sign ${detail?.State.toLowerCase()}`}>
                    <FontAwesomeIcon icon={getIcon(detail?.State).icon} style={{ color: getIcon(detail?.State).color }} />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = ({ statusDetail }) => ({ statusDetail });
// const mapStateToProps = (state) => ({
//   statusDetail: state.statusDetail || [],
// });

export default connect(mapStateToProps, serviceAction)(TEST);
