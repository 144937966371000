import { GET_NOTIFICATIONSMSG_LIST } from "../../actions/type";

const initialState = [];

export default function notification(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONSMSG_LIST:
      return action.payload;
    default:
      return state;
  }
}
