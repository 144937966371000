import { Box, Button, Divider, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import * as feedAction from "../../store/actions/feedAction"
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const AdFeed = ({ cleanadfeed, adfeed, adfeedSetting, updateadFeedSetting, type }) => {
    const [load, setLoad] = useState(false)
    const [fields, setFields] = useState({ percent: 0 })
    useEffect(() => {
        setLoad(true)
        adfeedSetting(type).then(() => setLoad(false))
        return () => cleanadfeed()
    }, [])

    function clean() {
        setFields({ percent: adfeed?.message?.ad_percent })
        setLoad(false)
    }
    function submit() {
        let data = {
            ...fields,
            id: adfeed?.message?._id,
        }
        setLoad(true)
        updateadFeedSetting(data, type).then((res) => {
            if (res.success) {
                toast.success(`${res.message}`)
                setLoad(false)
            } else {
                toast.error(`${res.message}`)
                setLoad(false)
            }
            adfeedSetting(type)
        })
    }

    const feedData = useMemo(() => {
        if (adfeed?.success) {
            if (adfeed?.message) {
                setFields({ percent: adfeed?.message?.ad_percent })
            }
        } else {
            setFields({ percent: 0 })
        }
    }, [adfeed])

    return (
        <div className='infoSection new mb-3' style={{ background: "#FFF" }} >
            <Box className="addons-cart-title d-flex align-items-center">
                <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                    AD Feed
                </Typography>
            </Box>
            <Divider className='mb-20' />
            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.percent}
                        onChange={e => {
                            let val = e.target.value;
                            if (val >= 0 && val <= 100) {
                                setFields({ ...fields, percent: val === "" ? 0 : val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="AD %"
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} className='text-right mb-2'>
                    {/* <Button
                        className="btn-width btn-gen-margin grey-btn-outline h-100"
                        variant="outlined"
                        sx={{ color: 'gray', marginLeft: "0px !important" }}
                        disableElevation
                        size="medium"
                        startIcon={<RotateLeftIcon />}
                        onClick={clean}
                    >
                        Reset
                    </Button> */}
                    <Button
                        className="btn-width btn-gen-margin  mr-0 h-100"
                        disableElevation
                        size="medium"
                        variant="contained"
                        startIcon={<SaveIcon />}
                        onClick={submit}
                    >
                        Save
                    </Button>
                </Col>
            </Row>


        </div>

    )
}

const mapStateToProps = ({ adfeed }) => ({ adfeed })
export default connect(mapStateToProps, feedAction)(AdFeed)