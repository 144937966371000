import { Box, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import numeral from 'numeral';
import OrderModal from 'pages/accountMain/components/OrderModal';
import NoRows from 'reUsable/NoRows';
import React, { useMemo } from 'react'
import { useState } from 'react';

export default function Table({ data, load, type, page, nowGet }) {
    const [modal, setModal] = useState(false)
    const [order, setOrder] = useState(null)
    const orderCol = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        {
            field: "created_date",
            headerName: "Order Date",
            width: 130,
            sortable: false,
            renderCell: ({ value }) => {
                return moment(value).format('MM-DD-YYYY')
            }
        },
        {
            field: "order_id",
            headerName: "Order#",
            width: 90,
            sortable: false,
            renderCell: (params) => (
                <div
                    className="orderBTN"
                    onClick={() => {
                        setModal(true);
                        setOrder(params?.row);
                    }}
                >
                    {params?.value}
                </div>
            ),
        },
        {
            field: "title",
            headerName: "Video Title",
            width: 250,
            sortable: false,
            renderCell: ({ value }) => {
                if (value === "-" || value == undefined) {
                    return <p className='mb-0'>-</p>
                } else {
                    return (
                        <Tooltip title={value} placement="top-start" arrow>
                            <p className='mb-0'>{value}</p>
                        </Tooltip>
                    )
                }
            }
        },
        {
            field: "brand",
            headerName: "Brand",
            width: 150,
            sortable: false,
        },
        {
            field: "general_category",
            headerName: "Department",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (value ? value : "All"),
        },
        {
            field: "category",
            headerName: "Category",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (value ? value : "All"),
        },
        {
            field: "sub_category",
            headerName: "Sub Category",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (value ? value : "All"),
        },
        {
            field: "channel",
            headerName: "Channel",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (value ? value === "direct" ? "Direct" : "Referred" : "All"),
        },
        {
            field: "content_by",
            headerName: "Content Source",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (value ? value === "brand" ? "Brand" : "Creator" : "All"),
        },

        {
            field: "purchased_by",
            headerName: "Purchaser",
            width: 150,
            sortable: false,
        },
        {
            field: "affiliate",
            headerName: "Referrer",
            width: 150,
            sortable: false,
        },
        {
            field: "gross_amount",
            headerName: "Gross Sales",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "returned_amount",
            headerName: "Return Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "balance_amount",
            headerName: "Balance Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee",
            headerName: "ORME Fee",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee_percent",
            headerName: "ORME Fee %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "cashback_amount",
            headerName: "Cashback",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "cashback_amount_percent",
            headerName: "Cashback %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "customer_commission",
            headerName: "Referral Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "customer_commission_percent",
            headerName: "Referral Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "creator_commission",
            headerName: "Creator Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "creator_commission_percent",
            headerName: "Creator Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "net_brand_amount",
            headerName: "Net Revenue",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
    ];
    const dateCol = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        {
            field: "created_date",
            headerName: "Order Date",
            width: 180,
            sortable: false, renderCell: ({ value }) => {
                return moment(value).format('MM-DD-YYYY')
            }
        },
        {
            field: "order_count",
            headerName: "Total Orders",
            width: 180,
            sortable: false,
        },
        {
            field: "gross_amount",
            headerName: "Gross Sales",
            width: 180,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "returned_amount",
            headerName: "Return Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "balance_amount",
            headerName: "Balance Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee",
            headerName: "ORME Fee",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee_percent",
            headerName: "ORME Fee %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "cashback_amount",
            headerName: "Cashback",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "cashback_amount_percent",
            headerName: "Cashback %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "customer_commission",
            headerName: "Referral Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "customer_commission_percent",
            headerName: "Referral Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "creator_commission",
            headerName: "Creator Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "creator_commission_percent",
            headerName: "Creator Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "net_brand_amount",
            headerName: "Net Revenue",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
    ];
    const departmentCol = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        { field: "general_category", headerName: "Department", width: 180, sortable: false, renderCell: ({ value }) => value ? value : "All" },
        {
            field: "order_count",
            headerName: "Total Orders",
            width: 180,
            sortable: false,
        },
        {
            field: "gross_amount",
            headerName: "Gross Sales",
            width: 180,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "returned_amount",
            headerName: "Return Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "balance_amount",
            headerName: "Balance Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee",
            headerName: "ORME Fee",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee_percent",
            headerName: "ORME Fee %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "cashback_amount",
            headerName: "Cashback",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "cashback_amount_percent",
            headerName: "Cashback %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "customer_commission",
            headerName: "Referral Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "customer_commission_percent",
            headerName: "Referral Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "creator_commission",
            headerName: "Creator Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "creator_commission_percent",
            headerName: "Creator Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "net_brand_amount",
            headerName: "Net Revenue",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
    ];
    const categoryCol = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        { field: "category", headerName: "Category", width: 180, sortable: false, renderCell: ({ value }) => value ? value : "All" },
        {
            field: "order_count",
            headerName: "Total Orders",
            width: 180,
            sortable: false,
        },
        {
            field: "gross_amount",
            headerName: "Gross Sales",
            width: 180,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "returned_amount",
            headerName: "Return Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "balance_amount",
            headerName: "Balance Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee",
            headerName: "ORME Fee",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee_percent",
            headerName: "ORME Fee %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "cashback_amount",
            headerName: "Cashback",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "cashback_amount_percent",
            headerName: "Cashback %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "customer_commission",
            headerName: "Referral Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "customer_commission_percent",
            headerName: "Referral Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "creator_commission",
            headerName: "Creator Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "creator_commission_percent",
            headerName: "Creator Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "net_brand_amount",
            headerName: "Net Revenue",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
    ];
    const subCategoryCol = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        { field: "sub_category", headerName: "Sub Category", width: 180, sortable: false, renderCell: ({ value }) => value ? value : "All" },
        {
            field: "order_count",
            headerName: "Total Orders",
            width: 180,
            sortable: false,
        },
        {
            field: "gross_amount",
            headerName: "Gross Sales",
            width: 180,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "returned_amount",
            headerName: "Return Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "balance_amount",
            headerName: "Balance Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee",
            headerName: "ORME Fee",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee_percent",
            headerName: "ORME Fee %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "cashback_amount",
            headerName: "Cashback",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "cashback_amount_percent",
            headerName: "Cashback %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "customer_commission",
            headerName: "Referral Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "customer_commission_percent",
            headerName: "Referral Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "creator_commission",
            headerName: "Creator Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "creator_commission_percent",
            headerName: "Creator Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "net_brand_amount",
            headerName: "Net Revenue",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
    ];
    const purchasedCol = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        { field: "purchased", headerName: "Purchaser", width: 180, sortable: false, renderCell: (params) => params?.row?.customer?.name },
        {
            field: "order_count",
            headerName: "Total Orders",
            width: 180,
            sortable: false,
        },
        {
            field: "gross_amount",
            headerName: "Gross Sales",
            width: 180,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "returned_amount",
            headerName: "Return Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "balance_amount",
            headerName: "Balance Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee",
            headerName: "ORME Fee",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee_percent",
            headerName: "ORME Fee %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "cashback_amount",
            headerName: "Cashback",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "cashback_amount_percent",
            headerName: "Cashback %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "customer_commission",
            headerName: "Referral Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "customer_commission_percent",
            headerName: "Referral Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "creator_commission",
            headerName: "Creator Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "creator_commission_percent",
            headerName: "Creator Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "net_brand_amount",
            headerName: "Net Revenue",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
    ];
    const channelCol = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        {
            field: "channel", headerName: "Channel", width: 180, sortable: false,
            renderCell: ({ value }) => (value ? value === "direct" ? "Direct" : "Referred" : "All"),
        },
        {
            field: "order_count",
            headerName: "Total Orders",
            width: 180,
            sortable: false,
        },
        {
            field: "gross_amount",
            headerName: "Gross Sales",
            width: 180,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "returned_amount",
            headerName: "Return Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "balance_amount",
            headerName: "Balance Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee",
            headerName: "ORME Fee",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee_percent",
            headerName: "ORME Fee %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "cashback_amount",
            headerName: "Cashback",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "cashback_amount_percent",
            headerName: "Cashback %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "customer_commission",
            headerName: "Referral Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "customer_commission_percent",
            headerName: "Referral Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "creator_commission",
            headerName: "Creator Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "creator_commission_percent",
            headerName: "Creator Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "net_brand_amount",
            headerName: "Net Revenue",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
    ];
    const contentCol = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        { field: "content_by", headerName: "Content Source", width: 180, sortable: false, renderCell: ({ value }) => (value ? value === "brand" ? "Brand" : "Creator" : "All"), },
        {
            field: "order_count",
            headerName: "Total Orders",
            width: 180,
            sortable: false,
        },
        {
            field: "gross_amount",
            headerName: "Gross Sales",
            width: 180,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "returned_amount",
            headerName: "Return Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "balance_amount",
            headerName: "Balance Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee",
            headerName: "ORME Fee",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee_percent",
            headerName: "ORME Fee %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "cashback_amount",
            headerName: "Cashback",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "cashback_amount_percent",
            headerName: "Cashback %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "customer_commission",
            headerName: "Referral Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "customer_commission_percent",
            headerName: "Referral Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "creator_commission",
            headerName: "Creator Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "creator_commission_percent",
            headerName: "Creator Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "net_brand_amount",
            headerName: "Net Revenue",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
    ];
    const referredCol = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        { field: "affiliate", headerName: "Referrer", width: 180, sortable: false, renderCell: (params) => params?.row?.customer?.name },
        {
            field: "order_count",
            headerName: "Total Orders",
            width: 180,
            sortable: false,
        },
        {
            field: "gross_amount",
            headerName: "Gross Sales",
            width: 180,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "returned_amount",
            headerName: "Return Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "balance_amount",
            headerName: "Balance Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee",
            headerName: "ORME Fee",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "kb_fee_percent",
            headerName: "ORME Fee %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "cashback_amount",
            headerName: "Cashback",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "cashback_amount_percent",
            headerName: "Cashback %",
            width: 130,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "customer_commission",
            headerName: "Referral Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "customer_commission_percent",
            headerName: "Referral Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "creator_commission",
            headerName: "Creator Fee",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "creator_commission_percent",
            headerName: "Creator Fee %",
            width: 150,
            renderCell: ({ value }) => `${value}%`,
            sortable: false,
        },
        {
            field: "net_brand_amount",
            headerName: "Net Revenue",
            width: 150,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
    ];
    function renderCol() {
        switch (type) {
            case 'order': return orderCol
            case 'date': return dateCol
            case 'department': return departmentCol
            case 'category': return categoryCol
            case 'subCategory': return subCategoryCol
            case 'purchaser': return purchasedCol
            case 'content': return contentCol
            case 'channel': return channelCol
            case 'referred': return referredCol
            default: return orderCol
        }
    }
    const summary = useMemo(() => {
        if (data?.summary) {
            return data?.summary?.[0]
        } else {
            return null
        }
    }, [data])
    return (
        <div style={{ height: "445px" }}>
            <OrderModal
                type="orders"
                // user={currentUser}
                data={order}
                visible={modal}
                close={() => {
                    setOrder(null);
                    setModal(false);
                }}
            />
            <DataGrid

                rowHeight={40}
                density="compact"
                disableColumnFilter={true}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                //   sx={{ marginTop: 2 }}
                className="bg-white "
                components={{
                    NoRowsOverlay: NoRows,
                    // Footer: ,
                    // Row: CustomFooterTotalComponent

                }}
                loading={load}
                rows={
                    data?.data?.length > 0 ?
                        data?.data?.map((item, index) => ({
                            ...item,
                            id: index + (page == 1 ? 1 : page * 12 - 11),
                            brand: item?.brand?.name,
                            category: item?.category?.category_name,
                            source: item.event,
                            // title: item?.review_payload?.title === "" ? "-" : item?.review_payload?.title,
                            title: (item?.event === "eventshop" || item?.event === "eventshare") ? item?.event_payload?.title === "" ? "-" : item?.event_payload?.title : item?.review_payload?.title === "" ? "-" : item?.review_payload?.title,


                            purchased_by: item?.customer?.name,
                            affiliate: item?.affiliate?.name,
                            sub_category: item?.sub_category?.sub_category_name,
                            general_category: item?.gen_category?.name,
                        })) : []
                }

                columns={renderCol()}
                pagination
                pageSize={12}
                rowsPerPageOptions={[12]}
                rowCount={data?.total_records ? data?.total_records : 0}
                paginationMode="server"
                onPageChange={(newPage) => nowGet(newPage + 1)}
            />
            <div className='sumContainer-height-235 ' style={{ background: "#fff" }} >
                <div className='sumContainer'>
                    <div className='sumTitle'>Summary</div>
                    <div className='sumBody'>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Orders</div>
                            <div className='sumBodyvalue'>{summary?.order_count}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Amount</div>
                            <div className='sumBodyvalue'>{numeral(summary?.gross_amount).format("$0,0.00", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Return Amount</div>
                            <div className='sumBodyvalue'>{numeral(summary?.returned_amount).format("$0,0.00", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Balance Amount</div>
                            <div className='sumBodyvalue'>{numeral(summary?.balance_amount).format("$0,0.00", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total ORME Fee</div>
                            <div className='sumBodyvalue'>{numeral(summary?.kb_fee).format("$0,0.00", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Cashback</div>
                            <div className='sumBodyvalue'>{numeral(summary?.cashback_amount).format("$0,0.00", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow' >
                            <div className='sumBodyTitle'>Total Referral Fee</div>
                            <div className='sumBodyvalue'>{numeral(summary?.customer_commission).format("$0,0.00", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow' >
                            <div className='sumBodyTitle'>Total Creator Fee</div>
                            <div className='sumBodyvalue'>{numeral(summary?.creator_commission).format("$0,0.00", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow' style={{ borderBottom: "none" }}>
                            <div className='sumBodyTitle'>Total Net Sales</div>
                            <div className='sumBodyvalue'>{numeral(summary?.net_brand_amount).format("$0,0.00", Math.floor)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


function CustomFooterTotalComponent(props) {
    return (
        <Box sx={{ padding: "10px", display: "flex" }}>Total : {props.total}</Box>
    );
}