import React, { useEffect, useMemo } from 'react'
import NoRows from "../../reUsable/NoRows"
import numeral from 'numeral'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import moment from 'moment'
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';
// import UserModal from "../../reUsable/UserModal";
// import BanModal from "./BanModal";
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material'
import { AiOutlineEdit } from 'react-icons/ai'
import SaveModal from "reUsable/SaveModal"

function DataTable({ nowGet, data, onDelete, flag, userban, onUpdate }) {
    const [user, setUser] = useState(null)
    const [modal, setModal] = useState(false)
    const [page, setPage] = useState(1)
    const [pageLoad, setPageLoad] = useState(false)
    const [banModal, setBanModal] = useState(false)
    const [detail, setDetail] = useState(null)
    const column = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        { field: "brand_name", headerName: "Brand", width: 180, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "name", headerName: "Discount Code", width: 220, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "discount", headerName: "Discount", width: 100, sortable: false, renderCell: ({ value }) => value ? <span >{value}%</span> : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "start_date", headerName: "Start Date", width: 130, sortable: false, renderCell: ({ value }) => value ? moment(value).format('MM-DD-YYYY') : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "end_date", headerName: "End Date", width: 130, sortable: false, renderCell: ({ value }) => value ? moment(value).format('MM-DD-YYYY') : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "active", headerName: "Status", width: 100, sortable: false, renderCell: ({ value }) => value ? <span style={{ color: "green" }}>Active</span> : <span style={{ color: "red" }}>Not Active</span> },
        { field: "discounted_product_marked", headerName: "Include Markdown", width: 150, sortable: false, renderCell: ({ value }) => value ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span> },

        {
            field: "action", headerName: "Action", width: 200, sortable: false, renderCell: (params) => {
                let data = params.row
                if (flag) {
                    return ""
                } else {
                    return (
                        <div style={{ display: "flex", alignItems: 'center', gap: 5 }}>
                            <Tooltip title={`Click to ${data.active ? "Deactivate" : "Activate"}`} placement="left" arrow>
                                <div onClick={() => {
                                    setDetail({ ...data, key: 'status' })
                                    setModal(true)
                                }} style={{ fontWeight: 500, fontSize: "0.8rem", cursor: 'pointer', padding: "5px", borderRadius: "5px", color: "#fff", background: `${data.active ? "#D32F2F" : "green"}` }}>
                                    {data.active ? "Deactive" : "Active"}
                                </div>
                            </Tooltip>
                            <div onClick={() => {
                                setDetail({ ...data, key: 'delete' })
                                setModal(true)
                            }} style={{ fontWeight: 500, fontSize: "0.8rem", cursor: 'pointer', padding: "5px", borderRadius: "5px", color: "#fff", background: "#D32F2F", width: "62px", textAlign: "center" }}>
                                Delete
                            </div>
                        </div>
                    )
                }
            }
        },
    ]
    useEffect(() => {
        setPageLoad(false)
    }, [data])

    function banUser(data) {
        userban({ ...data, ...user })
        setUser(null)
    }

    const discountData = useMemo(() => {
        if (data.success) {
            return data.message
        }
    }, [data])

    function deleteDis() {
        if (detail.key === "status") {
            onUpdate(detail)
        } else {
            onDelete(detail)
        }
        setDetail(null)
        setModal(false)

    }

    return (
        <div style={{ height: 620 }} className="pl-3">
            <SaveModal
                visible={modal}
                title={detail?.key === "status" ? `Are you sure you want to ${detail?.active ? 'deactive' : 'active'}  this discount?` : "Are you sure you want to delete this discount?"}
                close={() => {
                    setDetail(null)
                    setModal(false)
                }}
                type="status"
                call={deleteDis}
            />
            <DataGrid
                loading={pageLoad}
                showCellRightBorder={true}
                rowHeight={50}
                disableColumnFilter={true}
                density="compact"
                components={{ NoRowsOverlay: NoRows }}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                rows={
                    discountData?.data?.map((item, index) => ({
                        ...item,
                        id: index + (page == 1 ? 1 : (page * 15) - 14),
                    }))
                }
                columns={column}
                rowCount={discountData?.total ? discountData?.total : 0}
                pagination
                pageSize={15}
                rowsPerPageOptions={[15]}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPageLoad(true)
                    setPage(newPage + 1);
                    nowGet(newPage + 1);
                }}

            />
            {/* <BanModal
                data={user}
                visible={banModal}
                close={() => {
                    setBanModal(false)
                }}
                ban={banUser}
            />
            <UserModal
                from={'withdraw'}
                visible={modal}
                close={() => {
                    setUser(null)
                    setModal(false);
                }}
                userImage={user?.image}
                userName={user?.name}
                deleted={false}
                currentCustomerId={user?.customer_id}
                currentUser={user?.id}
                nowGet={() => { }}
                userType={user?.type}
            />
             */}
        </div>
    )
}
export default React.memo(DataTable)