import konnect from "./axios"
import { ADD_CAMPAIGN_MINIMIZE, ADD_MINIMIZE, MINUS_CAMPAIGN_MINIZE, MINUS_MINIZE } from "./type"



export const addMinimize=(data)=>async(dispatch)=>{
    dispatch({
        type:ADD_MINIMIZE,
        payload:data
    })
}

export const deleteMinimize=(id)=>async(dispatch)=>{
    dispatch({
        type:MINUS_MINIZE,
        payload:id
    })
}

export const addCampaignMinimize=(data)=>async(dispatch)=>{
    dispatch({
        type:ADD_CAMPAIGN_MINIMIZE,
        payload:data
    })
}

export const deleteCampaignMinimize=(id)=>async(dispatch)=>{
    dispatch({
        type:MINUS_CAMPAIGN_MINIZE,
        payload:id
    })
}