import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import numeral from "numeral";
import * as salesActions from "../../store/actions/sales"
import * as accountActions from "../../store/actions/account"

import { Col, Row } from "react-bootstrap";
import { Button, Typography, Divider, } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import NoRows from "../../reUsable/NoRows"
import Skeleton from '../../reUsable/skeleton';
import "../accountMain/components/sale.css"
import *  as payoutAction from 'store/actions/payout'
import PayoutModal from "reUsable/payoutModal";
import UserModals from "./UserModal";
import moment from "moment";

import UserModal from "../../reUsable/UserModal";



function Payout({ getALLWithdrawRequest, payout, handleWithdrawRequest }) {
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1);
    const [fields, setFields] = useState({ orderBy: -1, sort: "created_date", account_type: "all", id: '', status: 'Processing' })
    const [pageLoad, setPageLoad] = useState(false)
    const [decision, setDecision] = useState("")
    const [show, setShow] = useState(false)
    const [datatoSend, setDatatoSend] = useState({ _id: "", status: "" })
    const [message, setMessage] = useState("")
    const [modalLoading, setMoalLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [userInfo, setUserInfo] = useState(null)
    const [user, setUser] = useState(null)
    const [userModal, setUserModal] = useState(false)
    useEffect(() => {
        if (user) {
            // console.log(user)
            setUserModal(true)
        }
    }, [user])
    const restCol = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        {
            field: "created_date", headerName: "Date", width: 120, sortable: false, renderCell: ({ value }) => {
                return moment(value).format('MM-DD-YYYY')
            }
        },
        {
            field: "name", headerName: "Withdrawer Name", width: 200, sortable: false, renderCell: (params) => {
                return (
                    // <Link to={`/accountMain/detail/${params.value.id}`}>
                    <span
                        className="account-name-hover-style"
                        onClick={() => {
                            // console.log('111', params?.row)
                            setUser({
                                id: params.row.agency_id,
                                name: params?.row?.agencyData?.[0]?.company_name,
                                image: '',
                                customer_id: '',
                                userType: 'agency'
                            })
                            // setImage(params?.row?.profile_image_url)
                            // setUserType(params?.row?.account_type)
                            // setCurrentUser(params.row._id);
                            // setUserName(params.row.name);
                            // if (params.row.deleted) {
                            //     setCurrentDeleted(params.row.deleted);
                            // }
                            // setCurrentCustomerId(params.row.customer_id);
                            // setUserModal(true);
                        }}
                    >
                        {params.row.name ? params.row.name : <span style={{ color: "#bebebe" }}>Empty</span>}
                    </span>
                    // </Link>
                );
            },
        },
        { field: "email", headerName: "Withdrawer Email", width: 220, sortable: false },
        { field: "amount", headerName: "Withdraw Amount", width: 150, sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "status", headerName: "Status", width: 130, sortable: false, },
        { field: "approver_email", headerName: "Approved By", width: 220, sortable: false, },
        { field: "department", headerName: "Department", width: 120, sortable: false, },
        {
            field: 'actions',
            headerClassName: 'super-app-theme--header',
            headerName: "Actions",
            flex: 1.5,
            filterable: false,
            minWidth: 250,
            textAlign: 'center',
            renderCell: (value) => {
                return (
                    <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
                        <>
                            <Button
                                onClick={() => {
                                    setUserInfo({ ...value?.row, release: true })
                                    setModal(true)
                                }}
                                className='btn-gen-margin '
                                variant="contained"
                                style={{ fontSize: "10px" }}
                                color="primary"
                                disableElevation
                            >
                                Approve
                            </Button>
                        </>
                        <Button
                            onClick={() => {
                                setDatatoSend({ ...datatoSend, status: "reject", _id: value?.row?._id })
                                setDecision("Reject")
                                setShow(true)
                            }}
                            className='btn-gen-margin'
                            variant="contained"
                            style={{ fontSize: "10px" }}
                            color="error"
                            disableElevation
                        >
                            Reject
                        </Button>
                    </div>
                )
            }
        },

    ]
    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        getALLWithdrawRequest(fields, 1, false, 'agency').then((res) => {
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    const submitFn = () => {
        setMoalLoading(true)
        handleWithdrawRequest(datatoSend, 'agency').then((res) => {
            if (res?.success) {
                setMessage("Request has been updated.")
                setLoading(true)
                getALLWithdrawRequest(fields, page, false, 'agency').then((res) => {
                    getData(res.totalCount)
                    setLoading(false)
                })
            } else {
                setMessage("An error occured. Try again later.")
            }
            setMoalLoading(false)
            setDatatoSend({ _id: "", status: "" })
        })
    }

    return (
        <React.Fragment>

            <UserModal
                from={'withdraw'}
                visible={userModal}
                close={() => {
                    setUser(null)
                    setUserModal(false);
                }}
                userImage={user?.image}
                userName={user?.name}
                deleted={false}
                currentCustomerId={user?.customer_id}
                currentUser={user?.id}
                nowGet={() => console.log("NOW GETTTTTTTTTTTTTTTTTTTTTTT")}
                userType={'agency'}
            />
            <div className='container-fluid mt-3 mb-3 pl-0'>
                <Row className='pl-15'>
                    <Col xl className="mb-3 d-flex align-items-center">
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>

                            <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                                Agency Payout Release
                            </Typography>
                            <Button
                                variant="contained"
                                size="medium"
                                className="btn-gen-margin btn-width"
                                color="primary"
                                disableElevation
                                onClick={() => {
                                    {
                                        setLoading(true)
                                        getData()
                                    }
                                }}
                            >
                                Refresh
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Divider className="separater-sale ml-15" />
                <div style={{ height: 460 }} className="pl-3">
                    {
                        loading ? <Skeleton /> :
                            <DataGrid
                                density="compact"
                                loading={pageLoad}
                                components={{ NoRowsOverlay: NoRows }}
                                disableColumnSelector
                                disableColumnMenu
                                disableSelectionOnClick
                                rows={
                                    Object.keys(payout).length !== 0 ? payout?.data?.map((m, index) => ({
                                        ...m,
                                        id: index + (page == 1 ? 1 : (page * 10) - 9),
                                        created_date: m.created_date,
                                        amount: numeral(m.withdraw_amount).format("$0,0.00", Math.floor),
                                        status: m.status ? m.status : "Pending",
                                        name: m?.agencyData?.[0]?.company_name,
                                        email: m?.agencyData?.[0]?.email,
                                        department: m?.department,
                                        approver_email: m?.approver_email
                                    })) : []

                                }
                                columns={restCol}
                                rowCount={Object.keys(payout).length !== 0 ? payout?.totalCount : 0}
                                pagination
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                paginationMode="server"
                                onPageChange={(newPage) => {
                                    setPage(newPage + 1);
                                    setPageLoad(true)
                                    getALLWithdrawRequest(fields, newPage + 1, false, 'agency').then(() => {
                                        setPageLoad(false)
                                    })
                                }}
                            />
                    }
                </div>
            </div>
            <UserModals type={"agency"} info={userInfo} visible={modal} close={() => {
                setUserInfo(null)
                setModal(false)
            }} />
            <PayoutModal
                visible={show}
                setShow={setShow}
                approve={decision}
                submitFn={submitFn}
                setDatatoSend={setDatatoSend}
                setMessage={setMessage}
                message={message}
                loading={modalLoading} />

        </React.Fragment>
    )
}

function mapStateToProps({ accountList, withdrawal, payout, changePayout }) {
    return { accountList, withdrawal, payout }
}
export default connect(mapStateToProps, { ...salesActions, ...accountActions, ...payoutAction })(Payout)
