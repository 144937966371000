import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap"
import { Button, Divider, InputAdornment, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import * as codeAction from "../../../store/actions/code.action"
import { toast } from 'react-toastify';
import SaveModal from "../../../reUsable/SaveModal";

function Code({ getCode, updateCode }) {
    const [load, setLoad] = useState(true)
    const [modal, setModal] = useState(false)
    const [code, setCode] = useState('')

    useEffect(() => {
        getData()
    }, [])

    const handleSave = () => {
        setLoad(true)
        setModal(false)
        updateCode({ code }).then((res) => {
            if (res.success) {
                toast.success(`${res?.message}`)
            } else {
                toast.error(`${res?.message}`)
            }
            getData()
        })
    }

    function getData() {
        getCode().then((res) => {
            if (res?.success) {
                setCode(res?.data?.code)
            }
            setLoad(false)
        })
    }

    return (
        <div className="container-fluid mb-3 pt-3">
            <SaveModal
                visible={modal}
                title={`Are you sure you want to save these changes?`}
                close={() => setModal(false)}
                type="status"
                call={() => handleSave()}
            />
            <Row className="d-flex justify-content-between">
                <Col xl={4} lg={6} md={12}>
                    <div className='infoSection new mb-3' style={{ background: "#FFF" }} >
                        <Box className="addons-cart-title d-flex align-items-center">
                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                Master Key
                            </Typography>
                        </Box>
                        <Divider className='mb-20' />
                        <TextField
                            size="small"
                            value={code}
                            onChange={e => {
                                setCode(e.target.value)
                            }}
                            id="outlined-basic"
                            label="Code"

                            variant="outlined"
                            className="field-style mb-15"
                        />
                        <Button
                            disabled={load}
                            className="btn-width btn-gen-margin ml-0 h-100"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={() => setModal(true)}
                        >
                            Save
                        </Button>
                    </div>
                </Col>

            </Row>
        </div>
    )
}

const mapStateToProps = ({ feed }) => {
    return { feed };
};

export default connect(mapStateToProps, { ...codeAction })(Code);