import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { TailSpin } from 'react-loader-spinner'



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

export default function PayoutModal({ close, visible, title, call, btnText, approve, setShow, submitFn, setDatatoSend, message, loading,setMessage }) {

    return (
        <div>
            <Modal
                open={visible}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="deleteModal w-30">
                    {loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><TailSpin color='rgb(0,0,128)' /></div> : <>
                        <div className='modal-head-icon-area'>
                            <LiveHelpIcon color="primary" sx={{ fontSize: 60 }} />
                        </div>
                        <Typography className='modal-title' id="modal-modal-title" variant="h6" component="h2">
                            {message == "" ? `Are you sure you want to ${approve} this request ?`  : message} 
                        </Typography>
                        <Typography className='modal-description' id="modal-modal-description" sx={{ mt: 2 }}>
                            {title}
                        </Typography>

                        {message == "" ? <div className='boxes-buttons-row justify-content-around'>
                            <Button
                                onClick={submitFn}
                                variant="contained"
                                size="medium"
                                className="btn-gen-margin btn-width"
                                color="primary"
                                disableElevation
                            >
                                {approve}
                            </Button>
                            <Button
                                onClick={() => {
                                    setDatatoSend({ _id: "", status: "" })
                                    setShow(false)
                                }}
                                variant="outlined"
                                size="medium"
                                className="btn-gen-margin btn-width grey-btn-outline"
                            >
                                Cancel
                            </Button>
                        </div> : <div className='boxes-buttons-row justify-content-around'>
                            <Button
                            onClick={() => {
                                setShow(false)
                                setMessage("")
                            }}
                            variant="outlined"
                            size="medium"
                            className="btn-gen-margin btn-width grey-btn-outline"
                        >
                            Close
                        </Button></div>}</>}
                </Box>
            </Modal>
        </div>
    );
}