import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Button, Divider, Typography } from '@mui/material'
import MainFeed from "./MainFeed"
import FollowingFeed from "./FollowingFeed"
import ForYou from "./Foryou"
import AdFeed from "./AdFeed"
import VariantFeed from "./VariantFeed"
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import * as feedAction from "../../store/actions/feedAction"
import { connect } from 'react-redux'
import { toast } from 'react-toastify';


function FeedSetting({ type, generateFeed }) {
    const [load, setLoad] = useState(false)
    function generate() {
        setLoad(true)
        generateFeed(type).then((res) => {
            if (res.success) {
                toast.success(`Feed Generated Successfully`)
                setLoad(false)
            } else {
                toast.error(`Something went wrong`)
                setLoad(false)
            }
        }).catch(err => {
            toast.error(`Something went wrong`)
            setLoad(false)
        })
    }
    return (
        <div className="container-fluid mb-3">
            <Row className='pt-3'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        For You Feed Setting
                    </Typography>
                </Col>
            </Row>
            <Divider className="mb-15" />
            <Row className="d-flex justify-content-betwee">
                {/* <Col xl={5} lg={6} md={12}>
                    <FollowingFeed type={type} />
            
                </Col> */}
                <Col xl={5} lg={6} md={12}>
                    <ForYou type={type} />
                </Col>
                
            </Row>
        </div>
    )
}

const mapStateToProps = ({ }) => ({})
export default connect(mapStateToProps, feedAction)(FeedSetting)