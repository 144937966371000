import React, { useEffect, useMemo, useState } from 'react'
import * as accountActions from "../../../store/actions/account"
import * as packageActions from "../../../store/actions/package"
import { Row, Col } from "react-bootstrap"
import SkeletonLoader from "reUsable/skeleton";
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { toast } from "react-toastify";
import ConfirmModal from 'reUsable/ConfirmModal';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Input, TextField } from '@mui/material';


function Package({ getDetails, accountDetail, changeActive, getPackages, packages, currentUser, userType,nowGet }) {

    const [loading, setLoading] = useState(true);
    const [currentPackage, setCurrentPackage] = useState("");
    const [confirmModal, setConfirmModal] = useState(false)
    const [decLoading, setDecLoading] = useState(false);

    useEffect(() => {
        // Promise.all([getDetails(currentUser), getPackages()])
        getDetails(currentUser).then(() => setLoading(false))
    }, [])

    const accountDetailMemo = useMemo(() => {
        if (accountDetail?.packageDetails) {
            setCurrentPackage(accountDetail?.packageDetails?._id)
        }
    }, [accountDetail])

    function renderData() {
        const {
            userDetails,
        } = accountDetail

        // function renderPackages(item) {

        //     function checkDisable(item) {
        //         if (userDetails.account_type == "influencer") {
        //             if (!currentPackage) {
        //                 const dis = item.package_id == "61d695e9bccdaf69f46efc66" ? true : false //brand
        //                 return dis
        //             } else {
        //                 if (currentPackage == "61c02d43f40bec74fac2c9a0") {  //influencer

        //                     const dis = item.package_id == "61c02e2ff40bec74fac2ca09" ? false : true //influencer plus
        //                     return dis
        //                 } else {
        //                     return true
        //                 }
        //             }
        //         } else {
        //             return true
        //         }
        //         // if(userDetails.account_type=="influencer"){
        //         //     if(item.package_id=="61c02d43f40bec74fac2c9a0" || item.package_id=="61c02e2ff40bec74fac2ca09"){
        //         //     return true
        //         //     }
        //         //     const dis=item.package_id=="61c02e2ff40bec74fac2ca09"?false:true
        //         //     return dis
        //         // }
        //         // else{
        //         //     if(item.package_id=="61d695e9bccdaf69f46efc66"){
        //         //         return true
        //         //         }
        //         //     return true
        //         // }
        //     }
        //     console.log("123")
        //     return packages.map((item) => {

        //         return <MenuItem disabled={checkDisable(item)} key={item.package_id} value={item.package_id}>{item.package_name}</MenuItem>
        //     })
        // }

        function onChangePackage() {
            setDecLoading(true)
            setConfirmModal(false)
            changeActive({
                package_id: currentPackage,
                user_id: userDetails._id

            })
                .then((res) => {
                    setDecLoading(false)
                    getDetails(currentUser)
                    nowGet()
                    if (res.success) {
                        toast.success(res.message)
                    } else {
                        toast.error(res.message)
                    }
                })
        }

        return (
            <>
                <ConfirmModal
                    visible={confirmModal}
                    close={() => {
                        setConfirmModal(false)
                        setCurrentPackage(accountDetail?.packageDetails?._id)
                    }}
                    call={onChangePackage}
                    title="Are you sure you want to save this package?"
                />
                <Row className='align-items-center'>
                    <Col xl={5} lg={6}>
                        <Card variant="outlined" className='users-card-main' style={{ minHeight: 'initial' }} sx={{ minWidth: 275 }}>
                            <Typography className='fw-normal' color="primary" variant="h6" gutterBottom component="div">
                                Package
                            </Typography>
                            <Divider className='mb-3' />
                            <CardContent>
                                <FormControl className='w-100 mb-4' size="small">
                                    <InputLabel id="demo-simple-select-helper-label">Package</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={accountDetail?.packageDetails?._id}
                                        onChange={(e) => {
                                            setCurrentPackage(e.target.value)
                                        }}
                                        label="Package"
                                    >
                                        <MenuItem value="61d695e9bccdaf69f46efc66">
                                            Brand
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <div style={{ width: "100%", display: "flex", }}>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        className="btn-gen-margin btn-width ml-0"
                                        color="primary"
                                        disableElevation
                                        disabled={currentPackage == accountDetail?.packageDetails?._id || currentPackage === ""}
                                        onClick={() => {
                                            setConfirmModal(true)
                                        }}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </CardContent>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
    return (
        <React.Fragment>
            {loading ? (
                <SkeletonLoader />
            ) : renderData()}
        </React.Fragment>
    );
}

function mapStateToProps({ accountDetail, packages }) {
    return { accountDetail, packages }
}

export default connect(mapStateToProps, { ...accountActions, ...packageActions })(Package)