import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom"
import { RiDeleteBinLine } from "react-icons/ri"
import { AiOutlineEdit, AiOutlineEye } from "react-icons/ai"
import { Button } from "@mui/material"
import "../table.css"
import Payload from './Payload';
import moment from 'moment';


export default function DataTable({ logs, nowGet, tableLoading, from }) {
  const [currentItem, setCurrentItem] = React.useState({})
  const [payloadModal, setPayloadModal] = React.useState(false)
  const [userModal, setUserModal] = React.useState(false)

  const columns = [
    {
      field: 'sNo',
      headerClassName: 'super-app-theme--header',
      headerName: "S.#",
      width: 50,
      sortable: false,
      renderCell: ({ value }) => {
        return <span className="d-flex justify-content-center w-100">{value}</span>
      },
      filterable: false
    },
    {
      field: 'created_at',
      headerClassName: 'super-app-theme--header',
      headerName: "Date",
      width: 170,
      sortable: false,
      renderCell: (params) => {
        if (params.value) {
          return params.value
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      },
      filterable: false
    },
    {
      field: 'action',
      headerClassName: 'super-app-theme--header',
      headerName: "Action",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        if (params.value) {
          return params.value
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      },
      minWidth: 100,
      filterable: false
    },
    {
      field: 'api',
      headerClassName: 'super-app-theme--header',
      headerName: "API Duration",
      width: 150,
      sortable: false,
      renderCell: ({ value }) => {
        let classes = `time ${(value > 4000 && value < 8000 ? 'timeWarn' : value > 8000 ? "timeBad" : '')}`
        // let classes = `time ${(value > 600 && value < 1800 ? 'timeWarn' : value > 1800 ? "timeBad" : '')}`
        return value ? <div className={classes} >{`${value} ms`}</div> : "-"
      },
      minWidth: 100,
      filterable: false
    },
    // {
    //   field: 'status',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: "Status",
    //   width: 100,
    //   sortable: false,
    //   renderCell: ({ value }) => {
    //     if (value) {
    //       return value ? <div className='w-100' style={{ color: "green", textAlign: "center" }}>Success</div> : <span style={{ color: "red" }}>Failed</span>
    //     } else {
    //       return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
    //     }
    //   },
    //   minWidth: 100,
    //   filterable: false
    // },
    {
      field: 'platform',
      headerClassName: 'super-app-theme--header',
      headerName: "Platform",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        if (params.value) {
          return params.value.charAt(0).toUpperCase() + params.value.slice(1)
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      },
      minWidth: 100,
      filterable: false
    },
    {
      field: 'account_type',
      headerClassName: 'super-app-theme--header',
      headerName: "Account Type",
      minWidth: 140,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        if (value) {
          return value === "influencer" ? "User" : value
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      },
    },
    {
      field: 'pixel',
      headerClassName: 'super-app-theme--header',
      headerName: "PID",
      minWidth: 140,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        if (params.value) {
          return params.value
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      },
    },
    {
      field: 'phone',
      headerClassName: 'super-app-theme--header',
      headerName: "Phone",
      minWidth: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        if (params.value) {
          return params.value
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      },
    },




    {
      field: 'email',
      headerClassName: 'super-app-theme--header',
      headerName: "Email",
      minWidth: 250,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        if (value) {
          return value
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      },
    },
    {
      field: 'ip',
      headerClassName: 'super-app-theme--header',
      headerName: "IP Address",
      minWidth: 130,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        if (params.value) {
          return params.value
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      },
    },
    // {
    //   field: 'name',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: "Name",
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     if (params.value) {
    //       return params.value.name
    //     } else {
    //       return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
    //     }
    //   },
    //   filterable: false
    // },
    // {
    //   field: 'instaName',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: "Instagram Name",
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     if (params.value) {
    //       return <a target="_blank" href={`https://konnect.bio/${params.value}`}>{params.value}</a>
    //     } else {
    //       return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
    //     }
    //   },
    //   filterable: false
    // },
    // {
    //   field: 'email',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: "Email",
    //   minWidth: 200,
    //   renderCell: (params) => {
    //     if (params.value) {
    //       return params.value
    //     } else {
    //       return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
    //     }
    //   },
    //   filterable: false
    // },
    // {
    //   field: 'collection_name',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: "Module",
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     if (params.value) {
    //       return params.value
    //     } else {
    //       return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
    //     }
    //   },
    //   filterable: false
    // },
    // {
    //   field: 'status_code',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: "status",
    //   renderCell: (params) => {
    //     if (params.value) {
    //       return params.value
    //     } else {
    //       return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
    //     }
    //   },
    //   minWidth: 100,
    //   renderCell: (params) => {
    //     return <span>{params.value}</span>
    //   },
    //   filterable: false
    // },
    // {
    //   field: 'country',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: "Country",
    //   renderCell: (params) => {
    //     if (params.value) {
    //       return params.value
    //     } else {
    //       return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
    //     }
    //   },
    //   minWidth: 150,
    //   filterable: false
    // },
    // {
    //   field: 'city',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: "City",
    //   minWidth: 150,
    //   filterable: false
    // },
    {
      field: 'payload',
      headerClassName: 'super-app-theme--header',
      headerName: "Payload",
      minWidth: 150,
      filterable: false,
      renderCell: ({ value }) => {
        if (value) {
          return (
            <>
              <div
                onClick={() => {
                  setCurrentItem(value)
                  setPayloadModal(true)
                }}
                style={{ color: "green", fontSize: "0.9rem", cursor: 'pointer', borderRadius: '5px' }}
              >
                Payload
              </div>
              {/* <Button
                onClick={() => {
                  setCurrentItem(value)
                  setPayloadModal(true)
                }}
                style={{ color: 'green', border: "none" }}
                variant="outlined"
              >
                Payload
              </Button> */}
            </>
          )
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      }
    },
    // {
    //   field: 'deleted_user_info',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: "Deleted User",
    //   minWidth: 150,
    //   filterable: false,
    //   renderCell: (params) => {
    //     if (params.value) {
    //       return (
    //         <Button
    //           onClick={() => {
    //             setCurrentItem(params.value)
    //             setUserModal(true)
    //           }}
    //           style={{ color: 'gray', borderColor: 'gray' }}
    //           variant="outlined"
    //         >
    //           Detail
    //         </Button>
    //       )
    //     } else {
    //       return "-"
    //     }
    //   }
    // },

    // {
    //     field:'actions',
    //     headerClassName: 'super-app-theme--header',
    //     headerName:"Actions",
    //     flex:1,
    //     filterable:false,
    //     minWidth:60,
    //     textAlign:'center',
    //     renderCell:(params)=>{
    //         return(
    //             <div style={{ marginLeft: 'auto', marginRight: 'auto',width:'100%'  }}>
    //                   <Button
    //                     disabled={params.value.instagram_username?false:true}
    //                     style={{ color: 'grey', borderColor: 'grey', marginLeft: 5,padding:0 }}
    //                     variant="outlined"
    //                   >
    //                     <a target="_blank" style={{textDecoration: 'inherit'}} href={`https://konnect.bio/${params.value.instagram_username}`}>
    //                       <AiOutlineEye
    //                       color={'grey'}
    //                       size={15}
    //                     />
    //                      </a>
    //                   </Button>
    //               </div>
    //         )
    //     }
    // },

  ]
  const adminColumns = [
    {
      field: 'sNo',
      headerClassName: 'super-app-theme--header',
      headerName: "S.#",
      width: 50,
      sortable: false,
      renderCell: ({ value }) => {
        return <span className="d-flex justify-content-center w-100">{value}</span>
      },
      filterable: false
    },
    {
      field: 'created_at',
      headerClassName: 'super-app-theme--header',
      headerName: "Date",
      width: 170,
      sortable: false,
      renderCell: (params) => {
        if (params.value) {
          return params.value
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      },
      filterable: false
    },
    {
      field: 'action',
      headerClassName: 'super-app-theme--header',
      headerName: "Action",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        if (params.value) {
          return params.value
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      },
      minWidth: 100,
      filterable: false
    },
    {
      field: 'api',
      headerClassName: 'super-app-theme--header',
      headerName: "API Duration",
      width: 150,
      sortable: false,
      renderCell: ({ value }) => {
        let classes = `time ${(value > 4000 && value < 8000 ? 'timeWarn' : value > 8000 ? "timeBad" : '')}`
        // let classes = `time ${(value > 600 && value < 1800 ? 'timeWarn' : value > 1800 ? "timeBad" : '')}`
        return value ? <div className={classes} >{`${value} ms`}</div> : "-"
      },
      minWidth: 100,
      filterable: false
    },


    {
      field: 'email',
      headerClassName: 'super-app-theme--header',
      headerName: "Email",
      minWidth: 250,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        if (value) {
          return value
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      },
    },
    {
      field: 'ip',
      headerClassName: 'super-app-theme--header',
      headerName: "IP Address",
      minWidth: 130,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        if (params.value) {
          return params.value
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      },
    },

    {
      field: 'payload',
      headerClassName: 'super-app-theme--header',
      headerName: "Payload",
      minWidth: 150,
      filterable: false,
      renderCell: ({ value }) => {
        if (value) {
          return (
            <>
              <div
                onClick={() => {
                  setCurrentItem(value)
                  setPayloadModal(true)
                }}
                style={{ color: "green", fontSize: "0.9rem", cursor: 'pointer', borderRadius: '5px' }}
              >
                Payload
              </div>
            </>
          )
        } else {
          return <span style={{ margin: 0, width: '100%', textAlign: 'center' }}>-</span>
        }
      }
    },
  ]


  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const logData = React.useMemo(() => {
    if (logs?.logs?.length > 0) {
      return logs?.logs?.map(item => ({
        id: item?._id,
        data: { ...item?._source }
      }))
    } else {
      return null
    }
  }, [logs])
  return (
    <div style={{ height: 526 }}>
      <Payload
        visible={payloadModal}
        title="Payload"
        close={() => setPayloadModal(false)}
        data={currentItem}
      />
      {/* <Payload
        visible={userModal}
        title="User Information"
        close={() => setUserModal(false)}
        data={currentItem}
      /> */}
      <DataGrid
        showCellRightBorder={true}
        rowHeight={35}
        density="compact"
        disableColumnFilter={true}
        disableColumnSelector
        disableColumnMenu
        disableSelectionOnClick
        //   sx={{ marginTop: 2 }}
        className="bg-white "
        // rows={[]}
        rows={logData?.map((item, i) => {
          return {
            ...item,
            sNo: i + (page == 1 ? 1 : (page * 18) - 17),
            created_at: moment(item?.data?.date_time).format('YYYY-MM-DD - HH:mm:ss'),
            action: item?.data?.action,
            platform: item?.data?.platform,
            ip: item?.data?.ip,
            phone: item?.data?.user?.phone,
            pixel: item?.data?.user?.pixel_id,
            account_type: item?.data?.user?.account_type,
            name: item?.data?.user?.first_name + " " + item?.data?.user?.last_name,
            email: item?.data?.user?.email,
            payload: item?.data?.payload ? item?.data?.payload : null,
            status: item?.data?.success,
            api: item?.data?.api_duration?.duration
            // country:item.client_info?.country,
            // city:item.client_info?.city,
            // actions:item,
            // instaName:item.client_info?.instagram_username,
            // name:{_id:item.client_info?._id,name:item.client_info?.name},
            // email:item.client_info?.email,
          }
        })}
        columns={from === "admin" ? adminColumns : columns}
        pagination
        pageSize={18}
        rowsPerPageOptions={[18]}
        rowCount={logs.total}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPage(newPage + 1)
          setLoading(true)
          nowGet(newPage + 1).then(() => setLoading(false))
        }}
        loading={loading || tableLoading ? true : false}
      />
    </div>
  );
}
