import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";

import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { Col, Row } from 'react-bootstrap';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import * as priceAction from "../../store/actions/price"
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};
function AddPrice({ clear, visible, data, get, addPrice, updatePrice }) {
    const [fields, setFields] = useState({
        name: "",
        min: "",
        max: "",
        is_active: true
    })
    const [submit, setSubmit] = useState(false)
    function setter(k, v) {
        setFields({ ...fields, [k]: v })
    }

    function handleSubmit() {
        setSubmit(true)
        if (fields.name === "" || fields.min === "" || fields.max === "") {
            return
        }
        if (data) {
            updatePrice({ ...fields, _id: data?._id }).then(res => {
                if (res.success) {
                    toast.success(res?.message, {
                        autoClose: 3000,
                        hideProgressBar: true,
                    });
                } else {
                    toast.error(res?.message, {
                        autoClose: 3000,
                        hideProgressBar: true,
                    });
                }
                get()

            }).catch(err => {
                console.log("err", err)
            })
            close()
        } else {
            addPrice(fields).then(res => {
                if (res.success) {
                    toast.success(res?.message, {
                        autoClose: 3000,
                        hideProgressBar: true,
                    });
                } else {
                    toast.error(res?.message, {
                        autoClose: 3000,
                        hideProgressBar: true,
                    });
                }
                get()
            }).catch(err => {
                console.log("err", err)
            })
            close()
        }
    }
    useEffect(() => {
        if (data) {
            setFields({
                name: data?.name,
                min: data?.min,
                max: data?.max,
                is_active: data?.is_active,
            })
        }
    }, [data])

    function close(type) {
        setSubmit(false)
        if (data) {
            setFields({
                name: data?.name,
                min: data?.min,
                max: data?.max,
                is_active: data?.is_active,

            })
        } else {
            setFields({
                name: "",
                min: "",
                max: "",
                is_active: true,

            })
        }
        if (type !== "reset") {
            setFields({
                name: "",
                min: "",
                max: "",
                is_active: true,

            })
            clear()
        }
    }

    return (
        <Modal
            open={visible}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className={"deleteModal modal-generic-style modal-spacing-new"} style={{ width: "30%" }}>
                <Row className='heading-name-area-modal '>
                    <Col xl={12} lg={12} className="d-flex align-items-center justify-content-between">
                        <Box className="d-flex align-items-center">
                            <Typography className='text-center w-100 m-0' id="modal-modal-title" variant="h6" component="h2">
                                {data ? "Update" : "Add"} Price Point
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                className='custom-close-btn-moda'
                                onClick={close}
                                aria-label="delete" sx={{ color: "grey" }}>
                                <CancelIcon color="error" />
                            </IconButton>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} lg={12} className="">
                        <Divider className='mb-15' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-left" >
                        <TextField
                            error={submit && fields.name === "" ? true : false}
                            size="small"
                            label="Name"
                            name='name'
                            value={fields.name}
                            onChange={(e) => setter(e.target.name, e.target.value)}
                            variant="outlined"
                            className="field-style mb-3 "
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-left mb-3" >
                        <TextField
                            error={submit && fields.min === "" ? true : false}
                            size="small"
                            name='min'
                            label="Minimum"
                            type='number'
                            value={fields.min}
                            onChange={(e) => {
                                setter(e.target.name, e.target.value)
                            }}
                            variant="outlined"
                            className="field-style "
                            InputProps={{
                                endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                inputProps: {
                                    min: 1
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-left mb-3" >
                        <TextField
                            error={submit && fields.max === "" ? true : false}
                            size="small"
                            name='max'
                            label="Maximum"
                            type='number'
                            value={fields.max}
                            onChange={(e) => {
                                setter(e.target.name, e.target.value)
                            }}
                            variant="outlined"
                            className="field-style "
                            InputProps={{
                                endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                inputProps: {
                                    min: 1
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} >
                        <FormControl
                            className="field-style mb-3" size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Status</InputLabel>
                            <Select
                                value={fields.is_active}
                                label="Status"
                                name='is_active'
                                onChange={(e) => setter(e.target.name, e.target.value)}
                            >
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Not active</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                <div className='modal-buttons-row' style={{ marginTop: "5px" }}>
                    <LoadingButton
                        onClick={handleSubmit}
                        variant="contained"
                        size="medium"
                        color="primary"
                        className="btn-gen-margin ml-0 btn-width"
                        disableElevation
                    >
                        <SaveIcon className="mr-2" />  {data ? "Update" : "Save"}
                    </LoadingButton>
                    <Button
                        className='btn-gen-margin btn-width grey-btn-outline'
                        variant="outlined"
                        component="label"
                        size="large"
                        onClick={() => close('reset')}
                    >
                        Reset
                    </Button>
                </div>
            </Box>
        </Modal >
    )
}
const mapStateToProps = ({ }) => {
    return {};
};
export default connect(mapStateToProps, { ...priceAction })(AddPrice)

