import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import * as salesActions from "../../../store/actions/sales"
import * as categoryActions from "../../../store/actions/Categories/categories"
import * as accountActions from "../../../store/actions/account"

import { connect, } from 'react-redux'
import { InputLabel, FormControl, Select, TextField, Button, MenuItem, Typography, Divider, Box, Autocomplete } from '@mui/material'

import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import numeral from "numeral";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Col, Row } from "react-bootstrap";
import NoRows from "../../../reUsable/NoRows"
import Skeleton from '../../../reUsable/skeleton';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO } from 'date-fns';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faPercent } from "@fortawesome/free-solid-svg-icons";
import "./sale.css"


function Earnings({ currentUser, getAccountList, accountList, getEarning, earning, filterCategories }) {
    const [value, setValue] = useState([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState(false)
    const [page, setPage] = useState(1);
    const [fields, setFields] = useState({
        generalCategory: "all",
        category: "all",
        subCategory: "all",
        brand: 'all'
    })
    const [name, setName] = useState("")
    const [nameLoad, setNameLoad] = useState(true)

    const [gen, setGen] = useState([])
    const [cat, setCat] = useState([])
    const [sub, setSub] = useState([])
    function clean() {
        setGen([])
        setCat([])
        setSub([])
        setFields({ ...fields, generalCategory: "all", category: "all", subCategory: "all" })
    }
    useEffect(() => {
        if (fields.brand !== "all") {
            clean()
            filterCategories({ brand_id: fields.brand, source: "genCategory" }).then((res) => {
                if (res.success) {
                    setGen(res?.message)
                } else {
                    setGen([])
                }
            })
        } else {
            clean()
        }
    }, [fields.brand])
    useEffect(() => {
        getData()
        getAccountList('', false, 'brand').then(() => {
            setNameLoad(false)
        })
    }, [])
    useEffect(() => {
        if (search) { getData() }
    }, [search]);

    const getData = () => {
        getEarning(currentUser, fields, value, page).then(() => {
            setLoading(false)
            setSearch(false)
        })
    }
    const handleSearch = (type) => {
        setPage(1)
        if (type === "refresh") {
            setValue([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
            setFields({ generalCategory: "all", category: "all", subCategory: "all", brand: "all" })
            setName('')
        } else {
            setLoading(true)
            setSearch(true)
        }
    }
    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    const handleChange = (val) => {
        getAccountList(val, false, 'brand').then(() => {
            setNameLoad(false)
        })
    };
    const optimizedFn = useCallback(debounce(handleChange), []);
    const records = useMemo(() => {
        if (earning.success) {
            let data = {
                lifetime: {
                    ref: earning?.message?.lifetime_earning?.[0]?.customer_commission,
                    cashback: earning?.message?.lifetime_earning?.[0]?.cashback_amount,
                    content: earning?.message?.lifetime_earning?.[0]?.creator_commission,
                },
                pending: {
                    ref: earning?.message?.pending_balance?.[0]?.customer_commission,
                    cashback: earning?.message?.pending_balance?.[0]?.cashback_amount,
                    content: earning?.message?.pending_balance?.[0]?.creator_commission,
                },
                available: (earning?.message?.available_to_withdraw?.[0]?.customer_commission + earning?.message?.available_to_withdraw?.[0]?.cashback_amount + earning?.message?.available_to_withdraw?.[0]?.creator_commission) - earning?.message?.withdraw_amount,
                withdraw_amount: earning?.message?.withdraw_amount
            }
            return { ...data }
        }
    }, [earning])

    return (
        <div className='saleContainer'>
            <Typography className='fw-normal' color="primary" variant="h6" gutterBottom component="div">
                Earnings
            </Typography>
            <Divider className='' />

            <div className='body'>
                <Row className='mt-4'>
                    <Col xl={3} lg={12} className='mb-3 '>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className='dateBox'>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            helperText: 'YYYY / MM / DD',
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format('YYYY-MM-DD'), value[1]]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            helperText: 'YYYY / MM / DD',
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format('YYYY-MM-DD')]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <Autocomplete
                            variant="outlined"
                            className="field-style"
                            size="small"
                            id="disable-clearable"
                            disableClearable
                            loading={nameLoad}
                            options={nameLoad ? [] : accountList?.message ? accountList?.message.map(opt => ({ ...opt, label: opt.name })) : []}
                            onChange={(e, data) => {
                                setFields({ ...fields, brand: data?._id })
                                setName(data.label)
                            }}
                            value={name === "" ? "All" : name}
                            renderInput={(params) => <TextField  {...params} label="Brand" onChange={(e) => {
                                setName(e.target.value)
                                setNameLoad(true)
                                optimizedFn(e.target.value)
                            }} />}
                        />
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel id="demo-simple-select-label1">Department</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Department"
                                labelId="generalCategory"
                                id="generalCategory"
                                value={fields.generalCategory}
                                label="Department"
                                onChange={(e) => {
                                    setFields({ ...fields, generalCategory: e.target.value, category: 'all', subCategory: 'all' })
                                    setCat([])
                                    setSub([])
                                    if (e.target.value !== "all") {
                                        filterCategories({ brand_id: fields.brand, source: "category", gen_category_id: e.target.value }).then((res) => {
                                            if (res.success) {
                                                setCat(res?.message)
                                            } else {
                                                setCat([])
                                                setSub([])
                                            }
                                        })
                                    }
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {gen?.map((item) => (<MenuItem value={item._id} >{item.name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel id="demo-simple-select-label1">Category</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Category"
                                labelId="category"
                                id="category"
                                value={fields.category}
                                label="Category"
                                onChange={(e) => {
                                    setFields({ ...fields, category: e.target.value, subCategory: 'all' })
                                    setSub([])
                                    if (e.target.value !== "all") {
                                        filterCategories({ brand_id: fields.brand, source: "subCategory", gen_category_id: fields.generalCategory, category_id: e.target.value }).then((res) => {
                                            if (res.success) {
                                                setSub(res?.message)
                                            } else {
                                                setSub([])
                                            }
                                        })
                                    }
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {cat?.map((item) => (<MenuItem value={item._id} >{item.category_name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Sub Category</InputLabel>
                            <Select
                                style={fields.category !== "" ? {} : { background: "#ebebeb" }}
                                disabled={fields.category === ""}
                                sx={{ color: "black" }}
                                placeholder="Sub Category"
                                labelId="subCategory"
                                id="subCategory"
                                value={fields.subCategory}
                                label="Sub Category"
                                onChange={(e) => setFields({ ...fields, subCategory: e.target.value })}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {sub?.map((item) => (<MenuItem value={item._id} >{item.sub_category_name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width laptop"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => { handleSearch() }}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width laptop"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('refresh') }}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
                <Divider className="separater-sale" />
                <div className='container ml-0 p-0 mt-3'>
                    <div class='earningTitle'>Lifetime Earnings</div>
                    <div class="row">
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : records?.lifetime?.ref).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">TOTAL REFERRAL FEE</div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : records?.lifetime?.cashback).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">TOTAL CASHBACK</div>
                            </div>
                        </div>
                        {window.location.pathname == "/influencer" && <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : records?.lifetime?.content).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">CONTENT COMMISSION</div>
                            </div>
                        </div>}
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : records?.lifetime?.content + records?.lifetime?.cashback + records?.lifetime?.ref).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">TOTAL EARNINGS</div>
                            </div>
                        </div>
                    </div>
                    <div class='earningTitle'>Pending Balance</div>
                    <div class="row">
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : records?.pending?.ref).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">TOTAL REFERRAL FEE</div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : records?.pending?.cashback).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">TOTAL CASHBACK</div>
                            </div>
                        </div>
                        {window.location.pathname == "/influencer" && <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : records?.pending?.content).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">CONTENT COMMISSION</div>
                            </div>
                        </div>}
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : records?.pending?.content + records?.pending?.ref + records?.pending?.cashback).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">TOTAL EARNINGS</div>
                            </div>
                        </div>
                    </div>
                    <div class='earningTitle'>Total Withdrawals</div>
                    <div class="row">
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faPercent}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : (records?.withdraw_amount)).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">TOTAL WITHDRAWALS</div>
                            </div>
                        </div>
                    </div>
                    <div class='earningTitle'>Available to Withdraw</div>
                    <div class="row">
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faPercent}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : (records?.available)).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">AVAILABLE TO WITHDRAW</div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div >
    )
}

function mapStateToProps({ sales, accountDetail, genderCategories, earning, accountList }) {
    return { sales, accountDetail, genderCategories, earning, accountList }
}
export default connect(mapStateToProps, { ...salesActions, ...categoryActions, ...accountActions })(Earnings)

