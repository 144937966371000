import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../table.css";
import moment from "moment";

function DataTable({ tableLoading, nowGet, notificationRed }) {
  const columns = [
    {
      field: "sNo",
      headerClassName: "super-app-theme--header",
      headerName: "S.no",
      minWidth: 80,
      flex: 0.5,
      renderCell: ({ value }) => {
        return (
          <span className="d-flex justify-content-center w-100">{value}</span>
        );
      },
      filterable: false,
    },
    {
      field: "title",
      headerClassName: "super-app-theme--header",
      headerName: "Title",
      minWidth: 150,
      flex: 1,
      filterable: false,
    },
    {
      field: "body",
      headerClassName: "super-app-theme--header",
      headerName: "Description",
      flex: 1.5,
      filterable: false,
      minWidth: 190,
    },
    {
      field: "created_at",
      headerClassName: "super-app-theme--header",
      headerName: "Created At",
      flex: 1.5,
      filterable: false,
      minWidth: 190,
    },
  ];
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  return (
    <div style={{ height: 460, marginLeft: "15px" }}>
      <DataGrid
        density="compact"
        rows={notificationRed.data?.map((item, i) => {
          return {
            ...item,
            id: item._id,
            created_at: moment(item.created_at).format("MM/DD/YYYY - hh:mm A"),
            title: item.title,
            body: item.body,
            sNo: i + (page == 1 ? 1 : page * 10 - 9),
          };
        })}
        columns={columns}
        pagination
        className="bg-white"
        pageSize={10}
        rowsPerPageOptions={[10]}
        rowCount={notificationRed.count}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPage(newPage + 1);
          setLoading(true);
          nowGet(newPage + 1).then(() => setLoading(false));
        }}
        loading={loading || tableLoading ? true : false}
      />
    </div>
  );
}

export default DataTable;
