import React, { useEffect, useState } from 'react'
import * as salesActions from "../../../store/actions/sales"
import { connect, } from 'react-redux'
import { InputLabel, FormControl, Select, TextField, Button, MenuItem, Typography, Divider, Box } from '@mui/material'

import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import numeral from "numeral";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Col, Row } from "react-bootstrap";
import NoRows from "../../../reUsable/NoRows"
import Skeleton from '../../../reUsable/skeleton';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO } from 'date-fns';

import "./sale.css"
import { summaryColBrand, summaryColInfluencer, detailColBrand, detailColInfluencer, dateColBrand, dateColInfluencer, campaignColBrand, campaignColInfluencer, summaryColBrandInf, summaryColInfluencerBrn } from "./cols"
import { CSVLink } from 'react-csv';
import CSVButton from "../../../reUsable/CSVButton"

function Sales({ currentUser, sales, getSales, accountDetail, userType }) {
    const fileName = `${userType}_record`;
    const [value, setValue] = useState([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [loading, setLoading] = useState(false)
    const [groupBy, setGroupBy] = useState('summary')
    const [search, setSearch] = useState(false)
    const [page, setPage] = useState(1);
    const [date, setDate] = useState(null)
    const [test, setTest] = useState(null)

    const [fields, setFields] = useState({
        orderBy: "-1",
        sort: "total_sale"
    })
    const [header, setHeader] = useState([])
    const [data, setData] = useState([])
    const [load, setLoad] = useState(true)
    // var userType = accountDetail?.userDetails?.account_type;

    useEffect(() => {
        setLoading(true)
        getSales(currentUser, userType, groupBy, page, value, fields).then((res) => {
            getData(res.total_records)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (search || date) {
            setLoading(true)
            setPage(1)
            getSales(currentUser, userType, groupBy, 1, value, fields).then((res) => {
                getData(res.total_records)
                setSearch(false)
                setDate(false)
                setTest(groupBy)
                setLoading(false)
            })
        }
    }, [search, date]);

    useEffect(() => {
        if (groupBy === "date") {
            if (userType === "brand") {
                setHeader(dateColBrand.map(m => ({ label: m.headerName, key: m.field })))
            } else {
                setHeader(dateColInfluencer.map(m => ({ label: m.headerName, key: m.field })))
            }
        } else if (groupBy === "campaign") {
            if (userType === "brand") {
                setHeader(campaignColBrand.map(m => ({ label: m.headerName, key: m.field })))
            } else {
                setHeader(campaignColInfluencer.map(m => ({ label: m.headerName, key: m.field })))
            }
        } else if (groupBy === "brand") {
            if (userType === "brand") {
                setHeader(summaryColBrand.map(m => ({ label: m.headerName, key: m.field })))
            } else {
                setHeader(summaryColBrandInf.map(m => ({ label: m.headerName, key: m.field })))
            }
        } else if (groupBy === "influencer") {
            if (userType === "brand") {
                setHeader(summaryColInfluencerBrn.map(m => ({ label: m.headerName, key: m.field })))
            } else {
                setHeader(summaryColInfluencer.map(m => ({ label: m.headerName, key: m.field })))
            }
        } else if (groupBy === "detail") {
            if (userType === "brand") {
                setHeader(detailColBrand.map(m => ({ label: m.headerName, key: m.field })))
            } else {
                setHeader(detailColInfluencer.map(m => ({ label: m.headerName, key: m.field })))
            }
        } else {
            if (userType === "brand") {
                setHeader(summaryColBrand.map(m => ({ label: m.headerName, key: m.field })))
            } else {
                setHeader(summaryColInfluencer.map(m => ({ label: m.headerName, key: m.field })))
            }
        }
    }, [groupBy])

    const getData = (count) => {
        setLoad(true);
        getSales(currentUser, userType, groupBy, 1, value, fields, count).then((res) => {
            setData(res.data.map((m, i) => ({
                ...m,
                id: i + 1,
                campaign_name: m.campaign_name ? m.campaign_name : "BioShop",
                order_totalprice: numeral(m?.order_totalprice).format("$0,0.00", Math.floor),
                kb_commission: numeral(m?.kb_commission).format("$0,0.00", Math.floor),
                influencer_commission: numeral(m?.influencer_commission).format("$0,0.00", Math.floor),
                customer_commission: numeral(m?.customer_commission).format("$0,0.00", Math.floor),
                discount: numeral(m?.discount).format("$0,0.00", Math.floor),
                total_sale: numeral(m?.total_sale).format("$0,0.00", Math.floor),
            })))
            setLoad(false)
        })
    }

    function renderColumn() {
        // console.log("check", test, userType)
        switch (test) {
            case "date":
                return userType == "brand" ? dateColBrand : dateColInfluencer
            case "campaign":
                return userType == "brand" ? campaignColBrand : campaignColInfluencer
            case "brand":
                return userType === "brand" ? summaryColBrand : summaryColBrandInf
            case "influencer":
                return userType === "brand" ? summaryColInfluencerBrn : summaryColInfluencer
            case "detail":
                return userType == "brand" ? detailColBrand : detailColInfluencer
            case "summary":
                return userType === "brand" ? summaryColBrand : summaryColInfluencer
            default:
                return userType === "brand" ? summaryColBrand : summaryColInfluencer
        }

    }
    const handleSearch = (type) => {
        if (type === "refresh") {
            setGroupBy('summary')
            setValue([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
        }
        setSearch(true)
    }
    const handleSelect = (e) => {
        if (e.target.value === "detail") {
            setFields({ ...fields, sort: "created_date" })
        }
        setGroupBy(e.target.value)
    }

    function renderField() {
        switch (groupBy) {
            case "date": return userType === "brand" ?
                dateColBrand.map(m => {
                    if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
                })
                : dateColInfluencer.map(m => {
                    if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
                })
            case "campaign": return userType === "brand" ?
                campaignColBrand.map(m => {
                    if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
                })
                : campaignColInfluencer.map(m => {
                    if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
                })
            case "detail": return userType === "brand" ?
                detailColBrand.map(m => {
                    if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
                }) : detailColInfluencer.map(m => {
                    if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
                })
            case "summary": return userType === "brand" ?
                summaryColBrand.map(m => {
                    if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
                }) : summaryColInfluencer.map(m => {
                    if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
                })
            case "brand": return userType === "brand" ?
                summaryColBrand.map(m => {
                    if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
                }) : summaryColBrandInf.map(m => {
                    if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
                })
            case "influencer": return userType === "brand" ?
                summaryColInfluencerBrn.map(m => {
                    if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
                }) : summaryColInfluencer.map(m => {
                    if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
                })
            default: return userType === "brand" ?
                summaryColBrand.map(m => {
                    if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
                }) : summaryColInfluencer.map(m => {
                    if (m.field !== "id") return <MenuItem value={m.field} >{m.headerName}</MenuItem>
                })
        }
    }


    return (
        <div className='saleContainer'>
            <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                {userType === "influencer" ? "Earning" : "Sales"}
            </Typography>
            <div className='body'>
                <div className='buttons-sales'>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn-gen-margin btn-width ml-0 mb-2"
                        color="primary"
                        disabled={value[0] === moment().format('YYYY-MM-DD')}
                        disableElevation
                        onClick={() => {
                            let startDate = moment().format('YYYY-MM-DD')
                            setValue([startDate, startDate])
                            setDate(true);
                        }}
                    >
                        Today
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn-gen-margin btn-width ml-2 mb-2"
                        color="primary"
                        disableElevation
                        disabled={value[0] === moment().startOf('month').format('YYYY-MM-DD')}
                        onClick={() => {
                            let startDate = moment().startOf('month').format("YYYY-MM-DD")
                            setValue([startDate, moment().format('YYYY-MM-DD')])
                            setDate(true);
                        }}
                    >
                        MTD
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn-gen-margin btn-width ml-2 mb-2"
                        color="primary"
                        disabled={value[0] === moment().startOf('year').format('YYYY-MM-DD')}
                        disableElevation
                        onClick={() => {
                            let startDate = moment().startOf('year').format("YYYY-MM-DD")
                            setValue([startDate, moment().format('YYYY-MM-DD')])
                            setDate(true);
                        }}
                    >
                        YTD
                    </Button>
                </div>
                <Row className='align-items-center'>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Group By</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                defaultValue={groupBy}
                                placeholder="group by"
                                labelId="groupBy"
                                id="groupBy"
                                value={groupBy}
                                label="Group By"
                                onChange={(e) => handleSelect(e)}
                            >
                                <MenuItem value={"summary"}>Summary</MenuItem>
                                <MenuItem value={"detail"}>Detail</MenuItem>
                                <MenuItem value={"date"}>Date</MenuItem>
                                <MenuItem value={"campaign"}>Campaign</MenuItem>
                                {
                                    userType === "influencer" ?
                                        <MenuItem value={"brand"}>Brand</MenuItem> :
                                        <MenuItem value={"influencer"}>Influencer</MenuItem>
                                }
                                {userType === "customer" && <MenuItem value={"brand"}>Brand</MenuItem>}
                                {userType === "customer" && <MenuItem value={"influencer"}>Influencer</MenuItem>}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className='dateBox'>
                                <DatePicker
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format('YYYY-MM-DD'), value[1]]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format('YYYY-MM-DD')]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                </Row>
                <Row>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Field</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Field"
                                labelId="field"
                                id="field"
                                value={fields.sort}
                                label="Field"
                                onChange={(e) => setFields({ ...fields, sort: e.target.value })}
                            >
                                {renderField()}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Order By</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="order by"
                                labelId="orderBy"
                                id="orderBy"
                                value={fields.orderBy}
                                label="Order By"
                                onChange={(e) => setFields({ ...fields, orderBy: e.target.value })}
                            >
                                <MenuItem value={"1"}>Ascending</MenuItem>
                                <MenuItem value={"-1"}>Descending</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={5} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => { handleSearch() }}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('refresh') }}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
                <Divider className="separater-sale" />

                <div style={{ height: 460, width: '100%' }}>
                    {
                        loading ? <Skeleton /> :
                            <>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                                    <CSVLink
                                        headers={header}
                                        data={data}
                                        asyncOnClick={true}
                                        className="btn-width h-100 custom-btn"
                                        onClick={() => { }}
                                        filename={fileName}
                                    >
                                        <CSVButton load={load} />
                                    </CSVLink>
                                </div>
                                <DataGrid
                                    density='compact'
                                    components={{ NoRowsOverlay: NoRows, }}
                                    disableSelectionOnClick
                                    // sx={{ marginTop: 2 }}
                                    className="bg-white"
                                    rows={
                                        Object.keys(sales)?.length > 0 ? sales?.data?.map((single, index) => ({
                                            ...single,
                                            id: index + (page == 1 ? 1 : (page * 10) - 9),
                                        })) : []
                                    }
                                    columns={renderColumn()}
                                    rowCount={Object.keys(sales)?.length > 0 ? sales?.total_records : 0}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    paginationMode="server"
                                    onPageChange={(newPage) => {
                                        setPage(newPage + 1);
                                        getSales(currentUser, userType, groupBy, newPage + 1, value, fields).then(() => {
                                            setLoading(false)
                                            setTest(groupBy)
                                        })
                                    }}
                                />
                            </>
                    }
                </div>
            </div>
        </div >
    )
}

function mapStateToProps({ sales, accountDetail }) {
    return { sales, accountDetail }
}
export default connect(mapStateToProps, salesActions)(Sales)
