import React, { useState, useEffect, useMemo } from 'react'
import { connect } from "react-redux";
import { Card, Col, Row } from "react-bootstrap"
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CardActions, CardHeader, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import AddIcon from '@mui/icons-material/Add';
import AddShipping from "./AddShipping"
import * as shippingAciton from "../../store/actions/shipping"
import EditIcon from '@mui/icons-material/Edit';
import ShippingSkeleton from './ShippingSkeleton';
import numeral from 'numeral';
import DeleteModal from "../../reUsable/SaveModal";
import { toast } from 'react-toastify';

function Shipping({ deleteShipping, getShipping, shipping }) {
    const [loading, setLoading] = useState(true)
    const [visible, setVisible] = useState(false)
    const [modal, setModal] = useState(false)
    const [id, setId] = useState('')
    const [detail, setDetail] = useState(null)

    useEffect(() => {
        getData()
    }, [])

    function getData() {
        setLoading(true)
        getShipping().then(() => {
            setLoading(false)
        })
    }

    function save() {
        deleteShipping({ _id: id }).then((res) => {
            if (res?.success) {
                toast.success(res?.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
            setId('')
            setModal(false)
            getData()
        })

    }
    return (
        <div className="container-fluid mb-3">
            <DeleteModal
                visible={modal}
                title={`Are you sure you want to delete this Shipping Method?`}
                close={() => setModal(false)}
                type="status"
                call={() => save()}
            />
            <AddShipping
                visible={visible}
                clear={() => {
                    setDetail(null)
                    setVisible(false)
                }}
                data={detail}
                get={() => { getData() }}
            />
            <Row className='pt-3'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Shipping Methods
                    </Typography>
                </Col>
                <Col xs className="mb-3 text-right">
                    <Button
                        onClick={() => {
                            setVisible(true)
                        }}
                        className='btn-width'
                        startIcon={<AddIcon />}
                        disableElevation
                        variant="contained">
                        Add Method
                    </Button>
                </Col>
            </Row>
            <Divider className="mb-15" />
            {loading ? <ShippingSkeleton /> :
                <div className='container-fluid ml-0 p-0'>
                    <Row className='pt-3' >
                        {shipping?.map(item => (
                            <Col key={item?._id} md={6} lg={4} className='mb-3' >
                                <Card sx={{ display: 'flex' }} key={item?._id} >
                                    <CardHeader
                                        className='category-header'


                                    />
                                    <Box className='px-3'>
                                        <Row>
                                            <Col xs={12}>
                                                <div className='shipping-info-line'>
                                                    <div className='ship-info-hd'>Name: </div> <div>{item?.name}</div>
                                                </div>

                                            </Col>
                                            <Col xs={12}>
                                                <div className='shipping-info-line'>
                                                    <div className='ship-info-hd'>Amount: </div> <div>{numeral(item?.amount).format("$0,0.00", Math.floor)}</div>
                                                </div>

                                            </Col>
                                            <Col xs={12}>
                                                <div className='shipping-info-line'>
                                                    <div className='ship-info-hd'>Status: </div> <div style={item?.is_active ? { color: "green" } : { color: "red" }} >{item?.is_active ? "Active" : "False"}</div>
                                                </div>

                                            </Col>
                                            <Col xs={12}>
                                                <div className='shipping-info-line'>
                                                    <div className='ship-info-hd'>Default: </div> <div style={item?.is_default ? { color: "green" } : { color: "red" }} >{item?.is_default ? "Active" : "False"}</div>
                                                </div>

                                            </Col>



                                        </Row>
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        <CardActions className='categories-box-buttons d-flex justify-content-end'>
                                            <Button
                                                onClick={() => {
                                                    setDetail(item)
                                                    setVisible(true)
                                                }}
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                disableElevation
                                                startIcon={<EditIcon />}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                // disabled
                                                style={{ marginLeft: 10 }}
                                                onClick={() => {
                                                    setId(item?._id)
                                                    setModal(true)
                                                }}
                                                variant="outlined"
                                                size="small"
                                                color="primary"
                                                disableElevation
                                                startIcon={<DeleteIcon />}
                                            >
                                                Delete
                                            </Button>
                                        </CardActions>
                                    </Box>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            }
        </div>
    )
}

const mapStateToProps = ({ shipping }) => {
    return { shipping };
};

export default connect(mapStateToProps, { ...shippingAciton })(Shipping);