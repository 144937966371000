import React, { useMemo } from 'react'
import NoRows from "../../reUsable/NoRows"
import numeral from 'numeral'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import moment from 'moment'

function DataTable({ nowGet, data }) {
    const [page, setPage] = useState(1)
    const [pageLoad, setPageLoad] = useState(false)
    const videoCol = [
        {
            field: 'id', headerName: 'S.#', width: 40, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        {
            field: "started_date", headerName: "Start Date", width: 120, sortable: false, renderCell: ({ value }) => {
                return moment(value).format('MM-DD-YYYY')
            }
        },
        {
            field: "ended_date", headerName: "End Date", width: 120, sortable: false, renderCell: ({ value }) => {
                return moment(value).format('MM-DD-YYYY')
            }
        },
        {
            field: "status", headerName: "Status", width: 120, sortable: false,
            renderCell: ({ value }) => {
                if (value === "active") {
                    return <p className='mb-0' style={{ color: 'green' }}>Active</p>
                } else if (value === "pending") {
                    return <p className='mb-0' >Pending</p>
                } else if (value === "cancelled") {
                    return <p className='mb-0 ' style={{ color: 'red' }}>Cancelled</p>
                } else if (value === "completed") {
                    return <p className='mb-0 ' >Completed</p>
                } else {
                    return <p className='mb-0 ' >-</p>
                }

            }
        },
        // { field: "type", headerName: "Video Type", width: 120, sortable: false, },
        { field: "department", headerName: "Department", width: 120, sortable: false, },
        { field: "title", headerName: "Title", width: 250, sortable: false, },
        { field: "brand", headerName: "Brand", width: 150, sortable: false, },
        // { field: "limit", headerName: "Total Days", width: 150, sortable: false, },
        { field: "budget", headerName: "Budget", width: 150, sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
        { field: "spent", headerName: "Spent", width: 150, sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
        { field: "balance", headerName: "Balance", sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
        { field: "total_impression_spent", headerName: "Impressions", width: 150, sortable: false, },
        // { field: "cost", headerName: "Cost per Day", width: 150, sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
    ]

    const videos = useMemo(() => {
        if (data?.success) {
            setPageLoad(false)
            return data?.message?.data
        } else {
            return []
        }
    }, [data])
    // console.log("1", data?.message?.total_records)
    return (
        <>
            <DataGrid
                rowHeight={40}
                density="compact"
                disableColumnFilter={true}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                //   sx={{ marginTop: 2 }}
                className="bg-white ml-15"
                components={{ NoRowsOverlay: NoRows, }}
                loading={pageLoad}
                rows={
                    videos?.map((item, index) => ({
                        ...item,
                        id: index + (page == 1 ? 1 : (page * 18) - 17),
                        type: item?.review_id ? "Review" : "Show",
                        title: item?.review_id ? item?.reviews?.title : item?.shows?.title,
                        brand: item?.brandInfo?.name,
                        limit: `${item?.number_of_days} days`,
                        cost: item?.budget / item?.number_of_days,
                        department: item?.general_categories?.name

                    }))
                }
                columns={videoCol}
                rowCount={data?.message?.total_records ? data?.message?.total_records : 0}
                pagination
                pageSize={18}
                rowsPerPageOptions={[18]}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPageLoad(true)
                    setPage(newPage + 1);
                    nowGet(newPage + 1);
                }}

            />
            <div className='sumContainer-height-235  ml-15'>
                <div className='sumContainer'>
                    <div className='sumTitle'>Summary</div>
                    <div className='sumBody'>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Budget</div>
                            <div className='sumBodyvalue'>{numeral(data?.message?.summary?.totalBudget).format("$0,0.00", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Spent</div>
                            <div className='sumBodyvalue'>{numeral(data?.message?.summary?.totalSpent).format("$0,0.00", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Balance</div>
                            <div className='sumBodyvalue'>{numeral(data?.message?.summary?.totalBalance).format("$0,0.00", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Impressions</div>
                            <div className='sumBodyvalue'>{numeral(data?.message?.summary?.totalImpressionsSpent).format("$0,0.00", Math.floor)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default React.memo(DataTable)