import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";

import { LoadingButton } from '@mui/lab'
import { Avatar, Box, Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField, Tooltip, Typography } from '@mui/material'
import { Col, Row } from 'react-bootstrap';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import NoRows from 'reUsable/NoRows';
import { DataGrid } from '@mui/x-data-grid';

const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};
function ProductModal({ visible, close, data }) {


    return (
        <Modal
            open={visible}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className={"deleteModal modal-generic-style modal-spacing-new"} style={{ width: "30%" }}>
                <Row className='heading-name-area-modal '>
                    <Col xl={12} lg={12} className="d-flex align-items-center justify-content-between">
                        <Box className="d-flex align-items-center">
                            <Typography className='text-center w-100 m-0' id="modal-modal-title" variant="h6" component="h2">
                                Products
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                className='custom-close-btn-moda'
                                onClick={close}
                                aria-label="delete" sx={{ color: "grey" }}>
                                <CancelIcon color="error" />
                            </IconButton>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} lg={12} className="">
                        <Divider className='mb-15' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-left" >
                        <div
                            className="control-products-listing-main-wrapper scrollbar-style overflow-y-auto"
                        // style={{ maxHeight: 500 }}
                        >
                            {data?.length > 0 ? (
                                data.map(
                                    (item, i) => {
                                        return (
                                            <div className="control-products-listing-main ">
                                                <div className="control-products-listing">
                                                    <div className="control-product-image">
                                                        <img
                                                            src={item.mediaUrl}
                                                            alt={item.title}
                                                            className=""
                                                        />
                                                    </div>

                                                    <div className="control-product-content">
                                                        <div className="product-left">
                                                            {
                                                                item?.brand_name && <div className='control-product-title' style={{ color: "#052977" }}>{item.brand_name}</div>
                                                            }

                                                            <div
                                                                className="control-product-title"
                                                                title={item.title}
                                                            >
                                                                {item.title}
                                                            </div>
                                                            <div className="control-product-price">
                                                                ${item.price}
                                                            </div>
                                                        </div>
                                                        <div className="product-right">
                                                            <div className="product-quantity">
                                                                {/* Qty: 150 */}{" "}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )
                            ) : (
                                <h5 className="text-center">No Product</h5>
                            )}
                        </div>

                    </Col>
                </Row>

            </Box>
        </Modal >
    )
}
const mapStateToProps = ({ }) => {
    return {};
};
export default connect(mapStateToProps, {})(ProductModal)

