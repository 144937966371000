import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Box, Button, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import * as userActions from "../../store/actions/user.filters"
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/Save';
import SaveModal from "../../reUsable/SaveModal"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
function UserShop({ getUserFilters, setUserFilters }) {
    const [load, setLoad] = useState(true)
    const [modal, setModal] = useState(false)
    const [fields, setFields] = useState({
        trending: '',
        top: '',
        // days_trending: 3,
        // days_top: 30
    })

    useEffect(() => { getData() }, [])

    function submit() {
        setLoad(true)
        setUserFilters(fields).then((res) => {
            if (res.success) {
                toast.success(res?.message)
                setLoad(false)
            } else {
                toast.error(res?.message)
                setLoad(false)
            }
            getData()
        })
    }

    function getData() {
        getUserFilters().then((res) => {
            if (res?.success) {
                setFields({
                    trending: res?.data?.[0]?.usershop_filter?.trending,
                    top: res?.data?.[0]?.usershop_filter?.top,
                    // days_trending: res?.data?.[0]?.usershop_filter?.days_trending,
                    // days_top: res?.data?.[0]?.usershop_filter?.days_top
                })
            } else {
                setFields({
                    trending: '',
                    top: '',
                    // days_trending: 3,
                    // days_top: 10
                })
            }
        }).finally(() => {
            setModal(false)
            setLoad(false)
        })
    }
    return (
        <div className="container-fluid mb-3">
            <SaveModal
                visible={modal}
                title={`Are you sure you want to save these changes?`}
                close={() => setModal(false)}
                type="status"
                call={() => submit()}
            />
            <Row className='pt-3'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        User Shop Filters
                    </Typography>
                </Col>
            </Row>
            <Divider className="mb-15" />
            <Row className="d-flex justify-content-betwee">
                <TendingView
                    fields={fields}
                    setFields={setFields}
                    setModal={setModal}
                    load={load}
                    limit={[1, 3]}
                />
                <TopView
                    fields={fields}
                    setFields={setFields}
                    setModal={setModal}
                    load={load}
                    limit={[7, 365]}
                />
            </Row>

        </div>
    )
}

const mapStateToProps = ({ }) => ({})
export default connect(mapStateToProps, userActions)(UserShop)


function TendingView({ fields, setFields, setModal, load, limit }) {
    return (
        <Col xl={5} lg={6} md={12}>
            <div className='infoSection new mb-3' style={{ background: "#FFF" }} >
                <Box className="addons-cart-title d-flex align-items-center">
                    <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                        Trending
                    </Typography>
                </Box>
                <Divider className='mb-20' />
                <Row>
                    {/* <Col xs={12}>
                        <TextField
                            size="small"
                            value={fields.days_trending}
                            onChange={e => {
                                let val = e.target.value;
                                if (val >= limit[0] && val <= limit[1]) {
                                    setFields({ ...fields, days_trending: val })
                                }
                            }}
                            id="outlined-basic"
                            type='number'
                            label="Days"
                            InputProps={{
                                inputProps: {
                                    min: limit[0],
                                    max: limit[1]
                                },
                                endAdornment: <InputAdornment position="end">Days</InputAdornment>,
                            }}
                            variant="outlined"
                            className="field-style mb-15"
                        />
                    </Col> */}
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, trending: "sales" })}
                                    focused={fields.trending === "sales"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Sales "}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.trending === "sales" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, trending: "likes" })}
                                    focused={fields.trending === "likes"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Likes "}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.trending === "likes" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, trending: "shares" })}
                                    focused={fields.trending === "shares"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Shares"}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.trending === "shares" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, trending: "followers" })}
                                    focused={fields.trending === "followers"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Followers"}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.trending === "followers" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, trending: "views" })}
                                    focused={fields.trending === "views"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15 "
                                    value={"Views"}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.trending === "views" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className='mb-2'>
                        <Button
                            className="btn-width btn-gen-margin  mr-0 h-100 ml-0"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={() => setModal(true)}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </div>
        </Col>
    )
}
function TopView({ fields, setFields, setModal, load, limit }) {
    return (
        <Col xl={5} lg={6} md={12}>
            <div className='infoSection new mb-3' style={{ background: "#FFF" }} >
                <Box className="addons-cart-title d-flex align-items-center">
                    <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                        Top
                    </Typography>
                </Box>
                <Divider className='mb-20' />
                <Row>
                    {/* <Col xs={12}>
                        <TextField
                            size="small"
                            value={fields.days_top}
                            onChange={e => {
                                let val = e.target.value;
                                if (val >= limit[0] && val <= limit[1]) {
                                    setFields({ ...fields, days_top: val })
                                }
                            }}
                            id="outlined-basic"
                            type='number'
                            label="Days"
                            InputProps={{
                                inputProps: {
                                    min: limit[0],
                                    max: limit[1]
                                },
                                endAdornment: <InputAdornment position="end">Days</InputAdornment>,
                            }}
                            variant="outlined"
                            className="field-style mb-15"
                        />
                    </Col> */}
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, top: "sales" })}
                                    focused={fields.top === "sales"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Sales "}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.top === "sales" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, top: "likes" })}
                                    focused={fields.top === "likes"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Likes "}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.top === "likes" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, top: "shares" })}
                                    focused={fields.top === "shares"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Shares"}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.top === "shares" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, top: "followers" })}
                                    focused={fields.top === "followers"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Followers"}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.top === "followers" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, top: "views" })}
                                    focused={fields.top === "views"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15 "
                                    value={"Views"}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.top === "views" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className='mb-2'>
                        <Button
                            className="btn-width btn-gen-margin  mr-0 h-100 ml-0"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={() => setModal(true)}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </div>
        </Col>
    )
}