import { Box, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import numeral from 'numeral';
import NoRows from 'reUsable/NoRows';
import React, { useMemo } from 'react'
import { useState } from 'react';

export default function Table({ data, load, page, nowGet, total }) {
    const columns = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        {
            field: "created_at",
            headerName: "Date",
            width: 120,
            sortable: false,
            renderCell: ({ value }) => {
                return moment(value).format('MM-DD-YYYY')
            }
        },
        {
            field: "pixel_id",
            headerClassName: "super-app-theme--header",
            headerName: "PID",
            width: 100,
            flex: 0.5,
            filterable: false, sortable: false,
        },
        {
            field: "name",
            headerClassName: "super-app-theme--header",
            headerName: "Name",
            width: 160,
            flex: 0.5,
            filterable: false,
            sortable: false,
        },
        {
            field: "email",
            headerClassName: "super-app-theme--header",
            headerName: "Email",
            width: 160,
            flex: 0.5,
            filterable: false,
            sortable: false,
        },
        {
            field: "phone",
            headerClassName: "super-app-theme--header",
            headerName: "Phone",
            width: 160,
            flex: 0.5,
            filterable: false,
            sortable: false,
        },
        {
            field: "is_active",
            headerClassName: "super-app-theme--header",
            headerName: "User Status",
            width: 160,
            flex: 0.5,
            filterable: false,
            sortable: false,
            renderCell: ({ value }) => {
                return <span style={value ? { color: 'green' } : { color: "red" }}>{value ? "Active" : "Deactive"}</span>
            },
        },
        {
            field: "status",
            headerClassName: "super-app-theme--header",
            headerName: "Status",
            width: 160,
            flex: 0.5,
            filterable: false,
            sortable: false,
            renderCell: ({ value }) => {
                if (value === "approved") {
                    return <span style={{ color: "green" }}>Approved</span>
                } else if (value === "rejected") {
                    return <span style={{ color: "red" }}>Rejected</span>
                } else {
                    return <span>Pending</span>
                }
            },
        },
        {
            field: "disassociate_status",
            headerClassName: "super-app-theme--header",
            headerName: "Disassociation",
            width: 160,
            flex: 0.5,
            filterable: false,
            sortable: false,
            
        },

    ];

    return (
        <div style={{ height: "445px" }}>
            <DataGrid
                rowHeight={40}
                density="compact"
                disableColumnFilter={true}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                className="bg-white "
                components={{ NoRowsOverlay: NoRows, }}
                loading={load}
                rows={
                    data?.map((item, index) => ({
                        ...item,
                        id: index + (page == 1 ? 1 : page * 12 - 11),
                        status: item?.agency?.status,
                        pixel_id: item?.userData?.pixel_id,
                        name: item?.userData?.name,
                        phone: item?.userData?.phone,
                        email: item?.userData?.email,
                    }))
                }
                columns={columns}
                pagination
                pageSize={12}
                rowsPerPageOptions={[12]}
                rowCount={total}
                paginationMode="server"
                onPageChange={(newPage) => nowGet(newPage + 1)}
            />
        </div>
    )
}
