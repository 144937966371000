import React, { useState, useEffect, useCallback, useMemo } from 'react'
import DataTable from "./Table"
import { Col, Row } from 'react-bootstrap'
import { Autocomplete, Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'


import moment from 'moment';
import { connect } from 'react-redux';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";
import * as categoryActions from "../../store/actions/Categories/categories";
import * as accountActions from "../../store/actions/account"
import * as salesActions from "../../store/actions/sales"
import SkeletonLoader from "reUsable/skeleton";
import DateBtn from "reUsable/DateBtn";

import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { CSVLink } from 'react-csv';
import CSVButton from 'reUsable/CSVButton';
import DownloadIcon from '@mui/icons-material/Download';
import numeral from 'numeral';

const header = [
    { key: 'created_date', label: 'Order Date' },
    { key: 'order_id', label: 'Order No' },
    { key: 'status', label: 'Order Status' },
    { key: 'videoType', label: 'Source' },
    { key: 'title', label: 'Video Title' },
    { key: 'brand', label: 'Brand' },
    { key: 'general_category', label: 'Department' },
    { key: 'category', label: 'Category' },
    { key: 'sub_category', label: 'Sub Category' },
    { key: 'channel', label: 'Channel' },
    { key: 'content_by', label: 'Content Source' },
    { key: 'creator', label: 'Creator' },
    { key: 'purchased_by', label: 'Purchaser' },
    { key: 'affiliate', label: 'Referrer' },
    { key: 'gross_amount', label: 'Gross Sales' },
    { key: 'order_totalprice', label: 'Net Sales' },
    { key: 'discount', label: 'Discount' },
    { key: 'shipping', label: 'Shipping' },
    { key: 'tax', label: 'Tax' },
    { key: 'returned_amount', label: 'Return Amount' },
    { key: 'balance_amount', label: 'Balance Amount' },
    { key: 'kb_fee', label: 'ORME Fee' },
    { key: 'kb_fee_percent', label: 'ORME Fee %' },
    { key: 'cashback_amount', label: 'Cashback' },
    { key: 'cashback_amount_percent', label: 'Cashback %' },
    { key: 'customer_commission', label: 'Referral Fee' },
    { key: 'customer_commission_percent', label: 'Referral Fee %' },
    { key: 'creator_commission', label: 'Creator Fee' },
    { key: 'creator_commission_percent', label: 'Creator Fee %' },
    { key: 'net_brand_amount', label: 'Net Revenue' },
    { key: 'excess_fee_charged', label: 'Excess Fee Charged' },
]

function TotalSales({ filterAllCategories, getAccountList, accountList, totalSales, getTotalSales, getBrand, getTotalSalesCSV }) {
    const [value, setValue] = useState([
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
    ]);
    const [fields, setFields] = useState({
        generalCategory: "all",
        category: "all",
        subCategory: "all",
        source: "all",
        group_by: "order",
        channel: "all",
        content: "all",
        creator: '',
        referrer: '',
        order_id: '',
        brand_id: '',
        status: 'success'
    });
    const [gen, setGen] = useState([]);
    const [cat, setCat] = useState([]);
    const [sub, setSub] = useState([]);

    const [name, setName] = useState("")
    const [name2, setName2] = useState("")
    const [name3, setName3] = useState("")

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [load, setLoad] = useState(true);
    const [flag, setFlag] = useState('order')
    const [brandList, setBrandList] = useState([])

    const [date, setDate] = useState(false);

    const [csvData, setCSVData] = useState([])
    const [csvLoad, setCSVLoad] = useState(true)

    const [limit, setLimit] = useState(0)


    useEffect(() => {
        getData()
        getAccountList('', false, 'influencer', false)
        getBrand('', false).then(res => {
            if (res.success) {
                setBrandList(res?.data?.message)
            } else {
                setBrandList([])
            }
        })
        filterAllCategories({ source: "genCategory" }).then((res) => {
            if (res.success) {
                setGen(res?.message);
            } else { setGen([]); }
        }
        );
    }, []);


    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    const handleChange = (val, type) => {
        if (type === "brand") {
            getBrand(val, false).then(res => {
                if (res.success) {
                    setBrandList(res?.data?.message)
                } else {
                    setBrandList([])
                }
            })
        } else {
            getAccountList(val, false, type, false,)
        }
    };
    const optimizedFn = useCallback(debounce(handleChange), []);
    useEffect(() => {
        if (date) {
            getData()
        }
    }, [date])
    function getData(type) {
        setLoading(true)
        if (type === "reset") {
            setFields({
                generalCategory: "all",
                category: "all",
                subCategory: "all",
                source: "all",
                group_by: "order",
                channel: "all",
                content: "all",
                creator: '',
                referrer: '',
                order_id: '',
                brand_id: '',
                status: 'success'
            })
            setGen([])
            setCat([])
            setSub([])
            setName('')
            setName2('')
            setName3('')
            setPage(1)
            setFlag('order')
            setValue([moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),])
            getTotalSales({
                generalCategory: "all",
                category: "all",
                subCategory: "all",
                source: "all",
                group_by: "order",
                channel: "all",
                content: "all",
                creator: '',
                referrer: '',
                order_id: '',
                brand_id: '',
                status: 'success',

            }, [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),], 1,).then(() => {
                setLoading(false)
                setLoad(false)
            })
        } else {
            setPage(1)
            getTotalSales(fields, value, 1).then((res) => {
                if (res?.success) {
                    setLimit(res?.message?.total_records)
                }
                setFlag(fields.group_by)
                setDate(false)
                setLoading(false)
                setLoad(false)
            })
        }
    }

    const salesData = useMemo(() => {
        if (totalSales) {
            if (totalSales?.success) {
                return totalSales?.message
            } else {
                return null
            }
        }
        return null
    }, [totalSales])

    useEffect(() => {
        if (limit) {
            downloadCSV(limit)
        } else {
            setCSVData([])
            setCSVLoad(false)
        }
        // if (totalSales?.success) {
        //     if (totalSales?.message?.total_records > 0) {
        //         downloadCSV(totalSales?.message?.total_records)
        //     } else {
        //         setCSVData([])
        //         setCSVLoad(false)
        //     }
        // } else {
        //     setCSVData([])
        //     setCSVLoad(false)
        // }
    }, [limit])
    function downloadCSV(limit) {
        setCSVLoad(true)
        getTotalSalesCSV(fields, value, 1, limit).then((res) => {
            if (res?.success) {
                setCSVData(res?.message?.data?.map(item => {
                    let videoType = ''
                    if (item.event === "reviewshare" || item.event === "reviewshop") {
                        videoType = "Review"
                    } else if (item.event === "productsale" || item.event === "productshare") {
                        videoType = "Item"
                    } else {
                        videoType = "Show"
                    }
                    return ({
                        ...item,
                        status: item?.status !== "cancelled" ? "Success" : "Voided",
                        videoType,
                        title: (item?.event === "eventshop" || item?.event === "eventshare") ? item?.event_payload?.title === "" ? "-" : item?.event_payload?.title : item?.review_payload?.title === "" ? "-" : item?.review_payload?.title,
                        brand: item?.brand?.name,
                        general_category: item?.gen_category?.name ? item?.gen_category?.name : "All",
                        category: item?.category?.category_name ? item?.category?.category_name : "All",
                        sub_category: item?.sub_category?.sub_category_name ? item?.sub_category?.sub_category_name : "All",
                        content_by: item?.content_by ? item?.content_by : "All",
                        purchased_by: item?.customer?.name,
                        affiliate: item?.affiliate?.name,
                        creator: (item?.content_by === "brand" && item?.channel === "direct" && item?.content_creator == undefined) ? item?.brand?.name : item?.content_creator?.name,
                        source: item.event,
                        tax: numeral(item?.orderDetails?.[0]?.order_detail?.tax_lines?.reduce((acc, item) => acc += Number(item?.price), 0)).format('0.00'),
                        shipping: numeral(item?.orderDetails?.[0]?.order_detail?.shipping_lines?.reduce((acc, item) => acc += Number(item?.price), 0)).format('0.00'),
                        discount: numeral(item?.orderDetails?.[0]?.order_detail?.discount_applications?.reduce((acc, item) => acc += Number(item?.value), 0)).format('0.00'),
                    })
                }))
                setCSVLoad(false)
            } else {
                setCSVData([])
                setCSVLoad(false)
            }

        })
    }

    return (
        <div className='container-fluid mt-3 mb-3 pl-0'>
            <Row className='pl-15'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Total Sales
                    </Typography>
                </Col>
                <Col>
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                        {
                            csvLoad ?
                                <Button
                                    disabled
                                    className='btn-width'
                                    startIcon={<DownloadIcon />}
                                    disableElevation
                                    variant="contained">
                                    Loading...
                                </Button>
                                :
                                <CSVLink
                                    headers={header}
                                    data={csvData}
                                    asyncOnClick={true}
                                    className="btn-width h-100 custom-btn"
                                    onClick={() => { }}
                                    filename={'total_sales'}
                                >
                                    <CSVButton load={csvLoad} />
                                </CSVLink>
                        }

                    </div>
                </Col>
            </Row>
            <DateBtn value={value} setValue={(data) => {
                setPage(1)
                setValue(data)
            }} setDate={setDate} />
            <Row className="account-main-row pl-15">
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="dateBox">
                            <DatePicker
                                minDate={new Date(2023, 0, 1)}
                                slotProps={{
                                    textField: {
                                        helperText: "YYYY / MM / DD",
                                    },
                                }}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="Start Date"
                                value={parseISO(value[0])}
                                onChange={(newValue) => {
                                    setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style"
                                        size="small"
                                        style={{ backgroundColor: "white", width: "50%" }}
                                        {...params}
                                    />
                                )}
                            />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <DatePicker
                                minDate={new Date(2023, 0, 1)}
                                slotProps={{
                                    textField: {
                                        helperText: "YYYY / MM / DD",
                                    },
                                }}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="End Date"
                                value={parseISO(value[1])}
                                onChange={(newValue) => {
                                    setValue([value[0], moment(newValue).format("YYYY-MM-DD")]);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style"
                                        size="small"
                                        style={{ backgroundColor: "white", width: "50%" }}
                                        {...params}
                                    />
                                )}
                            />
                        </div>
                    </LocalizationProvider>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <Autocomplete
                        variant="outlined"
                        className="field-style"
                        size="small"
                        id="disable-clearable"
                        disableClearable
                        options={brandList?.map(opt => ({ ...opt, label: `${opt.pixel_id} - ${opt.name}` }))}
                        onChange={(e, data) => {
                            setFields({ ...fields, brand_id: data?._id })
                            setName3(data.label)
                        }}
                        value={name3}
                        renderInput={(params) => <TextField {...params} label="Brand" onChange={(e) => {
                            setName3(e.target.value)
                            optimizedFn(e.target.value, 'brand')
                        }} />}
                    />
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <TextField
                        size="small"
                        value={fields.order_id}
                        onChange={e => setFields({ ...fields, order_id: e.target.value })}
                        id="outlined-basic"
                        label="Order ID"
                        variant="outlined"
                        className="field-style"
                    />
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel id="demo-simple-select-label1">
                            Source
                        </InputLabel>
                        <Select
                            sx={{ color: "black" }}
                            labelId="Source"
                            id="Source"
                            value={fields.source}
                            label="Source"
                            onChange={(e) =>
                                setFields({ ...fields, source: e.target.value })
                            }
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="review_sales">Review</MenuItem>
                            <MenuItem value="event_sales">Show</MenuItem>
                            <MenuItem value="product_sales">Item</MenuItem>

                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel id="demo-simple-select-label1">Department</InputLabel>
                        <Select
                            sx={{ color: "black" }}
                            placeholder="Department"
                            labelId="Department"
                            id="Department"
                            value={fields.generalCategory}
                            label="Department"
                            onChange={(e) => {
                                setFields({
                                    ...fields,
                                    generalCategory: e.target.value,
                                    category: "all",
                                    subCategory: "all",
                                });
                                setCat([]);
                                setSub([]);
                                if (e.target.value !== "all") {
                                    filterAllCategories({
                                        source: "category",
                                        gen_category_id: e.target.value,
                                    }).then((res) => {
                                        if (res.success) {
                                            setCat(res?.message);
                                        } else {
                                            setCat([]);
                                            setSub([]);
                                        }
                                    });
                                }
                            }}
                        >
                            <MenuItem value="all">All</MenuItem>
                            {gen?.map((item) => (
                                <MenuItem value={item._id}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel id="demo-simple-select-label1">Category</InputLabel>
                        <Select
                            sx={{ color: "black" }}
                            placeholder="Category"
                            labelId="category"
                            id="category"
                            value={fields.category}
                            label="Category"
                            onChange={(e) => {
                                setFields({
                                    ...fields,
                                    category: e.target.value,
                                    subCategory: "all",
                                });
                                setSub([]);
                                if (e.target.value !== "all") {
                                    filterAllCategories({
                                        source: "subCategory",
                                        gen_category_id: fields.generalCategory,
                                        category_id: e.target.value,
                                    }).then((res) => {
                                        if (res.success) {
                                            setSub(res?.message);
                                        } else {
                                            setSub([]);
                                        }
                                    });
                                }
                            }}
                        >
                            <MenuItem value="all">All</MenuItem>
                            {cat?.map((item) => (
                                <MenuItem value={item._id}>{item.category_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel id="demo-simple-select-label1">
                            Sub Category
                        </InputLabel>
                        <Select
                            sx={{ color: "black" }}
                            placeholder="Sub Category"
                            labelId="subCategory"
                            id="subCategory"
                            value={fields.subCategory}
                            label="Sub Category"
                            onChange={(e) =>
                                setFields({ ...fields, subCategory: e.target.value })
                            }
                        >
                            <MenuItem value="all">All</MenuItem>
                            {sub?.map((item) => (
                                <MenuItem value={item._id}>{item.sub_category_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel id="demo-simple-select-label1">
                            Channel
                        </InputLabel>
                        <Select
                            sx={{ color: "black" }}
                            labelId="Channel"
                            id="Channel"
                            value={fields.channel}
                            label="Channel"
                            onChange={(e) =>
                                setFields({ ...fields, channel: e.target.value })
                            }
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="direct">Direct</MenuItem>
                            <MenuItem value="referred">Referred</MenuItem>

                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel id="demo-simple-select-label1">
                            Content Source
                        </InputLabel>
                        <Select
                            sx={{ color: "black" }}
                            labelId="Content"
                            id="Content"
                            value={fields.content}
                            label="Content Source"
                            onChange={(e) =>
                                setFields({ ...fields, content: e.target.value })
                            }
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="brand">Brand</MenuItem>
                            <MenuItem value="creator">Creator</MenuItem>
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <Autocomplete
                        variant="outlined"
                        className="field-style"
                        size="small"
                        id="disable-clearable"
                        disableClearable
                        options={accountList?.message ? accountList?.message.map(opt => ({ ...opt, label: `${opt.pixel_id} - ${opt.name}` })) : []}
                        onChange={(e, data) => {
                            setFields({ ...fields, creator: data._id })
                            setName(data.label)

                        }}
                        value={name}
                        renderInput={(params) => <TextField {...params} label="Purchaser" onChange={(e) => {
                            setName(e.target.value)
                            optimizedFn(e.target.value, 'influencer')
                        }} />}
                    />
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <Autocomplete
                        variant="outlined"
                        className="field-style"
                        size="small"
                        id="disable-clearable"
                        disableClearable
                        options={accountList?.message ? accountList?.message.map(opt => ({ ...opt, label: `${opt.pixel_id} - ${opt.name}` })) : []}
                        onChange={(e, data) => {
                            setFields({ ...fields, referrer: data._id })
                            setName2(data.label)

                        }}
                        value={name2}
                        renderInput={(params) => <TextField {...params} label="Referrer" onChange={(e) => {
                            setName2(e.target.value)
                            optimizedFn(e.target.value, 'influencer')
                        }} />}
                    />
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel id="demo-simple-select-label1">
                            Order Status
                        </InputLabel>
                        <Select
                            sx={{ color: "black" }}
                            labelId="GroupBy"
                            id="GroupBy"
                            value={fields.status}
                            label="Order Status"
                            onChange={(e) =>
                                setFields({ ...fields, status: e.target.value })
                            }
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="success">Success</MenuItem>
                            <MenuItem value="voided">Voided</MenuItem>

                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel id="demo-simple-select-label1">
                            Group By
                        </InputLabel>
                        <Select
                            sx={{ color: "black" }}
                            labelId="GroupBy"
                            id="GroupBy"
                            value={fields.group_by}
                            label="Group By"
                            onChange={(e) =>
                                setFields({ ...fields, group_by: e.target.value })
                            }
                        >
                            <MenuItem value="order">Order</MenuItem>
                            <MenuItem value="channel">Channel</MenuItem>
                            <MenuItem value="content">Content Source</MenuItem>
                            <MenuItem value="date">Date</MenuItem>
                            <MenuItem value="department">Department</MenuItem>
                            <MenuItem value="category">Category</MenuItem>
                            <MenuItem value="subCategory">Sub Category</MenuItem>
                            <MenuItem value="purchaser">Purchaser</MenuItem>
                            <MenuItem value="referred">Referrer</MenuItem>
                        </Select>
                    </FormControl>
                </Col>

                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <Button
                        variant="contained"
                        size="medium"
                        className="btn-gen-margin btn-width laptop ml-0"
                        color="primary"
                        startIcon={<SearchIcon />}
                        disableElevation
                        onClick={getData}
                    >
                        Search
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        startIcon={<RefreshIcon />}
                        className="btn-gen-margin btn-width laptop"
                        color="success"
                        disableElevation
                        onClick={() => {
                            getData('reset')
                        }}
                    >
                        Refresh
                    </Button>
                </Col>
            </Row >
            <Divider className="separater-sale" />
            {
                loading ?
                    <div className="pl-15" style={{ marginTop: 16 }}>
                        <SkeletonLoader />
                    </div>
                    :
                    <>
                        {/* <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                            <CSVLink
                                // headers={header}
                                // data={data}
                                asyncOnClick={true}
                                className="btn-width h-100 custom-btn"
                                onClick={() => { }}
                                filename={fileName}
                            >
                                <CSVButton load={load} />
                            </CSVLink>
                        </div> */}
                        <DataTable
                            page={page}
                            type={flag}
                            data={salesData}
                            load={load}
                            nowGet={(page) => {
                                setLoad(true)
                                getTotalSales(fields, value, page).then(() => {
                                    setPage(page)
                                    setLoad(false)
                                })
                            }}
                        />
                    </>

            }
        </div >
    )
}
const mapStateToProps = ({ accountList, totalSales }) => ({ accountList, totalSales })
export default connect(mapStateToProps, { ...categoryActions, ...accountActions, ...salesActions })(TotalSales)