import konnect from "./axios"
import { GET_ACCESS } from "./type"


export const getAccess = () => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/access/getAll')
        dispatch({
            type: GET_ACCESS,
            payload: res.data.data
        })
    } catch (err) {
        console.log(err.response)
    }
}

export const addAccess = (data) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/access/create', data)
    return res
}

export const deleteAccess = (_id) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/access/delete', { _id })
    return res
}

export const updateAccess = ({ is_active, permission, _id }) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/access/update', { is_active, permission, _id })
    return res
}
export const createBrand = (data) => async (dispatch) => {
    let newData = {
        account_type: 'brand',
        name: data.company_name,
        website_address: data.website,
        email: data.email,
        country: data.country,
        state: data.state,
        city: data.city,
        password: data.password,
        device: 'web',
        coming_from_app: false,
        phone: data.phone,
        address: data.address,
        address_2: data.address_2,
        zip: data.zip,
    }
    if (data.is_active) {
        newData.is_active = true
    } else {
        newData.is_active = false
    }
    return new Promise((resolve, reject) => {
        konnect.post('/v1/auth/brand/signup', newData)
            .then(res => { resolve(res.data) })
            .catch(err => { reject(err.response.data) })
    })
}
export const updateBrand = (data, user_id) => async (dispatch) => {
    let newData = {
        account_type: 'brand',
        name: data.company_name,
        merchant_name: data.merchant_name,
        website_address: data.website,
        email: data.email,
        country: data.country,
        state: data.state,
        city: data.city,
        password: data.password,
        device: 'web',
        coming_from_app: false,
        brand_phone: data.brand_phone,
        address: data.address,
        address_2: data.address_2,
        zip: data.zip,
        user_id
    }
    if (data.is_active) {
        newData.is_active = true
    } else {
        newData.is_active = false
    }
    return new Promise((resolve, reject) => {
        konnect.post('/v1/admin/brand/updateBrand', newData)
            .then(res => { resolve(res.data) })
            .catch(err => { reject(err.response.data) })
    })
}

export const updateUser = (data, user_id, email) => async (dispatch) => {
    let newData = {
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,
        user_id
    }
    if (email !== data.email) {
        newData.email = data.email
    }
    if (data?.instagram) {
        newData.form_name = data.instagram
        newData.no_of_followers = data.followers
    }
    if (data.tiktok_link) newData.tiktok_link = data.tiktok_link
    if (data.linkedin_link) newData.linkedin_link = data.linkedin_link
    if (data.instagram_link) newData.instagram_link = data.instagram_link
    if (data.is_active) {
        newData.is_active = true
    } else {
        newData.is_active = false
    }
    return new Promise((resolve, reject) => {
        konnect.post('/v1/admin/crm/updateUserInfo', newData)
            .then(res => { resolve(res.data) })
            .catch(err => { reject(err.response.data) })
    })
}

export const createHouse = (data, file) => async (dispatch) => {
    const formBody = new FormData();
    formBody.append('first_name', data.first_name)
    formBody.append('last_name', data.last_name)
    formBody.append('phone', data.phone)
    formBody.append('email', data.email)
    if (file) {
        formBody.append('file', file)
    }
    return new Promise((resolve, reject) => {
        konnect.post('/v1/admin/brand/createHouse', formBody)
            .then(res => { resolve(res.data) })
            .catch(err => { reject(err.response.data) })
    })
}