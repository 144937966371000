import { Box, Button, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save';
import * as feedAction from "../../store/actions/feedAction"
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

export const FollowingFeed = ({ type, getRange, followingFeedSetting, updateFollowingFeedSetting }) => {
    const [load, setLoad] = useState(false)
    const [fields, setFields] = useState({
        posts_to_read: 0, range: 0, is_category: false, sort_by: "created_date", boosted_percent: 0
    })
    const [value, setValue] = useState(0)
    const [flag, setFlag] = useState(false)
    const [error, setError] = useState(false);
    const [msg, setMSG] = useState('')

    useEffect(() => {
        getData()

    }, [])
    function getData() {
        setLoad(true)
        followingFeedSetting('review_following_feed_creation').then(res => {
            if (res.success) {
                setFields({
                    range: res?.message?.date_range ? res?.message?.date_range : 0,
                    posts_to_read: res?.message?.posts_to_read ? res?.message?.posts_to_read : 0,
                    boosted_percent: res?.message?.boosted_percent ? res?.message?.boosted_percent : 0,
                    is_category: res?.message?.is_category ? res?.message?.is_category : false,
                    sort_by: res?.message?.sort_by ? res?.message?.sort_by : 'created_date',
                    ...res?.message
                })
                setFlag(true)
                setLoad(false)
            }
        })
    }

    function submit() {
        if(fields.boosted_percent >100 ){
            setMSG('Boosted % must not be greater than 100')
            setError(true)
            return
        }
        setMSG('')
        setError(false)
        setLoad(true)
        updateFollowingFeedSetting(fields, 'review_following_feed_creation').then((res) => {
            if (res?.success) {
                toast.success(`${res?.message}`)
                setLoad(false)
            } else {
                toast.error(`${res?.message}`)
                setLoad(false)
            }
            getData()
        })
    }

    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }

    useEffect(() => {
        if (flag) {
            handleChange(fields.range)
        }
    }, [flag])

    const handleChange = (range) => {
        if (range > 0) {
            getRange({ type, range: Number(range) }).then(res => {
                if (res?.success) {
                    setValue(res?.message)
                }
            })
        }
    };
    const optimizedFn = useCallback(debounce(handleChange), []);

    return (
        <div className='infoSection new mb-3' style={{ background: "#FFF", }} >
            <Box className="addons-cart-title d-flex align-items-center">
                <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                    Following
                </Typography>

            </Box>
            <Divider className='mb-20' />
            <Row>
                <Col xs={8} className='mb-2'>
                    <div className='w-100'>
                        <TextField
                            disabled={load}
                            size="small"
                            value={fields.range}
                            onChange={e => {
                                let val = e.target.value;
                                if (val > 0) {
                                    setValue(0)
                                    setFields({ ...fields, range: val })
                                    optimizedFn(val)
                                }
                            }}
                            id="outlined-basic"
                            type='number'
                            label="Date Range"
                            InputProps={{
                                inputProps: {
                                    min: 1
                                },
                                endAdornment: <InputAdornment position="end">days</InputAdornment>,

                            }}
                            variant="outlined"
                            className="field-style"
                        />
                        <div style={{ textAlign: 'right', fontSize: "0.8rem", color: "gray", fontWeight: "500" }} >
                            {`${moment().subtract(fields.range, 'days').format("D MMM YY")} - ${moment().format("D MMM YY")}`}
                        </div>
                    </div>
                </Col>
                <Col xs={4}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={`${value} Videos`}
                        id="outlined-basic"
                        inputProps={{ readOnly: true, }}
                        label=" "
                        variant="outlined"
                        InputLabelProps={{ shrink: false }}
                        className="field-style mb-15"
                    />
                </Col>
            </Row>

            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.posts_to_read}
                        onChange={e => {
                            let val = e.target.value;
                            if (val > 0 && val <= value) {
                                setFields({ ...fields, posts_to_read: val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Number of Post"
                        InputProps={{
                            inputProps: {
                                min: 1
                                , max: value
                            }
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.boosted_percent}
                        onChange={e => {
                            let val = e.target.value;
                            if (val > 0 && val <= 100) {
                                setFields({ ...fields, boosted_percent: val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Boosted Videos %"
                        InputProps={{
                            inputProps: {
                                min: 1
                                , max: 100
                            }
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
                {error && <Col className='mb-3 -mt-1' xs={8}>
                                <div className='d-flex justify-content-between'>
                                    <div><span style={{ color: "#f51313", fontSize: "0.7rem", fontWeight: "600" }}>{msg}</span></div>
                                </div>
                            </Col>}
            </Row>
            <Row>
                <Col xs={12} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel
                            id="demo-simple-select-label"
                        >Include Category</InputLabel>
                        <Select
                            disabled={load}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fields.is_category}
                            label="Include Category"
                            onChange={(e) => {
                                let val = e.target.value
                                setFields({ ...fields, is_category: val })
                            }}
                        >
                            <MenuItem value={true}>Enable</MenuItem>
                            <MenuItem value={false}>Disable</MenuItem>
                        </Select>
                    </FormControl>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel
                            id="demo-simple-select-label"
                        >Sort By</InputLabel>
                        <Select
                            disabled={load}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fields.sort_by}
                            label="Sort By"
                            onChange={(e) => {
                                let val = e.target.value
                                setFields({ ...fields, sort_by: val })
                            }}
                        >
                            <MenuItem value={'created_date'}>Date</MenuItem>
                            <MenuItem value={"views"}>Views</MenuItem>
                            <MenuItem value={"likes"}>Likes</MenuItem>
                        </Select>
                    </FormControl>
                </Col>
            </Row>
            <Divider className='mb-3' />
            <Row>
                <Col xs={12} className='text-right mb-2'>
                    <Button
                        className="btn-width btn-gen-margin  mr-0 h-100"
                        disableElevation
                        size="medium"
                        variant="contained"
                        startIcon={<SaveIcon />}
                        onClick={submit}
                    >
                        Save
                    </Button>
                </Col>
            </Row>


        </div>
    )
}

const mapStateToProps = ({ feed }) => ({ feed })
export default connect(mapStateToProps, feedAction)(FollowingFeed)