import React, { useEffect, useState } from 'react'
import { connect, } from 'react-redux'
import { InputLabel, FormControl, Select, TextField, Button, MenuItem, Typography, Divider, Box } from '@mui/material'
import * as refundAction from "../../../store/actions/refund"

import moment from "moment";
import { Col, Row } from "react-bootstrap";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from "@mui/x-data-grid";
import NoRows from "../../../reUsable/NoRows"
import Skeleton from '../../../reUsable/skeleton';
import { refundCol } from "../../sales/cols"

function Refund({ currentUser, accountDetail, userType, getRefund, refund, currentCustomerId }) {
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState(false);

    useEffect(() => {
        getRefund(1, { customer: currentCustomerId }).then((res) => {
            setLoading(false)
        })
    }, [])

    // useEffect(() => {
    //     if (search) {
    //         setLoading(true)
    //         getRefund(1, { customer: currentCustomerId }).then((res) => {
    //             setLoading(false)
    //             setSearch(false)
    //         })
    //     }
    // }, [search])
    // const handleSearch = (type) => {
    //     setPage(1)
    //     setSearch(true)
    // }

    return (
        <div className='saleContainer'>
            <Typography className='title-bar-hd-main mb-3' color="primary" variant="h5" gutterBottom component="div">
                Refund
            </Typography>
            <div className='body'>
                {/* <Row className='align-items-center' >
                    <Col xl={3} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => handleSearch()}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width"
                            color="success"
                            disableElevation
                            onClick={() => handleSearch('reset')}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row> */}
                <Divider className="separater-sale" />
                {loading ?
                    <Skeleton /> :
                    <div style={{ height: 460, width: '100%' }}>
                        {
                            <>
                                <DataGrid
                                    density='compact'
                                    components={{ NoRowsOverlay: NoRows, }}
                                    disableSelectionOnClick
                                    className="bg-white"
                                    loading={loading}
                                    rows={Object.keys(refund).length !== 0 ? refund?.paymentIntents?.data?.map((single, index) => ({
                                        ...single,
                                        name: single.charges.data[0].billing_details.name,
                                        email: single.charges.data[0].billing_details.email,
                                        id: index + (page == 1 ? 1 : (page * 10) - 9),
                                        is_refund: single.charges.data[0].refunds.data.length === 0
                                    })) : []}
                                    columns={refundCol}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                />
                            </>
                        }
                    </div>
                }
            </div>
        </div >
    )
}

function mapStateToProps({ refund }) {
    return { refund }
}
export default connect(mapStateToProps, { ...refundAction })(Refund)
