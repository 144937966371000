import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Row, Col, } from 'react-bootstrap';
import { Card, Divider, Typography } from "@mui/material";

import * as accountActions from "../../../store/actions/account"
import * as promotionActions from "../../../store/actions/promotions"
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";



function PaymentMethods({ getDetails, currentUser, accountDetail, deleted, userType, paymentMethods }) {
    useEffect(() => {
        getDetails(currentUser, deleted)
        // if (userType === "brand") {
        //     console.log('hit api')
        //     paymentMethods({ user_id: currentUser }).then((res) => {
        //         console.log("123", res)
        //     })
        // } else {
        // }
    }, [])

    const cards = useMemo(() => {
        if (accountDetail?.paymentMethods) {
            return accountDetail?.paymentMethods?.data
        } else {
            return []
        }
    }, [accountDetail])
    return (
        <div className='saleContainer'>
            <Row className=''>
                <Col xl={5} lg={6}>
                    <Card variant="outlined" className='users-card-main' style={{ minHeight: 'initial' }} sx={{ minWidth: 275 }}>
                        <Typography className='title-bar-hd-main mb-1' color="primary" variant="h5" gutterBottom component="div">{userType==="brand" ? "Ad Payment Methods" : "Payment Methods"}</Typography>
                        <Divider className='mb-4' />
                        {cards?.length == 0 && <div className="fw-bold ml-2">Not Connected</div>}
                        {
                            cards?.map((item) => (
                                <div style={{ padding: "10px", border: "1px solid #dbdbdb", borderRadius: "5px" }} className="mb-2">
                                    <Row className="mb-1">
                                        <Col xs={2}>Card</Col>
                                        <Col xs={10}>
                                            <span className="fw-bold">{item?.card?.brand.toUpperCase()}</span>
                                        </Col>
                                    </Row>
                                    <Divider className="mb-2" />
                                    <Row>
                                        <Col xs={2}><FontAwesomeIcon color="#052977" icon={faCreditCard} /></Col>
                                        <Col xs={10}>
                                            <span className="fw-bold">**** **** **** {item?.card?.last4}</span>
                                        </Col>
                                    </Row>
                                </div>

                            ))
                        }
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = ({ accountDetail }) => ({ accountDetail })

export default connect(mapStateToProps, { ...accountActions, ...promotionActions })(PaymentMethods)
