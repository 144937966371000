import React, { useEffect, useState } from 'react'
import { connect, } from 'react-redux'
import { InputLabel, FormControl, Select, TextField, Button, MenuItem, Typography, Divider, Box } from '@mui/material'
import * as salesActions from "../../../store/actions/sales"
import * as withdrawActions from "../../../store/actions/payout"

import moment from "moment";
import DateButton from "../../../reUsable/DateButtons"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO } from 'date-fns';
import { Col, Row } from "react-bootstrap";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from "@mui/x-data-grid";
import NoRows from "../../../reUsable/NoRows"
import Skeleton from '../../../reUsable/skeleton';
import { withdrawCol } from "../../sales/cols"

function Withdrawal({ currentUser, accountDetail, userType, getWithdrawals, allSales, withdrawal, getWithdraw }) {
    const [date, setDate] = useState(false)
    const [value, setValue] = useState([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [fields, setFields] = useState({ status: "All", user_id: currentUser })
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0)

    useEffect(() => {
        getData(fields, page)
    }, [])
    useEffect(() => {
        if (search) {
            setPage(1)
            getData(fields, 1)
        }
    }, [search])
    const handleSearch = (type) => {
        setLoading(true)
        setPage(1)
        if (type === "reset") {
            setFields({ status: "All", user_id: currentUser, });
            setPage(1)
        }
        setSearch(true)
    }
    function getData(obj, p) {
        getWithdraw(obj, p, userType).then((res) => {
            if (res.success) {
                setData(res?.data)
                setTotal(res?.totalCount)
                setSearch(false)
                setLoading(false)
            } else {
                setData([])
                setTotal(0)
                setSearch(false)
                setLoading(false)
            }
        })
    }
    return (
        <div className='saleContainer'>
            <Typography className='title-bar-hd-main mb-3' color="primary" variant="h5" gutterBottom component="div">
                Withdrawal
            </Typography>
            <div className='body'>
                <Row className='align-items-center' >
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Status</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="order by"
                                labelId="orderBy"
                                id="orderBy"
                                value={fields.status}
                                label="Status"
                                onChange={(e) => setFields({ ...fields, status: e.target.value })}
                            >
                                <MenuItem value={"All"}>All</MenuItem>
                                <MenuItem value={"Pending"}>Pending</MenuItem>
                                <MenuItem value={"Processing"}>Processing</MenuItem>
                                <MenuItem value={"Approved"}>Approved</MenuItem>
                                <MenuItem value={"Rejected"}>Rejected</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={6} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => { handleSearch() }}

                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('reset') }}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
                <Divider className="separater-sale" />
                <div style={{ height: 460, width: '100%' }}>
                    {
                        <>
                            <DataGrid
                                density='compact'
                                components={{ NoRowsOverlay: NoRows, }}
                                loading={loading}
                                disableSelectionOnClick
                                className="bg-white"
                                rows={data?.length > 0 ? data?.map((item, index) => ({
                                    ...item,
                                    id: index + (page == 1 ? 1 : (page * 10) - 9)
                                })) : []}
                                columns={withdrawCol}
                                pagination
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                rowCount={total}
                                paginationMode="server"
                                onPageChange={(newPage) => {
                                    setLoading(true)
                                    setPage(newPage + 1);
                                    getData(fields, newPage + 1)
                                    // getWithdraw(fields, newPage + 1).then(() => {
                                    //     setLoading(false)

                                    // })
                                }}
                            />
                        </>
                    }
                </div>
            </div>
        </div >
    )
}

function mapStateToProps({ withdrawal }) {
    return { withdrawal }
}
export default connect(mapStateToProps, { ...salesActions, ...withdrawActions })(Withdrawal)
