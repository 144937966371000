import konnect from "./axios"
export const generatePDF = (data) => async (dispatch) => {
    try {
        const formData = new FormData()
        formData.append('user_id', data.user_id);
        formData.append('brand_name', data.brand_name);
        formData.append('merchant_name', data.merchant_name);
        formData.append('executed_by', data.executed_by);
        formData.append('website_address', data.website_address);
        formData.append('email', data.email);
        formData.append('fee', data.fee);
        formData.append('is_stripe_connect_user', data.is_stripe_connect_user);
        formData.append('file', data.blobData, 'test.pdf');
        const res = await konnect.post('/v1/users/agreement/save', formData)
        return res.data
    } catch (err) {
        return err.response
    }
}
export const generateAgencyPDF = (data) => async (dispatch) => {
    try {
        const formData = new FormData()
        formData.append('user_id', data.user_id);
        formData.append('company', data.company);
        formData.append('company_name', data.company_name);
        formData.append('executed_by', data.executed_by);
        formData.append('website_address', data.website_address);
        formData.append('email', data.email);
        formData.append('fee', data.fee);
        formData.append('date', data.date);
        formData.append('file', data.blobData, 'test.pdf');
        const res = await konnect.post('/v1/agency/agreement/save', formData)
        return res.data
    } catch (err) {
        return err.response
    }
}