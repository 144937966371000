import React, { useEffect, useMemo } from 'react'
import NoRows from "../../reUsable/NoRows"
import numeral from 'numeral'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import moment from 'moment'
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';
import UserModal from "../../reUsable/UserModal";
import BanModal from "./BanModal";
import EditIcon from '@mui/icons-material/Edit';

function BanTable({ nowGet, data, type, unban }) {
    const [user, setUser] = useState(null)
    const [modal, setModal] = useState(false)
    const [page, setPage] = useState(1)
    const [pageLoad, setPageLoad] = useState(false)

    const column = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        { field: "created_date", headerName: "Date", width: 180, sortable: false, renderCell: ({ value }) => value ? moment(value).format('MM-DD-YYYY') : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "type", headerName: "Ban Type", width: 180, sortable: false, renderCell: ({ value }) => type === "chatban" ? "Chat Ban" : "Permanent Ban" },
        { field: "pixel_id", headerName: "PID", width: 100, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        {
            field: "user_name", headerName: "User", width: 180, sortable: false, renderCell: (params) => {
                const user = params.row

                return <span
                    onClick={() => {
                        setUser({
                            id: user?.user_id,
                            name: user?.user_name,
                            image: user?.profile_image_url,
                            customer_id: user?.customer_id,
                            type: user?.account_type
                        })
                        setModal(true)
                    }}
                    className="account-name-hover-style"
                    style={user?.user_name ? {} : { color: "#bebebe" }}>
                    {user?.user_name ? user?.user_name : "Empty"}
                </span>
            }
        },
        {
            field: "review_title", headerName: "Video Title", width: 350, sortable: false, renderCell: ({ value }) => {
                if (value) {
                    return (
                        <Tooltip title={value} placement="top-start" arrow>
                            <span>{value}</span>
                        </Tooltip>
                    );
                } else {
                    return <span style={{ color: "#bebebe" }}>Empty</span>
                }
            }
        },
        {
            field: "reason", headerName: "Reason", width: 350, sortable: false, renderCell: ({ value }) => {
                if (value) {
                    return (
                        <Tooltip title={value} placement="top-start" arrow>
                            <span>{value}</span>
                        </Tooltip>
                    );
                } else {
                    return <span style={{ color: "#bebebe" }}>Empty</span>
                }
            }
        },
        {
            field: "action", headerName: "Action", width: 120, sortable: false, renderCell: (params) => {
                return <div
                    style={{ color: "#15226a", cursor: 'pointer', fontWeight: "500" }}
                    fontSize="small"
                    onClick={() => unban(params.row)}
                >
                    unban
                </div>
            }
        },
    ]
    const banColumn = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        { field: "type", headerName: "Ban Type", width: 180, sortable: false, renderCell: ({ value }) => "Permanent Ban" },
        { field: "pixel_id", headerName: "PID", width: 100, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        {
            field: "user_name", headerName: "User", width: 180, sortable: false, renderCell: (params) => {
                const user = params.row

                return <span
                    onClick={() => {
                        setUser({
                            id: user?._id,
                            name: user?.user_name,
                            image: user?.profile_image_url,
                            customer_id: user?.customer_id,
                            type: user?.account_type
                        })
                        setModal(true)
                    }}
                    className="account-name-hover-style"
                    style={user?.user_name ? {} : { color: "#bebebe" }}>
                    {user?.user_name ? user?.user_name : "Empty"}
                </span>
            }
        },
        {
            field: "reason", headerName: "Reason", width: 350, sortable: false, renderCell: ({ value }) => {
                if (value) {
                    return (
                        <Tooltip title={value} placement="top-start" arrow>
                            <span>{value}</span>
                        </Tooltip>
                    );
                } else {
                    return <span style={{ color: "#bebebe" }}>Empty</span>
                }
            }
        },
        {
            field: "action", headerName: "Action", width: 120, sortable: false, renderCell: (params) => {
                return <div
                    style={{ color: "#15226a", cursor: 'pointer', fontWeight: "500" }}
                    fontSize="small"
                    onClick={() => unban(params.row)}
                >
                    unban
                </div>
            }
        },
    ]
    useEffect(() => {
        setPageLoad(false)
    }, [data])



    return (
        <div style={{ height: 575 }} className="pl-3">
            <UserModal
                from={'withdraw'}
                visible={modal}
                close={() => {
                    setUser(null)
                    setModal(false);
                }}
                userImage={user?.image}
                userName={user?.name}
                deleted={false}
                currentCustomerId={user?.customer_id}
                currentUser={user?.id}
                nowGet={() => { }}
                userType={user?.type}
            />
            <DataGrid
                loading={pageLoad}
                showCellRightBorder={true}
                rowHeight={35}
                disableColumnFilter={true}
                density="compact"
                components={{ NoRowsOverlay: NoRows }}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                rows={
                    data?.data?.map((item, index) => ({
                        ...item,
                        id: index + (page == 1 ? 1 : (page * 20) - 19),
                    }))
                }
                columns={type === 'chatban' ? column : banColumn}
                rowCount={data?.total_count ? data?.total_count : 0}
                pagination
                pageSize={20}
                rowsPerPageOptions={[20]}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPageLoad(true)
                    setPage(newPage + 1);
                    nowGet(newPage + 1);
                }}

            />
        </div>
    )
}
export default React.memo(BanTable)