import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import ReportIcon from '@mui/icons-material/Report';
import SaveIcon from '@mui/icons-material/Save';
import { connect } from 'react-redux';
import * as returnsActions from "../../store/actions/returns"
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

function DeleteModal({ close, visible, title, call, type, flag, data, approved, nowGet }) {
    const [change, setChange] = useState(false)
    const [pass, setPass] = useState('')
    function clean() {
        setChange(false)
        setPass('')
        close()
    }
    function submit() {
        let obj = {
            password: pass,
            id: data?._id
        }
        approved(obj).then((res) => {
            if (res.success) {
                toast.success(`${res?.message}`)
                nowGet(1)
                clean()
            } else {
                toast.error(`${res?.message}`)
                setPass('')
                setChange(false)
            }
        })
    }
    return (
        <div>
            <Modal
                open={visible}
                onClose={clean}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="deleteModal w-40" >
                    <div className='modal-head-icon-area'>
                        <ReportIcon color="error" sx={{ fontSize: 60, color: "#ff9800" }} />
                    </div>

                    <Typography className='modal-title' id="modal-modal-title" variant="h6" component="h2">
                        Are You Sure?
                    </Typography>
                    <Typography className='modal-description' id="modal-modal-description" sx={{ mt: 2 }}>
                        {title}
                    </Typography>
                    {
                        change ?
                            <>
                                <div>
                                    <Typography className='modal-description' id="modal-modal-description" sx={{ mt: 2 }}>
                                        <span style={{ fontSize: "0.8rem" }}>Password:</span>  <input type='password' onChange={(e) => setPass(e.target.value)} value={pass} />
                                    </Typography>
                                </div>
                                <div className='boxes-buttons-row justify-content-around'>
                                    <Button
                                        onClick={clean}
                                        variant="outlined"
                                        size="medium"
                                        className="btn-gen-margin btn-width grey-btn-outline"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={submit}
                                        variant="contained"
                                        size="medium"
                                        className="btn-gen-margin btn-width"
                                        color="error"
                                        disableElevation
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </>
                            :
                            <div className='boxes-buttons-row justify-content-around'>

                                <Button
                                    onClick={clean}
                                    variant="outlined"
                                    size="medium"
                                    className="btn-gen-margin btn-width grey-btn-outline"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => setChange(true)}
                                    variant="contained"
                                    size="medium"
                                    className="btn-gen-margin btn-width"
                                    color="error"
                                    disableElevation
                                >
                                    Yes
                                </Button>
                            </div>
                    }

                </Box>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ }) => ({})
export default connect(mapStateToProps, { ...returnsActions })(DeleteModal)