import React from 'react';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Col, Row } from "react-bootstrap"
import CancelIcon from '@mui/icons-material/Cancel';
import { Divider } from '@mui/material';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

export default function Payload({ close, visible ,data,title}) {

    function renderData(){
        const keys=Object.keys(data)
        const values=Object.values(data)
        return values.map((v,i)=>{
            return(
                    <TableRow
                      key={i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {keys[i]}
                      </TableCell>
                      <TableCell>{JSON.stringify(v)}</TableCell>
                    </TableRow>
            )
        })
    }

    return (
        <div>
            <Modal
                open={visible}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="deleteModal  modal-spacing-new" style={{ width: '50%' }}>

                <Row className='heading-name-area-modal '>
                        <Col xl={12} lg={12} className="d-flex align-items-center justify-content-between">
                            <Box className="d-flex align-items-center">
                                <Typography className='text-center w-100 m-0' id="modal-modal-title" variant="h6" component="h2">
                                {title}
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    className='custom-close-btn-moda'
                                    onClick={close}
                                    aria-label="delete" sx={{ color: "grey" }}>
                                    <CancelIcon color="error" />
                                </IconButton>
                            </Box>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} className="">
                        <Divider className='mb-20' />
                        </Col>
                    </Row>


                    {/* <div style={{display:'flex',justifyContent:'flex-end'}}>
                        <IconButton 
                        onClick={close}
                        aria-label="delete" sx={{color:"grey"}}>
                            <ClearIcon />
                        </IconButton>
                        </div>
                    <div style={{display:'flex',marginBottom:20}}>
                        <Typography style={{ textAlign: 'center',width:'100%',margin:0}} id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                    </div> */}


                    <TableContainer sx={{ maxHeight: 500 }} component={Paper}>
                    <Table aria-label="simple table"  size="small">
                        <TableHead>
                        <TableRow>
                            <TableCell>Key</TableCell>
                            <TableCell>Name</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {renderData()}
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Box>
            </Modal>
        </div>
    );
}