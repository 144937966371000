import React, { useState, useEffect, } from 'react'
import DataTable from "./Table"
import { Col, Row } from 'react-bootstrap'
import { Button, Divider, Typography } from '@mui/material'


import { connect } from 'react-redux';


import * as priceActions from "../../store/actions/price"
import SkeletonLoader from "reUsable/skeleton";

import AddIcon from '@mui/icons-material/Add';
import AddPrice from "./AddPrice"


function PricePoint({ getPrice, prices }) {

    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [detail, setDetail] = useState(null)

    useEffect(() => {
        getData()
    }, []);


    function getData() {
        setLoading(true)
        getPrice().then(() => {
            setLoading(false)
        })
    }


    return (
        <div className='container-fluid mt-3 mb-3 pl-0'>
            <AddPrice
                visible={visible}
                clear={() => {
                    setDetail(null)
                    setVisible(false)
                }}
                data={detail}
                get={() => { getData() }}
            />
            <Row className='pl-15'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Price Points
                    </Typography>
                </Col>
                <Col xs className="mb-3 text-right">
                    <Button
                        onClick={() => {
                            setVisible(true)
                        }}
                        className='btn-width'
                        startIcon={<AddIcon />}
                        disableElevation
                        variant="contained">
                        Add Price Point
                    </Button>
                </Col>
            </Row>
            <Row className="account-main-row pl-15">
                {/* <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="dateBox">
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        helperText: "YYYY / MM / DD",
                                    },
                                }}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="Start Date"
                                value={parseISO(value[0])}
                                onChange={(newValue) => {
                                    setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style"
                                        size="small"
                                        style={{ backgroundColor: "white", width: "50%" }}
                                        {...params}
                                    />
                                )}
                            />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        helperText: "YYYY / MM / DD",
                                    },
                                }}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="End Date"
                                value={parseISO(value[1])}
                                onChange={(newValue) => {
                                    setValue([value[0], moment(newValue).format("YYYY-MM-DD")]);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style"
                                        size="small"
                                        style={{ backgroundColor: "white", width: "50%" }}
                                        {...params}
                                    />
                                )}
                            />
                        </div>
                    </LocalizationProvider>
                </Col> */}
                {/* <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <Autocomplete
                        variant="outlined"
                        className="field-style"
                        size="small"
                        id="disable-clearable"
                        disableClearable
                        options={brandList?.map(opt => ({ ...opt, label: `${opt.pixel_id} - ${opt.name}` }))}
                        onChange={(e, data) => {
                            setFields({ ...fields, brand_id: data?._id })
                            setName3(data.label)
                        }}
                        value={name3}
                        renderInput={(params) => <TextField {...params} label="Brand" onChange={(e) => {
                            setName3(e.target.value)
                            optimizedFn(e.target.value, 'brand')
                        }} />}
                    />
                </Col> */}
                {/* 
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <Button
                        variant="contained"
                        size="medium"
                        className="btn-gen-margin btn-width laptop"
                        color="primary"
                        startIcon={<SearchIcon />}
                        disableElevation
                        onClick={getData}
                    >
                        Search
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        startIcon={<RefreshIcon />}
                        className="btn-gen-margin btn-width laptop"
                        color="success"
                        disableElevation
                        onClick={() => {
                            getData('reset')
                        }}
                    >
                        Refresh
                    </Button>
                </Col> */}
            </Row >
            {
                loading ?
                    <div className="pl-15" style={{ marginTop: 16 }}>
                        <SkeletonLoader />
                    </div>
                    :
                    <DataTable data={prices} setter={(data) => {
                        setDetail(data)
                        setVisible(true)
                    }} />
            }
        </div >
    )
}
const mapStateToProps = ({ prices }) => ({ prices, })
export default connect(mapStateToProps, { ...priceActions })(PricePoint)