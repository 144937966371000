import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from 'react-bootstrap';
import { Card, Divider, TextField, Typography } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';

import * as playbackAction from "../../../store/actions/playbackURL"
import { toast } from 'react-toastify';



function Website({ currentUser, domain, getDomain, addDomain }) {
    useEffect(() => { getURL() }, [])
    const [url, setURL] = useState('')
    const [uri, setUri] = useState('')
    const [web, setWeb] = useState('')
    async function save() {
        let playback_url = "https://www.vcom." + url + "/"
        // console.log("playbackURL", playback_url)
        // console.log("web", web)
        addDomain({ playback_url, website_address: web, user_id: currentUser }).then((res) => {
            if (res.success) {
                toast.success('Successfully updated', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                });
                getURL()
            }
        }).catch(err => {
            console.log("32", err)
        })
    }
    function getURL() {
        getDomain({ user_id: currentUser }).then((res) => {
            if (res.success) {
                if (res?.data?.domain) {
                    let txt = res?.data?.domain?.split('vcom.')[1]
                    setUri(res?.data?.domain)
                    setURL(txt.substr(0, txt.length - 1))
                }
                setWeb(res?.data?.website)
            } else {
                setUri('')
                setURL('')
                setWeb('')
            }
        })
    }

    return (
        <div className='saleContainer'>
            <Row className='align-items-center'>
                <Col xl={5} lg={6}>
                    <Card variant="outlined" className='users-card-main' style={{ minHeight: 'initial' }} sx={{ minWidth: 275 }}>
                        <Typography className='title-bar-hd-main mb-1' color="primary" variant="h5" gutterBottom component="div">Links</Typography>
                        <Divider className='mb-4' />
                        <Row className="mb-2">
                            <Col xl={12} md={12} lg={12} sm={12}>
                                {
                                    <Row>
                                        <Col xl={3} md={3} lg={3} sm={3} style={style.colStyle}> <div style={style.rowStyle}>Website URL:</div></Col>
                                        <Col xl={9} md={9} lg={9} sm={9}> <div style={style.rowStyle}>{domain?.website ? domain?.website : "-"}</div></Col>
                                    </Row>
                                }
                                <Row>
                                    <Col xl={3} md={3} lg={3} sm={3} style={style.colStyle}> <div style={style.rowStyle}>Playback URL:</div></Col>
                                    <Col xl={9} md={9} lg={9} sm={9}> <div style={style.rowStyle}>{domain?.domain ? domain?.domain : "-"}</div></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider className='mb-4' />

                        <TextField
                            value={web}
                            onChange={(e) => {
                                setWeb(e.target.value)
                            }}
                            id="website"
                            label="Website URL"
                            type="text"
                            size="small"
                            className="field-style mb-2"
                            placeholder="google.com"
                            variant="outlined"
                        />
                        <TextField
                            value={url}
                            onChange={(e) => {
                                setURL(e.target.value)
                            }}
                            id="playback_url"
                            label="Playback URL"
                            type="text"
                            size="small"
                            className="field-style mb-4"
                            placeholder="google.com"
                            variant="outlined"
                        />
                        <Button
                            className="btn-width btn-gen-margin ml-0 h-100 webBTN"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={save}
                            disabled={url === ""}
                        >
                            Save
                        </Button>
                        {/* <Button
                            className="btn-width btn-gen-margin grey-btn-outline h-100"
                            variant="outlined"
                            sx={{ color: 'gray' }}
                            startIcon={<ClearIcon />}
                            onClick={() => {
                                setValue([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
                                setSearch(true)
                            }}
                        >
                            Clear
                        </Button> */}
                        <Button
                            className=" btn-gen-margin ml-0  btn-width btn-gen-margin grey-btn-outline h-100 resetBTN"
                            disableElevation
                            size="medium"
                            variant="outlined"
                            // style={{ marginTop: "10px !important" }}
                            sx={{ color: 'gray', }}
                            startIcon={<ClearIcon />}
                            onClick={() => {
                                if (domain?.domain) {
                                    let txt = domain?.domain?.split('vcom.')[1]
                                    setUri(domain?.domain)
                                    setURL(txt.substr(0, txt.length - 1))
                                }
                                setWeb(domain?.website)
                            }}
                        >
                            Reset
                        </Button>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = ({ domain }) => ({ domain })

export default connect(mapStateToProps, { ...playbackAction })(Website)

const style = {
    rowStyle: {
        marginBottom: "10px",
        fontWeight: "500",
        lineHeight: "1.25",
        color: "#001737"
    },
    colStyle: {
        textAlign: "left"
    }

}