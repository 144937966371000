import konnect from "./axios"
import { PRICEPOINT } from "./type"


export const getPrice = () => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/fee/getPricePoint',)
        dispatch({
            type: PRICEPOINT,
            payload: res?.data?.data,
        })
    } catch (err) {
        console.log(err.response)
    }
}

export const addPrice = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/fee/setPricePoint', data)
        return res.data
    } catch (err) {
        return err.message
    }
}

export const updatePrice = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/fee/updatePricePoint', data)
        return res.data
    } catch (err) {
        return err.message
    }

}



