import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import InfoBox from "../../reUsable/InfoBox";
import { Col, Row } from "react-bootstrap"
import * as dashboardActions from "../../store/actions/dashboard"
import SkeletonLoader from "reUsable/skeleton";
import Box from '@mui/material/Box';
import { Divider, Typography, Container } from '@mui/material';
import Paper from '@mui/material/Paper';

function DashboardComponent({ getDashboard, dashboard, refresh, getDashboardGraph, dashboardGraph }) {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        // refresh(refreshDash)
        // Promise.all([getDashboard(), getDashboardGraph()]).then(() => setLoading(false))

    }, [])

    function refreshDash() {
        setLoading(true)
        Promise.all([getDashboard(), getDashboardGraph()]).then(() => {
            setLoading(false);
        })
    }
    function renderBox() {
        return dashboard.map(({
            package_id,
            package_name,
            total_user,
            total_amount
        }) => {
            return (
                <Col key={package_id} xs={12} sm={6} md={6} lg={6} xl={3} >
                    <InfoBox
                        title={package_name === "Customer" ? "Shopper" : package_name}
                        amount={total_amount}
                        total={total_user}
                    />
                </Col>
            )
        })
    }
    function renderCommission() {
        let arr = [
            {
                package_id: "test1",
                package_name: "Customer",
                total_user: dashboardGraph?.customer?.total_user,
                total_amount: dashboardGraph?.customer?.kb_commission
            },
            {
                package_id: "test2",
                package_name: "influencer",
                total_user: dashboardGraph?.influencer?.total_user,
                total_amount: dashboardGraph?.influencer?.kb_commission
            },
            {
                package_id: "test3",
                package_name: "brand",
                total_user: dashboardGraph?.brand?.total_user,
                total_amount: dashboardGraph?.brand?.kb_commission
            },
            {
                package_id: "test4",
                package_name: "KBShop",
                total_user: 0,
                house: true,
                total_amount: dashboardGraph?.brand?.kb_commission + dashboardGraph?.influencer?.kb_commission + dashboardGraph?.customer?.kb_commission
            },
        ]
        return arr.map(({
            package_id,
            package_name,
            total_user,
            house,
            total_amount
        }) => {
            return (
                <Col key={package_id} xs={12} sm={6} md={6} lg={6} xl={3} >
                    <InfoBox
                        title={package_name === "Customer" ? "Shopper" : package_name}
                        amount={total_amount}
                        total={total_user}
                        house={house}
                    />
                </Col>
            )
        })
    }

    return (
        <div className="container-fluid">
            <Typography className='fw-normal mb-0 mt-3' color="primary" variant="h6" gutterBottom component="div">
                Dashboard
            </Typography>
            {/* {
                loading ?
                    <div className="mt-3">
                        <SkeletonLoader className="mt-3" />
                    </div>
                    :
                    <>
                        <div className="dashboard-package">
                            {
                                dashboard?.some(val => val === null) ? null :
                                    <>
                                        <Box className="addons-cart-title d-flex align-items-center">
                                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                                Packages & Addons
                                            </Typography>
                                        </Box>
                                        <Divider />
                                        <Row className="package-boxes-row">
                                            {renderBox()}
                                        </Row>
                                        <Box className="addons-cart-title d-flex align-items-center">
                                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                                Revenue
                                            </Typography>
                                        </Box>
                                        <Divider />
                                    </>
                            }

                            <Row className="package-boxes-row">
                                {renderCommission()}
                            </Row>
                        </div>
                    </>
            } */}
        </div>

    );
}

const mapStateToProps = ({ dashboard, dashboardGraph }) => {
    return { dashboard, dashboardGraph };
};

export default connect(mapStateToProps, dashboardActions)(DashboardComponent);
