import React, { useEffect, useState } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import CancelIcon from '@mui/icons-material/Cancel';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SaveIcon from '@mui/icons-material/Save';
import CardMedia from '@mui/material/CardMedia';
import LoadingButton from '@mui/lab/LoadingButton';
import { Col, Row } from "react-bootstrap";
import { Button, Divider, Typography, TextField } from '@mui/material';
import { toast } from "react-toastify";


import { Modal, Box, IconButton } from '@mui/material';
import imgDefaul from "../../assets/images/default.jpg"

import CustomAutoComplete from "./CustomAutoComplete"

export default function CreateModal({ generalCategoryId, visible, close, save, slot, rowData, name, setName, nameLoad, setNameLoad, optimizedFn, accountList, setBrandId, brandId, page, categoryId, subCategoryId, accountByCategory }) {
    const [fields, setFields] = useState({
        brand: "",
        slot: "",
        row: "",
        file: {},
        desktop: {},
        sm_desktop: {},
        mobile: {},
        app: {},
        logo: {},
        title: "",
        description: ""
    })
    const [submit, setSubmit] = useState(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
    };
    const imageLoad = (file) => {
        return new Promise((res, rej) => {
            let img = new Image();
            img.src = URL.createObjectURL(file)
            img.onload = function () {
                var width = img.naturalWidth,
                    height = img.naturalHeight;
                window.URL.revokeObjectURL(img.src);
                res({ width, height, file })
            };
        })
    }
    function renderMedia() {
        return (
            <>
                <Col xl={6} lg={6}>
                    <Row className="mb-15 justify-content-center"  >
                        <Col xl={12} lg={12}>
                            <div className="adManageImage" style={submit && !fields?.desktop?.name ? { border: "1px solid red " } : { border: "1px solid #c4c4c4" }}>
                                <CardMedia
                                    component="img"
                                    image={fields.desktop?.name ? URL.createObjectURL(fields?.desktop) : imgDefaul}
                                    sx={{ width: "100", borderRadius: 1, margin: 1 }}
                                    alt="green iguana"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-15 justify-content-around"  >
                        <Col xl={6} lg={6} className="text-left" >
                            <span className="btn-gen-margin"> Desktop:</span>
                            <Button
                                startIcon={<FileUploadIcon />}
                                className=' btn-gen-margin'
                                disableElevation
                                size="small"
                                component="label"
                                variant="contained">
                                Upload
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => {
                                        if (e.target.files[0]) {
                                            imageLoad(e.target.files[0]).then(({ height, width, file }) => {
                                                switch (rowData?.rowId) {
                                                    case 1: {
                                                        if (width === 1632 && height === 400) {
                                                            setFields({ ...fields, desktop: file })
                                                        } else {
                                                            toast.error("Select correct Dimention: 1632 x 400", {
                                                                autoClose: 3000,
                                                                hideProgressBar: true,
                                                            });
                                                        }
                                                        return 0
                                                    }
                                                    case 3: {
                                                        if (width === 1200 && height === 600) {
                                                            setFields({ ...fields, desktop: file })
                                                        } else {
                                                            toast.error("Select correct Dimention: 1200 x 600", {
                                                                autoClose: 3000,
                                                                hideProgressBar: true,
                                                            });
                                                        }
                                                        return 0
                                                    }
                                                    case 4: {
                                                        if (width === 480 && height === 220) {
                                                            setFields({ ...fields, desktop: file })
                                                        } else {
                                                            toast.error("Select correct Dimention: 480 x 220", {
                                                                autoClose: 3000,
                                                                hideProgressBar: true,
                                                            });
                                                        }
                                                        return 0
                                                    }
                                                    default: setFields({ ...fields, desktop: file })
                                                }
                                            })
                                        }
                                        // if (e.target.files[0]) {
                                        //     setFields({ ...fields, desktop: e.target.files[0] })
                                        // }
                                    }}
                                />
                            </Button>
                        </Col>
                        <Col xl={6} lg={6} className="text-right" >
                            <span style={{ color: "gray", fontSize: "0.8rem", position: "relative", top: "-12px" }}>Dimention: {rowData?.rowId === 1 ? "1632 x 400" : rowData?.rowId === 3 ? "1200 x 600" : "480 x 220"}</span>
                        </Col>
                    </Row>
                </Col>
                <Col xl={6} lg={6}>
                    <Row className="mb-15 justify-content-center"  >
                        <Col xl={12} lg={12}>
                            <div className="adManageImage" style={submit && !fields?.sm_desktop?.name ? { border: "1px solid red " } : { border: "1px solid #c4c4c4" }}>
                                <CardMedia
                                    component="img"
                                    image={fields.sm_desktop?.name ? URL.createObjectURL(fields?.sm_desktop) : imgDefaul}
                                    sx={{ width: "100", borderRadius: 1, margin: 1 }}
                                    alt="green iguana"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-15 justify-content-around"  >
                        <Col xl={6} lg={6} className="text-left" >
                            <span className="btn-gen-margin"> SM Desktop:</span>
                            <Button
                                startIcon={<FileUploadIcon />}
                                className=' btn-gen-margin'
                                disableElevation
                                size="small"
                                component="label"
                                variant="contained">
                                Upload
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => {
                                        if (e.target.files[0]) {
                                            imageLoad(e.target.files[0]).then(({ height, width, file }) => {
                                                switch (rowData?.rowId) {
                                                    case 1: {
                                                        if (width === 1270 && height === 340) {
                                                            setFields({ ...fields, sm_desktop: file })
                                                        } else {
                                                            toast.error("Select correct Dimention: 1270 x 340", {
                                                                autoClose: 3000,
                                                                hideProgressBar: true,
                                                            });
                                                        }
                                                        return 0
                                                    }
                                                    case 3: {
                                                        if (width === 1200 && height === 600) {
                                                            setFields({ ...fields, sm_desktop: file })
                                                        } else {
                                                            toast.error("Select correct Dimention: 1200 x 600", {
                                                                autoClose: 3000,
                                                                hideProgressBar: true,
                                                            });
                                                        }
                                                        return 0
                                                    }
                                                    case 4: {
                                                        if (width === 480 && height === 220) {
                                                            setFields({ ...fields, sm_desktop: file })
                                                        } else {
                                                            toast.error("Select correct Dimention: 480 x 220", {
                                                                autoClose: 3000,
                                                                hideProgressBar: true,
                                                            });
                                                        }
                                                        return 0
                                                    }
                                                    default: setFields({ ...fields, sm_desktop: file })
                                                }
                                            })
                                        }
                                    }}
                                />
                            </Button>
                        </Col>
                        <Col xl={6} lg={6} className="text-right" >
                            <span style={{ color: "gray", fontSize: "0.8rem", position: "relative", top: "-12px" }}>Dimention: {rowData?.rowId === 1 ? "1270 x 340" : rowData?.rowId === 3 ? "1200 x 600" : "480 x 220"}</span>
                        </Col>
                    </Row>
                </Col>
                <Col xl={6} lg={6}>
                    <Row className="mb-15 justify-content-center"  >
                        <Col xl={12} lg={12}>
                            <div className="adManageImage" style={submit && !fields?.mobile?.name ? { border: "1px solid red " } : { border: "1px solid #c4c4c4" }}>
                                <CardMedia
                                    component="img"
                                    image={fields?.mobile?.name ? URL.createObjectURL(fields?.mobile) : imgDefaul}
                                    sx={{ width: "100", borderRadius: 1, margin: 1 }}
                                    alt="green iguana"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-15 justify-content-around"  >
                        <Col xl={6} lg={6} className="text-left" >
                            <span className="btn-gen-margin"> Mobile:</span>
                            <Button
                                startIcon={<FileUploadIcon />}

                                className=' btn-gen-margin'
                                disableElevation
                                size="small"
                                component="label"
                                variant="contained">
                                Upload
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => {
                                        if (e.target.files[0]) {
                                            imageLoad(e.target.files[0]).then(({ height, width, file }) => {
                                                console.log("123", width, height)
                                                switch (rowData?.rowId) {
                                                    case 1: {
                                                        if (width === 1200 && height === 600) {
                                                            setFields({ ...fields, mobile: file })
                                                        } else {
                                                            toast.error("Select correct Dimention: 1200 x 600", {
                                                                autoClose: 3000,
                                                                hideProgressBar: true,
                                                            });
                                                        }
                                                        return 0
                                                    }
                                                    case 3: {
                                                        if (width === 1200 && height === 600) {
                                                            setFields({ ...fields, mobile: file })
                                                        } else {
                                                            toast.error("Select correct Dimention: 1200 x 600", {
                                                                autoClose: 3000,
                                                                hideProgressBar: true,
                                                            });
                                                        }
                                                        return 0
                                                    }
                                                    case 4: {
                                                        if (width === 480 && height === 220) {
                                                            setFields({ ...fields, mobile: file })
                                                        } else {
                                                            toast.error("Select correct Dimention: 480 x 220", {
                                                                autoClose: 3000,
                                                                hideProgressBar: true,
                                                            });
                                                        }
                                                        return 0
                                                    }
                                                    default: setFields({ ...fields, mobile: file })
                                                }


                                            })
                                        }
                                        // if (e.target.files[0]) {
                                        //     setFields({ ...fields, mobile: e.target.files[0] })
                                        // }
                                    }}
                                />
                            </Button>
                        </Col>
                        <Col xl={6} lg={6} className="text-right" >
                            <span style={{ color: "gray", fontSize: "0.8rem", position: "relative", top: "-12px" }}>Dimention: {(rowData?.rowId === 1 || rowData?.rowId === 3) ? "1200 x 600" : "480 x 220"}</span>
                        </Col>
                    </Row>
                </Col>
                <Col xl={6} lg={6}>
                    <Row className="mb-15 justify-content-center"  >
                        <Col xl={12} lg={12}>
                            <div className="adManageImage" style={submit && !fields?.app?.name ? { border: "1px solid red " } : { border: "1px solid #c4c4c4" }}>
                                <CardMedia
                                    component="img"
                                    image={fields.app?.name ? URL.createObjectURL(fields?.app) : imgDefaul}
                                    sx={{ width: "100", borderRadius: 1, margin: 1 }}
                                    alt="green iguana"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-15 justify-content-around"  >
                        <Col xl={6} lg={6} className="text-left" >
                            <span className="btn-gen-margin"> App:</span>
                            <Button
                                startIcon={<FileUploadIcon />}
                                className=' btn-gen-margin'
                                disableElevation
                                component="label"
                                size="small"
                                variant="contained">
                                Upload
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => {
                                        if (e.target.files[0]) {
                                            setFields({ ...fields, app: e.target.files[0] })
                                        }
                                    }}
                                />
                            </Button>
                        </Col>
                        <Col xl={6} lg={6} className="text-right" >
                            <span style={{ color: "gray", fontSize: "0.8rem", position: "relative", top: "-12px" }}>Dimention: {(rowData?.rowId === 1 || rowData?.rowId === 3) ? "1200 x 600" : "480 x 220"}</span>
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }
    function renderInfo() {
        return (
            <>
                <Col xl={6} lg={6} className="text-left" >
                    <TextField
                        error={submit && fields.title === "" ? true : false}
                        size="small"
                        value={fields.title}
                        onChange={e => setFields({ ...fields, title: e.target.value })}
                        id="outlined-basic"
                        label="Title"
                        variant="outlined"
                        className="field-style "
                    />
                </Col>
                <Col xl={6} lg={6} className="text-right" >
                    <TextField
                        error={submit && fields.description === "" ? true : false}
                        size="small"
                        value={fields.description}
                        onChange={e => setFields({ ...fields, description: e.target.value })}
                        id="outlined-basic"
                        label="Description"
                        variant="outlined"
                        className="field-style"
                    />
                </Col>
            </>
        )
    }
    function clear(type) {
        setFields({
            brand: "",
            slot: "",
            file: {},
            desktop: {},
            sm_desktop: {},
            mobile: {},
            app: {},
            logo: {},
            row: "",
            title: "",
            description: ""
        })
        setName("")
        setBrandId("")
        setSubmit(false)
        if (type !== "reset") close()
    }
    return (
        <div>
            <Modal
                open={visible}
                onClose={() => clear()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className={"deleteModal modal-generic-style modal-spacing-new"} style={rowData?.rowId === 2 ? { width: "30%" } : {}}>
                    <Row className='heading-name-area-modal '>
                        <Col xl={12} lg={12} className="d-flex align-items-center justify-content-between">
                            <Box className="d-flex align-items-center">
                                <Typography className='text-center w-100 m-0' id="modal-modal-title" variant="h6" component="h2">
                                    Upload Banner <span style={{ fontSize: "0.9rem", color: "gray" }} > ({rowData?.id + slot?.id}) </span>
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    className='custom-close-btn-moda'
                                    onClick={() => clear()}
                                    aria-label="delete" sx={{ color: "grey" }}>
                                    <CancelIcon color="error" />
                                </IconButton>
                            </Box>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} className="">
                            <Divider className='mb-15' />
                        </Col>
                    </Row>
                    {
                        rowData?.rowId === 2 &&
                        <Row>
                            <Col xl={12} lg={12}>
                                <CustomAutoComplete
                                    submit={submit}
                                    brandId={brandId}
                                    nameLoad={nameLoad}
                                    page={page}
                                    accountList={accountList}
                                    accountByCategory={accountByCategory}
                                    setBrandId={setBrandId}
                                    name={name}
                                    setName={setName}
                                    setNameLoad={setNameLoad}
                                    optimizedFn={optimizedFn}
                                    categoryId={categoryId}
                                    subCategoryId={subCategoryId}
                                />
                            </Col>
                        </Row>
                    }
                    <Row>
                        {
                            rowData?.rowId === 3 &&
                            <>
                                <Col xl={12} lg={12}>
                                    <Row>
                                        <Col xl={6} lg={6}>
                                            <div className="mb-15">
                                                <CustomAutoComplete
                                                    submit={submit}
                                                    brandId={brandId}
                                                    nameLoad={nameLoad}
                                                    page={page}
                                                    accountList={accountList}
                                                    accountByCategory={accountByCategory}
                                                    setBrandId={setBrandId}
                                                    name={name}
                                                    setName={setName}
                                                    setNameLoad={setNameLoad}
                                                    optimizedFn={optimizedFn}
                                                    categoryId={categoryId}
                                                    subCategoryId={subCategoryId}
                                                />
                                            </div>
                                            <Row className="justify-content-around mb-15"  >
                                                {renderInfo()}
                                            </Row>
                                            <Row className="mb-15">
                                                <Col >
                                                    <Button
                                                        startIcon={<FileUploadIcon />}
                                                        className="btn-width btn-gen-margin ml-0 w-100 "
                                                        size="small"
                                                        disableElevation
                                                        component="label"
                                                        variant="contained">
                                                        Brand Logo
                                                        <input
                                                            type="file"
                                                            hidden
                                                            onChange={(e) => {
                                                                if (e.target.files[0]) {
                                                                    setFields({ ...fields, logo: e.target.files[0] })
                                                                }
                                                            }}
                                                        />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xl={6} lg={6}>
                                            <div className="adManageImage brandYouLoveIMG"
                                                style={submit && !fields?.logo?.name ? { border: "1px solid red " } : { border: "1px solid #c4c4c4" }}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    image={fields.logo?.name ? URL.createObjectURL(fields?.logo) : imgDefaul}
                                                    sx={{ width: "100", borderRadius: 1, margin: 1 }}
                                                    alt="green iguana"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={12} lg={12}>
                                    <Divider className='mb-15' />
                                </Col>
                                {renderMedia()}
                            </>
                        }
                        {
                            (rowData?.rowId !== 2 && rowData?.rowId !== 3) &&
                            <>
                                <Col xl={12} lg={12} className="mb-15">
                                    <CustomAutoComplete
                                        submit={submit}
                                        brandId={brandId}
                                        nameLoad={nameLoad}
                                        page={page}
                                        accountList={accountList}
                                        accountByCategory={accountByCategory}
                                        setBrandId={setBrandId}
                                        name={name}
                                        setName={setName}
                                        setNameLoad={setNameLoad}
                                        optimizedFn={optimizedFn}
                                        categoryId={categoryId}
                                        subCategoryId={subCategoryId}
                                        generalCategoryId={generalCategoryId}
                                    />
                                </Col>
                                {
                                    rowData?.rowId === 3 &&
                                    <Col xl={12} lg={12}>
                                        <Row>
                                            <Col xl={6} lg={6}>
                                                <Row className="mb-15 justify-content-center"  >
                                                    <Col xl={12} lg={12}>
                                                        <div className="adManageImage"
                                                            style={submit && !fields?.logo?.name ? { border: "1px solid red " } : { border: "1px solid #c4c4c4" }}
                                                        >
                                                            <CardMedia
                                                                component="img"
                                                                image={fields.logo?.name ? URL.createObjectURL(fields?.logo) : imgDefaul}
                                                                sx={{ width: "100", borderRadius: 1, margin: 1 }}
                                                                alt="green iguana"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-15 justify-content-around"  >
                                                    <Col xl={6} lg={6} className="text-left" >
                                                        <span className="btn-gen-margin"> Brand Logo:</span>
                                                        <Button
                                                            startIcon={<FileUploadIcon />}
                                                            className=' btn-gen-margin'
                                                            disableElevation
                                                            component="label"
                                                            size="small"
                                                            variant="contained">
                                                            Upload
                                                            <input
                                                                type="file"
                                                                hidden
                                                                onChange={(e) => {
                                                                    if (e.target.files[0]) {
                                                                        setFields({ ...fields, logo: e.target.files[0] })
                                                                    }
                                                                }}
                                                            />
                                                        </Button>
                                                    </Col>
                                                    <Col xl={6} lg={6} className="text-right" >
                                                        <span style={{ color: "gray", fontSize: "0.8rem", position: "relative", top: "-12px" }}>Dimention: 600 x 200</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                                {
                                    (rowData?.rowId === 4) &&
                                    <Col xl={12} lg={12}>
                                        <Row className="mb-15 justify-content-around"  >
                                            {renderInfo()}
                                        </Row>
                                    </Col>
                                }
                                {renderMedia()}
                            </>
                        }
                    </Row>

                    <div className='modal-buttons-row'>
                        <LoadingButton
                            onClick={() => {
                                if (rowData.rowId === 1) {
                                    setSubmit(true)
                                    if (fields?.desktop?.name && fields?.sm_desktop?.name && fields?.app?.name && fields?.mobile?.name && brandId !== "") {
                                        save(1, fields)
                                        clear()
                                    }
                                }
                                if (rowData.rowId === 2) {
                                    setSubmit(true)
                                    if (brandId !== "") {
                                        save("HPSP-B")
                                        setName("")
                                        setBrandId("")
                                        setSubmit(false)
                                        close()
                                    }
                                }
                                if (rowData.rowId === 3) {
                                    setSubmit(true)
                                    if (fields?.logo?.name && fields?.desktop?.name && fields?.sm_desktop?.name && fields?.app?.name && fields?.mobile?.name && brandId !== "" && fields.title !== "" && fields.description !== "") {
                                        save("new", fields)
                                        clear()
                                    }
                                }
                                if (rowData.rowId === 4) {
                                    setSubmit(true)
                                    if (fields?.desktop?.name && fields?.sm_desktop?.name && fields?.app?.name && fields?.mobile?.name && brandId !== "" && fields.title !== "" && fields.description !== "") {
                                        save("new", fields)
                                        clear()
                                    }
                                }
                            }
                            }
                            variant="contained"
                            size="medium"
                            color="primary"
                            className="btn-gen-margin ml-0 btn-width"
                            disableElevation
                        >
                            <SaveIcon className="mr-2" />  Save
                        </LoadingButton>
                        <Button
                            className='btn-gen-margin btn-width grey-btn-outline'
                            variant="outlined"
                            component="label"
                            size="large"
                            onClick={() => clear('reset')}
                        >
                            Reset
                        </Button>
                    </div>
                </Box>
            </Modal >
        </div >
    )
}
