import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap"
import { Button, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import * as settingsAction from "../../../store/actions/settings.action"
import { toast } from 'react-toastify';

function EmailBlastSetting({ emailSetting, updateEmailSetting }) {
    const [load, setLoad] = useState(true)
    const [fields, setFields] = useState({
        welcomeeblast: false
    })

    useEffect(() => {
        getData()
    }, [])

    const handleSave = () => {
        setLoad(true)
        updateEmailSetting(fields).then((res) => {
            if (res.success) {
                toast.success(`${res?.message}`)
            } else {
                toast.error(`${res?.message}`)
            }
            getData()
        })
    }

    function getData() {
        emailSetting().then((res) => {
            if (res.success) {
                setFields({
                    welcomeeblast: res?.data?.setting?.welcomeeblast
                })
            } else {
                setFields({
                    welcomeeblast: false
                })
            }
        }).finally(() => setLoad(false))
    }

    return (
        <div className="container-fluid mb-3">
            <Row className='pt-3'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Email Blast Setting
                    </Typography>
                </Col>
            </Row>
            <Divider className="mb-15" />
            <Row className="d-flex justify-content-between">
                <Col xl={4} lg={6} md={12}>
                    <div className='infoSection new mb-3' style={{ background: "#FFF" }} >
                        <Box className="addons-cart-title d-flex align-items-center">
                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                Email Blast
                            </Typography>
                        </Box>
                        <Divider className='mb-20' />
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Email Blast</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className='mb-3'
                                value={fields.welcomeeblast}
                                label="Email Blast"
                                onChange={(e) => {
                                    setFields({ ...fields, welcomeeblast: e.target.value })
                                }}
                            >
                                <MenuItem value={true}>On</MenuItem>
                                <MenuItem value={false}>Off</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            disabled={load}
                            className="btn-width btn-gen-margin ml-0 h-100"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={() => handleSave()}
                        >
                            Save
                        </Button>
                    </div>
                </Col>

            </Row>
        </div>
    )
}

const mapStateToProps = ({ feed }) => {
    return { feed };
};

export default connect(mapStateToProps, { ...settingsAction })(EmailBlastSetting);