import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';

import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { Button, Typography, Divider, MenuItem, Select, InputLabel, FormControl, Box, TextField } from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";

import Skeleton from '../../reUsable/skeleton';
import "../accountMain/components/sale.css"
import *  as videoActions from 'store/actions/videobost'
import * as categoryActions from "../../store/actions/Categories/categories";

import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import DataTable from "./DataTable";
import SearchBrand from "reUsable/SearchBrand";


function VideoBoost({ videos, getVideoBoost, filterAllCategories }) {
    const [loading, setLoading] = useState(true)
    const [value, setValue] = useState([moment().startOf("year").format("YYYY-MM-DD"), moment().endOf('year').format("YYYY-MM-DD")]);
    const [fields, setFields] = useState({
        brand_id: '',
        status: 'all',
        department: 'all'
    })
    const [gen, setGen] = useState([]);

    useEffect(() => {
        getData(value, fields)
        filterAllCategories({ source: "genCategory" }).then((res) => {
            if (res.success) {
                setGen(res?.message);
            } else { setGen([]); }
        })
    }, [])

    function handleSearch(type) {
        if (type === "refresh") {
            setFields({ brand_id: '', status: 'all', department: 'all' })
            setValue([moment().startOf("year").format("YYYY-MM-DD"), moment().endOf('year').format("YYYY-MM-DD")])
            getData([moment().startOf("year").format("YYYY-MM-DD"), moment().endOf('year').format("YYYY-MM-DD")], { brand_id: '', status: 'all', department: 'all' })
        } else {
            getData(value, fields)
        }
    }

    const getData = (date, obj) => {
        setLoading(true)
        getVideoBoost(date, obj, 1).then(() => {
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }
    return (
        <React.Fragment>
            <div className='container-fluid mt-3 mb-3 pl-0'>
                <Row className='pl-15'>
                    <Col xl className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            Video Boost
                        </Typography>

                    </Col>
                </Row>
                <Row className="account-main-row pl-15">
                    <Col xl={3} lg={12} className='mb-3'>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="dateBox">
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => { setValue([value[0], moment(newValue).format("YYYY-MM-DD")]) }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.status}

                                label="Status"
                                onChange={(e) => {
                                    setFields({ ...fields, status: e.target.value, })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={'active'}>Active</MenuItem>
                                <MenuItem value={'pending'}>Pending</MenuItem>
                                <MenuItem value={'cancelled'}>Cancelled</MenuItem>
                                <MenuItem value={'completed'}>Completed</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <SearchBrand setter={(value) => setFields({ ...fields, brand_id: value })} brand={fields.brand_id} />
                    </Col>

                    <Col xs={12} md={12} lg={6} xl={3} className="mb-3">
                        <FormControl className="field-style" size="small">
                            <InputLabel id="demo-simple-select-label1">Department</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Department"
                                labelId="Department"
                                id="Department"
                                value={fields.department}
                                label="Department"
                                onChange={(e) => {
                                    setFields({
                                        ...fields,
                                        department: e.target.value,
                                    });
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {gen?.map((item) => (
                                    <MenuItem value={item._id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => { handleSearch() }}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('refresh') }}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
                <Divider className="separater-sale ml-15" />
                <div style={{ height: 600 }} className="">
                    {
                        loading ? <div className="ml-15"><Skeleton /></div> :
                            <>
                                <DataTable
                                    nowGet={(page) => getVideoBoost(value, fields, page)}
                                    data={videos}
                                />
                            </>
                    }
                </div>
            </div>

        </React.Fragment>
    )
}

function mapStateToProps({ videos }) {
    return { videos }
}
export default connect(mapStateToProps, { ...videoActions, ...categoryActions })(VideoBoost)
