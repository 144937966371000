import React, { useEffect, useState } from 'react'
import { connect, } from 'react-redux'
import * as refundAction from "../../../store/actions/refund"
import { DataGrid } from "@mui/x-data-grid";
import NoRows from "../../../reUsable/NoRows"
import Skeleton from '../../../reUsable/skeleton';
import { Typography, Divider, Box, IconButton, Modal, Paper } from '@mui/material'
import { Button, Col, Row } from "react-bootstrap"

import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';
import numeral from "numeral";
import moment from "moment"
import DeleteModal from "../../../reUsable/DeleteModal";
import { RiCloseCircleLine } from "react-icons/ri";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

function RefundModal({ close, visible, id, title, getRefund, refund, collectRefund }) {
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1);
    const [delModal, setDelModal] = useState(false)
    const [data, setData] = useState({})
    const refundCol = [
        {
            field: 'id', headerName: 'S.#', width: 20, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        { field: "created", headerName: "Date", width: 200, sortable: false, renderCell: ({ value }) => moment.unix(value).format("YYYY-MM-DD HH:mm:ss") },
        { field: "customer_name", headerName: "Name", width: 150, sortable: false, },
        { field: "customer_email", headerName: "Email", width: 200, sortable: false },
        { field: "amount_paid", headerName: "Amount", width: 130, sortable: false, renderCell: ({ value }) => numeral(value / 100).format("$0,0.00", Math.floor) },
        { field: "hosted_invoice_url", headerName: "Receipt", width: 100, sortable: false, renderCell: ({ value }) => <a href={value} target="_blank" >OPEN</a> },
        {
            field: "is_refund", headerClassName: 'super-app-theme--header', headerName: "Action", width: 150, sortable: false, renderCell: (params) => params.row.is_refund ?
                <>
                    <Button
                        className='btn-gen-margin'
                        variant="contained"
                        color='error'
                        disableElevation
                        onClick={() => {
                            setDelModal(true)
                            setData(params.row)
                        }}
                    >
                        <span style={{ color: "red", fontWeight: "500" }}>Refund</span>
                    </Button>
                </> : "Already Refunded"
        },
    ]
    useEffect(() => {
        if (id !== "") {
            getRefund(1, { subscription: id }).then((res) => {
                setLoading(false)
            })
        }
    }, [id])
    const onDelete = () => {
        let obj = {
            ch_id: data.charge,
            subscription: id,
            is_package: false,
            interval: data.lines.data[0].plan.interval,
            transaction_date: data.period_start,
            next_payment_date: data.period_end
        }
        if (data.productDetails.name === "Influencer Plus" || data.productDetails.name === "Brand") obj.is_package = true
        collectRefund(obj).then((res) => {
            getRefund(1, { subscription: id })
            setDelModal(false)
        }).catch((err) => {
            getRefund(1, { subscription: id })
            console.log("er", err)
        })
    }
    return (
        <div>
            <DeleteModal
                visible={delModal}
                title="Are you sure you want to Refund?"
                close={() => setDelModal(false)}
                call={onDelete}
            />
            <Modal
                open={visible}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="deleteModal modal-spacing-new" style={{ width: '70%', height: "initial" }}>
                    <Row className='heading-name-area-modal '>
                        <Col xl={12} lg={12} className="d-flex align-items-center justify-content-between">
                            <Box className="d-flex align-items-center">
                                <Typography className='text-center w-100 m-0' id="modal-modal-title" variant="h6" component="h2">
                                    {title}
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    className='custom-close-btn-moda'
                                    onClick={close}
                                    aria-label="delete" sx={{ color: "grey" }}>
                                    <CancelIcon color="error" />
                                </IconButton>
                            </Box>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} className="">
                            <Divider className='' />
                        </Col>
                    </Row>
                    {/* <div className='d-flex mb-20'> */}
                    {loading ?
                        <Skeleton /> :
                        <div style={{ height: 460, width: '100%' }}>
                            {
                                <>
                                    <DataGrid
                                        density='compact'
                                        components={{ NoRowsOverlay: NoRows, }}
                                        disableSelectionOnClick
                                        className="bg-white"
                                        loading={loading}
                                        rows={refund.length > 0 ? refund?.map((single, index) => ({
                                            ...single,
                                            id: index + (page == 1 ? 1 : (page * 10) - 9),
                                            is_refund: single.paymentIntent.charges.data[0].refunds.data.length === 0
                                        })) : []}
                                        columns={refundCol}
                                        pagination
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                    />
                                </>
                            }
                        </div>
                    }
                    {/* </div> */}
                </Box>
            </Modal>
        </div>
    );
}

function mapStateToProps({ refund }) {
    return { refund }
}
export default connect(mapStateToProps, { ...refundAction })(RefundModal)