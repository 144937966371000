import { Box, Button, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import * as feedAction from "../../store/actions/feedAction"
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const MainFeed = ({ cleanfeed, feed, feedSetting, updateFeedSetting, type, getRange }) => {
    const [load, setLoad] = useState(false)
    const [fields, setFields] = useState({
        post: 0, range: 0, is_category: false,
        follower_percent: 50,
        boosted_percent: 10,
        most_liked_percent: 10,
        most_viewed_percent: 10,
        most_shared_percent: 10,
        other_percent: 10,
    })
    const [value, setValue] = useState('')
    const [flag, setFlag] = useState(false)
    const [error, setError] = useState(false);
    const [msg, setMSG] = useState('')
    useEffect(() => {
        setLoad(true)
        feedSetting(type)
            .then(() => setLoad(false))
        return () => {
            cleanfeed()
            setValue('')
        }
    }, [])


    function submit() {
        if (maxWeight !== 100) {
            setMSG("Total Weight should be equal to 100")
            setError(true)
            return
        }
        setMSG('')
        setError(false)
        let data = {
            ...fields,
            id: feed?.message?._id
        }
        setLoad(true)
        updateFeedSetting(data, type).then((res) => {
            if (res?.success) {
                toast.success(`${res?.message}`)
                setLoad(false)
            } else {
                toast.error(`${res?.message}`)
                setLoad(false)
            }
            feedSetting(type)
        })
    }

    const feedData = useMemo(() => {
        if (feed?.success) {
            if (feed?.message) {
                setFields({
                    range: feed?.message?.date_range,
                    post: feed?.message?.posts_to_read,
                    is_category: feed?.message?.is_category ? feed?.message?.is_category : false,
                    follower_percent: feed?.message?.follower_percent,
                    boosted_percent: feed?.message?.boosted_percent,
                    most_liked_percent: feed?.message?.most_liked_percent,
                    most_viewed_percent: feed?.message?.most_viewed_percent,
                    most_shared_percent: feed?.message?.most_shared_percent,
                    other_percent: feed?.message?.other_percent,
                })
                setFlag(true)
            }
        } else {
            setFields({
                post: 0, range: 0, is_category: false,
                follower_percent: 50,
                boosted_percent: 10,
                most_liked_percent: 10,
                most_viewed_percent: 10,
                most_shared_percent: 10,
                other_percent: 10,
            })
        }
    }, [feed])

    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }

    useEffect(() => {
        if (flag) {
            handleChange(fields.range)
        }
    }, [flag])

    const handleChange = (range) => {
        if (range > 0) {
            getRange({ type, range: Number(range) }).then(res => {
                if (res?.success) {
                    setValue(`${res?.message} videos`)
                }
            })
        }
    };
    const optimizedFn = useCallback(debounce(handleChange), []);

    function addWeight(name, val) {
        if (val <= 100 && val >= 0) {
            setFields({ ...fields, [name]: val === "" ? 0 : Number(val) })
        }
    }
    const maxWeight = useMemo(() => {
        const obj = {
            follower_percent: fields.follower_percent,
            boosted_percent: fields.boosted_percent,
            most_liked_percent: fields.most_liked_percent,
            most_viewed_percent: fields.most_viewed_percent,
            most_shared_percent: fields.most_shared_percent,
            other_percent: fields.other_percent,
        }
        return Object.values(obj).reduce((acc, val) => acc += Number(val), 0)
    }, [fields])
    return (
        <div className='infoSection new mb-3' style={{ background: "#FFF", }} >
            <Box className="addons-cart-title d-flex align-items-center">
                <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                    Feed Setting
                </Typography>

            </Box>
            <Divider className='mb-20' />
            <Row>
                <Col xs={8}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.range}
                        onChange={e => {
                            let val = e.target.value;
                            if (val > 0) {
                                setValue('')
                                setFields({ ...fields, range: val })
                                optimizedFn(val)
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Range"
                        InputProps={{
                            inputProps: {
                                min: 1
                            },
                            endAdornment: <InputAdornment position="end">days</InputAdornment>,

                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
                <Col xs={4}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={value}
                        id="outlined-basic"
                        inputProps={{ readOnly: true, }}
                        label=" "
                        variant="outlined"
                        InputLabelProps={{ shrink: false }}
                        className="field-style mb-15"
                    />
                </Col>
            </Row>

            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.post}
                        onChange={e => {
                            let val = e.target.value;
                            if (val > 0) {
                                setFields({ ...fields, post: val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Number of Post"
                        InputProps={{
                            inputProps: {
                                min: 1
                            }
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>

            {/* <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.ttl}
                        onChange={e => {
                            let val = e.target.value;
                            if (val > 0) {
                                setFields({ ...fields, ttl: val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="TTL"
                        InputProps={{
                            inputProps: {
                                min: 1
                            },
                            endAdornment: <InputAdornment position="end">min</InputAdornment>,
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row> */}
            <Row>
                <Col xs={12} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel
                            id="demo-simple-select-label"
                        >Include Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fields.is_category}
                            label="Include Category"
                            onChange={(e) => {
                                let val = e.target.value
                                setFields({ ...fields, is_category: val })
                            }}
                        >
                            <MenuItem value={true}>Enable</MenuItem>
                            <MenuItem value={false}>Disable</MenuItem>
                        </Select>
                    </FormControl>
                </Col>

            </Row>
            <Divider className='mb-3' />
            <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                    <TextField
                        label=" "
                        inputProps={{ readOnly: true, }}
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        variant="outlined"
                        className="field-style mb-15"
                        value={"Follow %"}
                    />
                </Col>
                <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                    <TextField
                        label=" "
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        type='number'
                        name='follower_percent'
                        value={fields.follower_percent}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        onChange={(e) => {
                            addWeight(e.target.name, e.target.value)
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                    <TextField
                        label=" "
                        inputProps={{ readOnly: true, }}
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        variant="outlined"
                        className="field-style mb-15"
                        value={"Boosted %"}
                    />
                </Col>
                <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                    <TextField
                        label=" "
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        type='number'
                        name='boosted_percent'
                        value={fields.boosted_percent}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        onChange={(e) => {
                            addWeight(e.target.name, e.target.value)
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                    <TextField
                        label=" "
                        inputProps={{ readOnly: true, }}
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        variant="outlined"
                        className="field-style mb-15"
                        value={"Liked %"}
                    />
                </Col>
                <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                    <TextField
                        label=" "
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        type='number'
                        name='most_liked_percent'
                        value={fields.most_liked_percent}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        onChange={(e) => {
                            addWeight(e.target.name, e.target.value)
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                    <TextField
                        label=" "
                        inputProps={{ readOnly: true, }}
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        variant="outlined"
                        className="field-style mb-15"
                        value={"Views %"}
                    />
                </Col>
                <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                    <TextField
                        label=" "
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        type='number'
                        name='most_viewed_percent'
                        value={fields.most_viewed_percent}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        onChange={(e) => {
                            addWeight(e.target.name, e.target.value)
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                    <TextField
                        label=" "
                        inputProps={{ readOnly: true, }}
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        variant="outlined"
                        className="field-style mb-15"
                        value={"Shares %"}
                    />
                </Col>
                <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                    <TextField
                        label=" "
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        type='number'
                        name='most_shared_percent'
                        value={fields.most_shared_percent}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        onChange={(e) => {
                            addWeight(e.target.name, e.target.value)
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                    <TextField
                        label=" "
                        inputProps={{ readOnly: true, }}
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        variant="outlined"
                        className="field-style mb-15"
                        value={"Balance %"}
                    />
                </Col>
                <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                    <TextField
                        label=" "
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        type='number'
                        name='other_percent'
                        value={fields.other_percent}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        onChange={(e) => {
                            addWeight(e.target.name, e.target.value)
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Divider className='mb-2' />
            <Row>
                <Col className='mb-2' xs={8}>
                    <div className='d-flex justify-content-between'>
                        <div>Total</div>
                        <div>{error && <span style={{ color: "#f51313", fontSize: "0.7rem", fontWeight: "600" }}>{msg}</span>}</div>
                    </div>
                </Col>
                <Col className='mb-2 text-right' xs={4}>
                    {maxWeight}%
                </Col>
            </Row>
            <Divider className='mb-3' />
            {/* <Row>
                <Col xs={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={Number(fields.follower_percent)}
                        onChange={e => {
                            let val = Number(e.target.value);
                            if (val >= 0 && val <= 100) {
                                setFields({ ...fields, follower_percent: val, other_percent: 100 - val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Follower Percent"
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
                <Col xs={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={Number(fields.other_percent)}
                        onChange={e => {
                            let val = Number(e.target.value);
                            if (val >= 0 && val <= 100) {
                                setFields({ ...fields, other_percent: val, follower_percent: 100 - val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Other Percent"
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            },
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row> */}

            <Row>
                <Col xs={12} className='text-right mb-2'>
                    {/* <Button
                        className="btn-width btn-gen-margin grey-btn-outline h-100"
                        variant="outlined"
                        sx={{ color: 'gray', marginLeft: "0px !important" }}
                        disableElevation
                        size="medium"
                        startIcon={<RotateLeftIcon />}
                        onClick={clean}
                    >
                        Reset
                    </Button> */}
                    <Button
                        className="btn-width btn-gen-margin  mr-0 h-100"
                        disableElevation
                        size="medium"
                        variant="contained"
                        startIcon={<SaveIcon />}
                        onClick={submit}
                    >
                        Save
                    </Button>
                </Col>
            </Row>


        </div>
    )
}

const mapStateToProps = ({ feed }) => ({ feed })
export default connect(mapStateToProps, feedAction)(MainFeed)