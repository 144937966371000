import konnect from "./axios"
import { KB_COMMISSION, KB_EARNING, KB_BALANCE } from "./type"

export const getKBCommission = (val, page, fields, type, extra) => async (dispatch) => {
    try {
        let data = { ...val }
        if (fields) {
            data.orderBy = parseInt(fields.orderBy);
            data.sort = fields.sort
        }
        if (type === "download") {
            const res = await konnect.post('/v1/admin/report/kbCommission', data, { params: { page, limit: extra, } })
            return res.data
        } else {
            const res = await konnect.post('/v1/admin/report/kbCommission', data, { params: { page, limit: 12, } })
            dispatch({
                type: KB_COMMISSION,
                payload: res.data
            })
            return res.data

        }
    } catch (err) {
        console.log(err.response)
    }
}
export const getKBEarning = (val, page, fields, type, extra) => async (dispatch) => {
    try {
        let data = { ...val }
        if (fields) {
            data.orderBy = parseInt(fields.orderBy);
            data.sort = fields.sort
        }
        if (type === "download") {
            const res = await konnect.post('/v1/admin/report/kbEarning', data, { params: { page, limit: extra, } })
            return res.data
        } else {
            const res = await konnect.post('/v1/admin/report/kbEarning', data, { params: { page, limit: 12, } })
            dispatch({
                type: KB_EARNING,
                payload: res.data
            })
            return res.data
        }
    } catch (err) {
        console.log(err.response)
    }
}
export const getKBalance = (data, page) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/report/kbBalance')
        dispatch({
            type: KB_BALANCE,
            payload: res.data
        })
    } catch (err) {
        console.log(err.response)
    }
}