import konnect from "./axios"
import { GET_CASHBACK } from "./type"

export const getCashback = (type, groupBy, page, value, fields, typeCheck, extra, brandId, from, id) => async (dispatch) => {
    const url = "/v1/admin/report/cashBackReport"
    let filter;
    if (groupBy === "detail") filter = ""
    if (groupBy === "summary") {
        type === "Brand" ? filter = "brand" : type === "Customer" ? filter = "customer" : filter = "influencer"
    }
    let body = {
        orderBy: parseInt(fields.orderBy),
        sort: fields.sort,
        user_type: type === "All" ? "" : type.toLowerCase(),
        brand_id: brandId,
        id
    }
    try {
        if (typeCheck === "download") {
            const res = await konnect.post(url, body,
                {
                    params: {
                        group_by: (groupBy === "" || groupBy === "summary") ? filter : groupBy,
                        page: page,
                        limit: extra,
                        from_date: value[0],
                        to_date: value[1]
                    }
                })
            return res.data.message.data
        } else {
            try {
                const res = await konnect.post(url, body,
                    {
                        params: {
                            group_by: (groupBy === "" || groupBy === "summary") ? filter : groupBy,
                            page: page,
                            limit: 10,
                            from_date: value[0],
                            to_date: value[1]
                        }
                    })
                dispatch({
                    type: GET_CASHBACK,
                    payload: res?.data?.message
                })
                return res?.data?.message
            } catch (err) {
                console.log(err.response)
            }
        }
    } catch (err) {
        return err
    }

}

export const getCashbackByUser = (type, page, value, fields, id) => async (dispatch) => {
    const url = "/v1/admin/report/cashBackReport"
    let body = {
        orderBy: parseInt(fields.orderBy),
        sort: fields.sort,
        user_type: type === "All" ? "" : type.toLowerCase(),
        id
    }
    try {
        // if (typeCheck === "download") {
        //     const res = await konnect.post(url, body,
        //         {
        //             params: {
        //                 group_by: (groupBy === "" || groupBy === "summary") ? filter : groupBy,
        //                 page: page,
        //                 limit: extra,
        //                 from_date: value[0],
        //                 to_date: value[1]
        //             }
        //         })
        //     return res.data.message.data
        // } else {
        //     try {
        const res = await konnect.post(url, body,
            {
                params: {
                    group_by: 'detail',
                    page: page,
                    limit: 10,
                    from_date: value[0],
                    to_date: value[1]
                }
            })
        dispatch({
            type: GET_CASHBACK,
            payload: res?.data?.message
        })
        return res?.data?.message
        //     } catch (err) {
        //         console.log(err.response)
        //     }
        // }
    } catch (err) {
        return err
    }

}
export const getCashbackAccounting = (page, value, fields, typeCheck, extra,) => async (dispatch) => {
    const url = "/v1/admin/report/cashBackReport"
    let body = {
        ...fields,
        user_type: fields.user_type === "All" ? '' : fields.user_type
    }
    try {
        if (typeCheck === "download") {
            const res = await konnect.post(url, body,
                {
                    params: {
                        page: page,
                        limit: extra,
                        from_date: value[0],
                        to_date: value[1]
                    }
                })
            return res.data.message.data
        } else {
            try {
                const res = await konnect.post(url, body,
                    {
                        params: {
                            page: page,
                            limit: 10,
                            from_date: value[0],
                            to_date: value[1]
                        }
                    })
                dispatch({
                    type: GET_CASHBACK,
                    payload: res?.data?.message
                })
                return res?.data?.message
            } catch (err) {
                console.log(err.response)
            }
        }
    } catch (err) {
        return err
    }

}

