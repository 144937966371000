import konnect from "./axios"
import { VIDEO } from "./type"

export const getVideoBoost = (date, fields, page) => async (dispatch) => {
    try {
        let updated = {
            ...fields,
            status: fields.status === "all" ? "" : fields.status,
            department: fields.department === "all" ? "" : fields.department,
            from_date: date[0],
            to_date: date[1]
        }
        const res = await konnect.post(`/v1/admin/promotedVideos/getpromotedlist`, updated, { params: { page, limit: 18 } })
        dispatch({
            type: VIDEO,
            payload: res?.data
        })
    } catch (err) {
        return err?.response
    }
}
export const boostSettings = () => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/promotedVideos/getreviewandshowscost`)
        return res?.data
    } catch (err) {
        console.log('222', err?.response)
        return err?.response
    }
}
export const updateBoostSettings = (fields) => async (dispatch) => {
    try {

        const res = await konnect.post(`/v1/admin/promotedVideos/reviewandshowscost`, fields)
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
        return err?.response
    }
}