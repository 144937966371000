import konnect from "./axios"



export const getDiscover = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/filters/getDiscoverFilters`, { key: 'review' })
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
        return err?.response
    }
}

export const updateDiscover = (data) => async (dispatch) => {
    try {
        console.log("data", data)
        const res = await konnect.post(`/v1/admin/filters/addDiscoverFilter`, data)
        return res?.data
    } catch (err) {
        return err?.response
    }
}