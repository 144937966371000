import konnect from "../axios"
import { GENDER_CATEGORY, CATEGORY, SUBCATEGORY } from "../type"

//Gender Category Actions//
export const getGenderCategories = () => async (dispatch) => {
    try {
        let res = await konnect.post('/v1/admin/general/categories/getallgeneralcategories')
        dispatch({ type: GENDER_CATEGORY, payload: res.data?.message })
    } catch (err) {
        console.log(err)
    }
}
export const addGenderCategory = (data, type) => async (dispatch) => {
    let updatedBody = { name: data.category_name }
    let res = await konnect.post('/v1/admin/general/categories/creategeneralcategories', { ...updatedBody })
    return res
}
export const updateGenderCategory = (data, id) => async (dispatch) => {
    // const formBody = new FormData();
    // formBody.append('category_name', data.category_name)
    // formBody.append('image', data.file)
    let res = await konnect.put(`/v1/admin/general/categories/updategeneralcategories/${id}`, { name: data.category_name })
    return res
}
export const deleteGenderCategory = (id) => async (dispatch) => {
    let res = await konnect.delete(`/v1/admin/general/categories/${id}`)
    return res
}
export const changeStatusGeneral = (data) => async (dispatch) => {
    let res = await konnect.put(`v1/admin/general/categories/changestatus/${data}`)
    return res
}
export const sortGeneral = (data) => async (dispatch) => {
    let res = await konnect.post(`v1/admin/general/categories/updateAllPostionsGeneralCategories`, data)
    return res
}


//Category Actions//
export const getCategories = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/categories/getallcategories')
        dispatch({ type: CATEGORY, payload: res.data })
        return res.data
    } catch (err) {
        console.log(err)
    }
}
export const addCategory = (data, id) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/categories/createcategories', { name: data.category_name, gen_category_id: id })
    return res
}
export const updateCategory = (data, id) => async (dispatch) => {
    const res = await konnect.put(`/v1/admin/categories/updatecategories/${id}`, data)
    return res
}
export const deleteCategory = (id) => async (dispatch) => {
    const res = await konnect.delete(`/v1/admin/categories/${id}`)
    return res
}
export const changeStatusCategory = (data) => async (dispatch) => {
    let res = await konnect.put(`v1/admin/categories/changestatus/${data}`)
    return res
}
export const categoryByID = (gen_id) => async (dispatch) => {
    try {
        let res = await konnect.post('/v1/admin/categories/getcategoriesbygenid', { gen_id })
        return res.data
    } catch (err) {
        console.log(err)
    }
}

//Sub Category Aciton //
export const getSubCategories = () => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/subcategories/getallsubcategories')
        dispatch({ type: SUBCATEGORY, payload: res.data })
        return res.data
    } catch (err) {
        console.log(err)
    }
}
export const addSubCategory = (data) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/subcategories/createsubcategories', data)
    return res
}
export const updateSubCategory = (data, id) => async (dispatch) => {
    const res = await konnect.put(`/v1/admin/subcategories/updatesubcategories/${id}`, { name: data.category_name })
    return res
}
export const deleteSubCategory = (id) => async (dispatch) => {
    const res = await konnect.delete(`/v1/admin/subcategories/${id}`)
    return res
}
export const changeStatusSubCategory = (data) => async (dispatch) => {
    let res = await konnect.put(`v1/admin/subcategories/changestatus/${data}`)
    return res
}
export const subCategoryByID = (cat_id, gen_id) => async (dispatch) => {
    try {
        let res = await konnect.post('/v1/admin/subcategories/getSubCategoriesByCatId', { cat_id, gen_id })
        return res.data
    } catch (err) {
        console.log(err)
    }
}

//filter
export const filterCategories = (data) => async (dispatch) => {
    try {
        let res = await konnect.post('/v1/admin/crm/filter/getCategory', data)
        return res.data
    } catch (err) {
        return err.response.data
    }
}
export const filterAllCategories = (data) => async (dispatch) => {
    try {
        let res = await konnect.post('/v1/admin/crm/filter/getAllCategory', data)
        return res.data
    } catch (err) {
        return err.response.data
    }
}