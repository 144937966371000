import React, { useState, useEffect, useCallback, useMemo } from 'react'
import DataTable from "./Table"
import { Col, Row } from 'react-bootstrap'
import { Autocomplete, Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'


import moment from 'moment';
import { connect } from 'react-redux';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO, setDate } from "date-fns";
import * as agencyActions from "../../../../store/actions/agency";
import SkeletonLoader from "reUsable/skeleton";

import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";

function TotalSales({ currentUser, getTeams }) {
    const [value, setValue] = useState([
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
    ]);
    const [fields, setFields] = useState({
        agency_id: currentUser,
        name: '',
        email: '',
        phone: '',
        pixel_id: '',
        is_active: 'all',
        status: 'all'
    });
    const [loading, setLoading] = useState(false)
    const [teams, setTeams] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [load, setLoad] = useState(false)
    useEffect(() => { getData() }, []);

    function getData(type) {
        setLoading(true)
        if (type === 'reset') {
            setFields({
                agency_id: currentUser,
                name: '',
                email: '',
                phone: '',
                pixel_id: '',
                is_active: 'all',
                status: 'all'
            })
            setValue([moment().startOf("year").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")])
            getTeams({
                agency_id: currentUser,
                name: '',
                email: '',
                phone: '',
                pixel_id: '',
                is_active: 'all',
                status: 'all'
            }, [moment().startOf("year").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")], 1).then((res) => {
                if (res?.success) {
                    setTeams(res?.data)
                    setTotal(res?.total)
                    setLoading(false)
                } else {
                    setTeams([])
                    setTotal(0)
                    setLoading(false)
                }
            })
        } else {
            getTeams(fields, value, 1).then((res) => {
                if (res?.success) {
                    setTeams(res?.data)
                    setTotal(res?.total)
                    setLoading(false)
                } else {
                    setTeams([])
                    setTotal(0)
                    setLoading(false)
                }
            })
        }
       
    }
    return (
        <div className='saleContainer'>
            <Typography className="fw-normal" color="primary" variant="h6" gutterBottom component="div">Talent List</Typography>
            <Divider className="" />
            <div className="body">
                <Row className="mt-4">
                    <Col xs={12} md={12} xl={3} className="mb-20">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="dateBox">
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format("YYYY-MM-DD")]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} md={12} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.pixel_id}
                            onChange={e => setFields({ ...fields, pixel_id: e.target.value })}
                            id="outlined-basic"
                            label="PID"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={12} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.name}
                            onChange={e => setFields({ ...fields, name: e.target.value })}
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={12} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.email}
                            onChange={e => setFields({ ...fields, email: e.target.value })}
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={12} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.phone}
                            onChange={e => setFields({ ...fields, phone: e.target.value })}
                            id="outlined-basic"
                            label="Phone"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={12} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel id="demo-simple-select-label1">
                                User Status
                            </InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                labelId="User Status"
                                id="User Status"
                                value={fields.is_active}
                                label="User Status"
                                onChange={(e) =>
                                    setFields({ ...fields, is_active: e.target.value })
                                }
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Deactive</MenuItem>

                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel id="demo-simple-select-label1">
                                Status
                            </InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                labelId="Status"
                                id="Status"
                                value={fields.status}
                                label="Status"
                                onChange={(e) =>
                                    setFields({ ...fields, status: e.target.value })
                                }
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="approved">Approved</MenuItem>
                                <MenuItem value="rejected">Rejected</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} xl={3} className="mb-20">
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width laptop"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={getData}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width laptop"
                            color="success"
                            disableElevation
                            onClick={() => {
                                getData('reset')
                            }}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
            </div>
            <Divider className="separater-sale" />
            {
                loading ?
                    <div >
                        <SkeletonLoader />
                    </div>
                    :
                    <DataTable
                        page={page}
                        data={teams}
                        load={load}
                        total={total}
                        nowGet={(page) => {
                            setLoad(true)
                            getTeams(fields, value, page).then((res) => {
                                if (res?.success) {
                                    setTeams(res?.data)
                                    setTotal(res?.total)
                                    setPage(page)
                                    setLoad(false)
                                } else {
                                    setTeams([])
                                    setTotal(0)
                                    setPage(page)
                                    setLoad(false)
                                }
                            })
                        }}
                    />
            }
        </div >
    )
}
const mapStateToProps = ({ }) => ({})
export default connect(mapStateToProps, agencyActions)(TotalSales)