import konnect from "./axios"
import { REFUND } from "./type"


// export const createPromocode=(data)=>async(dispatch)=>{

//     const end_date=new Date(data.end_date);
//     const start_date=new Date(data.start_date);
//     const startTime=new Date(data.startTime);
//     const endTime=new Date(data.endTime);

//     start_date.setHours(startTime.getHours())
//     start_date.setMinutes(startTime.getMinutes())
//     end_date.setHours(endTime.getHours())
//     end_date.setMinutes(endTime.getMinutes())


//     const res=await konnect.post(`/v1/admin/PromoCode/create`,{
//         ...data,
//         start_date,
//         end_date
//     })
//     return res
// }

export const collectRefund = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/transaction/refundStripeAmount', data)
        return res;
    } catch (err) {
        return err.response
    }
}
export const cancelSubscription = (data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/transaction/cancelSubscriptions', data)
        return res;
    } catch (err) {
        return err.response
    }
}
export const getRefund = (page, data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/transaction/UserSubscriptionStripeList', data, {
            params: {
                limit: 10,
                page
            }
        })
        dispatch({
            type: REFUND,
            payload: res.data.data
        })
    } catch (err) {
        console.log(err.response)
    }
}