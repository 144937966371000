import React, { useState, useEffect, useMemo } from 'react'
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap"
import { Button, Divider, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import * as AppAction from "../../../store/actions/appSetting"
import { toast } from 'react-toastify';

function AppSetting({ appSetting, getAppSetting, updateAppSetting }) {
  const [fields, setFields] = useState({
    _id: "",
    colour: "",
    version: "",
    ios_version: ""
  })

  useEffect(() => {
    setFields({
      _id: appSetting._id,
      colour: appSetting.colour,
      version: appSetting.version,
      ios_version: appSetting.ios_version,
    })
  }, [appSetting])

  useEffect(() => {
    getAppSetting()
  }, [])

  const handleSave = () => {
    updateAppSetting(fields).then((res) => {
      if (res.status === 200) {
        toast.success(`${res.data.message}`)
      }
      getAppSetting();
    })
  }
  return (
    <div className="container-fluid mb-3">
      <Row className='pt-3'>
        <Col xs className="mb-3 d-flex align-items-center">
          <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
            App Setting
          </Typography>
        </Col>
      </Row>
      <Divider className="mb-15" />
      <Row className="d-flex justify-content-between">
        <Col xl={4} lg={6} md={12}>
          <div className='infoSection new mb-3' style={{ background: "#FFF" }} >
            <Box className="addons-cart-title d-flex align-items-center">
              <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                Versions
              </Typography>
            </Box>
            <Divider className='mb-20' />
            {/* <TextField
              size="small"
              value={fields.colour}
              onChange={e => setFields({ ...fields, colour: e.target.value })}
              id="outlined-basic"
              label="Colour"
              variant="outlined"
              className="field-style mb-15"
            /> */}
            <TextField
              size="small"
              value={fields.version}
              onChange={e => setFields({ ...fields, version: e.target.value })}
              id="outlined-basic"
              label="Android Version"
              variant="outlined"
              className="field-style mb-15"
            />
            <TextField
              size="small"
              value={fields.ios_version}
              onChange={e => setFields({ ...fields, ios_version: e.target.value })}
              id="outlined-basic"
              label="IOS Version"
              variant="outlined"
              className="field-style mb-15"
            />
            <Button
              className="btn-width btn-gen-margin grey-btn-outline h-100"
              variant="outlined"
              sx={{ color: 'gray', marginLeft:0 }}
              disableElevation
              size="medium"
              startIcon={<RotateLeftIcon />}
              onClick={() => {
                setFields({
                  _id: appSetting._id,
                  colour: appSetting.colour,
                  version: appSetting.version,
                  ios_version: appSetting.ios_version,
                })

              }}
            >
              Reset
            </Button>
            <Button
              className="btn-width btn-gen-margin ml-0 h-100"
              disableElevation
              size="medium"
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = ({ appSetting }) => {
  return { appSetting };
};

export default connect(mapStateToProps, { ...AppAction })(AppSetting);