import React, { useMemo } from 'react'
import NoRows from "../../reUsable/NoRows"
import numeral from 'numeral'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import moment from 'moment'
import Tooltip from "@mui/material/Tooltip";
import { Avatar, Backdrop, Fade, Modal, Typography, Box } from '@mui/material'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};
function VideoTable({ nowGet, data }) {
    const [page, setPage] = useState(1)
    const [pageLoad, setPageLoad] = useState(false)
    const [modal, setModal] = useState(false)
    const [image, setImage] = useState('')
    const column = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        { field: "brandname", headerName: "Brand", width: 200, sortable: false, renderCell: (params) => <span>{params?.row?.brand?.name}</span> },
        { field: "ProductSku", headerName: "SKU", width: 120, sortable: false, },

        {
            field: "general_category",
            headerName: "Department",
            width: 130,
            sortable: false,
            renderCell: ({ value }) => (value ? value : "All"),
        },
        {
            field: "category",
            headerName: "Category",
            width: 130,
            sortable: false,
            renderCell: ({ value }) => (value ? value : "All"),
        },
        {
            field: "sub_category",
            headerName: "Sub Category",
            width: 170,
            sortable: false,
            renderCell: ({ value }) => (value ? value : "All"),
        },
        {
            field: "mediaUrl", headerName: "Image", width: 120, sortable: false, renderCell: ({ value }) => <div onClick={() => {
                setImage(value)
                setModal(true)
            }} style={{ width: "100%", display: "flex", justifyContent: "center", cursor: 'pointer' }}><Avatar alt="product-img" src={value} /></div>
        },
        {
            field: "productDesc", headerName: "Description", width: 300, sortable: false, renderCell: ({ value }) => {
                return <Tooltip title={value} placement="top-start" arrow>
                    <div className='mb-0 description-limit'>{value}</div>
                </Tooltip>
            }
        },
        { field: "price", headerName: "Price", width: 150, sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor) },
        { field: "inventory", headerName: "Stock", width: 150, sortable: false, },
        {
            field: "shows", headerName: "Linked Shows", width: 150, sortable: false, renderCell: ({ value }) => {
                if (value?.length > 0) {
                    // item?.review_counter?.[0] + "-" + item?.review_counter?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-')

                    return <Tooltip title={value.map(m => {
                        if (m.event_counter) {
                            return m?.event_counter?.[0] + "-" + m?.event_counter?.split('E')[1].split('-').join('').match(/.{1,3}/g).join('-')
                        } else if (m.show_counter) {
                            return m?.show_counter?.[0] + "-" + m?.show_counter?.split('S')[1].split('-').join('').match(/.{1,3}/g).join('-')
                        } else {
                            return ""
                        }
                    }).join(', ')} placement="top-start" arrow>
                        <div className='mb-0 description-limit'>{value.map(m => {
                            if (m.event_counter) {
                                return m?.event_counter?.[0] + "-" + m?.event_counter?.split('E')[1].split('-').join('').match(/.{1,3}/g).join('-')
                            } else if (m.show_counter) {
                                return m?.show_counter?.[0] + "-" + m?.show_counter?.split('S')[1].split('-').join('').match(/.{1,3}/g).join('-')
                            } else {
                                return ""
                            }
                        }).join(', ')}</div>
                    </Tooltip>
                } else {
                    return <span style={{ color: 'gray' }}>Empty</span>
                }
            }
        },
        {
            field: "reviews", headerName: "Linked Reviews", width: 150, sortable: false, renderCell: ({ value }) => {
                if (value?.length > 0) {
                    return <Tooltip title={value.map(m => {
                        if (m.review_counter) {
                            return m?.review_counter?.[0] + "-" + m?.review_counter?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-')
                        } else {
                            return ''
                        }
                    }).join(', ')} placement="top-start" arrow>
                        <div className='mb-0 description-limit'>{value.map(m => {
                            if (m.review_counter) {
                                return m?.review_counter?.[0] + "-" + m?.review_counter?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-')
                            } else {
                                return ''
                            }
                        }).join(', ')}</div>
                    </Tooltip>
                } else {
                    return <span style={{ color: 'gray' }}>Empty</span>
                }
            }
        },
    ]
    const productsData = useMemo(() => {
        if (data) {
            setPageLoad(false)
            return data
        } else {
            return null
        }
    }, [data])


    return (
        <>
            <Modal
                open={modal}
                onClose={() => {
                    setModal(false)
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={modal} timeout={500} style={style}>
                    <img
                        src={image}
                        alt="asd"
                        style={{ maxHeight: "90%", maxWidth: "90%" }}
                    />
                </Fade>
                {/* <Box sx={style} className="deleteModal modal-spacing-new" style={{ width: '52rem', height: "initial" }}>
                </Box> */}
            </Modal>
            <DataGrid
                showCellRightBorder={true}
                rowHeight={100}
                disableColumnFilter={true}
                density="compact"
                loading={pageLoad}
                components={{ NoRowsOverlay: NoRows }}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                rows={
                    productsData?.data?.map((item, index) => ({
                        ...item,
                        id: index + (page == 1 ? 1 : (page * 8) - 7),
                        general_category: item?.gen_category?.name,
                        category: item?.category?.category_name,
                        sub_category: item?.sub_category?.sub_category_name,
                        shows: item?.events,
                    }))
                }
                columns={column}
                rowCount={productsData ? productsData?.total : 0}
                pagination
                pageSize={8}
                rowsPerPageOptions={[8]}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPageLoad(true)
                    setPage(newPage + 1);
                    nowGet(newPage + 1);
                }}
            />
        </>

    )
}
export default VideoTable