import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { connect } from 'react-redux';
import * as accActions from "../../../store/actions/access"
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Avatar, FormControl, MenuItem, Select } from '@mui/material';
import imgDefaul from "../../../assets/images/default.jpg"

import FileUploadIcon from '@mui/icons-material/FileUpload';


const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
const validationSchema = yup.object({
    first_name: yup.string('Enter your First Name').required('First Name is required'),
    last_name: yup.string('Enter your Last Name').required('Last Name is required'),
    email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    phone: yup.string('Enter your Phone Number').matches(phoneRegExp, 'Phone number is not valid').required('Phone Number is required'),
});

function AddBrand({ createHouse, id, }) {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState(null)
    const [err, setErr] = useState(false)

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            phone: "+1",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // if (!image) {
            //     setErr(true)
            //     return
            // } else {
                setLoading(true)
                createHouse(values, image)
                    .then((res) => {
                        if (res.success) {
                            toast.success(res.message, {
                                autoClose: 3000,
                                hideProgressBar: true,
                            });
                        }
                        setLoading(false)
                        setTimeout(() => {
                            history.push("/house")
                        }, 1000)
                    })
                    .catch(err => {
                        toast.error(err.message, {
                            autoClose: 3000,
                            hideProgressBar: true
                        })
                        setLoading(false)
                    })
            // }

        },
    });

    const imageLoad = (file) => {
        return new Promise((res, rej) => {
            let img = new Image();
            img.src = URL.createObjectURL(file)
            img.onload = function () {
                var width = img.naturalWidth,
                    height = img.naturalHeight;
                window.URL.revokeObjectURL(img.src);
                res({ width, height, file })
            };
        })
    }




    return (
        <div style={{ padding: 20 }}>
            <div className='row'>
                <div className='col-lg-8 col-xl-6'>
                    <Paper variant="outlined" className='box-style-new' style={{ padding: 20, borderRadius: 7 }}>
                        <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}>
                            <div>
                                <Typography className='generic-hd2' style={{ textAlign: 'left', color: "primary" }} variant="h5" gutterBottom component="div">
                                    Add House
                                </Typography>
                                <p>House onboarding</p>
                            </div>
                            <div>
                                <Avatar
                                    variant='rounded'
                                    alt="house-img"
                                    src={image ? URL.createObjectURL(image) : imgDefaul}
                                    sx={{ width: 65, height: 65 }}
                                />
                            </div>
                        </div>
                        <hr className='divider-line mb-30' style={{ marginTop: "-5px" }} />
                        <form onSubmit={formik.handleSubmit}>
                            <div >
                                <span style={{ marginBottom: "5px " }} >Basic Information</span>
                                <Row style={{ marginTop: "10px", }} >
                                    <Col md={12} lg={6} xl={6}>
                                        <TextField
                                            id="fname"
                                            name="first_name"
                                            label="First Name"
                                            size="small"
                                            className="field-style"
                                            style={styles.input}
                                            variant="outlined"
                                            value={formik.values.first_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                            helperText={formik.touched.first_name && formik.errors.first_name}
                                        />
                                    </Col>
                                    <Col md={12} lg={6} xl={6}>
                                        <TextField
                                            id="lname"
                                            name='last_name'
                                            label="Last Name"
                                            type="text"
                                            size="small"
                                            className="field-style"
                                            style={styles.input}
                                            variant="outlined"
                                            value={formik.values.last_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                            helperText={formik.touched.last_name && formik.errors.last_name}
                                        />
                                    </Col>
                                    <Col md={12} lg={6} xl={6}>
                                        <TextField
                                            id="email"
                                            name='email'
                                            label="Email"
                                            type="text"
                                            size="small"
                                            className="field-style"
                                            style={styles.input}
                                            variant="outlined"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </Col>
                                    <Col md={12} lg={6} xl={6} className='pid-field-main'>

                                        <FormControl className='field-style pid-field' size="small">
                                            <Select
                                                sx={{ color: "black" }}
                                                labelId="UserType"
                                                id="UserType"
                                                defaultValue={"US"}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                label=' '
                                                onChange={(e) => {
                                                    let c = e.target.value
                                                    if (c === "US") {
                                                        // formik.setFieldValue('phone', "+1")
                                                        formik.setFieldValue('phone', '+1')
                                                    } else if (c === "PK") {
                                                        formik.setFieldValue('phone', '+92')
                                                    }
                                                }
                                                }
                                            >
                                                <MenuItem key={"US"} value={"US"} >US</MenuItem>
                                                <MenuItem key={"PK"} value={"PK"} >PK</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            id="phone"
                                            name='phone'
                                            label="Phone"
                                            type="text"
                                            size="small"
                                            className="field-style pid-field2"
                                            style={styles.input}
                                            variant="outlined"
                                            value={formik.values.phone}
                                            onChange={formik.handleChange}

                                            // onChange={(e) => {
                                            //     // if (code === "US") {
                                            //     //     formik.setFieldValue('phone', `+1${e.target.value}`)
                                            //     // } else {
                                            //     //     formik.setFieldValue('phone', `+92${e.target.value}`)
                                            //     // }
                                            //     // }
                                            // }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                                            helperText={formik.touched.phone && formik.errors.phone}
                                        />
                                    </Col>
                                    <Col md={12} lg={6} xl={6} className='mb-3'>
                                        <Button
                                            startIcon={<FileUploadIcon />}
                                            className=' btn-gen-margin'
                                            disableElevation
                                            size="small"
                                            component="label"
                                            variant="contained">
                                            Upload Image
                                            <input
                                                type="file"
                                                hidden
                                                onChange={(e) => {
                                                    if (e.target.files[0]) {
                                                        imageLoad(e.target.files[0])
                                                            .then(({ file }) => {
                                                                setImage(file)
                                                                if(err){
                                                                    setErr(false)
                                                                }
                                                            })
                                                    }
                                                }}
                                            />
                                        </Button>
                                        {/* {err && <span style={{ color: "#d32f2f", fontSize: "0.75rem", fontWeight: "400", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif" }}>Image is required</span>} */}
                                    </Col>
                                </Row>
                            </div>
                            <div className='boxes-buttons-row2'>
                                <LoadingButton
                                    type='submit'
                                    loading={loading}
                                    variant="contained"
                                    size="medium"
                                    className="btn-gen-margin btn-width ml-0"
                                    color="primary"
                                    disableElevation
                                    endIcon={<SaveIcon />}
                                >
                                    Save
                                </LoadingButton>
                                <Button
                                    onClick={() => history.push('/house')}
                                    variant="outlined"
                                    size="medium"
                                    className="btn-gen-margin btn-width grey-btn-outline"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </form>

                    </Paper>
                </div>
            </div>


        </div >
    );
}

const styles = {
    input: {
        marginBottom: 20,
    },
    span: {
        marginTop: 5,
        marginBottom: 20
    }
}

function mapStateToProps({ }) {
    return {}
}
export default connect(mapStateToProps, { ...accActions, })(AddBrand)