import React from 'react'
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import Button from '@mui/material/Button';

export default function DateButtons({ value, setValue, setDate }) {
    return (
        <Col xs={12} md={12} lg={12} xl={12} className="mb-20">
            <Button
                variant="contained"
                size="small"
                className="btn-gen-margin btn-width ml-0 mb-2"
                color="primary"
                disabled={value[0] === moment().format('YYYY-MM-DD')}
                disableElevation
                onClick={() => {
                    let startDate = moment().format('YYYY-MM-DD')
                    setValue([startDate, startDate])
                    setDate(true);
                }}
            >
                Today
            </Button>
            <Button
                variant="contained"
                size="small"
                className="btn-gen-margin btn-width ml-2 mb-2"
                color="primary"
                disableElevation
                disabled={value[0] === moment().startOf('month').format('YYYY-MM-DD')}
                onClick={() => {
                    let startDate = moment().startOf('month').format("YYYY-MM-DD")
                    setValue([startDate, moment().format('YYYY-MM-DD')])
                    setDate(true);
                }}
            >
                MTD
            </Button>
            <Button
                variant="contained"
                size="small"
                className="btn-gen-margin btn-width ml-2 mb-2"
                color="primary"
                disabled={value[0] === moment().startOf('year').format('YYYY-MM-DD')}
                disableElevation
                onClick={() => {
                    let startDate = moment().startOf('year').format("YYYY-MM-DD")
                    setValue([startDate, moment().format('YYYY-MM-DD')])
                    setDate(true);
                }}
            >
                YTD
            </Button>
        </Col>
    )
}
