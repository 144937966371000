import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Divider } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import * as accountActions from "../../store/actions/account"
import SkeletonLoader from "reUsable/skeleton";
import * as packActions from "../../store/actions/package"
import * as countryActions from "../../store/actions/country"
import ClearIcon from '@mui/icons-material/Clear';
import DataTable from "./table";
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import NotFound from "reUsable/NotFound";
import Checkbox from '@mui/material/Checkbox';
import { columnsCustomer } from "./columns"
import { CSVLink } from 'react-csv';
import CSVButton from "../../reUsable/CSVButton"

function Accounts({ getAccount, accounts, getPackages, packages, getCountry, countries, getStates, getCity, clearAccounts, getAccountList, accountList }) {
    const fileName = "customerDetail";
    const dispatch = useDispatch()
    const [load, setLoad] = useState(true)
    const [loading, setLoading] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [search, setSearch] = useState(true)
    const [fields, setFields] = useState({
        name: "",
        email: "",
        // package_id: "all",
        status: 1,
        country: "",
        // cycle: "",
        // instagram_username: "",
        is_login_permission: "all",
        state: "",
        city: "",
        show_delete_user: false,
        account_type: "customer",
        app: "all",
        // shop: "all",
        // featured: "all",
        user_id: '',
        device: 'all',
        orderBy: -1,
        sort: "pixel_id",
        version: "",
        phone: ''

    })
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const [countryName, setCountryName] = useState("")
    const [stateName, setStateName] = useState("")
    const [submit, setSubmit] = useState(false)
    const [name, setName] = useState("")
    const [nameLoad, setNameLoad] = useState(false)
    const [header, setHeader] = useState([])
    const [data, setData] = useState([])

    useEffect(() => {
        getCountry()
        // getPackages()
        onSearch()
        return () => {
            dispatch({
                type: "clearMinimize"
            })
        }
    }, [])
    useEffect(() => {
        setHeader(columnsCustomer.map(m => ({ label: m.headerName, key: m.field })))
    }, [])

    const getData = (count) => {
        setLoad(true);
        getAccount(1, fields, "download", count ? count : accounts.count)
            .then((res) => {
                setData(res.message.map((m, i) => ({ ...m, sNo: i + 1, status: m.is_active ? "True" : "False", packageName: m.account_type, featured: m.is_featured })))
                setLoad(false)
            }).catch((err) => {
                console.log("Error: ", err);
                setLoad(false);
            })

    }

    function onSearch() {
        setSubmit(true)
        // fields.account_type && fields.country
        if (true) {
            setLoading(true)
            const upFields = { ...fields }
            if (fields.status == 2) {
                upFields.is_active = false
            }
            else if (fields.status == 1) {
                upFields.is_active = true
            } else {
                upFields.is_active = undefined;
            }
            setTableLoading(true)
            getAccount(1, upFields)
                .then((res) => {
                    setSearch(true)
                    setLoading(false)
                    setTableLoading(false)
                    // getData(res.count)
                })
        }
    }

    function renderClear() {
        return (
            <Col xs={12} md={3} xl={3} className="filter-clear-col mb-20">
                <Button
                    className="btn-width btn-gen-margin ml-0 h-100"
                    disableElevation
                    size="medium"
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={onSearch}
                >
                    Search
                </Button>
                <Button
                    className="btn-width btn-gen-margin grey-btn-outline h-100"
                    variant="outlined"
                    sx={{ color: 'gray' }}
                    startIcon={<ClearIcon />}
                    onClick={() => {
                        setSearch(false)
                        clearAccounts()
                        setNameLoad(true)
                        getAccountList('', false, fields.account_type).then(() => setNameLoad(false))
                        const payload = {
                            name: "",
                            email: "",
                            // plan: "",
                            // package_id: "all",
                            status: 1,
                            country: "",
                            // cycle: "",
                            // instagram_username: "",
                            is_login_permission: "all",
                            city: "",
                            state: "",
                            account_type: "customer",
                            show_delete_user: false,
                            app: "all",
                            // shop: "all",
                            // featured: "all",
                            user_id: '',
                            device: 'all',
                            orderBy: -1,
                            sort: "pixel_id",
                            version: "",
                            phone:''
                        }
                        setFields(payload)
                        setCountryName("")
                        setStateName("")
                        setSubmit(false)
                        setName("")
                    }}
                >
                    Clear
                </Button>
            </Col>
        )
    }


    function renderPackages() {
        return packages.map((item) => {
            return <MenuItem key={item.package_id} value={item.package_id}>{item.package_name}</MenuItem>
        })
    }

    useEffect(() => {
        setNameLoad(true)
        getAccountList('', false, fields.account_type).then(() => setNameLoad(false))
    }, [])

    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    const handleChange = (val) => {
        getAccountList(val, false, fields.account_type).then(() => setNameLoad(false))
    };
    const optimizedFn = useCallback(debounce(handleChange), []);
    function renderField() {
        return columnsCustomer.map((m, i) => {
            if (m.field !== "sNo") return <MenuItem key={i} value={m.field} >{m.headerName}</MenuItem>
        })
    }
    return (
        <React.Fragment>
            <div className='container-fluid mt-3 mb-3 pl-0'>

                <Row className='pl-15'>
                    <Col xs className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            Shoppers
                        </Typography>
                    </Col>
                </Row>
                <Row className="account-main-row pl-15">
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl
                            // error={(submit && !fields.account_type)} 
                            className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Account Type</InputLabel>
                            <Select
                                disabled
                                labelId="demo-simple-select-label1"
                                id="demo-simple-select1"
                                value={"customer"}
                                label="Account Type"
                                onChange={(e) => {
                                    setFields({ ...fields, account_type: e.target.value })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"influencer"}>Influencer</MenuItem>
                                <MenuItem value={"brand"}>Brand</MenuItem>
                                <MenuItem value={"customer"}>Shopper</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <Autocomplete
                            variant="outlined"
                            className="field-style"
                            size="small"
                            id="disable-clearable"
                            disableClearable
                            loading={nameLoad}
                            options={accountList?.message ? accountList?.message.map(opt => ({ ...opt, label: `${opt.pixel_id} - ${opt.name}` })) : []}
                            onChange={(e, data) => {
                                setFields({ ...fields, user_id: data._id })
                                // setFields({ ...fields, name: data.label })

                                setName(data.label)

                            }}
                            value={name}
                            renderInput={(params) => <TextField {...params} label="Name" onChange={(e) => {
                                setName(e.target.value)
                                setFields({ ...fields, name: e.target.value })
                                setNameLoad(true)
                                // getAccountList(e.target.value, false, fields.account_type).then(() => setNameLoad(false))
                                optimizedFn(e.target.value)
                            }} />}
                        />
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.email}
                            onChange={e => setFields({ ...fields, email: e.target.value })}
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.phone}
                            onChange={e => setFields({ ...fields, phone: e.target.value })}
                            id="outlined-basic"
                            label="Phone"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={countryName}
                            options={countries.map(it => ({ ...it, label: it.name }))}
                            onChange={(e, data) => {
                                if (data?.code1) {
                                    setCountryName(data.name)
                                    setFields({ ...fields, country: data.code1 })
                                    getStates(data.code1)
                                        .then((res) => {
                                            setStates(res.data.message)
                                        })
                                } else {
                                    setFields({ ...fields, country: "", state: "", city: "" })
                                    setCountryName("")
                                    setStateName("")
                                    setStates([])
                                }
                            }}
                            size="small"
                            className="field-style"
                            renderInput={(params) => (
                                <TextField
                                    // error={(submit && !countryName)} 
                                    {...params} label="Country" />
                            )}
                        />
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <Autocomplete
                            disabled={!fields.country}
                            disablePortal
                            value={stateName}
                            id="combo-box-demo"
                            options={states.map(it => ({ ...it, label: it.name }))}
                            onChange={(e, data) => {
                                if (data?.isoCode) {
                                    setStateName(data.name)
                                    setFields({ ...fields, state: data.isoCode })
                                    getCity({
                                        country_code: fields.country,
                                        state_code: data.isoCode
                                    }).then(res => {
                                        setCities(res.data.message)
                                    })
                                } else {
                                    setFields({ ...fields, state: "", city: "" })
                                    setStateName("")
                                    setCities([])
                                }
                            }}
                            size="small"
                            className="field-style"
                            renderInput={(params) => <TextField {...params} label="State" />}
                        />
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <Autocomplete
                            disabled={(!fields.country || !fields.state)}
                            disablePortal
                            value={fields.city}
                            id="combo-box-demo"
                            options={cities.map(it => ({ ...it, label: it.name }))}
                            onChange={(e, data) => {
                                if (data?.name) {
                                    setFields({ ...fields, city: data.name })
                                } else {
                                    setFields({ ...fields, city: "" })
                                }
                            }}
                            size="small"
                            className="field-style"
                            renderInput={(params) => <TextField {...params} label="City" />}
                        />
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Status</InputLabel>
                            <Select
                                sx={[{ color: "black" }]}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.status === "active" ? 1 : fields.status}
                                label="Status"
                                onChange={(e) => {
                                    if (e.target.value == 3) {
                                        setFields({ ...fields, status: e.target.value, show_delete_user: true })
                                    } else {
                                        setFields({ ...fields, status: e.target.value, show_delete_user: false })
                                    }
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={1}>Active</MenuItem>
                                <MenuItem value={2}>Deactive</MenuItem>
                                <MenuItem value={3}>Delete</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Device</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.device}
                                label="Device"
                                onChange={(e) => {
                                    setFields({ ...fields, device: e.target.value })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"android"}>Android</MenuItem>
                                <MenuItem value={"ios"}>IOS</MenuItem>
                                <MenuItem value={"web"}>Web</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.version}
                            onChange={e => setFields({ ...fields, version: e.target.value })}
                            id="outlined-basic"
                            label="Version"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl className='field-style' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Field</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Field"
                                labelId="field"
                                id="field"
                                value={fields.sort}
                                label="Field"
                                onChange={(e) => setFields({ ...fields, sort: e.target.value })}
                            >
                                {renderField()}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl className=' field-style' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Order By</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="order by"
                                labelId="orderBy"
                                id="orderBy"
                                value={fields.orderBy}
                                label="Order By"
                                onChange={(e) => setFields({ ...fields, orderBy: e.target.value })}
                            >
                                <MenuItem value={1}>Ascending</MenuItem>
                                <MenuItem value={-1}>Descending</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>

                    {renderClear()}
                </Row>
                <Divider className="ml-15" />
                {loading ? (
                    <div className="pl-15" style={{ marginTop: 16 }}>
                        <SkeletonLoader />
                    </div>
                ) : (
                    search ?
                        <>
                            {/* <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>

                                <CSVLink
                                    headers={header}
                                    data={data}
                                    filename={fileName}
                                    onClick={() => { }}
                                    className="btn-width h-100 custom-btn"
                                >
                                    <CSVButton load={load} />
                                </CSVLink>
                            </div> */}
                            {
                                <DataTable
                                    user="customer"
                                    tableLoading={tableLoading}
                                    nowGet={(page) => getAccount(page, fields)}
                                    accounts={accounts}
                                />
                            }
                        </>
                        : null
                )}
            </div>
        </React.Fragment>
    );
}

const styles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

const mapStateToProps = ({ accounts, packages, countries, accountList }) => {
    return { accounts, packages, countries, accountList };
};

export default connect(mapStateToProps, { ...accountActions, ...packActions, ...countryActions })(Accounts);
