import React, { useEffect, useState } from 'react'
import * as salesActions from "../../../store/actions/sales"
import { Table, Modal } from "react-bootstrap";
import { connect } from 'react-redux'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import numeral from "numeral";
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Divider } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import NotFound from "../../../reUsable/NotFound"
import Skeleton from '../../../reUsable/skeleton';
import Box from '@mui/material/Box';

import NoRows from '../../../reUsable/NoRows';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO } from 'date-fns';

import "./sale.css"

function Sales({ currentUser, sales, getSales, accountDetail, userType }) {
    const [value, setValue] = useState([moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [loading, setLoading] = useState(false)
    const [groupBy, setGroupBy] = useState('detail')
    const [search, setSearch] = useState(false)
    const [page, setPage] = useState(1);
    const [date, setDate] = useState(null)
    // var userType = accountDetail?.userDetails?.account_type;

    useEffect(() => {
        setLoading(true)
        getSales(currentUser, userType, groupBy, page, value).then(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (search || date) {
            setLoading(true)
            getSales(currentUser, userType, groupBy, page, value).then((res) => {
                setSearch(false)
                setDate(false)
                setLoading(false)
            })
        }
    }, [search, date]);

    let spendingCol = [
        {
            field: 'id', headerName: 'S.#', width: 60,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
        },
        { field: "created_date", headerName: "Date", width: 100 },
        { field: "campaign_name", headerName: "Campaign Name", width: 180, renderCell: ({ row }) => row.campaign_name.length > 0 ? row.campaign_name : "BioShop" },
        { field: "influencer_name", headerName: "Influencer Name", width: 180 },
        { field: "order_id", headerName: "Order#", width: 100 },
        { field: "total_qty", headerName: "Qty", width: 70 },
        {
            field: 'commission_paid', headerName: "Commission Paid", width: 150, renderCell: (params) => {
                let total = params?.row?.influencer_commission + params?.row?.kb_commission
                return numeral(total).format("$0,0.00", Math.floor)
            }
        },

    ]

    const handleSearch = (type) => {
        if (type === "refresh") {
            setGroupBy('summary')
        }
        setSearch(true)
    }
    const handleSelect = (e) => {
        setGroupBy(e.target.value)
    }
    return (
        <div className='saleContainer'>
            <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                Spending
            </Typography>
            <div className='body'>
                <div className='buttons-sales'>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn-gen-margin btn-width ml-0 mb-2"
                        color="primary"
                        disabled={value[0] === moment().format('YYYY-MM-DD')}
                        disableElevation
                        onClick={() => {
                            let startDate = moment().format('YYYY-MM-DD')
                            setValue([startDate, startDate])
                            setDate(true);
                        }}
                    >
                        Today
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn-gen-margin btn-width ml-2 mb-2"
                        color="primary"
                        disableElevation
                        disabled={value[0] === moment().startOf('month').format('YYYY-MM-DD')}
                        onClick={() => {
                            let startDate = moment().startOf('month').format("YYYY-MM-DD")
                            setValue([startDate, moment().format('YYYY-MM-DD')])
                            setDate(true);
                        }}
                    >
                        MTD
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn-gen-margin btn-width ml-2 mb-2"
                        color="primary"
                        disabled={value[0] === moment().startOf('year').format('YYYY-MM-DD')}
                        disableElevation
                        onClick={() => {
                            let startDate = moment().startOf('year').format("YYYY-MM-DD")
                            setValue([startDate, moment().format('YYYY-MM-DD')])
                            setDate(true);
                        }}
                    >
                        YTD
                    </Button>
                </div>
                <Row className='align-items-center'>
                    <Col xl={4} lg={12} className='mb-3'>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className='dateBox'>
                                <DatePicker
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format('YYYY-MM-DD'), value[1]]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format('YYYY-MM-DD')]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Group By</InputLabel>
                            <Select
                                disabled={true}
                                sx={{ color: "black" }}
                                defaultValue={groupBy}
                                placeholder="group by"
                                labelId="groupBy"
                                id="groupBy"
                                value={groupBy}
                                label="Group By"
                                onChange={(e) => handleSelect(e)}
                            >
                                <MenuItem value={"summary"}>Summary</MenuItem>
                                <MenuItem value={"detail"}>Detail</MenuItem>
                                <MenuItem value={"date"}>Date</MenuItem>
                                <MenuItem value={"campaign"}>Campaign</MenuItem>
                                {
                                    userType === "influencer" ?
                                        <MenuItem value={"brand"}>Brand</MenuItem> :
                                        <MenuItem value={"influencer"}>Influencer</MenuItem>
                                }
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={5} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => { handleSearch() }}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('refresh') }}
                        >
                            Refresh
                        </Button>
                    </Col>

                </Row>
                <Divider className="separater-sale" />
                <div style={{ height: 650, width: '100%' }}>
                    {
                        userType &&
                            (userType === "brand" || userType === "influencer") ?
                            <>
                                {
                                    loading ? <Skeleton /> :
                                        <DataGrid
                                            components={{ NoRowsOverlay: NoRows, }}

                                            disableSelectionOnClick
                                            className="bg-white"
                                            rows={
                                                sales?.data?.map((single, index) => ({
                                                    ...single,
                                                    id: index + 1,
                                                }))
                                            }
                                            columns={spendingCol}
                                            rowCount={sales?.total_records}
                                            pagination
                                            pageSize={10}
                                            rowsPerPageOptions={[10]}
                                            paginationMode="server"
                                            onPageChange={(newPage) => {
                                                setPage(newPage + 1);
                                                getSales(currentUser, userType, groupBy, newPage + 1, value).then(() => {
                                                    setLoading(false)
                                                })
                                            }}
                                        />
                                }
                            </>
                            :
                            <NotFound />
                    }

                </div>
            </div>
        </div >
    )
}

function mapStateToProps({ sales, accountDetail }) {
    return { sales, accountDetail }
}
export default connect(mapStateToProps, salesActions)(Sales)
