import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { CSVLink } from 'react-csv';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";
import { Col, Row } from "react-bootstrap";
import { Box, Button, Divider, InputLabel, MenuItem, FormControl, Select, TextField, Typography } from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';

import * as accountActions from "../../store/actions/account"
import DataTable from "./table";
import { deviceDetail } from "./columns"
import CSVButton from "reUsable/CSVButton"
import DateBtn from "reUsable/DateBtn";
import devicename from "reUsable/devicename"


const column = [
    {
        key: "pixel_id",
        label: "PID",
    },
    {
        key: "created_at",
        label: "Created On",
    },
    {
        key: "is_ai_search",
        label: "AI Search",
    },
    {
        key: "name",
        label: "Name",
    },

    {
        key: "status",
        label: "Status",
    },
    {
        key: "device",
        label: "Device",
    },
    {
        key: "device_detail",
        label: "Device Name",
    },
    {
        key: "device_version",
        label: "OS Version",
    },
    {
        key: "version",
        label: "Version",
    },
    {
        key: "email",
        label: "Email",
    },
    {
        key: "phone",
        label: "Phone No.",
    },
    {
        key: "otp_verified",
        label: "OTP",
    },
    {
        key: "email_verified",
        label: "Email Verified",
    },
    {
        key: "paypal_email",
        label: "Paypal",
    },
    {
        key: "shipping_addresses",
        label: "Shipping",
    }

];
function UserDetails({ getAccount, accounts, clearAccounts, type, getAccountCSV }) {
    const [loading, setLoading] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [search, setSearch] = useState(true)
    const [fields, setFields] = useState({
        status: 1,
        account_type: "influencer",
        show_delete_user: false,
        app: "all",
        user_id: '',
        device: 'all',
        orderBy: -1,
        sort: "pixel_id",
        version: "",
        phone: '',
        is_house_influencer: type === "house" ? true : false,
        is_ai_search: 'all',
        is_talent: 'all',
        email_verified: 'all',
        shipping_addresses: 'all',
        otp_verified: "all",
        paypal_email: 'all'

    })

    const [submit, setSubmit] = useState(false)

    const [value, setValue] = useState([
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
    ]);
    const [date, setDate] = useState(false);
    const [count, setCount] = useState(0)
    const [csvLoad, setCSVLoad] = useState(false)
    const [csvData, setCSVData] = useState([])
    useEffect(() => {
        onSearch()
    }, [])

    function onSearch() {
        setSubmit(true)
        setLoading(true)
        const upFields = { ...fields }
        if (fields.status == 2) {
            upFields.is_active = false
        }
        else if (fields.status == 1) {
            upFields.is_active = true
        } else {
            upFields.is_active = undefined;
        }
        setTableLoading(true)
        getAccount(1, upFields, value)
            .then((res) => {
                setCount(res?.count)
                setDate(false)
                setSearch(true)
                setLoading(false)
                setTableLoading(false)
                // getData(res.count)
            })
    }
    useEffect(() => {
        if (date) {
            onSearch()
        }
    }, [date])

    useEffect(() => {
        setCSVLoad(true)

        if (count > 0) {
            const upFields = { ...fields }
            if (fields.status == 2) {
                upFields.is_active = false
            }
            else if (fields.status == 1) {
                upFields.is_active = true
            } else {
                upFields.is_active = undefined;
            }
            getAccountCSV(upFields, value, count).then((res) => {
                console.log("res", res)
                setCSVData(res?.message?.map(item => {
                    let deviceDetail = ''
                    if (item?.device_brand) {
                        if (item?.device === 'ios') {
                            deviceDetail = `${item?.device_brand} ${devicename(item?.device_name)}`
                        } else {
                            deviceDetail = `${item?.device_brand} ${item?.device_name}`
                        }
                    }
                    return {
                        ...item,
                        created_at: moment(item.created_at).format('MM-DD-YYYY'),
                        is_ai_search: item?.is_ai_search ? "On" : "Off",
                        status: item?.deleted ? "Deleted" : item?.is_active ? "Active" : "Deactive",
                        device: item?.device ? item?.device.charAt(0).toUpperCase() + item?.device.slice(1) : "Web",
                        device_detail: deviceDetail,
                        otp_verified: item?.otp_verified ? "Verified" : "Not Verified",
                        email_verified: item?.email_verified ? "Verified" : "Not Verified",
                        paypal_email: item?.paypal_email ? "Connected" : "Not Connected",
                        shipping_addresses: item?.shipping_addresses ? "Connected" : "Not Connected",
                    }
                }))
                setCSVLoad(false)
            })
        } else {
            setCSVData([])
            setCSVLoad(false)
        }
    }, [count])

    function renderClear() {
        return (
            <Col xs={12} md={12} lg={6} xl={3} className="filter-clear-col mb-20">
                <Button
                    className="btn-width btn-gen-margin laptop ml-0"
                    disableElevation
                    size="medium"
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={onSearch}
                >
                    Search
                </Button>
                <Button
                    className="btn-width btn-gen-margin grey-btn-outline laptop"
                    variant="outlined"
                    sx={{ color: 'gray' }}
                    startIcon={<ClearIcon />}
                    onClick={() => {
                        setSearch(false)
                        clearAccounts()
                        const payload = {
                            status: 1,
                            account_type: "influencer",
                            show_delete_user: false,
                            app: "all",
                            user_id: '',
                            device: 'all',
                            orderBy: -1,
                            sort: "pixel_id",
                            version: "",
                            phone: '',
                            is_house_influencer: type === "house" ? true : false,
                            is_ai_search: 'all',
                            is_talent: 'all',
                            email_verified: 'all',
                            shipping_addresses: 'all',
                            otp_verified: "all",
                            paypal_email: 'all'
                        }
                        setFields(payload)
                        setSubmit(false)
                        ///
                        setLoading(true)
                        const upFields = { ...payload }
                        if (payload.status == 2) {
                            upFields.is_active = false
                        }
                        else if (payload.status == 1) {
                            upFields.is_active = true
                        } else {
                            upFields.is_active = undefined;
                        }
                        setTableLoading(true)
                        setValue([moment().format("YYYY-MM-DD"),
                        moment().format("YYYY-MM-DD"),])
                        getAccount(1, upFields, [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),])
                            .then((res) => {
                                setDate(false)
                                setSearch(true)
                                setLoading(false)
                                setTableLoading(false)
                                // getData(res.count)
                            })
                    }}
                >
                    Clear
                </Button>
            </Col>
        )
    }

    function renderField() {
        return deviceDetail.map((m, i) => {
            if (m.field !== "sNo") return <MenuItem key={i} value={m.field} >{m.headerName}</MenuItem>
        })
    }
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    return (
        <React.Fragment>
            <div className='container-fluid mt-3 mb-3 pl-0'>

                <Row className='pl-15'>
                    <Col xs className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            User Details
                        </Typography>
                    </Col>
                    <Col xs className="mb-3 d-flex justify-content-end align-items-center">
                        {
                            type !== 'house' &&
                            <>
                                <div style={{}}>
                                    {
                                        csvLoad ?
                                            <Button
                                                disabled
                                                className='btn-width'
                                                startIcon={<DownloadIcon />}
                                                disableElevation
                                                variant="contained">
                                                Loading...
                                            </Button>
                                            :
                                            <CSVLink
                                                headers={column}
                                                data={csvData}
                                                asyncOnClick={true}
                                                className="btn-width h-100 custom-btn"
                                                onClick={() => { }}
                                                filename={'users'}
                                            >
                                                <CSVButton load={csvLoad} />
                                            </CSVLink>
                                    }

                                </div>


                            </>
                        }
                    </Col>

                </Row>
                <DateBtn value={value} setValue={(data) => {
                    setValue(data)
                }} setDate={setDate} />
                <Row className="account-main-row pl-15">
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="dateBox">
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format("YYYY-MM-DD")]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Status</InputLabel>
                            <Select
                                sx={[{ color: "black" }]}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.status === "active" ? 1 : fields.status}
                                label="Status"
                                onChange={(e) => {
                                    if (e.target.value == 3) {
                                        setFields({ ...fields, status: e.target.value, show_delete_user: true })
                                    } else {
                                        setFields({ ...fields, status: e.target.value, show_delete_user: false })
                                    }
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={1}>Active</MenuItem>
                                <MenuItem value={2}>Deactive</MenuItem>
                                <MenuItem value={3}>Delete</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>

                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Device</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.device}
                                label="Device"
                                onChange={(e) => {
                                    setFields({ ...fields, device: e.target.value })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"android"}>Android</MenuItem>
                                <MenuItem value={"ios"}>IOS</MenuItem>
                                <MenuItem value={"web"}>Web</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.version}
                            onChange={e => setFields({ ...fields, version: e.target.value })}
                            id="outlined-basic"
                            label="Version"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Email Verified</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.email_verified}
                                label="Email Verified"
                                onChange={(e) => {
                                    setFields({ ...fields, email_verified: e.target.value })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={true}>Verified</MenuItem>
                                <MenuItem value={false}>Not Verified</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Paypal</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.paypal_email}
                                label="Paypal"
                                onChange={(e) => {
                                    setFields({ ...fields, paypal_email: e.target.value })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={true}>Connected</MenuItem>
                                <MenuItem value={false}>Not Connected</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Shipping</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.shipping_addresses}
                                label="Shipping"
                                onChange={(e) => {
                                    setFields({ ...fields, shipping_addresses: e.target.value })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={true}>Connected</MenuItem>
                                <MenuItem value={false}>Not Connected</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >OTP</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.otp_verified}
                                label="OTP"
                                onChange={(e) => {
                                    setFields({ ...fields, otp_verified: e.target.value })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={true}>Verified</MenuItem>
                                <MenuItem value={false}>Not Verified</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className='field-style' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Field</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Field"
                                labelId="field"
                                id="field"
                                value={fields.sort}
                                label="Field"
                                onChange={(e) => setFields({ ...fields, sort: e.target.value })}
                            >
                                {renderField()}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className=' field-style' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Order By</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="order by"
                                labelId="orderBy"
                                id="orderBy"
                                value={fields.orderBy}
                                label="Order By"
                                onChange={(e) => setFields({ ...fields, orderBy: e.target.value })}
                            >
                                <MenuItem value={1}>Ascending</MenuItem>
                                <MenuItem value={-1}>Descending</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    {renderClear()}
                </Row>
                <Divider className="ml-15" />
                Data
                {/* <DataTable
                    fields={fields}
                    date={date}
                    timezone={timezone}
                    userTypes={type}
                    user="customer"
                    tableLoading={tableLoading}
                    nowGet={(page) => {
                        setTableLoading(true)
                        getAccount(page, fields, value).then(() => setTableLoading(false))
                    }}
                    accounts={accounts}
                    search={search}
                    loadTable={loading}
                /> */}
            </div>
        </React.Fragment>
    );
}

const styles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

const mapStateToProps = ({ accounts, packages, }) => {
    return { accounts, packages, };
};

export default connect(mapStateToProps, { ...accountActions, })(UserDetails);
