import React, { useState } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { GoVerified } from "react-icons/go";
import { ImCross } from "react-icons/im";
import Switch from '@mui/material/Switch';
import CardHeader from '@mui/material/CardHeader';
import Tooltip from '@mui/material/Tooltip';


export default function Box({ item, changeStatus, reload }) {

    const [check, setCheck] = useState(item.is_active)

    function renderVerify(verify) {
        if (verify) {
            return <GoVerified style={{ marginLeft: 10 }} color="green" size={18} />
        } else {
            return <ImCross style={{ marginLeft: 10 }} color="red" size={14} />
        }
    }

    return (
        <Card variant="outlined" className='country-card-main'>
            <CardHeader
                className='category-header'
                action={
                    <Switch
                        size="small"
                        color="success"
                        variant="contained"
                        disableElevation
                        checked={check}
                        onChange={(e) => {
                            setCheck(e.target.checked)
                            changeStatus(item.code1, e.target.checked).then(() => {
                                reload()
                            })
                        }} />
                }
                title={
                    <Tooltip title={item?.name} placement="left" arrow>
                        <Typography gutterBottom variant="body1" component="div">
                            {item.name.length > 18 ? item.name.slice(0, 18) + "..." : item.name}
                        </Typography>
                    </Tooltip>
                }
            // subheader={<Typography>Active: {renderVerify(verify)}</Typography>}
            />

            <div className='country-flag-box'>
                <img className='country-flag'
                    src={item.flag}
                    alt="green iguana"
                />
            </div>
            {/* <CardContent>

                <div>
                    Active: {renderVerify(item.is_active)}
                    <Switch
                        size="small"
                        color="success"
                        checked={check}
                        onChange={(e) => {
                            setCheck(e.target.checked)
                            changeStatus(item.code1, e.target.checked).then(() => {
                                reload()
                            })
                        }} />
                </div>
            </CardContent> */}
        </Card>
    )
}
