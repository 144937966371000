import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap"
import { Button, Divider, InputAdornment, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import * as accountAction from "../../store/actions/account"
import { toast } from 'react-toastify';
import SaveModal from "../../reUsable/SaveModal";
import SearchBrand from "reUsable/SearchBrand";

const urlLive = 'https://www.manage.ormelive.com/'
const urlDev = 'https://www.managedev.ormelive.com/'

function AdminControl({ getBrandToken, changeBrandPassword }) {
    const [load, setLoad] = useState(false)
    const [modal, setModal] = useState(false)
    const [passModal, setPassModal] = useState(false)
    const [fields, setFields] = useState({ brand_id: '' })
    const [data, setData] = useState({ brand_id: '', password: '' })

    const handleSave = () => {
        getBrandToken(fields).then((res) => {
            if (res.success) {
                const link = process.env.REACT_APP_BASE_URL.includes('backendlive') ? urlLive : urlDev
                window.open(`${link}auth/${res?.message}`, "_blank")
            } else {
                console.log("Err", res)

            }
        })
        setModal(false)
        setLoad(false)
    }
    const changePassword = () => {
        console.log(data, passModal)

        changeBrandPassword(data).then((res) => {
            if (res.success) {
                toast.success(res.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
                setData({ brand_id: '', password: '' })
                setPassModal(false)
            } else {
                console.log("Err", res)

            }
        }).catch(err => {
            toast.error(err.message, {
                autoClose: 3000,
                hideProgressBar: true
            })
            setData({ brand_id: '', password: '' })
            setPassModal(false)
        })
    }


    return (
        <div className="container-fluid mb-3 pt-3">
            <SaveModal
                visible={modal}
                title={`Are you sure you want to redirect to Manage?`}
                close={() => {
                    setLoad(false)
                    setModal(false)
                }}
                type="status"
                call={() => handleSave()}
            />
            <SaveModal
                visible={passModal}
                title={`Are you sure you want to change the password?`}
                close={() => {
                    setData({ ...data, password: '' })
                    setPassModal(false)
                }}
                type="status"
                call={() => changePassword()}
            />
            <Row className="d-flex justify-content-between">
                <Col xl={5} lg={5} md={12}>
                    <div className='infoSection new mb-3 ' style={{ background: "#FFF" }} >
                        <Box className="addons-cart-title d-flex align-items-center">
                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                Admin Control
                            </Typography>
                        </Box>
                        <Divider className='mb-20' />
                        <div className='mb-3'>
                            <SearchBrand setter={(value) => setFields({ ...fields, brand_id: value })} brand={fields.brand_id} />
                        </div>
                        <div className='d-flex justify-content-end'>
                            <Button
                                className="btn-width btn-gen-margin grey-btn-outline  laptop ml-0 mb-3"
                                variant="outlined"
                                sx={{ color: 'gray' }}
                                // className="btn-width btn-gen-margin ml-0 h-100"
                                disableElevation
                                size="medium"
                                onClick={() => {
                                    setFields({ brand_id: '' })
                                }}
                            >
                                Clear
                            </Button>
                            <Button
                                disabled={load || fields.brand_id === ""}
                                className="btn-width btn-gen-margin ml-0 h-100 mb-3"
                                disableElevation
                                size="medium"
                                variant="contained"
                                onClick={() => {
                                    setLoad(true)
                                    setModal(true)
                                }}
                            >
                                Goto Manage
                            </Button>
                        </div>


                    </div>
                </Col>

            </Row>
            <Row className="d-flex justify-content-between">
                <Col xl={5} lg={5} md={12}>
                    <div className='infoSection new mb-3 ' style={{ background: "#FFF" }} >
                        <Box className="addons-cart-title d-flex align-items-center">
                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                Change Password (Brand)
                            </Typography>
                        </Box>
                        <Divider className='mb-20' />
                        <div className='mb-3'>
                            <SearchBrand setter={(value) => setData({ ...data, brand_id: value })} brand={data.brand_id} />
                        </div>
                        <div className='mb-3'>
                            <TextField
                                autoCorrect={false}
                                defaultValue={''}
                                label="Password"
                                size="small"
                                // type='password'
                                name='password'
                                value={data.password}
                                onChange={(e) => {
                                    setData({ ...data, password: e.target.value })
                                }}
                                variant="outlined"
                                className="field-style mb-15"
                            />
                        </div>
                        <div className='d-flex justify-content-end'>
                            <Button
                                disabled={data.brand_id === "" || data.password === ""}
                                className="btn-width btn-gen-margin ml-0 h-100 mb-3"
                                disableElevation
                                size="medium"
                                variant="contained"
                                onClick={() => {
                                    setPassModal(true)
                                }}
                            >
                                Change
                            </Button>
                        </div>


                    </div>
                </Col>

            </Row>
        </div>
    )
}

const mapStateToProps = ({ }) => {
    return {};
};

export default connect(mapStateToProps, accountAction)(AdminControl);