import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import * as agencyActions from "../../../store/actions/agency"

import { connect, } from 'react-redux'
import { InputLabel, FormControl, Select, TextField, Button, MenuItem, Typography, Divider, Box, Autocomplete } from '@mui/material'

import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import numeral from "numeral";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Col, Row } from "react-bootstrap";
import NoRows from "../../../reUsable/NoRows"
import Skeleton from '../../../reUsable/skeleton';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO } from 'date-fns';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faPercent } from "@fortawesome/free-solid-svg-icons";
import "./sale.css"


function Earnings({ currentUser, getAgencyEarnings }) {
    const [value, setValue] = useState([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState(false)
    const [data, setData] = useState(null)


    useEffect(() => { getData() }, [])
    useEffect(() => {
        if (search) { getData() }
    }, [search]);

    const getData = () => {
        getAgencyEarnings(currentUser, value).then((res) => {
            if (res?.success) {
                setData(res)
            } else {
                setData(null)
            }
        }).finally(() => {
            setSearch(false)
            setLoading(false)
        })
    }
    const handleSearch = (type) => {
        if (type === "refresh") {
            setValue([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
            setLoading(true)
            setSearch(true)
        } else {
            setLoading(true)
            setSearch(true)
        }
    }

    const records = useMemo(() => {
        if (data) {
            let obj = {
                lifetime: {
                    creatorRef: data?.message?.lifetime_earning?.[0]?.ref_agency_commission,
                    creatorFee: data?.message?.lifetime_earning?.[0]?.creator_agency_commission,
                },
                pending: {
                    creatorRef: data?.message?.pending_balance?.[0]?.ref_agency_commission,
                    creatorFee: data?.message?.pending_balance?.[0]?.creator_agency_commission,
                },
                available: (data?.message?.available_to_withdraw?.[0]?.creator_agency_commission + data?.message?.available_to_withdraw?.[0]?.ref_agency_commission) - data?.message?.withdraw_amount,
                withdraw_amount: data?.message?.withdraw_amount
            }
            return obj
        }
    }, [data])
    console.log("rec", records)
    return (
        <div className='saleContainer'>
            <Typography className='fw-normal' color="primary" variant="h6" gutterBottom component="div">
                Earnings
            </Typography>
            <Divider className='' />

            <div className='body'>
                <Row className='mt-4'>
                    <Col xl={3} lg={12} className='mb-3 '>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className='dateBox'>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            helperText: 'YYYY / MM / DD',
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format('YYYY-MM-DD'), value[1]]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            helperText: 'YYYY / MM / DD',
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format('YYYY-MM-DD')]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width laptop"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => { handleSearch() }}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width laptop"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('refresh') }}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
                <Divider className="separater-sale" />
                <div className='container ml-0 p-0 mt-3'>
                    <div class='earningTitle'>Lifetime Earnings</div>
                    <div class="row">
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : records?.lifetime?.creatorFee).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">TOTAL CREATOR FEE</div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : records?.lifetime?.creatorRef).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">TOTAL CREATOR REFERRAL</div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : records?.lifetime?.creatorFee + records?.lifetime?.creatorRef).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">TOTAL EARNINGS</div>
                            </div>
                        </div>
                    </div>
                    <div class='earningTitle'>Pending Balance</div>
                    <div class="row">
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : records?.pending?.creatorFee).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">TOTAL CREATOR FEE</div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : records?.pending?.creatorRef).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">TOTAL CREATOR REFERRAL</div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : records?.pending?.creatorFee + records?.pending?.creatorRef).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">TOTAL EARNINGS</div>
                            </div>
                        </div>
                    </div>
                    <div class='earningTitle'>Total Withdrawals</div>
                    <div class="row">
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faPercent}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : (records?.withdraw_amount)).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">TOTAL WITHDRAWALS</div>
                            </div>
                        </div>
                    </div>
                    <div class='earningTitle'>Available to Withdraw</div>
                    <div class="row">
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faPercent}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : (records?.available)).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">AVAILABLE TO WITHDRAW</div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div >
    )
}

function mapStateToProps({ }) {
    return {}
}
export default connect(mapStateToProps, agencyActions)(Earnings)

