import konnect from "./axios"
import { GET_ADMIN_LOGS, GET_LOGS, APP_LOGS, APP_USER_LOGS } from "./type"




export const getLogs = (page, fields, date, from) => async (dispatch) => {
    try {
        const url = from === "admin" ? "/v1/admin/adminLogs/getAll" : "/v1/admin/logs/getLogs"
        const filtered = fields?.action?.filter(f => {
            if (f.label !== "All") {
                return f.label
            }
        })
        let obj = {
            from: date[0],
            to: date[1],
            ...fields,
            action: filtered?.map(i => i.label)
        }
        const res = await konnect.post(url, obj, {
            params: {
                limit: 18,
                page
            }
        })
        dispatch({
            type: GET_LOGS,
            payload: res?.data?.data
        })
    } catch (err) {
        console.log(err.response)
    }
}

export const getAdminLogs = (page, data) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/adminActivityLog', data, {
            params: {
                limit: 10,
                page
            }
        })
        dispatch({
            type: GET_ADMIN_LOGS,
            payload: res.data
        })
    } catch (err) {
        console.log(err.response)
    }
}

export const getAppLogs = (page, data, value) => async (dispatch) => {
    try {
        let updatedData = {
            ...data,
            from_date: value[0],
            to_date: value[1]
        }
        // const res = await konnect.post('/v1/admin/report/getMobileActivityLogs', updatedData, {
        const res = await konnect.post('/v1/admin/report/getMobileParentActivityLogs', updatedData, {
            params: {
                limit: 10,
                page
            }
        })
        dispatch({
            type: APP_LOGS,
            payload: res.data
        })
    } catch (err) {
        console.log(err.response)
    }
}
export const getAppLogsUser = (page, data, userData) => async (dispatch) => {
    try {
        let updatedData = {
            ...data,
            from_date: userData.date,
            to_date: userData.date,
            user_id: userData.id

        }
        const res = await konnect.post('/v1/admin/report/getMobileActivityLogs', updatedData, {
            params: {
                limit: 10,
                page
            }
        })
        dispatch({
            type: APP_USER_LOGS,
            payload: res.data
        })
    } catch (err) {
        console.log(err.response)
    }
}