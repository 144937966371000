import konnect from "./axios"



export const getUserFilters = () => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/filters/getAllUserFilters`)
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
        return err?.response
    }
}

export const setUserFilters = (fields) => async (dispatch) => {
    try {
        let data = {
            usershop_filter: { ...fields,
                days_trending: Number(fields.days_trending),
                days_top: Number(fields.days_top),
             }
        }
        const res = await konnect.post(`/v1/admin/filters/addUserFilter`, data)
        return res?.data
    } catch (err) {
        return err?.response
    }
}