import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';
import { parseISO } from "date-fns";
import { Col, Row } from 'react-bootstrap'
import { Autocomplete, Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SkeletonLoader from "reUsable/skeleton";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import *  as feedActions from 'store/actions/feedAction'
import *  as commentsActions from 'store/actions/comments'
import SearchBrand from 'reUsable/SearchBrand';
import DataTable from "./Table"
import ConfirmModal from 'reUsable/ConfirmModal';
import SaveModal from "reUsable/SaveModal"
import DateBtn from 'reUsable/DateBtn';
import { toast } from 'react-toastify';
export const Comments = ({ feedUsers, comments, getComments, deleteComment, getFeedUsers, banUser }) => {
    const [value, setValue] = useState([moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]);
    const [fields, setFields] = useState({ pixel_id: '', brand_id: '', deleted: false, type: 'review' })
    const [name, setName] = useState('')
    const [nameLoad, setNameLoad] = useState(true)
    const [type, setType] = useState('name')
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [data, setData] = useState(null)
    const [date, setDate] = useState(false)
    const [flag, setFlag] = useState(false)
    const [section, setSection] = useState('review')
    useEffect(() => {
        getFeedUsers({ pixel_id: '', search_type: type }).then(() => setNameLoad(false))
        getData(fields, value)
    }, [])

    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    const handleChange = (val, t) => {
        if (val === "") {
            setFields({ ...fields, pixel_id: '', deleted: false, type: 'review' })
            setNameLoad(false)
            return
        }
        getFeedUsers({ pixel_id: val, search_type: t }).then(() => setNameLoad(false))
    };
    const optimizedFn = useCallback(debounce(handleChange), []);

    function handleSearch(type) {
        if (type === "refresh") {
            setValue([moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),])
            setFields({ pixel_id: '', brand_id: '', deleted: false, type: 'review' })
            getData({ pixel_id: '', brand_id: '', deleted: false, type: 'review' }, [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),])
            setName('')
            setType('name')
        } else {
            getData(fields, value)
        }
    }

    const getData = (obj, date) => {
        setLoading(true)
        getComments({ ...obj, to: date[1], from: date[0] }, 1).then(() => {
            setDate(false)
            setFlag(fields.deleted)
            setSection(fields.type)
            setLoading(false)
        })
    }

    function onDelete() {
        console.log(data)
        setModal(false)
        deleteComment(data.chat_id, section).then((res) => {
            if (res.success) {
                toast.success(res?.message)
            }
            getData({ ...fields }, value)
        })
    }

    useEffect(() => {
        if (date) {
            getData({ ...fields }, value)
        }
    }, [date])


    function userban(data) {
        if (data.account_type === "influencer") {
            const obj = {
                user_id: data.user_id,
                reason: data.reason,
                ban_type: data.ban_type,
                // review_id: data.review_id
            }
            if (data.ban_type === "chatban") {
                if (section === 'review') {
                    obj.review_id = data.review_id
                } else if (section === 'event') {
                    obj.event_id = data.event_id
                }
            }
            banUser(obj).then((res) => {
                if (res.success) {
                    toast.success(res?.message)
                }
                // getData(fields, value)
            })
        }
    }

    return (
        <div className='container-fluid mt-3 mb-3 pl-0'>
            <SaveModal
                visible={modal}
                title={`Are you sure you want to delete this comment?`}
                close={() => {
                    setData(null)
                    setModal(false)
                }}
                type="status"
                call={onDelete}
            />

            <Row className='pl-15'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Comments
                    </Typography>
                </Col>
            </Row>
            <DateBtn value={value} setValue={(data) => {
                setValue(data)
            }} setDate={setDate} />
            <Row className="account-main-row pl-15">
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="dateBox">
                            <DatePicker
                                minDate={new Date(2023, 0, 1)}
                                slotProps={{ textField: { helperText: "YYYY / MM / DD" } }}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="Start Date"
                                value={parseISO(value[0])}
                                onChange={(newValue) => { setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]) }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style"
                                        size="small"
                                        style={{ backgroundColor: "white", width: "50%" }}
                                        {...params}
                                    />
                                )}
                            />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <DatePicker
                                minDate={new Date(2023, 0, 1)}
                                slotProps={{ textField: { helperText: "YYYY / MM / DD" } }}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="End Date"
                                value={parseISO(value[1])}
                                onChange={(newValue) => { setValue([value[0], moment(newValue).format("YYYY-MM-DD")]) }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style"
                                        size="small"
                                        style={{ backgroundColor: "white", width: "50%" }}
                                        {...params}
                                    />
                                )}
                            />
                        </div>
                    </LocalizationProvider>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <SearchBrand setter={(value) => {
                            setFields({ ...fields, brand_id: value })
                        }} brand={fields.brand_id} />

                    </FormControl>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className='mb-3 pid-field-main'>
                    <FormControl className='field-style pid-field' size="small" style={{ width: '134px' }}>
                        <Select
                            sx={{ color: "black" }}
                            labelId="UserType"
                            id="UserType"
                            value={type}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            label=' '
                            onChange={(e) => { setName(''); setType(e.target.value); }}
                        >
                            <MenuItem key={"pixel_id"} value={"pixel_id"} >PID</MenuItem>
                            <MenuItem key={"name"} value={"name"} >Name</MenuItem>
                        </Select>
                    </FormControl>
                    <Autocomplete
                        variant="outlined"
                        className="field-style pid-field2"
                        size="small"
                        id="outlined-basic"
                        disableClearable
                        loading={nameLoad}
                        options={feedUsers?.data ? feedUsers?.data.map(opt => ({ ...opt, label: `${opt.pixel_id} - ${type == "name" ? opt.name : opt.email}` })) : []}
                        onChange={(e, data) => {
                            setName(data?.label)
                            setFields({ ...fields, pixel_id: data.pixel_id })
                        }}
                        value={name}
                        renderInput={(params) => <TextField {...params} label="User" onChange={(e) => {
                            setNameLoad(true)
                            optimizedFn(e.target.value, type)
                        }} />}
                    />
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel
                            id="demo-simple-select-label"
                        >Type</InputLabel>
                        <Select
                            sx={[{ color: "black" }]}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fields.type}
                            label="Type"
                            onChange={(e) => {
                                setFields({ ...fields, type: e.target.value, })
                            }}
                        >
                            <MenuItem value={'review'}>Review</MenuItem>
                            <MenuItem value={'event'}>Shows</MenuItem>
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel
                            id="demo-simple-select-label"
                        >Status</InputLabel>
                        <Select
                            sx={[{ color: "black" }]}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fields.deleted}
                            label="Status"
                            onChange={(e) => {
                                setFields({ ...fields, deleted: e.target.value, })
                            }}
                        >
                            <MenuItem value={false}>Default</MenuItem>
                            <MenuItem value={true}>Deleted</MenuItem>
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <Button
                        variant="contained"
                        size="medium"
                        className="btn-gen-margin btn-width laptop ml-0"
                        color="primary"
                        startIcon={<SearchIcon />}
                        disableElevation
                        onClick={() => { handleSearch() }}
                    >
                        Search
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        startIcon={<RefreshIcon />}
                        className="btn-gen-margin btn-width laptop"
                        color="success"
                        disableElevation
                        onClick={() => { handleSearch('refresh') }}
                    >
                        Refresh
                    </Button>
                </Col>
            </Row>
            <Divider className="ml-15" />
            {loading ? (
                <div className="pl-15" style={{ marginTop: 16 }}>
                    <SkeletonLoader />
                </div>
            ) : (
                <DataTable
                    section={section}
                    userban={userban}
                    flag={flag}
                    data={comments}
                    nowGet={(page) => {
                        getComments({ ...fields, to: value[1], from: value[0] }, page)
                    }}
                    onDelete={(data) => {
                        setData(data)
                        setModal(true)
                    }}
                />


            )}
        </div>
    )
}

const mapStateToProps = ({ feedUsers, comments }) => ({ feedUsers, comments })
export default connect(mapStateToProps, { ...feedActions, ...commentsActions })(Comments)