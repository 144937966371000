import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card, Col, Row } from "react-bootstrap"
import { Box, CardHeader, Checkbox, Divider, TextField, Typography } from "@mui/material";
import * as accountActions from "../../../store/actions/account"
import { CheckBox } from "@mui/icons-material";
import numeral from "numeral";



function Discounts({ getBrandDiscounts, currentUser, }) {
    const [data, setData] = useState([])
    useEffect(() => {
        getBrandDiscounts(currentUser).then((res) => {
            if (res.success) {
                setData(res?.message?.data)
            }
        })
    }, [])
    function getDuration(value) {

        const startDate = new Date(value.start_date);
        const endDate = new Date(value.end_date);
        const differenceMs = endDate.getTime() - startDate.getTime();
        const numberOfDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
        return <span style={{ fontWeight: "500" }} >
            {value.start_date} <span style={{ fontWeight: "400" }}>to</span> {value.end_date}  ({numberOfDays} days)
        </span>

    }
    return (
        <div className='saleContainer'>
            <Row className='align-items-center'>
                <Col xl={12} lg={12}>
                    <Card variant="outlined" className='users-card-main' style={{ minHeight: 'initial' }} sx={{ minWidth: 275 }}>
                        <Typography className='title-bar-hd-main mb-1' color="primary" variant="h5" gutterBottom component="div">Discounts </Typography>
                        <Divider className='mb-4' />
                        {
                            data?.length == 0 ? <div className="fw-bold ml-2">No Data</div> :
                                <div className='container-fluid ml-0 p-0 shipping-new'>
                                    <Row className="pt-3">
                                        {
                                            data?.map(item => (
                                                <Col key={item?._id} md={12} lg={12} xl={4} className='mb-3' >
                                                    <Card className={item?.active ? "active-discount" : ""} sx={{ display: 'flex' }} key={item?._id}>
                                                        <CardHeader className='category-header' />
                                                        <Box className='px-3'>
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line'>
                                                                        <div className='ship-info-hd'>Discount Code: </div> <div className='ship-info-hd text-right'>{item?.name}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line'>
                                                                        <div className='ship-info-hd'>Discount Percent: </div> <div className="text-right">{item?.discount}%</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line '>
                                                                        <div className='ship-info-hd'>Duration:</div>
                                                                        <div className="text-right">{getDuration(item)}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line '>
                                                                        <div className='ship-info-hd'>MarkDowns:</div>
                                                                        <div className="text-right">{item?.discounted_product_marked ? "Yes" : "No"}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line no-border'>
                                                                        <div className='ship-info-hd'>Discount Status:</div>
                                                                        <div className="text-right">{item?.active ? <span style={{ color: 'green' }}>Activated</span> : "De Activated"}</div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Box>
                                                    </Card>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                    {/* <Row className='pt-3' >
                                {userType === "brand" ?
                                    <>
                                        {data?.map(item => (
                                            <Col key={item?._id} md={12} lg={12} className='mb-3' >
                                                <Card sx={{ display: 'flex' }} key={item?._id}>
                                                    <CardHeader className='category-header' />
                                                    <Box className='px-3'>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <div className='shipping-info-line'>
                                                                    <div className='ship-info-hd'>Name: </div> <div className='ship-info-hd text-right'>{item?.name}</div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <div className='shipping-info-line'>
                                                                    <div className='ship-info-hd'>Amount: </div> <div className="text-right">{numeral(item?.amount).format("$0,0.00", Math.floor)}</div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <div className='shipping-info-line no-border'>
                                                                    <div className='ship-info-hd'>Default:</div>
                                                                    <div className="text-right"> <Checkbox size="small" checked={item?.is_default} color="primary" /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Box>
                                                </Card>
                                            </Col>
                                        ))}
                                    </> :
                                    <>
                                        {
                                            data?.map((item) => (
                                                <Col key={item?._id} md={12} lg={12} className='mb-3' >
                                                    <Card sx={{ display: 'flex' }} >
                                                        <CardHeader className='category-header' />
                                                        <Box className='px-3'>
                                                            <Row>
                                                                <Col xs={12}>
                                                                    {
                                                                        item?.is_default &&
                                                                        <div className='shipping-info-line'>
                                                                            <div className='ship-info-hd' style={{ color: "gray", }}>Default </div>
                                                                        </div>
                                                                    }

                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line'>
                                                                        <div className='ship-info-hd'>Name: </div> <div className='ship-info-hd text-right'>{item?.firstName} {item?.lastName}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line'>
                                                                        <div className='ship-info-hd'>Phone: </div> <div className='ship-info-hd text-right'>{item?.phone} </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line'>
                                                                        <div className='ship-info-hd'>Address: </div> <div className='ship-info-hd text-right'>{item?.address1}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line'>
                                                                        <div className='ship-info-hd'>Zip: </div> <div className='ship-info-hd text-right'>{item?.zip}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line'>
                                                                        <div className='ship-info-hd'>City: </div> <div className='ship-info-hd text-right'>{item?.city}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line'>
                                                                        <div className='ship-info-hd'>State: </div> <div className='ship-info-hd text-right'>{item?.province}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <div className='shipping-info-line no-border'>
                                                                        <div className='ship-info-hd'>Country: </div> <div className='ship-info-hd text-right'>{item?.country}</div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Box>
                                                    </Card>
                                                </Col>
                                            ))
                                        }
                                    </>
                                }

                            </Row> */}
                                </div>
                        }

                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = ({ }) => ({})

export default connect(mapStateToProps, { ...accountActions })(Discounts)

const style = {
    rowStyle: {
        marginBottom: "10px",
        fontWeight: "500",
        lineHeight: "1.25",
        color: "#001737"
    },
    colStyle: {
        textAlign: "left"
    }

}