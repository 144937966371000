import React, { useState, useEffect, useCallback, useMemo } from 'react'
import DataTable from "./PaymentTable"
import { Col, Row } from 'react-bootstrap'
import { Autocomplete, Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'


import moment from 'moment';
import { connect } from 'react-redux';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";

import * as promotionActions from "../../store/actions/promotions"
import SkeletonLoader from "reUsable/skeleton";

import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import DateBtn from 'reUsable/DateBtn';
import SearchBrand from 'reUsable/SearchBrand';

function PaymentReport({ getStripeDetail, stripeDetail }) {
    const [fields, setFields] = useState({
        brand_id: '', type: 'success'
    });
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [load, setLoad] = useState(true);
    const [flag, setFlag] = useState('success')
    useEffect(() => {
        getData()
    }, []);

    function getData(type) {
        setLoading(true)
        if (type === "reset") {
            setFields({
                brand_id: '', type: 'success'
            })
            setPage(1)
            getStripeDetail({
                brand_id: '', type: 'success'
            }, 1).then(() => {
                setLoading(false)
                setLoad(false)
                setFlag('success')
            })
        } else {
            getStripeDetail(fields, 1).then(() => {
                setLoading(false)
                setLoad(false)
                setFlag(fields.type)
            })
        }
    }

    return (
        <div className='container-fluid mt-3 mb-3 pl-0'>
            <Row className='pl-15'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Payment Report
                    </Typography>
                </Col>
            </Row>
            <Row className="account-main-row pl-15">

                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <SearchBrand setter={(value) => setFields({ ...fields, brand_id: value })} brand={fields.brand_id} />

                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel id="demo-simple-select-label1">
                            Type
                        </InputLabel>
                        <Select
                            sx={{ color: "black" }}
                            labelId="Type"
                            id="Type"
                            value={fields.type}
                            label="Type"
                            onChange={(e) =>
                                setFields({ ...fields, type: e.target.value })
                            }
                        >
                            <MenuItem value="success">Success</MenuItem>
                            <MenuItem value="error">Error</MenuItem>
                        </Select>
                    </FormControl>

                </Col>

                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <Button
                        variant="contained"
                        size="medium"
                        className="btn-gen-margin btn-width laptop"
                        color="primary"
                        startIcon={<SearchIcon />}
                        disableElevation
                        onClick={getData}
                    >
                        Search
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        startIcon={<RefreshIcon />}
                        className="btn-gen-margin btn-width laptop"
                        color="success"
                        disableElevation
                        onClick={() => {
                            getData('reset')
                        }}
                    >
                        Refresh
                    </Button>
                </Col>
            </Row >
            <Divider className="separater-sale" />
            {
                loading ?
                    <div className="pl-15" style={{ marginTop: 16 }}>
                        <SkeletonLoader />
                    </div>
                    :
                    <>
                        <DataTable
                            type={flag}
                            page={page}
                            data={stripeDetail}
                            load={load}
                            nowGet={(page) => {
                                setLoad(true)
                                getStripeDetail(fields, page).then(() => {
                                    setPage(page)
                                    setLoad(false)
                                })
                            }}
                        />
                    </>
            }
        </div >
    )
}
const mapStateToProps = ({ promotionSales, stripeDetail }) => ({ promotionSales, stripeDetail })
export default connect(mapStateToProps, { ...promotionActions })(PaymentReport)