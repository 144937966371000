import { Box, Button, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import numeral from 'numeral';
import OrderModal from 'pages/accountMain/components/OrderModal';
import NoRows from 'reUsable/NoRows';
import React, { useMemo } from 'react'
import { useState } from 'react';
import SaveModal from "./Modal";

export default function Table({ data, load, type, page, nowGet, open }) {
    const [modal, setModal] = useState(false)
    const [visible, setVisible] = useState(false)
    const [order, setOrder] = useState(null)
    const [record, setRecord] = useState(null)
    const orderCol = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        {
            field: "created_date",
            headerName: "Order Date",
            width: 130,
            sortable: false,
            renderCell: ({ value }) => {
                return moment(value).format('MM-DD-YYYY')
            }
        },
        {
            field: "order_id",
            headerName: "Order#",
            width: 90,
            sortable: false,
            renderCell: (params) => (
                <div
                    className="orderBTN"
                    onClick={() => {
                        setModal(true);
                        setOrder(params?.row);
                    }}
                >
                    {params?.value}
                </div>
            ),
        },
        {
            field: "status",
            headerName: "Order Status",
            width: 110,
            sortable: false,
            renderCell: ({ value }) => (
                <p className='mb-0' style={{ color: value !== "cancelled" ? "green" : "red" }}>{value !== "cancelled" ? "Success" : "Voided"}</p>

            ),
        },
        {
            field: "videoType",
            headerName: "Source",
            width: 110,
            sortable: false,
            renderCell: (params) => {
                let row = params?.row
                if (row.event === "reviewshare" || row.event === "reviewshop") {
                    return "Review"
                } else {
                    return "Show"
                }
            },
        },
        {
            field: "title",
            headerName: "Video Title",
            width: 250,
            sortable: false,
            renderCell: ({ value }) => {
                if (value === "-" || value == undefined) {
                    return <p className='mb-0'>-</p>
                } else {
                    return (
                        <Tooltip title={value} placement="top-start" arrow>
                            <p className='mb-0'>{value}</p>
                        </Tooltip>
                    )
                }
            }
        },
        {
            field: "brand",
            headerName: "Brand",
            width: 150,
            sortable: false,
        },
        {
            field: "general_category",
            headerName: "Department",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (value ? value : "All"),
        },
        {
            field: "category",
            headerName: "Category",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (value ? value : "All"),
        },
        {
            field: "sub_category",
            headerName: "Sub Category",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (value ? value : "All"),
        },
        {
            field: "purchased_by",
            headerName: "Purchaser",
            width: 150,
            sortable: false,
        },
        {
            field: "gross_amount",
            headerName: "Gross Sales",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "returned_amount",
            headerName: "Return Amount",
            width: 130,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: 'actions',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            headerName: "Actions",
            flex: 1.5,
            filterable: false,
            minWidth: 250,
            renderCell: (params) => {
                return (
                    <div style={{ width: '100%', margin: 0 }}>
                        <>
                            <Button
                                disabled={params?.row?.is_refunded}
                                onClick={() => {
                                    setVisible(true)
                                    setRecord(params?.row)
                                    // setUserInfo({ ...value?.row, release: true })
                                    // setModal(true)
                                }}
                                className='btn-gen-margin '
                                variant="contained"
                                style={{ fontSize: "10px" }}
                                color="primary"
                                disableElevation
                            >
                                Approve
                            </Button>
                        </>
                    </div>
                )
            }
        },
    ];
    // const orderCol = [
    //     {
    //         field: "id",
    //         headerName: "S.#",
    //         width: 50,
    //         renderCell: ({ value }) => {
    //             return (
    //                 <span className="d-flex justify-content-center w-100">{value}</span>
    //             );
    //         },
    //         sortable: false,
    //     },
    //     {
    //         field: "created_date",
    //         headerName: "Order Date",
    //         width: 130,
    //         sortable: false,
    //         renderCell: ({ value }) => {
    //             return moment(value).format('MM-DD-YYYY')
    //         }
    //     },
    //     {
    //         field: "order_id",
    //         headerName: "Order#",
    //         width: 90,
    //         sortable: false,
    //         renderCell: (params) => (
    //             <div
    //                 className="orderBTN"
    //                 onClick={() => {
    //                     setModal(true);
    //                     setOrder(params?.row);
    //                 }}
    //             >
    //                 {params?.value}
    //             </div>
    //         ),
    //     },
    //     {
    //         field: "is_refunded",
    //         headerName: "Order Status",
    //         width: 150,
    //         sortable: false,
    //         renderCell: ({ value }) => (value ? <span style={{ color: "green" }}>Approved</span> : "Pending"),
    //     },
    //     {
    //         field: "videoType",
    //         headerName: "Source",
    //         width: 110,
    //         sortable: false,
    //         renderCell: (params) => {
    //             let row = params?.row
    //             if (row.event === "reviewshare" || row.event === "reviewshop") {
    //                 return "Review"
    //             } else {
    //                 return "Show"
    //             }
    //         },
    //     },
    //     {
    //         field: "title",
    //         headerName: "Video Title",
    //         width: 250,
    //         sortable: false,
    //         renderCell: ({ value }) => {
    //             if (value === "-" || value == undefined) {
    //                 return <p className='mb-0'>-</p>
    //             } else {
    //                 return (
    //                     <Tooltip title={value} placement="top-start" arrow>
    //                         <p className='mb-0'>{value}</p>
    //                     </Tooltip>
    //                 )
    //             }
    //         }
    //     },
    //     {
    //         field: "brand",
    //         headerName: "Brand",
    //         width: 150,
    //         sortable: false,
    //     },
    //     {
    //         field: "general_category",
    //         headerName: "Department",
    //         width: 150,
    //         sortable: false,
    //         renderCell: ({ value }) => (value ? value : "All"),
    //     },
    //     {
    //         field: "category",
    //         headerName: "Category",
    //         width: 150,
    //         sortable: false,
    //         renderCell: ({ value }) => (value ? value : "All"),
    //     },
    //     {
    //         field: "sub_category",
    //         headerName: "Sub Category",
    //         width: 150,
    //         sortable: false,
    //         renderCell: ({ value }) => (value ? value : "All"),
    //     },

    //     // {
    //     //     field: "content_by",
    //     //     headerName: "Content Source",
    //     //     width: 150,
    //     //     sortable: false,
    //     //     renderCell: ({ value }) => (value ? value === "brand" ? "Brand" : "Creator" : "All"),
    //     // },

    //     {
    //         field: "purchased_by",
    //         headerName: "Purchaser",
    //         width: 150,
    //         sortable: false,
    //     },
    //     // {
    //     //     field: "affiliate",
    //     //     headerName: "Referrer",
    //     //     width: 150,
    //     //     sortable: false,
    //     // },
    //     {
    //         field: "gross_amount",
    //         headerName: "Gross Sales",
    //         width: 130,
    //         renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
    //         sortable: false,
    //     },
    //     {
    //         field: "returned_amount",
    //         headerName: "Return Amount",
    //         width: 130,
    //         renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
    //         sortable: false,
    //     },
    //     // {
    //     //     field: "balance_amount",
    //     //     headerName: "Net Sales",
    //     //     width: 130,
    //     //     renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
    //     //     sortable: false,
    //     // },
    //     // {
    //     //     field: "kb_fee",
    //     //     headerName: "ORME Fee",
    //     //     width: 130,
    //     //     renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
    //     //     sortable: false,
    //     // },
    //     // {
    //     //     field: "kb_fee_percent",
    //     //     headerName: "ORME Fee %",
    //     //     width: 130,
    //     //     renderCell: ({ value }) => `${value}%`,
    //     //     sortable: false,
    //     // },
    //     // {
    //     //     field: "cashback_amount",
    //     //     headerName: "Cashback",
    //     //     width: 130,
    //     //     renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
    //     //     sortable: false,
    //     // },
    //     // {
    //     //     field: "cashback_amount_percent",
    //     //     headerName: "Cashback %",
    //     //     width: 130,
    //     //     renderCell: ({ value }) => `${value}%`,
    //     //     sortable: false,
    //     // },
    //     // {
    //     //     field: "customer_commission",
    //     //     headerName: "Referral Fee",
    //     //     width: 150,
    //     //     renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
    //     //     sortable: false,
    //     // },
    //     // {
    //     //     field: "customer_commission_percent",
    //     //     headerName: "Referral Fee %",
    //     //     width: 150,
    //     //     renderCell: ({ value }) => `${value}%`,
    //     //     sortable: false,
    //     // },
    //     // {
    //     //     field: "creator_commission",
    //     //     headerName: "Creator Fee",
    //     //     width: 150,
    //     //     renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
    //     //     sortable: false,
    //     // },
    //     // {
    //     //     field: "creator_commission_percent",
    //     //     headerName: "Creator Fee %",
    //     //     width: 150,
    //     //     renderCell: ({ value }) => `${value}%`,
    //     //     sortable: false,
    //     // },
    //     // {
    //     //     field: "net_brand_amount",
    //     //     headerName: "Net Revenue",
    //     //     width: 150,
    //     //     renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
    //     //     sortable: false,
    //     // },
    //     {
    //         field: 'actions',
    //         sortable: false,
    //         headerClassName: 'super-app-theme--header',
    //         headerName: "Actions",
    //         flex: 1.5,
    //         filterable: false,
    //         minWidth: 250,
    //         renderCell: (params) => {
    //             return (
    //                 <div style={{ width: '100%', margin: 0 }}>
    //                     <>
    //                         <Button
    //                             disabled={params?.row?.is_refunded}
    //                             onClick={() => {
    //                                 setVisible(true)
    //                                 setRecord(params?.row)
    //                                 // setUserInfo({ ...value?.row, release: true })
    //                                 // setModal(true)
    //                             }}
    //                             className='btn-gen-margin '
    //                             variant="contained"
    //                             style={{ fontSize: "10px" }}
    //                             color="primary"
    //                             disableElevation
    //                         >
    //                             Approve
    //                         </Button>
    //                     </>
    //                 </div>
    //             )
    //         }
    //     },
    // ];
    const summary = useMemo(() => {
        if (data?.summary) {
            return data?.summary?.[0]
        } else {
            return null
        }
    }, [data])
    return (
        <div style={{ height: "445px" }}>
            <SaveModal
                nowGet={nowGet}
                data={record}
                visible={visible}
                // title={`Are you sure you want to Refund ${numeral(record?.returned_amount).format("$0,0.00", Math.floor)} ?`}
                title={<div>Are you sure you want to Refund <span className='fw-bold'>{numeral(Math.abs(record?.returned_amount)).format("$0,0.00", Math.floor)}</span> ?</div>}
                close={() => { setVisible(false); setRecord(null) }}
                type="status"
                call={() => { open(); setVisible(false); setRecord(null) }}
            />
            <OrderModal
                type="orders"
                data={order}
                visible={modal}
                close={() => {
                    setOrder(null);
                    setModal(false);
                }}
            />
            <DataGrid
                // rowHeight={40}
                density="compact"
                disableColumnFilter={true}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                className="bg-white ml-15"
                components={{ NoRowsOverlay: NoRows, }}
                loading={load}
                rows={
                    data?.data?.map((item, index) => ({
                        ...item,
                        id: index + (page == 1 ? 1 : page * 12 - 11),
                        brand: item?.brand?.name,
                        category: item?.category?.category_name,
                        title: (item?.event === "eventshop" || item?.event === "eventshare") ? item?.event_payload?.title === "" ? "-" : item?.event_payload?.title : item?.review_payload?.title === "" ? "-" : item?.review_payload?.title,
                        source: item.event,
                        purchased_by: item?.customer?.name,
                        affiliate: item?.affiliate?.name,
                        sub_category: item?.sub_category?.sub_category_name,
                        general_category: item?.gen_category?.name,
                    }))
                }

                columns={orderCol}
                pagination
                pageSize={12}
                rowsPerPageOptions={[12]}
                rowCount={data?.total_records}
                paginationMode="server"
                onPageChange={(newPage) => nowGet(newPage + 1)}
            />
            <div className='sumContainer-height-235 ml-15 pb-2' style={{ background: "#fff" }} >
                <div className='sumContainer'>
                    <div className='sumTitle'>Summary</div>
                    <div className='sumBody'>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Orders</div>
                            <div className='sumBodyvalue'>{summary?.order_count}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Amount</div>
                            <div className='sumBodyvalue'>{numeral(summary?.gross_amount).format("$0,0.00", Math.floor)}</div>
                        </div>
                        <div className='sumBodyRow'>
                            <div className='sumBodyTitle'>Total Return Amount</div>
                            <div className='sumBodyvalue'>{numeral(summary?.returned_amount).format("$0,0.00", Math.floor)}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
