import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import * as catActions from "../../../store/actions/categories"
import * as subCatAction from "../../../store/actions/subCategory"
import { Row, Col } from "react-bootstrap";
import SkeletonLoader from "../../../reUsable/skeleton";
import { Button, Divider, Typography } from '@mui/material';
import AddModal from "./AddCategory";
import Categories from 'reUsable/SubCategories';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import NotFound from "../../../reUsable/NotFound"
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import * as catActions from "../../../store/actions/Categories/categories"
import { useMemo } from 'react';

function SubCategory({ getGenderCategories, genderCategories, getCategories, categories, getSubCategories, subcategories }) {
    const [loading, setLoading] = useState(true)
    const [addModal, setAddModal] = useState(false)
    const [cat, setCat] = useState([])
    const [subCat, setSubCat] = useState([]);
    const [search, setSearch] = useState('')

    useEffect(() => {
        Promise.all([getSubCategories(), getGenderCategories(), getCategories()]).then(() => { setLoading(false) })
    }, [])

    const settingData = useMemo(() => {
        if (subcategories.success) {
            setSubCat(subcategories?.message)
        } else {
            setSubCat([])
        }
    }, [subcategories])
    function renderCategories() {
        if (subCat?.some(s => s?.categories.length > 0)) {
            return subCat?.map((item) => (
                <Categories item={item} reloadData={() => { getSubCategories() }} catData={genderCategories} />
            ))
        } else {
            return (<div className='col' >
                <NotFound />
            </div>)
        }
    }
    return (
        <React.Fragment>
            <AddModal
                visible={addModal}
                close={() => setAddModal(false)}
                reloadData={() => { getSubCategories() }}
                catData={genderCategories}
                data={categories}
            />
            <div className="table-width container-fluid">
                <Row className='pt-3'>
                    <Col xs className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            Sub Categories
                        </Typography>
                    </Col>

                    <Col xs className="mb-3 text-right">
                        <Button
                            onClick={() => setAddModal(true)}
                            className='btn-width'
                            startIcon={<AddIcon />}
                            disableElevation
                            variant="contained">
                            Add Sub Category
                        </Button>
                    </Col>
                </Row>
                <Divider className="mb-15" />
                {!loading ? (
                    <Row>
                        {renderCategories()}
                    </Row>
                ) : <SkeletonLoader />}
            </div>
        </React.Fragment>
    );
}

function mapStateToProps({ genderCategories, categories, subcategories }) {
    return { genderCategories, categories, subcategories }
}

export default connect(mapStateToProps, { ...catActions })(SubCategory)
