import React, { useEffect, useState } from 'react'
import * as accountActions from "../../../store/actions/account"
import { Row, Col } from "react-bootstrap"
import SkeletonLoader from "reUsable/skeleton";
import { connect } from 'react-redux'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TagIcon from '@mui/icons-material/Tag';
import CategoryIcon from '@mui/icons-material/Category';
import Box from '@mui/material/Box';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ConfirmModal from '../../../reUsable/ConfirmModal';
import { toast } from 'react-toastify';

function AccountDetail({ getDetails, accountDetail, updateAddon, currentUser }) {
    const [loading, setLoading] = useState(true);
    const [confirmModal, setConfirmModal] = useState(false);
    const [fields, setFields] = useState({
        hashtag_limit: "",
        category_limit: "",
        profile_limit: "",
        type: "",
        title: ""
    })

    useEffect(() => {
        Promise.all([getDetails(currentUser)])
            .then(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (accountDetail.userDetails) {
            const { userDetails: { hashtag_limit, category_limit, profile_limit } } = accountDetail
            setFields({
                hashtag_limit,
                category_limit,
                profile_limit
            })
        }
    }, [accountDetail])
    function renderData() {

        return (
            <>

                <Row className='align-items-center'>
                    <Col xl={4} lg={6} className='mb-3'>
                        <Card variant="outlined" className='users-card-main addons' sx={{ minWidth: 275 }}>
                            <Box className="addons-cart-title d-flex align-items-center">
                                <TagIcon className='tag-icon mb-0' />
                                <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                    Hashtags
                                </Typography>
                            </Box>
                            <Divider className='mb-3' />
                            <CardContent className='pb-0 pt-0'>

                                <FormControl className='w-100 mb-4' size="small">
                                    <InputLabel id="demo-simple-select-helper-label">No. of Hashtag</InputLabel>

                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={fields.hashtag_limit}
                                        onChange={(e) => {
                                            setFields({ ...fields, hashtag_limit: e.target.value })
                                        }}
                                        disabled={accountDetail.userDetails.package_id == "61c02d43f40bec74fac2c9a0"}
                                        label="No. of Hashtag"
                                    >
                                    <MenuItem disabled={accountDetail.userDetails.account_type == "brand"} value={3}>
                                        3
                                    </MenuItem>
                                    <MenuItem disabled={accountDetail.userDetails.account_type == "brand"} value={6}>
                                        6
                                    </MenuItem>
                                    <MenuItem value={9}>
                                        9
                                    </MenuItem>
                                    <MenuItem value={12}>
                                        12
                                    </MenuItem>
                                    <MenuItem value={15}>
                                        15
                                    </MenuItem>
                                    <MenuItem value={18}>
                                        18
                                    </MenuItem>
                                    <MenuItem value={21}>
                                        21
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <Button
                                variant="contained"
                                size="medium"
                                className="btn-gen-margin btn-width ml-0"
                                color="primary"
                                disabled={accountDetail.userDetails.package_id == "61c02d43f40bec74fac2c9a0"}
                                disableElevation
                                onClick={() => {
                                    setFields({ ...fields, type: "hashtag", title: "Are you sure you want to update hashtag limit?" })
                                    setConfirmModal(true)
                                }}
                            //endIcon={<SendIcon/>}
                            >
                                Update Hashtags
                            </Button>

                        </CardContent>
                    </Card>



                </Col>
                <Col xl={4} lg={6} className='mb-3'>
                    <Card variant="outlined" className='users-card-main addons' sx={{ minWidth: 275 }}>
                        <Box className="addons-cart-title d-flex align-items-center">
                            <CategoryIcon className='tag-icon mb-0' />
                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                Categories
                            </Typography>
                        </Box>


                        <Divider className='mb-3' />
                        <CardContent className='pb-0 pt-0'>

                            <FormControl className='w-100 mb-4' size="small">
                                <InputLabel id="demo-simple-select-helper-label">No. of Categories</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={fields.category_limit}
                                    onChange={(e) => {
                                        setFields({ ...fields, category_limit: e.target.value })
                                    }}
                                    label="No. of Categories"
                                >
                                   <MenuItem disabled={accountDetail.userDetails.account_type == "brand"} value={3}>
                                        3
                                    </MenuItem>
                                    <MenuItem disabled={accountDetail.userDetails.account_type == "brand"} value={6}>
                                        6
                                    </MenuItem>
                                    <MenuItem value={9}>
                                        9
                                    </MenuItem>
                                    <MenuItem value={12}>
                                        12
                                    </MenuItem>
                                    <MenuItem value={15}>
                                        15
                                    </MenuItem>
                                    <MenuItem value={18}>
                                        18
                                    </MenuItem>
                                    <MenuItem value={21}>
                                        21
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <Button
                                variant="contained"
                                size="medium"
                                className="btn-gen-margin btn-width ml-0"
                                color="primary"
                                disableElevation
                                onClick={() => {
                                    setFields({ ...fields, type: "category", title: "Are you sure you want to update category limit?" })
                                    setConfirmModal(true)
                                }}
                            >
                                Update Categories
                            </Button>

                        </CardContent>
                    </Card>



                </Col>
                <Col xl={4} lg={6} className='mb-3'>
                    <Card variant="outlined" className='users-card-main addons' sx={{ minWidth: 275 }}>

                        <Box className="addons-cart-title d-flex align-items-center">
                            <ManageAccountsIcon className='tag-icon mb-0' />
                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                Profiles
                            </Typography>
                        </Box>


                        <Divider className='mb-3' />
                        <CardContent className='pb-0 pt-0'>

                            <FormControl className='w-100 mb-4' size="small">
                                <InputLabel id="demo-simple-select-helper-label">No. of Profiles</InputLabel>

                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={fields.profile_limit}
                                    onChange={(e) => {
                                        setFields({ ...fields, profile_limit: e.target.value })
                                    }}
                                    disabled={accountDetail.userDetails.package_id == "61c02d43f40bec74fac2c9a0"}
                                    label="No. of Hashtag"
                                >
                                    <MenuItem disabled={accountDetail.userDetails.account_type == "brand"} value={3}>
                                        3
                                    </MenuItem>
                                    <MenuItem disabled={accountDetail.userDetails.account_type == "brand"} value={6}>
                                        6
                                    </MenuItem>
                                    <MenuItem value={9}>
                                        9
                                    </MenuItem>
                                    <MenuItem value={12}>
                                        12
                                    </MenuItem>
                                    <MenuItem value={15}>
                                        15
                                    </MenuItem>
                                    <MenuItem value={18}>
                                        18
                                    </MenuItem>
                                    <MenuItem value={21}>
                                        21
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <Button
                                variant="contained"
                                size="medium"
                                className="btn-gen-margin btn-width ml-0"
                                color="primary"
                                disableElevation
                                disabled={accountDetail.userDetails.package_id == "61c02d43f40bec74fac2c9a0"}
                                onClick={() => {
                                    setFields({ ...fields, type: "profile", title: "Are you sure you want to update profile limit?" })
                                    setConfirmModal(true)
                                }}
                            >
                                Update Profiles
                            </Button>

                        </CardContent>
                    </Card>
                </Col>
                <ConfirmModal
                    title={fields.title}
                    visible={confirmModal}
                    call={() => {
                        updateAddon({ ...fields, userId: accountDetail.userDetails._id, account_type: accountDetail.userDetails.account_type })
                            .then(() => toast.success("Successfully updated"))
                        setConfirmModal(false)
                    }}
                    close={() => setConfirmModal(false)}
                />
            </Row>

            </>
        )
    }
    return (
        <React.Fragment>
            {loading ? (
                <SkeletonLoader />
            ) : renderData()}
        </React.Fragment>
    );
}

function mapStateToProps({ accountDetail, packages }) {
    return { accountDetail, packages }
}


export default connect(mapStateToProps, { ...accountActions })(AccountDetail)
