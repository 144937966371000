import React, { useEffect, useState } from 'react'
import * as ledgerActions from "../../../store/actions/ledger"
import { connect } from 'react-redux'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import numeral from "numeral";
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Divider } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import Box from '@mui/material/Box';
import NoRows from '../../../reUsable/NoRows';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO } from 'date-fns';

function Deposit({ ledger, getDeposit, currentUser, userType }) {
    const [load, setLoad] = useState(false)
    const [value, setValue] = useState([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [fields, setFields] = useState({
        user_id: currentUser,
        transaction_type: "deposit"
    })
    const [date, setDate] = useState(false)
    const [search, setSearch] = useState(false)
    const [page, setPage] = useState(1)

    useEffect(() => {
        setLoad(true)
        getDeposit(fields, value, page, userType).then(() => setLoad(false))
    }, [])



    useEffect(() => {
        if (search || date) {
            setLoad(true)
            getDeposit(fields, value, page, userType).then(() => {
                setLoad(false)
                setSearch(false)
                setDate(false)
            })
        }
    }, [search, date])
    const columns = [
        {
            field: "id",
            headerClassName: "super-app-theme--header",
            headerName: "S.no",
            maxWidth: 60,
            flex: 0.5,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            filterable: false,
        },
        {
            field: 'transactionId',
            headerClassName: 'super-app-theme--header',
            headerName: "Transaction Id",
            flex: 1,
            minWidth: 150,
            maxWidth: 250,
            filterable: false,
        },
        {
            field: 'transaction_date',
            headerClassName: 'super-app-theme--header',
            headerName: "transaction Date",
            flex: 1,
            minWidth: 100,
            maxWidth: 200,
            renderCell: (params) => {
                return <p className='mb-0' >{moment(params.value).format("lll")}</p>
            },
            filterable: false,
        },
        // {
        //     field: 'desc',
        //     headerClassName: 'super-app-theme--header',
        //     headerName: "Description",
        //     flex: 1,
        //     minWidth: 250,
        //     maxWidth: 450,
        //     filterable: false
        // },
        // {
        //     field: 'debit',
        //     headerClassName: 'super-app-theme--header',
        //     headerName: "Debit",
        //     flex: 1,
        //     minWidth: 80,
        //     maxWidth: 120,
        //     renderCell: ({ value }) => {
        //         return numeral(value).format("$0,0.00", Math.floor)
        //     },
        //     filterable: false,
        // },
        {
            field: 'credit',
            headerClassName: 'super-app-theme--header',
            headerName: "Amount",
            flex: 1,
            minWidth: 80,
            maxWidth: 120,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            filterable: false,
        },
        // {
        //     field: 'balance',
        //     headerClassName: 'super-app-theme--header',
        //     headerName: "Balance",
        //     flex: 1,
        //     minWidth: 80,
        //     maxWidth: 120,
        //     renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
        //     filterable: false,
        // },

    ]
    return (
        <div className='saleContainer'>
            <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                Deposit
            </Typography>
            <div className='body'>
                <div className='buttons-sales'>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn-gen-margin btn-width ml-0 mb-2"
                        color="primary"
                        disabled={value[0] === moment().format('YYYY-MM-DD')}
                        disableElevation
                        onClick={() => {
                            let startDate = moment().format('YYYY-MM-DD')
                            setValue([startDate, startDate])
                            setDate(true);
                        }}
                    >
                        Today
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn-gen-margin btn-width ml-2 mb-2"
                        color="primary"
                        disableElevation
                        disabled={value[0] === moment().startOf('month').format('YYYY-MM-DD')}
                        onClick={() => {
                            let startDate = moment().startOf('month').format("YYYY-MM-DD")
                            setValue([startDate, moment().format('YYYY-MM-DD')])
                            setDate(true);
                        }}
                    >
                        MTD
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn-gen-margin btn-width ml-2 mb-2"
                        color="primary"
                        disabled={value[0] === moment().startOf('year').format('YYYY-MM-DD')}
                        disableElevation
                        onClick={() => {
                            let startDate = moment().startOf('year').format("YYYY-MM-DD")
                            setValue([startDate, moment().format('YYYY-MM-DD')])
                            setDate(true);
                        }}
                    >
                        YTD
                    </Button>
                </div>
                <Row className='align-items-center'>
                    <Col xl={4} lg={12} className='mb-3'>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className='dateBox'>
                                <DatePicker
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format('YYYY-MM-DD'), value[1]]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format('YYYY-MM-DD')]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xl={5} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => setSearch(true)}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width"
                            color="success"
                            disableElevation
                            onClick={() => setSearch(true)}
                        >
                            Refresh
                        </Button>
                    </Col>

                </Row>
                <Divider className="separater-sale" />
                <div style={{ height: 460, width: '100%' }}>
                    {
                        <DataGrid
                            density='compact'
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            disableVirtualization
                            disableSelectionOnClick
                            components={{ NoRowsOverlay: NoRows, }}
                            className="bg-white"
                            rows={
                                Object.keys(ledger).length > 0 ?
                                    ledger?.data?.map((item, i) => {
                                        return {
                                            ...item,
                                            id: i + (page == 1 ? 1 : (page * 10) - 9),
                                            userId: item.user_id,
                                            name: item.name,
                                            accountName: item.account_name,
                                            createdDate: item.created_at,
                                            credit: item.credit,
                                            debit: item.debit,
                                            desc: item.description,
                                            transactionId: item.transaction_id,
                                            transactionType: item.transaction_type,
                                            transaction_date: item.transaction_date,
                                            balance: item.runningTotal,
                                            amount: item.amount,
                                            account_head: item.account_head
                                        }
                                    })
                                    : []

                            }
                            columns={columns}
                            rowCount={ledger?.count}
                            pagination
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            paginationMode="server"
                            onPageChange={(newPage) => {
                                setPage(newPage + 1);
                                setLoad(true)
                                getDeposit(fields, value, newPage + 1, userType).then(() => setLoad(false))
                            }}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

function mapStateToProps({ ledger }) {
    return { ledger }
}
export default connect(mapStateToProps, ledgerActions)(Deposit)