import konnect from "./axios";
import { RETURNS } from "./type";

export const getReturns = (fields, value, page = 1, limit = 12) => async (dispatch) => {
    try {
        let obj = {
            from_date: value[0],
            to_date: value[1],
            gen_category: fields.generalCategory,
            category: fields.category,
            sub_category: fields.subCategory,
            source: fields.source,
            group_by: fields.group_by,
            channel: fields.channel,
            content: fields.content,
            creator: fields.creator,
            referral: fields.referrer,
            brand_id: fields.brand_id,
            order_id: fields.order_id,
            is_refunded: fields.is_refunded === "all" ? "" : fields.is_refunded
        };
        const res = await konnect.post("/v1/admin/crm/getRefundReport", obj, {
            params: { page, limit },
        });
        dispatch({
            type: RETURNS,
            payload: res?.data,
        });
        return res?.data;
    } catch (err) {
        return err.response.data
    }
};

export const approved = (data) => async (dispatch) => {
    try {
        const res = await konnect.post("/v1/admin/shopify/refund", data);
        return res?.data;
    } catch (err) {
        return err.response.data
    }
};