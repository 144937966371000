import React, { useState } from 'react'
import { Autocomplete, TextField } from '@mui/material';
import { connect } from 'react-redux'
import * as accountActions from "../store/actions/account"
import { useCallback } from 'react';
import { useEffect } from 'react';


export const SearchInfluencerMultiple = ({ accountList, getUsers, setter, type, users }) => {
    const [nameLoad, setNameLoad] = useState(true);
    const [name, setName] = useState('')
    const [list, setList] = useState([])
    function debounce(cb, delay = 250) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    const handleChange = (val) => {
        getUsers(val, false, 'influencer', type).then((res) => {
            if (res?.success) {
                setList(res?.data?.message)
            }
            setNameLoad(false)
        })
    };
    const optimizedFn = useCallback(debounce(handleChange), []);

    useEffect(() => {
        setNameLoad(false)
        setName('')
        getUsers('', false, 'influencer', '', type).then((res) => {
            if (res?.success) {
                setList(res?.data?.message)
            }
            setNameLoad(false)
        })
    }, [users])

    return (
        <Autocomplete
            multiple
            variant="outlined"
            className="field-style"
            size="small"
            disableCloseOnSelect
            id="disable-clearable"
            // disableClearable
            loading={nameLoad}
            getOptionDisabled={(option) => users?.find(f => f.key === option.key) ? true : false}
            options={nameLoad ? [] : list?.map(opt => ({ ...opt, key: opt.pixel_id, label: `${opt.pixel_id} - ${opt.name}` }))}
            onChange={(e, data) => {
                setter(data)
            }}
            // value={users?.length === 0 ? [{ id: 0, label: "All", value: "All" }] : users}
            value={users}
            renderInput={(params) => <TextField {...params} label="User" onChange={(e) => {
                if (e.target.value !== "") {
                    // setName(e.target.value)
                    setNameLoad(true)
                    optimizedFn(e.target.value)
                } else {
                    // setName(e.target.value)
                    setNameLoad(true)
                    optimizedFn(e.target.value)
                }
            }} />}
        />
    )
}

const mapStateToProps = ({ accountList }) => ({ accountList })

export default connect(mapStateToProps, accountActions)(SearchInfluencerMultiple)