import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import * as logActions from "../../store/actions/logs"
import SkeletonLoader from "reUsable/skeleton";
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import DataTable from "./table";
import { Button, TextField, Box, MenuItem, FormControl, InputLabel, Select, Autocomplete } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";

const options = [
    { id: 0, label: "All", value: "All" },
    { id: 1, label: "login", value: "Login" },
    { id: 2, label: "bookBanner", value: "Book Banner" },
    { id: 3, label: "deleteBanner", value: "Delete Banner" },
    { id: 4, label: "updateBanner", value: "Update Banner" },
    { id: 6, label: "getCustomers", value: "Get Customers" },
    { id: 7, label: "getReferers", value: "Get Referers" },
    { id: 8, label: "createCategory", value: "Create Category" },
    { id: 9, label: "updateCategory", value: "Update Category" },
    { id: 10, label: "getCategory", value: "Get Category" },
    { id: 11, label: "deleteCategory", value: "Delete Category" },
    { id: 12, label: "changeCategorystatus", value: "Change Category Status" },
    { id: 13, label: "createCategory", value: "Create Category" },
    { id: 14, label: "createsubCategory", value: "Create Sub Category" },
    { id: 15, label: "updatesubCategory", value: "Update Sub Category" },
    { id: 16, label: "getsubCategory", value: "Get Sub Category" },
    { id: 17, label: "changeCountryStatus", value: "Change Country Status" },
    { id: 18, label: "getFeedCount", value: "Get Feed Count" },
    { id: 19, label: "createFeedSetting", value: "Create Feed Setting" },
    { id: 20, label: "updateFeedSetting", value: "Update Feed Setting" },
    { id: 21, label: "createUsersFeed", value: "Create Users Feed" },
    { id: 22, label: "updateFee", value: "Update Fee" },
    { id: 23, label: "saveFee", value: "Save Fee" },
    { id: 24, label: "updatewithdrawAmount", value: "Update Withdraw Amount" },
    { id: 25, label: "setwithdrawAmount", value: "Set Withdraw Amount" },
    { id: 26, label: "createShipping", value: "Create Shipping" },
    { id: 27, label: "updateShipping", value: "Update Shipping" },
    { id: 28, label: "deleteShipping", value: "Delete Shipping" },
    { id: 29, label: "getCountries", value: "getCountries" },
    { id: 30, label: "deleteReview", value: "Delete Review " },
    { id: 31, label: "publishReview", value: "Publish Review " },
    { id: 32, label: "unpublishReview", value: "Un Publish Review " },
]
function UserLogs({ getLogs, logs }) {
    const [loading, setLoading] = useState(true)
    const [tableLoading, setTableLoading] = useState(false)
    const [value, setValue] = useState([
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
    ]);
    const [fields, setFields] = useState({
        action: [{ id: 0, label: "All", value: "All" }],
        ip_address: "",
        email: '',
        duration: null || ""
    })

    useEffect(() => { onSearch() }, [])

    function onSearch() {
        setLoading(true)

        getLogs(1, fields, value, 'admin')
            .then(() => {
                setLoading(false)
                setLoading(false)
            })
    }

    function renderClear() {
        return (
            <Col xs={12} md={12} lg={6} xl={3} className="filter-clear-col mb-20">
                <Button
                    className="btn-width btn-gen-margin ml-0 h-100  laptop"
                    disableElevation
                    size="medium"
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={onSearch}
                >
                    Search
                </Button>
                <Button
                    className="btn-width btn-gen-margin grey-btn-outline h-100 laptop"
                    variant="outlined"
                    sx={{ color: 'gray' }}
                    startIcon={<ClearIcon />}
                    onClick={() => {
                        setFields({
                            action: [{ id: 0, label: "All", value: "All" }],
                            ip_address: "",
                            email: '',
                            duration: null || ""
                        })
                        setValue([
                            moment().startOf("year").format("YYYY-MM-DD"),
                            moment().format("YYYY-MM-DD"),
                        ])
                        setLoading(true)
                        getLogs(1, {
                            action: [{ id: 0, label: "All" }],
                            ip_address: "",
                            email: '',
                            duration: null || ""
                        }, [
                            moment().startOf("year").format("YYYY-MM-DD"),
                            moment().format("YYYY-MM-DD"),
                        ], "admin")
                            .then(() => {
                                setLoading(false)
                                setLoading(false)
                            })
                    }}
                >
                    Clear
                </Button>
            </Col>
        )

    }
    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="dateBox">
                                <DatePicker
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => { setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]); }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    slotProps={{ textField: { helperText: "YYYY / MM / DD", }, }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => { setValue([value[0], moment(newValue).format("YYYY-MM-DD")]); }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <Autocomplete
                                variant="outlined"
                                className="field-style"
                                size="small"
                                id="disable-clearable"
                                limitTags={2}
                                disableClearable
                                multiple
                                disableCloseOnSelect
                                value={fields.action}
                                // loading={nameLoad}
                                getOptionDisabled={(option) =>
                                    option === options[0]
                                }
                                getOptionLabel={(option) => option.value}
                                options={options}
                                onChange={(e, data) => {
                                    if (data.length > 1) {
                                        setFields({ ...fields, action: data?.filter(f => f.label != "All") })
                                    } else if (data.length === 0) {
                                        setFields({ ...fields, action: [{ id: 0, label: "All", value: "All" }] })
                                    } else {
                                        setFields({ ...fields, action: data })
                                    }

                                }}
                                renderInput={(params) => <TextField {...params} label="Action" onChange={(e) => {

                                }} />}
                            />
                        </FormControl>
                    </Col>

                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.email}
                            onChange={e => setFields({ ...fields, email: e.target.value })}
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.ip_address}
                            onChange={e => setFields({ ...fields, ip_address: e.target.value })}
                            id="outlined-basic"
                            label="IP Address"
                            variant="outlined"
                            className="field-style" />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            type="number"
                            value={fields.duration}
                            onChange={e => setFields({ ...fields, duration: e.target.value })}
                            id="outlined-basic"
                            label="API Duration"
                            variant="outlined"
                            className="field-style" />
                    </Col>
                    {renderClear()}
                </Row>
                {loading ? <SkeletonLoader /> : (
                    <DataTable
                        tableLoading={tableLoading}
                        nowGet={(page) => getLogs(page, fields, value, 'admin')}
                        logs={logs}
                        from="admin"
                    />
                )}
            </div>
        </React.Fragment>
    );
}


const mapStateToProps = ({ logs }) => {
    return { logs };
};

export default connect(mapStateToProps, logActions)(UserLogs);
