import React, { useEffect, useState } from 'react'
import moment from 'moment';

export default function DateBtn({ value, setValue, setDate, hours, setDateKey, type }) {
    const [active, setActive] = useState(null)
    useEffect(() => {
        if (typeof value[0] === 'string') {
            setActive(null)
        }
    }, [value])
    return (
        <div className="pl-15 btn-div mb-3">
            <button className={value[0] === moment('2023-1-1').format("YYYY-MM-DD") ? "btn-dates btn-active" : 'btn-dates'} onClick={() => {

                let startDate = moment('2023-1-1').format("YYYY-MM-DD")
                setValue([startDate, moment().format('YYYY-MM-DD')])
                if (type === "userstats") {
                    setDateKey('all')
                }
                setDate(true);
            }}>All Time</button>
            <button className={value[0] == moment().startOf('year').format('YYYY-MM-DD') ? "btn-dates btn-active" : 'btn-dates'} onClick={() => {

                let startDate = moment().startOf('year').format("YYYY-MM-DD")
                if (type === "userstats") {

                    setDateKey('ytd')
                }

                setValue([startDate, moment().format('YYYY-MM-DD')])
                setDate(true);
            }}>YTD</button>
            <button className={value[0] == moment().subtract(30, 'days').format('YYYY-MM-DD') ? "btn-dates btn-active" : 'btn-dates'} onClick={() => {
                let startDate = moment().subtract(30, 'days').format("YYYY-MM-DD")
                if (type === "userstats") {

                    setDateKey('month')
                }

                setValue([startDate, moment().format('YYYY-MM-DD')])
                setDate(true);
            }}>Last 30 Days</button>
            <button className={value[0] == moment().subtract(7, 'days').format('YYYY-MM-DD') ? "btn-dates btn-active" : 'btn-dates'} onClick={() => {
                let startDate = moment().subtract(7, 'days').format("YYYY-MM-DD")
                if (type === "userstats") {
                    setDateKey('week')
                }

                setActive(null)
                setValue([startDate, moment().format('YYYY-MM-DD')])
                setDate(true);
            }}>Last 7 Days</button>
            <button className={(value[0] == moment().subtract(1, 'days').format('YYYY-MM-DD') && value[1] == moment().subtract(1, 'days').format('YYYY-MM-DD')) ? "btn-dates btn-active" : 'btn-dates'} onClick={() => {
                let startDate = moment().subtract(1, 'days').format("YYYY-MM-DD")
                if (type === "userstats") {

                    setDateKey('yesterday')
                }
                setValue([startDate, startDate])
                setDate(true);
            }}>Yesterday</button>
            <button className={value[0] == moment().format('YYYY-MM-DD') ? "btn-dates btn-active" : 'btn-dates'} onClick={() => {
                let startDate = moment().format('YYYY-MM-DD')
                setActive(null)
                if (type === "userstats") {

                    setDateKey('today')
                }

                setValue([startDate, startDate])
                setDate(true);
            }} >Today</button>
            {
                hours &&
                <>
                    <button className={active === 24 ? "btn-dates btn-active" : 'btn-dates'} onClick={() => {
                        setActive(24)
                        let startDate = moment().subtract(24, 'hours');
                        if (type === "userstats") {
                            setDateKey('hr24')
                        }
                        setValue([startDate, moment().format('YYYY-MM-DD')])
                        setDate(true);
                    }} >24 Hours</button>
                    <button className={active === 12 ? "btn-dates btn-active" : 'btn-dates'} onClick={() => {
                        setActive(12)
                        let startDate = moment().subtract(12, 'hours');

                        if (type === "userstats") {
                            setDateKey('hr12')
                        }
                        setValue([startDate, moment().format('YYYY-MM-DD')])
                        setDate(true);
                    }} >12 Hours</button>
                    <button className={active === 6 ? "btn-dates btn-active" : 'btn-dates'} onClick={() => {
                        setActive(6)
                        if (type === "userstats") {
                            setDateKey('hr6')
                        }

                        let startDate = moment().subtract(6, 'hours');
                        setValue([startDate, moment().format('YYYY-MM-DD')])
                        setDate(true);
                    }} >6 Hours</button>
                    <button className={active === 3 ? "btn-dates btn-active" : 'btn-dates'} onClick={() => {
                        setActive(3)
                        let startDate = moment().subtract(3, 'hours');
                        if (type === "userstats") { setDateKey('hr3') }
                        setValue([startDate, moment().format('YYYY-MM-DD')])
                        setDate(true);
                    }} >3 Hours</button>
                    <button className={active === 1 ? "btn-dates btn-active" : 'btn-dates'} onClick={() => {
                        setActive(1)
                        let startDate = moment().subtract(1, 'hours');
                        if (type === "userstats") { setDateKey('hr1') }
                        setValue([startDate, moment().format('YYYY-MM-DD')])
                        setDate(true);
                    }} >1 Hours</button>
                </>
            }

        </div>
    )
}
