import React, { useEffect, useMemo, useState } from 'react'
import * as accountActions from "../../../store/actions/account"
import { Row, Col } from "react-bootstrap"
import SkeletonLoader from "reUsable/skeleton";
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import { GoVerified } from "react-icons/go"
import { ImCross } from "react-icons/im"
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ConfirmModal from 'reUsable/ConfirmModal';
import { toast } from 'react-toastify';
import Divider from '@mui/material/Divider';

function AccountDetail({ getDetails, accountDetail, changeActive,currentUser }) {
    const [loading, setLoading] = useState(true);
    const [confirmModal, setConfirmModal] = useState(false)
    const [check, setCheck] = useState(false)

    const handleChange = (event) => {
        setCheck(event.target.value);
    };
    useEffect(() => {
        getDetails(currentUser)
        .then(() => setLoading(false))
    }, [])

    const accountDetailMemo = useMemo(() => {
        if (setCheck(accountDetail.userDetails?.is_active)) {
            setCheck(accountDetail.userDetails?.is_active)
        }
    }, [accountDetail])

    function renderData() {
        const {
            userDetails,
        } = accountDetail

        function renderVerify(verify) {
            if (verify) {
                return <GoVerified style={{ marginLeft: 10 }} color="green" size={18} />
            } else {
                return <ImCross style={{ marginLeft: 10 }} color="red" size={14} />
            }
        }

        function onChangeStatus() {
            setConfirmModal(false)
            changeActive({ user_id: userDetails._id, is_active: check })
                .then((res) => {
                    toast.success(res.data.message)
                    getDetails(currentUser)
                })
        }
        return (
            <>
                <ConfirmModal
                    visible={confirmModal}
                    close={() => {
                        setCheck(accountDetail.userDetails?.is_active)
                        setConfirmModal(false)
                    }}
                    call={onChangeStatus}
                    title="Are you sure you want to change this account Status"
                />
                <Row className='align-items-center'>
                    <Col xl={5} lg={6}>
                        <Card variant="outlined" className='users-card-main' sx={{ minWidth: 275 }}>
                            <Typography className='fw-normal' color="primary" variant="h6" gutterBottom component="div">
                            Account Status
                            </Typography>
                            <Divider className='mb-3' />

                            <CardContent>
                                <FormControl className='w-100 mb-4' size="small">
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={check}
                                        label="Status"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={true}>Active</MenuItem>
                                        <MenuItem value={false}>Deactive</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    className="btn-gen-margin btn-width ml-0"
                                    color="primary"
                                    onClick={()=>setConfirmModal(true)}
                                    disableElevation
                                    disabled={accountDetail.userDetails?.is_active==check}
                                >
                                    Update
                                </Button>
                            </CardContent>
                            <CardActions>


                            </CardActions>
                        </Card>



                    </Col>
                </Row>
            </>
        )
    }
    return (
        <React.Fragment>
            {loading ? (
                <SkeletonLoader />
            ) : renderData()}
        </React.Fragment>
    );
}

function mapStateToProps({ accountDetail }) {
    return { accountDetail }
}


export default connect(mapStateToProps, { ...accountActions })(AccountDetail)