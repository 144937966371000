import { Box, Button, Divider, IconButton, Input, Modal, TextField, Typography } from '@mui/material'
import * as salesActions from "../../store/actions/sales"
import * as payoutActions from "../../store/actions/payout"
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect } from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import aes from 'crypto-js/aes';
import { toast } from 'react-toastify';


const style = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};
function UserModal({ visible, close, info, getEarnings, payoutRelease, getALLWithdrawRequest, type }) {
    const [data, setData] = useState(null)
    const [submit, setSubmit] = useState(false)
    const [value, setValue] = useState('')
    const [password, setPassword] = useState('')
    const [load, setLoad] = useState(false)
    const [error, setError] = useState(null)
    useEffect(() => {
        if (info) {
            if (type === "agency") {
                getEarnings(info?.agency_id, type).then((res) => {
                    let data = {
                        available: res?.available_to_Withdraw_amount,
                        lifetime: res?.lifetime_earning?.[0]?.cashback_amount + res?.lifetime_earning?.[0]?.creator_commission + res?.lifetime_earning?.[0]?.customer_commission,
                        pending: res?.pending_balance?.[0]?.cashback_amount + res?.pending_balance?.[0]?.creator_commission + res?.pending_balance?.[0]?.customer_commission
                    }
                    setData(data)
                }).catch(err => {
                    setData(null)
                })
            } else {

                getEarnings(info?.user_id).then((res) => {
                    let data = {
                        available: res?.available_to_Withdraw_amount,
                        lifetime: res?.lifetime_earning?.[0]?.cashback_amount + res?.lifetime_earning?.[0]?.creator_commission + res?.lifetime_earning?.[0]?.customer_commission,
                        pending: res?.pending_balance?.[0]?.cashback_amount + res?.pending_balance?.[0]?.creator_commission + res?.pending_balance?.[0]?.customer_commission
                    }
                    setData(data)
                }).catch(err => {
                    setData(null)
                })
            }

        }
    }, [info])
    function clear() {
        // console.log("1", checkRef)
        setValue('')
        setPassword('')
        setSubmit(false)
        setData(null)
        setLoad(false)
        setError(null)
        close()
    }


    return (
        <div>
            <Modal
                open={visible}
                onClose={() => clear()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className={"deleteModal modal-generic-style modal-spacing-new"} style={{ width: "50%" }}>
                    <Row className='heading-name-area-modal '>
                        <Col xl={12} lg={12} className="d-flex align-items-center justify-content-between">
                            <Box className="d-flex align-items-center">
                                <Typography className='text-center w-100 m-0' id="modal-modal-title" variant="h6" component="h2">
                                    Details
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    className='custom-close-btn-moda'
                                    onClick={() => clear()}
                                    aria-label="delete" sx={{ color: "grey" }}>
                                    <CancelIcon color="error" />
                                </IconButton>
                            </Box>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} className="">
                            <Divider className='mb-15' />
                        </Col>
                    </Row>
                    <h6 className='titleVie'>Account Information</h6>
                    <div className='infoSection new mb-3' style={{ minHeight: '78px' }}>
                        <Row className=''>
                            <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'>
                                <span className='info-hd'>PID: </span> {info?.userData?.[0]?.pixel_id} </Col>
                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Name:</span> {info?.name} </Col>
                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Email: </span> {info?.email} </Col>
                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Paypal Email:</span> <span >{info?.userData?.[0]?.paypal_email}</span>  </Col>
                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Amount to withdraw:</span> <span style={{ fontWeight: "bold" }}>{info?.amount}</span>  </Col>
                        </Row>
                    </div>
                    <h6 className='titleVie'>Balance</h6>
                    <div className='infoSection new mb-3' style={{ minHeight: '78px' }}>
                        <Row className=''>
                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'>
                                <span className='info-hd'>Available To Withdraw: </span> {numeral(data?.available).format("$0,0.00", Math.floor)} </Col>
                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Pending Balance:</span> {numeral(data?.pending).format("$0,0.00", Math.floor)} </Col>
                            <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>LifeTime Earnings:</span> {numeral(data?.lifetime).format("$0,0.00", Math.floor)} </Col>
                        </Row>
                    </div>
                    {
                        info?.release &&
                        <>
                            <h6 className='titleVie'>Operation</h6>
                            <div className='infoSection new mb-3' style={{ minHeight: '78px' }}>
                                <Row className=''>
                                    {
                                        !submit ?
                                            <>
                                                <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'>
                                                    <img src='https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png' alt='paypal-logo' />
                                                </Col>
                                                <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'>
                                                    < span className='info-hd'>Type an amount <span style={{ fontWeight: "bold" }}>{info?.amount}</span> to confirm this release. </span>
                                                    <input style={{ width: "19rem" }}
                                                        value={value}
                                                        onChange={(e) => setValue(e.target.value)}
                                                    />
                                                    <Button
                                                        onClick={() => {
                                                            setSubmit(true)
                                                            // console.log("1", aes.encrypt(flag, 'orme_payout').toString())
                                                        }}
                                                        className='btn-gen-margin '
                                                        variant="contained"
                                                        style={{ fontSize: "10px" }}
                                                        color="primary"
                                                        disableElevation
                                                        disabled={value !== info?.amount}
                                                    >
                                                        Approve
                                                    </Button></Col>

                                            </> :
                                            <>
                                                <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'>
                                                    <img src='https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png' alt='paypal-logo' />
                                                </Col>
                                                <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'>
                                                    {/* <span className='info-hd'>Password</span> */}
                                                    < span className='info-hd'>Type <span style={{ fontWeight: "bold" }}>Password</span> to confirm this release. </span>
                                                    <input style={{ width: "19rem" }} type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                                    <Button
                                                        onClick={() => {
                                                            let encrypt = aes.encrypt(password, process.env.REACT_APP_SECRET).toString()
                                                            setLoad(true)
                                                            payoutRelease(encrypt, info?._id, type).then((res) => {
                                                                if (!res.success) {
                                                                    // toast.error(res?.message, {
                                                                    //     autoClose: 3000,
                                                                    //     hideProgressBar: true
                                                                    // })
                                                                    setLoad(false)
                                                                    setPassword('')
                                                                    setValue('')
                                                                    setSubmit(false)
                                                                    setError(res?.message)
                                                                    return
                                                                } else {
                                                                    setError(null)
                                                                    toast.success(res?.message, {
                                                                        autoClose: 3000,
                                                                        hideProgressBar: true
                                                                    })
                                                                    getALLWithdrawRequest({ orderBy: -1, sort: "created_date", account_type: "all", id: '', status: 'Processing' }, 1, false, type)
                                                                    clear()
                                                                }
                                                            })
                                                        }}
                                                        className='btn-gen-margin '
                                                        variant="contained"
                                                        style={{ fontSize: "10px" }}
                                                        color="primary"
                                                        disableElevation
                                                        disabled={load}

                                                    >
                                                        Submit
                                                    </Button></Col>
                                            </>
                                    }
                                </Row>

                            </div>
                        </>
                    }
                    {error &&
                        <div style={{ color: "#d10808", fontWeight: "600", fontSize: "0.8rem" }}>{error}</div>
                    }
                </Box>
            </Modal >
        </div >
    )
}

function mapStateToProps({ earning }) {
    return { earning }
}
export default connect(mapStateToProps, { ...payoutActions, ...salesActions })(UserModal)
