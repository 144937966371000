import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import { Col, Row } from "react-bootstrap";
import * as logActions from "../../store/actions/logs"
import SkeletonLoader from "reUsable/skeleton";
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import DataTable from "./table";
import { Button, TextField, Box, MenuItem, FormControl, InputLabel, Select, Autocomplete } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";
//import CancelIcon from '@mui/icons-material/Cancel';
const options = [
    { id: 0, label: "All", value: "All" },
    { id: 1, label: "signup", value: "Signup" },
    { id: 2, label: "signin", value: "Signin" },
    { id: 3, label: "otpVerification", value: "OTP Verification" },
    { id: 4, label: "eventCreate", value: "Event Create" },
    { id: 5, label: "eventDelete", value: "Event Delete" },
    { id: 6, label: "eventUpdate", value: "Event Update" },
    { id: 7, label: "showCreate", value: "Show Create" },
    { id: 8, label: "showUpdate", value: "Show Update" },
    { id: 9, label: "showDelete", value: "Show Delete" },
    { id: 10, label: "showPublish", value: "Show Publish" },
    { id: 11, label: "showUnpublish", value: "Show Unpublish" },
    { id: 12, label: "reviewPublish", value: "Review Publish" },
    { id: 13, label: "reviewUnpublish", value: "Review Unpublish" },
    { id: 14, label: "reviewDelete", value: "Review Delete" },
    { id: 15, label: "unbanUser", value: "Unban User" },
    { id: 16, label: "banUser", value: "Ban User" },
    { id: 17, label: "unfollow", value: "Unfollow" },
    { id: 18, label: "follow", value: "Follow" },
    { id: 19, label: "save", value: "Save" },
    { id: 20, label: "unsave", value: "Unsave" },
    { id: 21, label: "share", value: "Share" },
    { id: 22, label: "payout", value: "Payout" },
    { id: 23, label: "withdraw", value: "Withdraw" },
    { id: 24, label: "brandCreation", value: "Brand Creation" },
    { id: 25, label: "brandLogin", value: "Brand Login" },
    { id: 26, label: "getAllBrands", value: "All Brands" },
    { id: 27, label: "getGenCategories", value: "Gen Categories" },
    { id: 28, label: "getFeed", value: "Get Feeds" },
    { id: 29, label: "getReviews", value: "Get Reviews" },
    { id: 30, label: "getUpcomingEvents", value: "Get Upcoming events" },
    { id: 31, label: "getLiveEvents", value: "Get Live Events" },
    { id: 32, label: "getEvents", value: "Get Events" },
    { id: 33, label: "getOneReview", value: "Get One Review" },
    { id: 34, label: "registerView", value: "Register View" },
    { id: 35, label: "getBanners", value: "Get Banners" },
    { id: 36, label: "getFeatured", value: "Get Featured" },
    { id: 37, label: "getOneEvent", value: "Get One Event" },
    { id: 38, label: "getBrandEvent", value: "Get brand Event" },
    { id: 39, label: "getFollowers", value: "Get Followers" },
    { id: 40, label: "getInfluencerMedia", value: "Get Influencer Media" },
    { id: 41, label: "getLikedReviews", value: "Get Liked Review" },
    { id: 42, label: "getInfluencerProfile", value: "Get Influencer Profile" },
    { id: 43, label: "getCategories", value: "Get Categories" },
    { id: 44, label: "getSubCategories", value: "Get Sub Categories" },
    { id: 45, label: "getCategoryBanners", value: "Get Category Banners" },
    { id: 46, label: "getCategoryFeatured", value: "Get Category Featured" },
    { id: 47, label: "getLoveBrands", value: "Get Love Brands" },
    { id: 48, label: "getInfluencerBrands", value: "Get Influencer Brands" },
    { id: 49, label: "getProducts", value: "Get Products" },
    { id: 50, label: "getFeaturedBrands", value: "Get Featured Brands" },
    { id: 51, label: "getInfluencers", value: "Get Influencers" },
    { id: 52, label: "connectShopify", value: "Connect Shopify" },
    { id: 53, label: "contactUs", value: "Contact Us" },
    { id: 54, label: "createSalesTracker", value: "Create Sales Tracker" },
    { id: 55, label: "orderCreate", value: "Order Create" },
    { id: 56, label: "createFeed", value: "Create Feed" },
    { id: 57, label: "deleteProfile", value: "Delete Profile" },
    { id: 58, label: "discountCreate", value: "Discount Create" },
    { id: 59, label: "discountActiveDeactive", value: "Discount status" },
    { id: 60, label: "discountDelete", value: "Discount Delete" },
    { id: 61, label: "discountUpdate", value: "Discount Update" },


]
function UserLogs({ getLogs, logs }) {
    const [loading, setLoading] = useState(true)
    const [tableLoading, setTableLoading] = useState(false)
    const [value, setValue] = useState([
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
    ]);
    const [fields, setFields] = useState({
        action: [{ id: 0, label: "All", value: "All" }],
        platform: "all",
        ip_address: "",
        phone: "",
        pixel_id: "",
        account_type: 'all',
        email: '',
        duration: null || ""
    })

    useEffect(() => { onSearch() }, [])

    function onSearch() {
        setLoading(true)

        getLogs(1, fields, value)
            .then(() => {
                setLoading(false)
                setLoading(false)
            })
    }

    function renderClear() {
        return (
            <Col xs={12} md={12} lg={6} xl={3} className="filter-clear-col mb-20">
                <Button
                    className="btn-width btn-gen-margin ml-0 h-100  laptop"
                    disableElevation
                    size="medium"
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={onSearch}
                >
                    Search
                </Button>
                <Button
                    className="btn-width btn-gen-margin grey-btn-outline h-100 laptop"
                    variant="outlined"
                    sx={{ color: 'gray' }}
                    startIcon={<ClearIcon />}
                    onClick={() => {
                        setFields({
                            action: [{ id: 0, label: "All", value: "All" }],
                            platform: "all",
                            ip_address: "",
                            phone: "",
                            pixel_id: "",
                            account_type: 'all',
                            email: '',
                            duration: null || ""
                        })
                        setValue([
                            moment().startOf("year").format("YYYY-MM-DD"),
                            moment().format("YYYY-MM-DD"),
                        ])
                        setLoading(true)
                        getLogs(1, {
                            action: [{ id: 0, label: "All" }],
                            platform: "all",
                            ip_address: "",
                            phone: "",
                            pixel_id: "",
                            account_type: 'all',
                            email: '',
                            duration: null || ""
                        }, [
                            moment().startOf("year").format("YYYY-MM-DD"),
                            moment().format("YYYY-MM-DD"),
                        ])
                            .then(() => {
                                setLoading(false)
                                setLoading(false)
                            })
                    }}
                >
                    Clear
                </Button>
            </Col>
        )

    }
    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="dateBox">
                                <DatePicker
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => { setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]); }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    slotProps={{ textField: { helperText: "YYYY / MM / DD", }, }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => { setValue([value[0], moment(newValue).format("YYYY-MM-DD")]); }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <Autocomplete
                                variant="outlined"
                                className="field-style"
                                size="small"
                                id="disable-clearable"
                                limitTags={2}
                                disableClearable
                                multiple
                                disableCloseOnSelect
                                value={fields.action}
                                // loading={nameLoad}
                                getOptionDisabled={(option) =>
                                    option === options[0]
                                }
                                getOptionLabel={(option) => option.value}
                                options={options}
                                onChange={(e, data) => {
                                    if (data.length > 1) {
                                        setFields({ ...fields, action: data?.filter(f => f.label != "All") })
                                    } else if (data.length === 0) {
                                        setFields({ ...fields, action: [{ id: 0, label: "All", value: "All" }] })
                                    } else {
                                        setFields({ ...fields, action: data })
                                    }

                                }}
                                renderInput={(params) => <TextField {...params} label="Action" onChange={(e) => {

                                }} />}
                            />
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel id="demo-simple-select-label1">Platform</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Platform"
                                labelId="Platform"
                                id="Platform"
                                label="Platform"
                                value={fields.platform}
                                onChange={(e) => {
                                    setFields({ ...fields, platform: e.target.value })
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="web">Web</MenuItem>
                                <MenuItem value="app">App</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel id="demo-simple-select-label1">Account Type</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Account Type"
                                labelId="Account Type"
                                id="Account Type"
                                label="Account Type"
                                value={fields.account_type}
                                onChange={(e) => {
                                    setFields({ ...fields, account_type: e.target.value })
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="brand">Brand</MenuItem>
                                <MenuItem value="influencer">User</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.pixel_id}
                            onChange={e => setFields({ ...fields, pixel_id: e.target.value })}
                            id="outlined-basic"
                            label="Pixel ID"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.phone}
                            onChange={e => setFields({ ...fields, phone: e.target.value })}
                            id="outlined-basic"
                            label="Phone"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.email}
                            onChange={e => setFields({ ...fields, email: e.target.value })}
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.ip_address}
                            onChange={e => setFields({ ...fields, ip_address: e.target.value })}
                            id="outlined-basic"
                            label="IP Address"
                            variant="outlined"
                            className="field-style" />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            type="number"
                            value={fields.duration}
                            onChange={e => setFields({ ...fields, duration: e.target.value })}
                            id="outlined-basic"
                            label="API Duration"
                            variant="outlined"
                            className="field-style" />
                    </Col>
                    {renderClear()}
                </Row>
                {loading ? <SkeletonLoader /> : (
                    <DataTable
                        tableLoading={tableLoading}
                        nowGet={(page) => getLogs(page, fields, value)}
                        logs={logs} />
                )}
            </div>
        </React.Fragment>
    );
}


const mapStateToProps = ({ logs }) => {
    return { logs };
};

export default connect(mapStateToProps, logActions)(UserLogs);
