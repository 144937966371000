import konnect from "./axios"
import { APP_SETTING } from "./type"

export const getAppSetting = () => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/appSetting/getAllCriteria')
        dispatch({
            type: APP_SETTING,
            payload: res.data.data[0]
        })
        return res.data.data[0]
    } catch (err) {
        console.log(err.response)
    }
}

export const updateAppSetting = (data) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/appSetting/updateCriteria', data)
    return res
}