import { Box, Button, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save';
import * as feedAction from "../../store/actions/feedAction"
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

export const ForyouFeed = ({ type, getRange, forYouFeedSetting, updateForYouFeedSetting }) => {
    const [load, setLoad] = useState(false)
    const [fields, setFields] = useState({
        posts_to_read: 0, range: 0, is_category: false, most_liked_percent: 0, most_viewed_percent: 0, most_shared_percent: 0, boosted_percent: 0
    })
    const [value, setValue] = useState(0)
    const [flag, setFlag] = useState(false)
    const [error, setError] = useState(false);
    const [msg, setMSG] = useState('')
    const [error1, setError1] = useState(false);
    const [msg1, setMSG1] = useState('')
    useEffect(() => {
        getData()
    }, [])

    function getData() {
        setLoad(true)
        forYouFeedSetting('review_foryou_feed_creation').then(res => {
            if (res.success) {
                setFields({
                    range: res?.message?.date_range ? res?.message?.date_range : 0,
                    posts_to_read: res?.message?.posts_to_read ? res?.message?.posts_to_read : 0,
                    most_liked_percent: res?.message?.section1 ? res?.message?.section1?.most_liked_percent : 0,
                    most_viewed_percent: res?.message?.section1 ? res?.message?.section1?.most_viewed_percent : 0,
                    most_shared_percent: res?.message?.section1 ? res?.message?.section1?.most_shared_percent : 0,
                    boosted_percent: res?.message?.boosted_percent ? res?.message?.boosted_percent : 0,
                    is_category: res?.message?.is_category ? res?.message?.is_category : false,
                    ...res?.message
                })
                setFlag(true)
                setLoad(false)
            }
        })
    }


    function submit() {
        if (maxWeight !== 100  || fields.boosted_percent >100 ) {
            if(maxWeight !== 100){
            setMSG("Total Weight should be equal to 100")
            setError(true)
            }
            if(fields.boosted_percent >100 ){
                setMSG1('Boosted % must not be greater than 100')
                setError1(true)
            }
            return
        }
        setMSG('')
        setError(false)
        setMSG1('')
        setError1(false)

        console.log(fields)

        const data = {
            type: "review_foryou_feed_creation",
            posts_to_read: fields.posts_to_read,
            boosted_percent:fields.boosted_percent,
            is_category: fields.is_category,
            date_range: fields.range,
            section1: {
                most_liked_percent: fields.most_liked_percent,
                most_shared_percent: fields.most_shared_percent,
                most_viewed_percent: fields.most_viewed_percent,
                post_count: Math.floor((fields.posts_to_read / 100) * 50)
            },
            section2: {
                post_count: Math.floor((fields.posts_to_read / 100) * 50)
            }
        }

        setLoad(true)
        updateForYouFeedSetting(data, 'review_foryou_feed_creation').then((res) => {
            if (res?.success) {
                toast.success(`${res?.message}`)
                setLoad(false)
            } else {
                toast.error(`${res?.message}`)
                setLoad(false)
            }
            getData()
        })
    }


    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    useEffect(() => {
        if (flag) {
            handleChange(fields.range)
        }
    }, [flag])
    const handleChange = (range) => {
        if (range > 0) {
            getRange({ type, range: Number(range) }).then(res => {
                if (res?.success) {
                    setValue(res?.message)
                }
            })
        }
    };
    const optimizedFn = useCallback(debounce(handleChange), []);
    const maxWeight = useMemo(() => {
        const obj = {
            most_liked_percent: fields.most_liked_percent,
            most_viewed_percent: fields.most_viewed_percent,
            most_shared_percent: fields.most_shared_percent,
        }
        return Object.values(obj).reduce((acc, val) => acc += Number(val), 0)
    }, [fields])
    function addWeight(name, val) {
        if (val <= 100 && val >= 0) {
            setFields({ ...fields, [name]: val === "" ? 0 : val })
        }
    }
    return (
        <div className='infoSection new mb-3' style={{ background: "#FFF", }} >
            <Box className="addons-cart-title d-flex align-items-center">
                <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                    For You
                </Typography>

            </Box>
            <Divider className='mb-20' />
            <Row>
                <Col xs={8} className='mb-2'>
                    <div className='w-100'>
                        <TextField
                            // disabled={load}
                            size="small"
                            value={fields.range}
                            onChange={e => {
                                let val = e.target.value;
                                if (val > 0) {
                                    setValue(0)
                                    setFields({ ...fields, range: val })
                                    optimizedFn(val)
                                }
                            }}
                            id="outlined-basic"
                            type='number'
                            label="Date Range"
                            InputProps={{
                                inputProps: {
                                    min: 1
                                },
                                endAdornment: <InputAdornment position="end">days</InputAdornment>,

                            }}
                            variant="outlined"
                            className="field-style"
                        />
                        <div style={{ textAlign: 'right', fontSize: "0.8rem", color: "gray", fontWeight: "500" }} >
                            {`${moment().subtract(fields.range, 'days').format("D MMM YY")} - ${moment().format("D MMM YY")}`}
                        </div>
                    </div>
                </Col>
                <Col xs={4}>
                    <TextField
                        // disabled={load}
                        size="small"
                        value={`${value} videos`}
                        id="outlined-basic"
                        inputProps={{ readOnly: true, }}
                        label=" "
                        variant="outlined"
                        InputLabelProps={{ shrink: false }}
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.posts_to_read}
                        onChange={e => {
                            let val = e.target.value;
                            if (val > 0 && val <= value) {
                                setFields({ ...fields, posts_to_read: val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Number of Posts"
                        InputProps={{
                            inputProps: {
                                min: 1,
                                max: value
                            }
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <TextField
                        disabled={load}
                        size="small"
                        value={fields.boosted_percent}
                        onChange={e => {
                            let val = e.target.value;
                            if (val > 0 && val <= value) {
                                setFields({ ...fields, boosted_percent: val })
                            }
                        }}
                        id="outlined-basic"
                        type='number'
                        label="Boosted Videos %"
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 100
                            }
                        }}
                        variant="outlined"
                        className="field-style mb-15"
                    />
                    {error1 && <Col className='mb-3 -mt-1' xs={8}>
                                <div className='d-flex justify-content-between'>
                                    <div><span style={{ color: "#f51313", fontSize: "0.7rem", fontWeight: "600" }}>{msg1}</span></div>
                                </div>
                            </Col>}
                    {/* <div>{error1 && <span style={{ color: "#f51313", fontSize: "0.7rem", fontWeight: "600" }}>{msg1}</span>}</div> */}
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel
                            id="demo-simple-select-label"
                        >Include Category</InputLabel>
                        <Select
                            // disabled={load}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fields.is_category}
                            label="Include Category"
                            onChange={(e) => {
                                let val = e.target.value
                                setFields({ ...fields, is_category: val })
                            }}
                        >
                            <MenuItem value={true}>Enable</MenuItem>
                            <MenuItem value={false}>Disable</MenuItem>
                        </Select>
                    </FormControl>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className='mb-2 newVideoFeed'>
                        <h6 style={{ color: "#052977" }}>Section 1 (Overall Platform)</h6>
                        <Divider className='mb-20' />
                        <Row>
                            <Col xs={12}>
                                <TextField
                                    disabled
                                    size="small"
                                    value={Math.floor((fields.posts_to_read / 100) * 50)}
                                    id="outlined-basic"
                                    type='number'
                                    label="Post Count"
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <TextField
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Most Liked %"}
                                />
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                <TextField
                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='most_liked_percent'
                                    value={fields.most_liked_percent}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addWeight(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <TextField
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Most Viewed %"}
                                />
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                <TextField
                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='most_viewed_percent'
                                    value={fields.most_viewed_percent}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addWeight(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <TextField
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Most Shared %"}
                                />
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                <TextField
                                    label=" "
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    type='number'
                                    name='most_shared_percent'
                                    value={fields.most_shared_percent}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        addWeight(e.target.name, e.target.value)
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Divider className='mb-2' />
                        <Row>
                            <Col className='mb-2' xs={8}>
                                <div className='d-flex justify-content-between'>
                                    <div>Total</div>
                                    <div>{error && <span style={{ color: "#f51313", fontSize: "0.7rem", fontWeight: "600" }}>{msg}</span>}</div>
                                </div>
                            </Col>
                            <Col className='mb-2 text-right' xs={4}>
                                {maxWeight}%
                            </Col>
                        </Row>
                        <Divider className='mb-3' />
                    </div>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col xs={12}>
                    <div className='mb-2 newVideoFeed'>
                        <h6 style={{ color: "#052977" }}>Section 2 (New)</h6>
                        <Divider className='mb-20' />
                        <Row>
                            <Col xs={12}>
                                <TextField
                                    disabled
                                    size="small"
                                    value={Math.floor((fields.posts_to_read / 100) * 50)}
                                    id="outlined-basic"
                                    type='number'
                                    label="Post Count"
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={12} className='text-right mb-2'>
                    <Button
                        className="btn-width btn-gen-margin  mr-0 h-100"
                        disableElevation
                        size="medium"
                        variant="contained"
                        startIcon={<SaveIcon />}
                        onClick={submit}
                    >
                        Save
                    </Button>
                </Col>
            </Row>


        </div>
    )
}

const mapStateToProps = ({ }) => ({})
export default connect(mapStateToProps, feedAction)(ForyouFeed)