import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';
import { parseISO } from "date-fns";
import { Col, Row } from 'react-bootstrap'
import { Autocomplete, Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SkeletonLoader from "reUsable/skeleton";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import *  as commentsActions from 'store/actions/comments'
import DataTable from "./BanTable.js"
import ConfirmModal from 'reUsable/ConfirmModal';
import SaveModal from "reUsable/SaveModal"
import DateBtn from 'reUsable/DateBtn';
import { toast } from 'react-toastify';
export const BanUsers = ({ feedUsers, getBanUsers, deleteComment, banUser, banUserList, unbanUser }) => {
    const [fields, setFields] = useState({ ban_type: 'chatban' })
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [temp, setTemp] = useState(null)
    const [flag, setFlag] = useState('chatban')
    useEffect(() => {
        getData(fields)
    }, [])

    function handleSearch(type) {
        if (type === "refresh") {
            setFields({ ban_type: 'chatban' })
            getData({ ban_type: 'chatban' }, true)
        } else {
            getData(fields)
        }
    }

    const getData = (obj, f) => {
        setLoading(true)
        getBanUsers({ ...obj }, 1).then(() => {
            setFlag(f ? "chatban" : fields.ban_type)
            setLoading(false)
        })
    }

    function unBan() {
        unbanUser(temp).then((res) => {
            if (res?.success) {
                toast.success(res?.message)
            } else {
                toast.error(res?.message)
            }
            getData({ ...fields })
        })
        setTemp(null)
        setModal(false)
    }


    function unban(data) {
        if (flag === "chatban") {
            setTemp({
                user_id: data?.user_id,
                review_id: data?.review_id,
                ban_type: 'chatban'
            })
        } else {
            setTemp({
                user_id: data?._id,
                ban_type: 'permaban'
            })
        }
        setModal(true)
    }

    return (
        <div className='container-fluid mt-3 mb-3 pl-0'>
            <SaveModal
                visible={modal}
                title={`Are you sure you want to unban this user?`}
                close={() => {
                    setTemp(null)
                    setModal(false)
                }}
                type="status"
                call={unBan}
            />

            <Row className='pl-15'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Ban Users
                    </Typography>
                </Col>
            </Row>

            <Row className="account-main-row pl-15">

                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel
                            id="demo-simple-select-label"
                        >Ban Type</InputLabel>
                        <Select
                            sx={[{ color: "black" }]}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fields.ban_type}
                            label="Ban Type"
                            onChange={(e) => {
                                setFields({ ...fields, ban_type: e.target.value, })
                            }}
                        >
                            <MenuItem value={'chatban'}>Chat Ban</MenuItem>
                            <MenuItem value={'permaban'}>Permanent Ban</MenuItem>
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <Button
                        variant="contained"
                        size="medium"
                        className="btn-gen-margin btn-width laptop ml-0"
                        color="primary"
                        startIcon={<SearchIcon />}
                        disableElevation
                        onClick={() => {
                            handleSearch()
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        startIcon={<RefreshIcon />}
                        className="btn-gen-margin btn-width laptop"
                        color="success"
                        disableElevation
                        onClick={() => { handleSearch('refresh') }}
                    >
                        Refresh
                    </Button>
                </Col>
            </Row>
            <Divider className="ml-15" />
            {loading ? (
                <div className="pl-15" style={{ marginTop: 16 }}>
                    <SkeletonLoader />
                </div>
            ) : (
                <DataTable
                    type={flag}
                    data={banUserList}
                    unban={unban}
                    nowGet={(page) => {
                        getBanUsers({ ...fields }, page)
                    }}
                />
            )}
        </div>
    )
}

const mapStateToProps = ({ comments, banUserList, }) => ({ comments, banUserList })
export default connect(mapStateToProps, { ...commentsActions })(BanUsers)