import konnect from "./axios"
import { GET_AUTH_LIST } from "./type"


export const getAuthList = () => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/access/getActiveDeactiveAuthList')
        dispatch({
            type: GET_AUTH_LIST,
            payload: res.data?.data
        })
    } catch (err) {
        console.log(err.response)
    }
}

export const changePermission = (data) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/access/activeDeactiveLoginSignup', data)
    return res
}

export const makeMasterUser = (user_id) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/addLoginPermission', { user_id })
    return res
}

export const deleteMasterUser = (_id) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/deleteUserLoginPermission', { _id })
    return res
}

export const deleteUser = (_id) => async (dispatch) => {
    // const res=await konnect.post('/v1/admin/appSetting/deleteUser',{_id})
    const res = await konnect.post('/v1/admin/crm/updatestatus', { user_id: _id, is_active: false, is_deleted: true })
    return res.data
}
