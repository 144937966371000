import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CreateIcon from '@mui/icons-material/AddCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import { connect } from 'react-redux';
import * as accActions from "../../../store/actions/access"
import * as countryActions from "../../../store/actions/country"
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormHelperText } from '@mui/material';
const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
const validationSchema = yup.object({
    company_name: yup.string('Enter your Brand Name').required('Brand Name is required'),
    website: yup.string('Enter your Website').matches(
        /(https?:\/\/)?([\da-z\.-]+)\.([a-z]{2,6})([\/\w\.-]*)*\/?/,
        'Enter correct url!'
    ).required('Website is required'),
    email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    // phone: yup.string('Enter your Phone Number').required('Phone Number is required'),
    phone: yup.string('Enter your Phone Number').matches(phoneRegExp, 'Phone number is not valid').required('Phone Number is required'),
    is_active: yup.bool().required('Status is required'),
    address: yup.string('Enter your Address').required('Address is required'),
    address_2: yup.string('Enter your Address Line 2'),
    country: yup.string('Enter your Country').required('Country is required'),
    state: yup.string('Enter your State').required('State is required'),
    city: yup.string('Enter your City').required('City is required'),
    zip: yup.string('Enter your Zip Code').required('Zip Code is required'),
    password: yup.string('Enter your password').required('Password is required'),
    con_password: yup.string("Enter your Confirm password").required('Password is required').oneOf([yup.ref('password'), null], 'Passwords must match'),
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function AddBrand({ getCountries, getStates, getCity, countries, createBrand }) {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState({
        company_name: "",
        website: "",
        email: "",
        phone: "",
        is_active: true,
        address: '',
        address_2: '',
        country: "",
        state: "",
        city: "",
        zip: "",
        password: "",
        con_password: "",
    })
    const [states, setState] = useState([])
    const [city, setCity] = useState([])
    useEffect(() => { getCountries() }, [])

    const formik = useFormik({
        initialValues: {
            // email: '',
            // password: '',
            // con_password: '',
            // company_name: '',
            // website: '',
            // country: '',
            // state: '',
            // city: '',
            // is_active: true
            company_name: "",
            website: "",
            email: "",
            phone: "",
            is_active: true,
            address: '',
            address_2: '',
            country: "",
            state: "",
            city: "",
            zip: "",
            password: "",
            con_password: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true)
            createBrand(values)
                .then((res) => {
                    if (res.success) {
                        toast.success(res.message, {
                            autoClose: 3000,
                            hideProgressBar: true,
                        });
                    }
                    setLoading(false)
                    setTimeout(() => {
                        history.push("/brand")
                    }, 1000)
                })
                .catch(err => {
                    toast.error(err.message, {
                        autoClose: 3000,
                        hideProgressBar: true
                    })
                    setLoading(false)
                })
        },
    });
    return (
        <div style={{ padding: 20 }}>
            <div className='row'>
                <div className='col-lg-8 col-xl-6'>
                    <Paper variant="outlined" className='box-style-new' style={{ padding: 20, borderRadius: 7 }}>

                        <Typography className='generic-hd2' style={{ textAlign: 'left', color: "primary" }} variant="h5" gutterBottom component="div">
                            Add Brand
                        </Typography>
                        <p>Brand onboarding</p>
                        <hr className='divider-line mb-30' />
                        <form onSubmit={formik.handleSubmit}>
                            <div >
                                <span style={{ marginBottom: "5px " }}>Basic Information</span>
                                <Row style={{ marginTop: "10px", }} >
                                    <Col md={12} lg={6} xl={6}>
                                        <TextField
                                            // onChange={e => getValue('company_name', e.target.value)}
                                            // error={submit && !fields.company_name ? true : false}
                                            // helperText={submit && !fields.company_name ? "Please fill the Field" : null}
                                            id="company_name"
                                            name="company_name"
                                            label="Brand Name"
                                            size="small"
                                            className="field-style"
                                            style={styles.input}
                                            variant="outlined"
                                            value={formik.values.company_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                                            helperText={formik.touched.company_name && formik.errors.company_name}
                                        />
                                    </Col>
                                    <Col md={12} lg={6} xl={6}>
                                        <TextField
                                            // onChange={e => getValue('website', e.target.value)}
                                            // error={submit && !fields.website ? true : false}
                                            // helperText={submit && !fields.website ? "Please fill the Field" : null}
                                            id="website"
                                            name='website'
                                            label="Website"
                                            type="text"
                                            size="small"
                                            className="field-style"
                                            style={styles.input}
                                            variant="outlined"
                                            value={formik.values.website}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.website && Boolean(formik.errors.website)}
                                            helperText={formik.touched.website && formik.errors.website}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} lg={6} xl={4}>
                                        <span style={{ marginBottom: "5px" }}>Email</span>
                                        <TextField
                                            id="email"
                                            name="email"
                                            label="Email"
                                            size="small"
                                            className="field-style"
                                            variant="outlined"
                                            style={styles.span}
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </Col>
                                    <Col md={12} lg={6} xl={4}>
                                        <span style={{ marginBottom: "5px" }}>Phone #</span>
                                        <TextField
                                            id="phone"
                                            name='phone'
                                            label="Phone #"
                                            type="text"
                                            size="small"
                                            className="field-style"
                                            style={styles.span}
                                            variant="outlined"
                                            value={formik.values.phone}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                                            helperText={formik.touched.phone && formik.errors.phone}
                                        />
                                    </Col>
                                    <Col md={12} lg={6} xl={4}>
                                        <span style={{ marginBottom: "5px" }}> Account Status</span>
                                        <FormControl className="field-style multiple-checkbox-style">
                                            {/* <InputLabel id="demo-simple-select-label">Account Status</InputLabel> */}
                                            <Select
                                                placeholder='Select Account Status'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label=" "
                                                size="small"
                                                name='is_active'
                                                style={styles.span}
                                                value={formik.values.is_active}
                                                error={formik.touched.is_active && Boolean(formik.errors.is_active)}
                                                onChange={(e) => {
                                                    setFields({ ...fields, is_active: e.target.value })
                                                    formik.handleChange(e)
                                                }}
                                            >
                                                <MenuItem value={true}>Active</MenuItem>
                                                <MenuItem value={false}>Deactive</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col>
                                </Row>
                                <span style={{ marginBottom: "5px" }}>Address</span>
                                <Row style={{ marginTop: "10px", }} >
                                    <Col md={12} lg={12} xl={12}>
                                        <TextField
                                            id="address"
                                            name="address"
                                            label="Street Address"
                                            size="small"
                                            className="field-style"
                                            style={styles.input}
                                            variant="outlined"
                                            value={formik.values.address}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.address && Boolean(formik.errors.address)}
                                            helperText={formik.touched.address && formik.errors.address}
                                        />
                                    </Col>
                                    <Col md={12} lg={12} xl={12}>
                                        <TextField
                                            id="address_2"
                                            name="address_2"
                                            label="Street Address Line 2"
                                            size="small"
                                            className="field-style"
                                            variant="outlined"
                                            style={styles.input}
                                            value={formik.values.address_2}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.address_2 && Boolean(formik.errors.address_2)}
                                            helperText={formik.touched.address_2 && formik.errors.address_2}
                                        />
                                    </Col>
                                    <Col md={12} lg={6} xl={4}>
                                        <FormControl
                                            className="field-style multiple-checkbox-style"
                                            style={styles.input}

                                        >
                                            <InputLabel
                                                id="demo-simple-select-label"
                                            >Country</InputLabel>
                                            <Select
                                                MenuProps={MenuProps}
                                                placeholder='Select Country'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Country"
                                                name='country'
                                                size="small"
                                                value={formik.values.country}
                                                error={formik.touched.country && Boolean(formik.errors.country)}
                                                onBlur={formik.handleBlur}
                                                onChange={(e) => {
                                                    formik.resetForm({ values: { ...formik.values, city: '', state: '' } })
                                                    setState([])
                                                    setFields({ ...fields, country: e.target.value, state: '', city: '' })
                                                    getStates(e.target.value).then((res) => {
                                                        if (res.success) {
                                                            setState((prev) => res.message)
                                                        }
                                                    })
                                                    formik.handleChange(e)
                                                }}
                                            >
                                                {
                                                    countries?.map(item => <MenuItem value={item.code1}>{item.name}</MenuItem>)
                                                }
                                            </Select>
                                            {formik.touched.country && <FormHelperText style={{ color: "#d32f2f" }} >{formik.errors.country}</FormHelperText>}
                                        </FormControl>
                                    </Col>
                                    <Col md={12} lg={6} xl={4}>
                                        <FormControl
                                            className="field-style multiple-checkbox-style"
                                            style={styles.input}>
                                            <InputLabel
                                                id="demo-simple-select-label"
                                            >State / Province</InputLabel>
                                            <Select
                                                MenuProps={MenuProps}
                                                placeholder='Select State'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="State / Province"
                                                size="small"
                                                name='state'
                                                value={formik.values.state}
                                                error={formik.touched.state && Boolean(formik.errors.state)}
                                                onChange={(e) => {
                                                    formik.resetForm({ values: { ...formik.values, city: '' } })
                                                    setCity([])
                                                    setFields({ ...fields, state: e.target.value, city: '' })
                                                    getCity({ country_code: fields.country, state_code: e.target.value }).then(res => {
                                                        if (res.success) {
                                                            setCity(res.message)
                                                            setCity((prev) => res.message)
                                                        }
                                                    })
                                                    formik.handleChange(e)
                                                }}
                                            >
                                                {states?.map(item => <MenuItem value={item.isoCode}>{item.name}</MenuItem>)}
                                            </Select>
                                            {formik.touched.state && <FormHelperText style={{ color: "#d32f2f" }} >{formik.errors.state}</FormHelperText>}
                                        </FormControl>
                                    </Col>
                                    <Col md={12} lg={6} xl={4}>
                                        <FormControl
                                            className="field-style multiple-checkbox-style"
                                            style={styles.input}>
                                            <InputLabel
                                                id="demo-simple-select-label"
                                            >City</InputLabel>
                                            <Select
                                                MenuProps={MenuProps}
                                                placeholder='Select City'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="City"
                                                name="city"
                                                size="small"
                                                value={formik.values.city}
                                                error={formik.touched.city && Boolean(formik.errors.city)}
                                                onChange={(e) => {
                                                    setFields({ ...fields, city: e.target.value })
                                                    formik.handleChange(e)
                                                }}
                                            >
                                                {city?.map(item => <MenuItem value={item.name}>{item.name}</MenuItem>)}
                                            </Select>
                                            {formik.touched.city && <FormHelperText style={{ color: "#d32f2f" }} >{formik.errors.city}</FormHelperText>}

                                        </FormControl>
                                    </Col>
                                    <Col md={12} lg={12} xl={12}>
                                        <TextField
                                            id="zip"
                                            name="zip"
                                            label="Postal / Zip Code"
                                            size="small"
                                            className="field-style"
                                            variant="outlined"
                                            style={styles.input}
                                            value={formik.values.zip}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.zip && Boolean(formik.errors.zip)}
                                            helperText={formik.touched.zip && formik.errors.zip}
                                        />
                                    </Col>
                                </Row>
                                <span style={{ marginBottom: "5px" }}>Security</span>
                                <Row style={{ marginTop: "10px" }}>
                                    <Col md={12} lg={6} xl={6}>
                                        <TextField
                                            id="password"
                                            name="password"
                                            label="Password"
                                            size="small"
                                            type='password'
                                            className="field-style"
                                            style={styles.input}
                                            variant="outlined"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.password && Boolean(formik.errors.password)}
                                            helperText={formik.touched.password && formik.errors.password}

                                        />
                                    </Col>
                                    <Col md={12} lg={6} xl={6}>
                                        <TextField
                                            id="con_password"
                                            name="con_password"
                                            label="Confirm Password"
                                            size="small"
                                            type='password'
                                            className="field-style"
                                            style={styles.input}
                                            variant="outlined"
                                            value={formik.values.con_password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.con_password && Boolean(formik.errors.con_password)}
                                            helperText={formik.touched.con_password && formik.errors.con_password}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className='boxes-buttons-row2'>
                                <LoadingButton
                                    type='submit'
                                    loading={loading}
                                    variant="contained"
                                    size="medium"
                                    className="btn-gen-margin btn-width ml-0"
                                    color="primary"
                                    disableElevation
                                    endIcon={<SaveIcon />}
                                >
                                    Save
                                </LoadingButton>
                                <Button
                                    onClick={() => history.push('/brand')}
                                    variant="outlined"
                                    size="medium"
                                    className="btn-gen-margin btn-width grey-btn-outline"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </form>

                    </Paper>
                </div>
            </div>


        </div >
    );
}

const styles = {
    input: {
        marginBottom: 20,
    },
    span: {
        marginTop: 5,
        marginBottom: 20
    }
}

function mapStateToProps({ countries }) {
    return { countries }
}
export default connect(mapStateToProps, { ...accActions, ...countryActions })(AddBrand)