import konnect from "./axios"
import { PROMOTION_SALES, STRIPE_PAYMENTS } from "./type"

export const getPromotionsSettings = (type) => async (dispatch) => {
    try {
        const res = await konnect.get('/v1/admin/promotion/getPromotionCriteria ')
        return res.data
    } catch (err) {
        console.log(err.response)
        return err.response
    }
}

export const updatePromotionCriteria = (data) => async (dispatch) => {
    const res = await konnect.post(`/v1/admin/promotion/promotionCriteria`, data)
    return res.data
}

export const getPromotionSales = (fields, date, page) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/promotedVideos/promotedVidReport', { ...fields }, {
            params: {
                limit: 12,
                page
            }
        })
        dispatch({
            type: PROMOTION_SALES,
            payload: res.data
        })
    } catch (err) {
        console.log(err.response)
    }
}
export const paymentMethods = (fields) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/promotedVideos/getPaymentMethods', { ...fields })
        return res.data
    } catch (err) {
        console.log(err.response)
        return err.response
    }
}

export const getStripeDetail = (fields, page) => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/promotion/stripeChargeDetails', { ...fields }, {
            params: {
                limit: 12,
                page
            }
        })
        dispatch({
            type: STRIPE_PAYMENTS,
            payload: res.data
        })
    } catch (err) {
        console.log(err.response)
    }
}