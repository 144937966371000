import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Box, Button, Divider, InputAdornment, TextField, Typography } from '@mui/material'
// import FollowingFeed from "./FollowingFeed"
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import * as promotionAction from "../../store/actions/promotions"
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/Save';
import SaveModal from "../../reUsable/SaveModal";



function FeedSetting({ getPromotionsSettings, updatePromotionCriteria }) {
    const [load, setLoad] = useState(true)
    const [fields, setFields] = useState({ price: 0, impression: 0, threshold: 0 })
    const [modal, setModal] = useState(false)
    useEffect(() => {
        getData()
    }, [])
    function submit() {
        setLoad(true)
        setModal(false)
        updatePromotionCriteria(fields).then((res) => {
            if (res?.success) {
                toast.success(`${res?.message}`)
            } else {
                toast.error(`${res?.message}`)
            }
            getData()
        })
    }

    function getData() {
        getPromotionsSettings().then(res => {
            if (res?.success) {
                setFields({
                    impression: res?.data?.[0]?.impression,
                    price: res?.data?.[0]?.price,
                    threshold: res?.data?.[0]?.threshold,
                })
                setLoad(false)
            } else {
                setLoad(false)
            }
        })
    }

    return (
        <div className="container-fluid mb-3">
            <SaveModal
                visible={modal}
                title={"Are you sure you want to save these changes?"}
                close={() => {
                    setModal(false)
                }}
                type="status"
                call={submit}
            />
            <Row className='pt-3'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Promotion Criteria
                    </Typography>
                </Col>
            </Row>
            <Divider className="mb-15" />
            <Row className="d-flex justify-content-betwee">
                <Col xl={5} lg={6} md={12}>

                    <div className='infoSection new mb-3' style={{ background: "#FFF", }} >
                        <Box className="addons-cart-title d-flex align-items-center">
                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                Promotion Criteria Setting
                            </Typography>

                        </Box>
                        <Divider className='mb-20' />

                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <TextField
                                    disabled={load}
                                    size="small"
                                    value={fields.threshold}
                                    onChange={e => {
                                        let val = e.target.value;
                                        if (val > 0) {
                                            setFields({ ...fields, threshold: val })
                                        }
                                    }}
                                    id="outlined-basic"
                                    type='number'
                                    label="Threshold Amount"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        inputProps: {
                                            min: 1
                                        }
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <TextField
                                    disabled={load}
                                    size="small"
                                    value={fields.price}
                                    onChange={e => {
                                        let val = e.target.value;
                                        if (val > 0) {
                                            setFields({ ...fields, price: val })
                                        }
                                    }}
                                    id="outlined-basic"
                                    type='number'
                                    label="Price"

                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">$ </InputAdornment>,
                                        inputProps: {
                                            min: 1
                                        }
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <TextField
                                    disabled={load}
                                    size="small"
                                    value={fields.impression}
                                    onChange={e => {
                                        let val = e.target.value;
                                        if (val > 0) {
                                            setFields({ ...fields, impression: val })
                                        }
                                    }}
                                    id="outlined-basic"
                                    type='number'
                                    label="Impressions"
                                    InputProps={{
                                        inputProps: {
                                            min: 1
                                        }
                                    }}
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>

                        <Divider className='mb-3' />
                        <Row>
                            <Col xs={12} className='text-right mb-2'>
                                <Button
                                    disabled={load}
                                    className="btn-width btn-gen-margin  mr-0 h-100"
                                    disableElevation
                                    size="medium"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    onClick={() => setModal(true)}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>


                    </div>

                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = ({ }) => ({})
export default connect(mapStateToProps, promotionAction)(FeedSetting)