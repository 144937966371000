import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import * as catActions from "../../../store/actions/categories"
import * as subCatAction from "../../../store/actions/subCategory"
import { Row, Col } from "react-bootstrap";
import SkeletonLoader from "../../../reUsable/skeleton";
import { Button, Divider, Typography } from '@mui/material';
import AddModal from "./AddCategory";
import Categories from 'reUsable/subCategory';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import NotFound from "../../../reUsable/NotFound"
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import * as catActions from "../../../store/actions/Categories/categories"
import { useMemo } from 'react';

function Category({ getGenderCategories, genderCategories, getCategories, categories }) {
    const [loading, setLoading] = useState(true)
    const [addModal, setAddModal] = useState(false)
    const [cat, setCat] = useState([])
    const [subCat, setSubCat] = useState([]);
    const [search, setSearch] = useState('')

    useEffect(() => {
        Promise.all([getCategories(), getGenderCategories()]).finally(() => setLoading(false))
    }, [])

    const settingData = useMemo(() => {
        if (categories.success) {
            setCat(categories?.message)
        } else {
            setCat([])
        }
    }, [categories])

    function renderCategories() {
        if (cat?.some(s => s?.categories.length > 0)) {
            return cat?.map((item) => (
                <Categories
                    item={item}
                    reloadData={() => {
                        getCategories().then((res) => {
                            if (res?.success) {
                                setCat(res?.message)
                            } else {
                                setCat([])
                            }
                        })
                    }} catData={genderCategories} />
            ))
        } else {
            return <div className='col' >
                <NotFound />
            </div>
        }

    }
    return (
        <React.Fragment>
            <AddModal
                visible={addModal}
                close={() => setAddModal(false)}
                reloadData={() => {
                    getCategories().then((res) => {
                        setSubCat(res)
                    })
                }}
                catData={genderCategories}
            />
            <div className="table-width container-fluid">
                <Row className='pt-3'>
                    <Col xs className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            Categories
                        </Typography>
                    </Col>
                    <Col xs className="mb-3 text-right">
                        <Button
                            onClick={() => setAddModal(true)}
                            className='btn-width'
                            startIcon={<AddIcon />}
                            disableElevation
                            variant="contained">
                            Add Category
                        </Button>
                    </Col>
                </Row>
                <Divider className="mb-15" />
                {!loading ? (
                    <Row>
                        {renderCategories()}
                    </Row>
                ) : <SkeletonLoader />}
            </div>
        </React.Fragment>
    );
}

function mapStateToProps({ genderCategories, categories }) {
    return { genderCategories, categories }
}

export default connect(mapStateToProps, { ...catActions })(Category)
