import konnect from "./axios"

export const getTeams = (data, date) => async (dispatch) => {
    try {
        let url = `/v1/admin/agency/agencyAssociation`
        const updated = {
            ...data,
            status: data.status === 'all' ? '' : data?.status,
            is_active: data.is_active === 'all' ? '' : data?.is_active,
            from_date: date[0],
            to_date: date[1],
        }
        const res = await konnect.post(url, updated);
        return res.data
    } catch (err) {
        console.log(err)
    }
}
export const getAgencyEarnings = (user_id, date) => async (dispatch) => {
    try {
        let url = `/v1/admin/agency/earningSummary`
        const updated = {
            user_id,
            from_date: date[0],
            to_date: date[1],
        }
        const res = await konnect.post(url, updated);
        return res.data
    } catch (err) {
        console.log(err)
    }
}

