import konnect from "./axios"

export const addContract = (data) => async (dispatch) => {
    try {

        console.log("data", data)
        const res = await konnect.post('/v1/admin/crm/createContract', data)
        return res
    }
    catch (err) {
        return err.response
    }
}
