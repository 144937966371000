import React, { useCallback, useEffect, useState, useMemo, } from 'react'
import * as salesActions from "../../../store/actions/sales"
import * as categoryActions from "../../../store/actions/Categories/categories"
import * as accountActions from "../../../store/actions/account"

import { connect, } from 'react-redux'
import { InputLabel, FormControl, Select, TextField, Button, MenuItem, Typography, Divider, Box, Autocomplete } from '@mui/material'

import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import numeral from "numeral";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Col, Row } from "react-bootstrap";
import NoRows from "../../../reUsable/NoRows"
import Skeleton from '../../../reUsable/skeleton';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getReferral } from '../../../store/actions/sales'
import { parseISO } from 'date-fns';
import { useDispatch } from 'react-redux'
import "./sale.css"
import Summary from "./Summary"
import OrderModal from './OrderModal'


function ReferralStats({ currentUser, getAccountList, accountList, referral, filterCategories, user_type }) {
    const [value, setValue] = useState([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState(false)
    const [page, setPage] = useState(1);
    const [refData, setRefData] = useState([])
    const dispatch = useDispatch()
    const [fields, setFields] = useState({
        generalCategory: "all",
        category: "all",
        subCategory: "all",
        source: "all",
        brand: 'all',
        user_type: user_type,
        status: 'all'
    })
    const [visible, setVisible] = useState(false)
    const [order, setOrder] = useState(null)

    const [name, setName] = useState("")
    const [nameLoad, setNameLoad] = useState(true)

    const [gen, setGen] = useState([])
    const [cat, setCat] = useState([])
    const [sub, setSub] = useState([])
    function clean() {
        setGen([])
        setCat([])
        setSub([])
        setFields({ ...fields, generalCategory: "all", category: "all", subCategory: "all" })
    }
    useEffect(() => {
        if (fields.brand !== "all") {
            clean()
            filterCategories({ brand_id: fields.brand, source: "genCategory" }).then((res) => {
                if (res.success) {
                    setGen(res?.message)
                } else {
                    setGen([])
                }
            })
        } else {
            clean()
        }
    }, [fields.brand])

    useEffect(() => {
        getData()
        getAccountList('', false, 'brand').then(() => {
            setNameLoad(false)
        })
    }, [])

    useEffect(() => {
        if (search) { getData() }
    }, [search]);
    const getData = () => {
        dispatch(getReferral(currentUser, fields, value, page)).then((res) => {
            setRefData(res?.message?.data)
            setLoading(false)
            setSearch(false)
        })
    }

    const handleSearch = (type) => {
        setPage(1)
        if (type === "refresh") {
            setValue([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
            setFields({ generalCategory: "all", category: "all", subCategory: "all", source: "all", brand: "all", status: 'all', })
            setName('')
            setLoading(true)
            setSearch(true)
        } else {
            setLoading(true)
            setSearch(true)
        }
    }


    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    const handleChange = (val) => {
        getAccountList(val, false, 'brand').then(() => {
            setNameLoad(false)
        })
    };
    const optimizedFn = useCallback(debounce(handleChange), []);

    const noneCol = [
        {
            field: 'id', headerName: 'S.#', width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            }, sortable: false,
        },
        {
            field: "created_date", headerName: "Order Date", width: 130, sortable: false, renderCell: ({ value }) => {
                return moment(value).format('MM-DD-YYYY')
            }
        },
        {
            field: "order_id", headerName: "Order#", width: 110, sortable: false, renderCell: (params) => (<div className='orderBTN' onClick={() => {
                setVisible(true)
                setOrder(params?.row)
            }}>{params?.value}</div>)
        },
        { field: "brand", headerName: "Brand", width: 130, sortable: false, },
        { field: "general_category", headerName: "Department", width: 150, sortable: false, renderCell: ({ value }) => value ? value : "All" },
        { field: "category", headerName: "Category", width: 150, sortable: false, renderCell: ({ value }) => value ? value : "All" },
        { field: "sub_category", headerName: "Sub Category", width: 150, sortable: false, renderCell: ({ value }) => value ? value : "All" },

        { field: "order_totalprice", headerName: "Amount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "returned_amount", headerName: "Return Amount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "balance_amount", headerName: "Balance Amount", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "customer_commission_percent", headerName: "Fee%", width: 150, renderCell: ({ value }) => `${value}%`, sortable: false, },
        { field: "customer_commission", headerName: "Fee Earned", width: 150, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        {
            field: "event", headerName: "Source", width: 150, sortable: false, renderCell: ({ value }) => {
                if (value === "eventshare") { return "Event Share" }
                else if (value === "reviewshare") { return "Review Share" }
            }
        },
        { field: "status", headerName: "Status", width: 150, sortable: false, },
    ]


    const records = useMemo(() => {
        if (referral.success) {
            return referral?.message
        }
    }, [referral])

    return (
        <div className='saleContainer'>
            <OrderModal type="purchase" user={currentUser} data={order} visible={visible} close={() => {
                setOrder(null)
                setVisible(false)
            }} />
            <Typography className='fw-normal' color="primary" variant="h6" gutterBottom component="div">
                Referral Sales
            </Typography>
            <Divider className='' />

            <div className='body'>
                <Row className='mt-4'>
                    <Col xl={3} lg={12} className='mb-3 '>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className='dateBox'>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            helperText: 'YYYY / MM / DD',
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format('YYYY-MM-DD'), value[1]]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            helperText: 'YYYY / MM / DD',
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format('YYYY-MM-DD')]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <Autocomplete
                            variant="outlined"
                            className="field-style"
                            size="small"
                            id="disable-clearable"
                            disableClearable
                            loading={nameLoad}
                            options={nameLoad ? [] : accountList?.message ? accountList?.message?.map(opt => ({ ...opt, label: opt.name })) : []}
                            onChange={(e, data) => {
                                setFields({ ...fields, brand: data?._id })
                                setName(data.label)
                            }}
                            value={name === "" ? "All" : name}
                            renderInput={(params) => <TextField  {...params} label="Brand" onChange={(e) => {
                                setName(e.target.value)
                                setNameLoad(true)
                                optimizedFn(e.target.value)
                            }} />}
                        />
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel id="demo-simple-select-label1">Department</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Department"
                                labelId="Department"
                                id="Department"
                                value={fields.generalCategory}
                                label="Department"
                                onChange={(e) => {
                                    setFields({ ...fields, generalCategory: e.target.value, category: 'all', subCategory: 'all' })
                                    setCat([])
                                    setSub([])
                                    if (e.target.value !== "all") {
                                        filterCategories({ brand_id: fields.brand, source: "category", gen_category_id: e.target.value }).then((res) => {
                                            if (res.success) {
                                                setCat(res?.message)
                                            } else {
                                                setCat([])
                                                setSub([])
                                            }
                                        })
                                    }
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {gen?.map((item) => (<MenuItem value={item._id} >{item.name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel id="demo-simple-select-label1">Category</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Category"
                                labelId="category"
                                id="category"
                                value={fields.category}
                                label="Category"
                                onChange={(e) => {
                                    setFields({ ...fields, category: e.target.value, subCategory: 'all' })
                                    setSub([])
                                    if (e.target.value !== "all") {
                                        filterCategories({ brand_id: fields.brand, source: "subCategory", gen_category_id: fields.generalCategory, category_id: e.target.value }).then((res) => {
                                            if (res.success) {
                                                setSub(res?.message)
                                            } else {
                                                setSub([])
                                            }
                                        })
                                    }
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {cat?.map((item) => (<MenuItem value={item._id} >{item.category_name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Sub Category</InputLabel>
                            <Select
                                style={fields.category !== "" ? {} : { background: "#ebebeb" }}
                                disabled={fields.category === ""}
                                sx={{ color: "black" }}
                                placeholder="Sub Category"
                                labelId="subCategory"
                                id="subCategory"
                                value={fields.subCategory}
                                label="Sub Category"
                                onChange={(e) => setFields({ ...fields, subCategory: e.target.value })}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {sub?.map((item) => (<MenuItem value={item._id} >{item.sub_category_name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Source</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Source"
                                labelId="source"
                                id="source"
                                value={fields.source}
                                label="Source"
                                onChange={(e) => setFields({ ...fields, source: e.target.value })}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"eventsahre"}>Event Share</MenuItem>
                                <MenuItem value={"reviewshare"}>Review Share</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Status</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Status"
                                labelId="status"
                                id="status"
                                value={fields.status}
                                label="Status"
                                onChange={(e) => setFields({ ...fields, status: e.target.value })}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"pending"}>Pending</MenuItem>
                                <MenuItem value={"payable"}>Payable</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width laptop"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => { handleSearch() }}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width laptop"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('refresh') }}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
                <Divider className="separater-sale" />

                <div style={{ height: 460, width: '100%' }}>
                    {
                        loading ? <Skeleton /> :
                            <>
                                <DataGrid
                                    density="compact"
                                    disableColumnFilter={true}
                                    disableColumnSelector
                                    disableColumnMenu
                                    disableSelectionOnClick
                                    components={{ NoRowsOverlay: NoRows, }}
                                    className="bg-white"
                                    rows={
                                        records ? records?.data?.map((item, index) => ({
                                            ...item,
                                            id: index + (page == 1 ? 1 : (page * 10) - 9),
                                            brand: item?.brand?.brand_name,
                                            general_category: item?.gen_category?.name,
                                            category: item?.category?.category_name,
                                            sub_category: item?.sub_category?.sub_category_name ? item?.sub_category?.sub_category_name : "All",
                                            fee: item?.fee_review ? item?.fee_review : item?.fee_event
                                        })) : refData?.map((item, index) => ({
                                            ...item,
                                            id: index + (page == 1 ? 1 : (page * 10) - 9),
                                            brand: item?.brand?.brand_name,
                                            general_category: item?.gen_category?.name,
                                            category: item?.category?.category_name,
                                            sub_category: item?.sub_category?.sub_category_name ? item?.sub_category?.sub_category_name : "All",
                                            fee: item?.fee_review ? item?.fee_review : item?.fee_event
                                        }))
                                    }
                                    columns={noneCol}
                                    rowCount={records ? records?.total_records : 0}
                                    pagination
                                    pageSize={10}
                                    paginationMode="server"
                                    onPageChange={(newPage) => {
                                        setPage(newPage + 1);
                                        dispatch(getReferral(currentUser, fields, value, newPage + 1)).then((res) => {
                                            setLoading(false)
                                            setSearch(false)
                                        })
                                        // getReferral(currentUser, fields, value, newPage + 1).then(() => {
                                        //     setLoading(false)
                                        //     setSearch(false)
                                        // })
                                    }}
                                />
                                <Summary load={loading} data={records?.summary?.[0]} type="referral" />

                            </>
                    }
                </div>
            </div>
        </div >
    )
}

function mapStateToProps({ sales, accountDetail, genderCategories, referral, accountList }) {
    return { sales, accountDetail, genderCategories, referral, accountList }
}
export default connect(mapStateToProps, { ...salesActions, ...categoryActions, ...accountActions })(ReferralStats)

