import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { connect } from 'react-redux';
import * as accActions from "../../../store/actions/access"
import * as countryActions from "../../../store/actions/country"
import * as accountActions from "../../../store/actions/account"

import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useMemo } from 'react';
import { InputLabel } from '@mui/material';
const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function UpdateBrand({ getDetails, updateUser, type, id, close }) {
    // const { id, type } = useParams()
    const [email, setEmail] = useState('')
    const [flag, setFlag] = useState(false)
    const validationSchema = useMemo(() => {
        if (type === "customer") {
            return yup.object({
                first_name: yup.string('Enter First Name').required('First Name is required'),
                last_name: yup.string('Enter Last Name').required('Last Name is required'),
                email: yup.string('Enter email').email('Enter a valid email').required('Email is required'),
                phone: yup.string('Enter Phone Number').matches(phoneRegExp, 'Phone number is not valid').required('Phone Number is required'),
                tiktok_link: yup.string('Enter Tiktok'),
                instagram_link: yup.string('Enter Instagram'),
                linkedin_link: yup.string('Enter LinkedIn'),
                is_active: yup.bool().required('Status is required'),
            });
        } else {
            return yup.object({
                first_name: yup.string('Enter First Name').required('First Name is required'),
                last_name: yup.string('Enter Last Name').required('Last Name is required'),
                email: yup.string('Enter email').email('Enter a valid email').required('Email is required'),
                phone: yup.string('Enter Phone Number').matches(phoneRegExp, 'Phone number is not valid').required('Phone Number is required'),
                is_active: yup.bool().required('Status is required'),
                // instagram: yup.string('Enter Instagram').required('Instagram handle is required'),
                // followers: yup.number('Enter no. of followers').required('No. followers is required'),
            });
        }

    }, [type])

    const history = useHistory()
    const [loading, setLoading] = useState(true)

    const formik = useFormik({
        initialValues: type === "customer" ? {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            tiktok_link: "",
            instagram_link: "",
            linkedin_link: "",
            is_active: true,
            // instagram: "",
            // followers: 0
        } : {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            is_active: true,
            // instagram: "",
            // followers: 0
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log('1', values)
            setLoading(true)
            updateUser(values, id, email)
                .then((res) => {
                    if (res.success) {
                        toast.success(res.message, {
                            autoClose: 3000,
                            hideProgressBar: true,
                        });
                    }
                    setLoading(false)
                    setTimeout(() => {
                        // if (flag) {
                        //     history.push('/house')
                        // } else {
                        //     history.push("/" + type)
                        // }
                        close()
                    }, 1000)
                })
                .catch(err => {
                    toast.error(err.message, {
                        autoClose: 3000,
                        hideProgressBar: true
                    })
                    setLoading(false)
                })
        },
    });
    useEffect(() => {
        if (id) {
            getDetails(id, false)
                .then((res) => {
                    if (res) {
                        if (res.is_house_influencer) {
                            setFlag(true)
                        }
                        // setFields({ ...fields, })
                        setEmail(res?.email)
                        console.log('1', res)
                        formik.setFieldValue('first_name', res?.first_name)
                        formik.setFieldValue('last_name', res?.last_name)
                        formik.setFieldValue('email', res?.email)
                        formik.setFieldValue('phone', res?.phone)
                        formik.setFieldValue('is_active', res?.is_active)
                        formik.setFieldValue('tiktok_link', res?.tiktok_link)
                        formik.setFieldValue('instagram_link', res?.instagram_link)
                        formik.setFieldValue('linkedin_link', res?.linkedin_link)
                        // if (type === "influencer") {
                        //     formik.setFieldValue('instagram', res?.form_name)
                        //     formik.setFieldValue('followers', res?.no_of_followers)
                        // }
                    } else {
                        // if (res?.is_house_influencer) {
                        //     history.push("/house")
                        // } else {
                        //     history.push("/" + type)
                        // }
                        close()
                    }
                    setLoading(false)
                })
        }
    }, [id])
    return (
        <div className='row'>
            <div className='col-12' >
                <Paper variant="outlined" className='box-style-new' style={{ padding: 20, borderRadius: 7, }}>
                    <Typography className='generic-hd2' style={{ textAlign: 'left', color: "primary" }} variant="h5" gutterBottom component="div">
                        Edit {type === "customer" ? "User" : "House"}
                    </Typography>
                    <hr className='divider-line mb-30' />
                    <form onSubmit={formik.handleSubmit} >
                        <div >
                            <span style={{ marginBottom: "5px " }}>Basic Information</span>
                            <Row style={{ marginTop: "10px", }} >
                                <Col md={12} lg={6} xl={6}>
                                    <TextField
                                        id="first_name"
                                        name="first_name"
                                        label="First Name"
                                        size="small"
                                        className="field-style"
                                        style={styles.input}
                                        variant="outlined"
                                        value={formik.values.first_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                        helperText={formik.touched.first_name && formik.errors.first_name}
                                    />
                                </Col>
                                <Col md={12} lg={6} xl={6}>
                                    <TextField
                                        id="last_name"
                                        name="last_name"
                                        label="Last Name"
                                        size="small"
                                        className="field-style"
                                        style={styles.input}
                                        variant="outlined"
                                        value={formik.values.last_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                        helperText={formik.touched.last_name && formik.errors.last_name}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} lg={6} xl={6}>
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        size="small"
                                        className="field-style"
                                        variant="outlined"
                                        style={styles.span}
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </Col>
                                <Col md={12} lg={6} xl={6}>
                                    <TextField
                                        id="phone"
                                        name='phone'
                                        label="Phone #"
                                        type="text"
                                        size="small"
                                        className="field-style"
                                        style={styles.span}
                                        variant="outlined"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                                        helperText={formik.touched.phone && formik.errors.phone}
                                    />
                                </Col>
                                {
                                    type === "customer" &&
                                    <>
                                        <Col md={12} lg={6}>
                                            <TextField
                                                id="tiktok_link"
                                                name="tiktok_link"
                                                label="TikTok"
                                                size="small"
                                                className="field-style"
                                                variant="outlined"
                                                style={styles.span}
                                                value={formik.values.tiktok_link}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.tiktok_link && Boolean(formik.errors.tiktok_link)}
                                                helperText={formik.touched.tiktok_link && formik.errors.tiktok_link}
                                            />
                                        </Col>
                                        <Col md={12} lg={6}>
                                            <TextField
                                                id="instagram_link"
                                                name="instagram_link"
                                                label="Instagram"
                                                size="small"
                                                className="field-style"
                                                variant="outlined"
                                                style={styles.span}
                                                value={formik.values.instagram_link}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.instagram_link && Boolean(formik.errors.instagram_link)}
                                                helperText={formik.touched.instagram_link && formik.errors.instagram_link}
                                            />
                                        </Col>
                                        <Col md={12} lg={6}>
                                            <TextField
                                                id="linkedin_link"
                                                name="linkedin_link"
                                                label="LinkedIn"
                                                size="small"
                                                className="field-style"
                                                variant="outlined"
                                                style={styles.span}
                                                value={formik.values.linkedin_link}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.linkedin_link && Boolean(formik.errors.linkedin_link)}
                                                helperText={formik.touched.linkedin_link && formik.errors.linkedin_link}
                                            />
                                        </Col>
                                    </>
                                }

                            </Row>
                            {/* {
                                    type === "influencer" &&
                                    <>
                                        <span style={{ marginBottom: "5px" }}>Social</span>
                                        <Row style={{ marginTop: "10px", }} >
                                            <Col md={6} lg={6} xl={6}>
                                                <TextField
                                                    id="instagram"
                                                    name="instagram"
                                                    label="Instagram"
                                                    size="small"
                                                    className="field-style"
                                                    variant="outlined"
                                                    style={styles.span}
                                                    value={formik.values.instagram}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.instagram && Boolean(formik.errors.instagram)}
                                                    helperText={formik.touched.instagram && formik.errors.instagram}
                                                />
                                            </Col>
                                            <Col md={6} lg={6} xl={6}>
                                                <TextField
                                                    id="followers"
                                                    name="followers"
                                                    label="Followers"
                                                    size="small"
                                                    type='number'
                                                    className="field-style"
                                                    variant="outlined"
                                                    style={styles.span}
                                                    value={formik.values.followers}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.followers && Boolean(formik.errors.followers)}
                                                    helperText={formik.touched.followers && formik.errors.followers}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                } */}
                            <span >Status</span>
                            <Row style={{ marginTop: "5px" }}>
                                <Col md={12} lg={6} xl={6}>
                                    <FormControl className="field-style">
                                        <InputLabel id="demo-simple-select-label" style={{ marginTop: "5px" }}>Account Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Account Status"
                                            size='small'
                                            name='is_active'
                                            style={styles.span}
                                            value={formik.values.is_active}
                                            onChange={(e) => { formik.setFieldValue('is_active', e.target.value) }}
                                        >
                                            <MenuItem value={true}>Active</MenuItem>
                                            <MenuItem value={false}>Deactive</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>

                        </div>
                        <div className='boxes-buttons-row2'>
                            <LoadingButton
                                type='submit'
                                loading={loading}
                                variant="contained"
                                size="medium"
                                className="btn-gen-margin btn-width ml-0"
                                color="primary"
                                disableElevation
                                endIcon={<SaveIcon />}
                            >
                                Save
                            </LoadingButton>
                            <Button
                                onClick={() => {
                                    close()
                                    // if (flag) {
                                    //     history.push("/house")
                                    // } else {
                                    //     history.push("/" + type)
                                    // }
                                }}
                                variant="outlined"
                                size="medium"
                                className="btn-gen-margin btn-width grey-btn-outline"
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>

                </Paper>
            </div>
        </div>
    );
}

const styles = {
    input: {
        marginBottom: 20,
    },
    span: {
        marginTop: 5,
        marginBottom: 20
    }
}

function mapStateToProps({ countries }) {
    return { countries }
}
export default connect(mapStateToProps, { ...accActions, ...countryActions, ...accountActions })(UpdateBrand)