import React, { useMemo } from 'react'
import NoRows from "../../reUsable/NoRows"
import numeral from 'numeral'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import moment from 'moment'

function DataTable({ nowGet, data }) {
    const [page, setPage] = useState(1)
    const [pageLoad, setPageLoad] = useState(false)
    const feedCol = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        { field: "pixel_id", headerName: "PID", width: 120, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "name", headerName: "Brand", width: 200, sortable: false, renderCell: ({ value }) => value ? value : <span style={{ color: "#bebebe" }}>Empty</span> },
        { field: "login_data", headerName: "Login", width: 200, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_uploads", headerName: "Upload Videos", width: 150, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_published", headerName: "Published Video", width: 150, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "update_profile", headerName: "Profile Updates", width: 150, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        // { field: "total_number_of_categories", headerName: "Categories", width: 150, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_sync_product", headerName: "Products", width: 150, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "discount", headerName: "Discount", width: 150, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{value}</span> },
        { field: "total_sales", headerName: "Sales", width: 150, sortable: false, renderCell: ({ value }) => <span style={{ margin: 0, width: '100%', textAlign: 'left' }}>{numeral(value).format("$0,0.00", Math.floor)}</span> },
    ]

    const feeds = useMemo(() => {
        if (data?.success) {
            setPageLoad(false)
            return data?.getUserAnalyticsevent ? data?.getUserAnalyticsevent?.[0] : data?.getUserAnalyticsreviews?.[0]
        }
    }, [data])
    function formatDuration(seconds) {
        if (seconds === 0) {
            return 0
        } else if (seconds <= 60) {
            return `${(seconds / 60).toFixed(1)} min`
        } else {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            if (hours) {
                return `${hours} ${hours > 1 ? 'hours' : 'hour'} ${minutes} ${minutes > 1 ? "minutes" : "minute"}`
            } else {
                return `${minutes} ${minutes > 1 ? "minutes" : "minute"}`
            }
        }

    }
    return (
        <DataGrid
            showCellRightBorder={true}
            rowHeight={35}
            disableColumnFilter={true}
            density="compact"
            loading={pageLoad}
            components={{ NoRowsOverlay: NoRows }}
            disableColumnSelector
            disableColumnMenu
            disableSelectionOnClick
            rows={
                feeds?.data?.map((item, index) => ({
                    ...item,
                    id: index + (page == 1 ? 1 : (page * 20) - 19),
                    // duration: item?.total_duration == 0 ? 0 : `${moment(item?.total_duration).format('hh:mm')}` 
                    // duration: item?.total_duration == 0 ? 0 : `${numeral(item?.total_duration / 60).format('0.0')} min`
                    duration: formatDuration(item?.total_duration)


                }))
            }
            columns={feedCol}
            // rowCount={Object.keys(feeds?.data).length !== 0 ? feeds?.total : 0}
            rowCount={feeds?.total}
            pagination
            pageSize={20}
            rowsPerPageOptions={[20]}
            paginationMode="server"
            onPageChange={(newPage) => {
                setPageLoad(true)
                setPage(newPage + 1);
                nowGet(newPage + 1);
            }}

        />
    )
}
export default React.memo(DataTable)