import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap"
import { Button, Divider, InputAdornment, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import * as settingsAction from "../../../store/actions/settings.action"
import { toast } from 'react-toastify';

function PayoutSetting({ payoutSetting, updatePayoutSetting }) {
    const [load, setLoad] = useState(true)
    const [fields, setFields] = useState({
        min_amount: 0,
        max_amount: 0
    })

    useEffect(() => {
        getData()
    }, [])

    const handleSave = () => {
        setLoad(true)
        if(fields.min_amount > fields.max_amount){
            toast.error("Minimum amount can not be greater than maximum amount")
            setLoad(false)
            return
        }
        updatePayoutSetting(fields).then((res) => {
            if (res.success) {
                toast.success(`${res?.message}`)
            } else {
                toast.error(`${res?.message}`)
            }
            getData()
        })
    }

    function getData() {
        payoutSetting().then((res) => {
            if (res.success) {
                setFields({
                    min_amount: res?.data?.min_amount,
                    max_amount: res?.data?.max_amount,
                })
            } else {
                setFields({
                    min_amount: 0,
                    min_amount: 0
                })
            }
        }).finally(() => setLoad(false))
    }

    return (
        <div className="container-fluid mb-3">
            <Row className='pt-3'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Agency Payout Settings
                    </Typography>
                </Col>
            </Row>
            <Divider className="mb-15" />
            <Row className="d-flex justify-content-between">
                <Col xl={4} lg={6} md={12}>
                    <div className='infoSection new mb-3' style={{ background: "#FFF" }} >
                        <Box className="addons-cart-title d-flex align-items-center">
                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                Payout Setting
                            </Typography>
                        </Box>
                        <Divider className='mb-20' />
                        <TextField
                            size="small"
                            value={fields.min_amount}
                            onChange={e => {
                                let val = e.target.value;
                                if (val > 0) {
                                    setFields({ ...fields, min_amount: val })
                                }
                            }}
                            id="outlined-basic"
                            type='number'
                            label="Minimum Amount"
                            InputProps={{
                                inputProps: {
                                    min: 1
                                },
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            variant="outlined"
                            className="field-style mb-15"
                        />
                        <TextField
                            size="small"
                            value={fields.max_amount}
                            onChange={e => {
                                let val = e.target.value;
                                if (val > 0) {
                                    setFields({ ...fields, max_amount: val })
                                }
                            }}
                            id="outlined-basic"
                            type='number'
                            label="Maximum Amount"
                            InputProps={{
                                inputProps: {
                                    min: 1
                                },
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            variant="outlined"
                            className="field-style mb-15"
                        />
                        <Button
                            disabled={load}
                            className="btn-width btn-gen-margin ml-0 h-100"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={() => handleSave()}
                        >
                            Save
                        </Button>
                    </div>
                </Col>

            </Row>
        </div>
    )
}

const mapStateToProps = ({ feed }) => {
    return { feed };
};

export default connect(mapStateToProps, { ...settingsAction })(PayoutSetting);