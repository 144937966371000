import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as accountActions from "../../../store/actions/account"
import * as feesActions from "../../../store/actions/feeSetting"
import * as feedActions from "../../../store/actions/feedAction"
import * as salesActions from "../../../store/actions/sales"

import { Row, Col, Button } from "react-bootstrap"
import SkeletonLoader from "reUsable/skeleton";
import { toast } from 'react-toastify';
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';
import { GoVerified } from "react-icons/go"
import { ImCross } from "react-icons/im"
import { Autocomplete, Checkbox, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from "moment"
import Switch from '@mui/material/Switch';
import numeral from 'numeral';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteModal from "../../../reUsable/DeleteModal";
import SaveModal from "../../../reUsable/SaveModal";
import EditModal from "../../../reUsable/EditModal";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import UpdateBrand from './UpdateBrand';
import EditUser from './EditUser';
import VerifiedIcon from '@mui/icons-material/Verified';

function ViewInfo({ agencyDetail, updateAgency, getFeedStats, getInfluencers, getAgencyDetail, accountDetail, currentUser, deleted, changeActive, nowGet, userType, changeAccountType, close, fees, getFeesSetting }) {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [check, setCheck] = useState(false);
    const [check2, setCheck2] = useState(false);
    const [check3, setCheck3] = useState(false);
    const [check4, setCheck4] = useState(false);
    const [influencer, setInfluencer] = useState(false)
    const [statusModal, setStatusModal] = useState(false)
    const [appModal, setAppModal] = useState(false)
    const [infModal, setInfModal] = useState(false)
    const [featuredModal, setFeaturedModal] = useState(false)
    const [tags, setTags] = useState([])
    const [records, setRecords] = useState([])
    const [house, setHouse] = useState([])
    const [isHouse, setIsHouse] = useState(false)
    const [ai, setAi] = useState(false)

    const [modal, setModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [stats, setStats] = useState(null)
    const [fee, setFees] = useState({
        shopper_cashback: 0,
        referral_fee: 0,
        content_creator_fee: 0
    })
    const [brandModal, setBrandModal] = useState(false)

    const address = useMemo(() => {
        if (accountDetail?.userDetails?.shipping_addresses?.length === 0) {
            return null
        } else {
            return accountDetail?.userDetails?.shipping_addresses?.find(f => f.is_default)

        }
    }, [accountDetail])
    useEffect(() => {
        if (process.env.REACT_APP_BASE_URL.includes("backendlive")) {
            setUrl('https://ormelive.com/')
        } else {
            setUrl('https://dev.ormelive.com/')
        }
        getAgencyDetail(currentUser)
            .then((res) => {
                // if (userType !== "brand") {
                //     getFeedStats({ pixel_id: res?.pixel_id, type: 'review' }).then((res) => {
                //         if (res?.success) {
                //             setStats(res?.getUserAnalyticsreviews?.[0]?.data?.[0])
                //         }
                //     })
                // }
                setLoading(false)
            })
    }, [])

    // useEffect(() => {
    //     if (accountDetail.userDetails) {
    //         if (accountDetail.userDetails.became_influencer === "approved") {
    //             setInfluencer(true)
    //         } else {
    //             setInfluencer(false)
    //         }
    //         if (userType === "brand") {
    //             setFees(accountDetail?.userDetails?.fee_structure)
    //             if (accountDetail?.userDetails?.brandWiseInfluencer?.length > 0) {
    //                 setHouse(accountDetail?.userDetails?.brandWiseInfluencer?.map(item => ({
    //                     title: `${item?.pixel_id} - ${item?.name}`,
    //                     key: item?.pixel_id,
    //                     ...item
    //                 })))
    //             } else {
    //                 setHouse([])
    //             }
    //         } else {
    //             setFees({
    //                 content_creator_fee: 0,
    //                 referral_fee: 0,
    //                 shopper_cashback: 0
    //             })
    //         }
    //         setCheck(accountDetail.userDetails?.is_active)
    //         setCheck2(accountDetail.userDetails?.is_verified)
    //         setCheck3(accountDetail.userDetails?.is_featured)
    //         setCheck4(accountDetail.userDetails?.app)
    //         if (userType === "influencer") {
    //             setIsHouse(accountDetail?.userDetails?.is_house_influencer ? accountDetail?.userDetails?.is_house_influencer : false)
    //             setAi(accountDetail?.userDetails?.is_ai_search ? accountDetail?.userDetails?.is_ai_search : false)

    //         }
    //     }
    // }, [accountDetail])

    useEffect(() => {
        if (agencyDetail?.success) {
            setCheck(agencyDetail?.data?.is_active)
        }
    }, [agencyDetail])

    function formatDuration(seconds) {
        if (seconds === 0) {
            return 0
        } else if (seconds <= 60) {
            return `${(seconds / 60).toFixed(1)} min`
        } else {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            if (hours) {
                return `${hours} ${hours > 1 ? 'hours' : 'hour'} ${minutes} ${minutes > 1 ? "minutes" : "minute"}`
            } else {
                return `${minutes} ${minutes > 1 ? "minutes" : "minute"}`
            }
        }

    }
    function renderData() {
        const { data: agency } = agencyDetail
        const {
            userDetails,
            packageDetails,
            followersData
        } = accountDetail

        function renderVerify(verify) {
            if (verify) {
                return <GoVerified style={{ marginLeft: 10 }} color="green" size={18} />
            } else {
                return <ImCross style={{ marginLeft: 10 }} color="red" size={14} />
            }
        }

        function renderIspaid(isPaid) {
            if (isPaid == "NONE") {
                return ""
            }
            else if (isPaid == "PAID") {
                return "green"
            }
            else if (isPaid == "UN-PAID") {
                return "red"
            }
        }
        function handleModals(type) {
            if (type === "status") {
                setCheck(false)
                setStatusModal(false)
                // changeActive({ user_id: userDetails._id, is_active: false })
                //     .then(() => {
                //         setStatusModal(false)
                //         nowGet()
                //     })
            }
            if (type === "app") {
                setCheck4(false)
                setAppModal(false)
                // changeActive({ user_id: userDetails._id, app: false })
                //     .then(() => {
                //         setAppModal(false)
                //         nowGet()
                //     })
            }
            if (type === "featured") {
                setCheck3(false)
                setFeaturedModal(false)
                // changeActive({ user_id: userDetails._id, is_featured: false })
                //     .then(() => {
                //         setFeaturedModal(false)
                //         nowGet()
                //     })
            }
            if (type === "influencer") {
                setInfluencer(false)
                setInfModal(false)
                // changeAccountType(userDetails._id)
                //     .then(() => {
                //         nowGet()
                //     })

            }
        }

        function save() {
            setModal(false)

            updateAgency(currentUser, check).then((res) => {
                toast.success(res?.message, {
                    autoClose: 2000,
                    hideProgressBar: true,
                });
                nowGet()
            })

        }
        function clear(body, type) {
            if (accountDetail.userDetails) {
                if (accountDetail.userDetails.became_influencer === "approved") {
                    setInfluencer(true)
                } else {
                    setInfluencer(false)
                }
                setCheck(agency?.is_active)
                setCheck2(accountDetail.userDetails?.is_verified)
                setCheck3(accountDetail.userDetails?.is_featured)
                setCheck4(accountDetail.userDetails?.app)
            }
            close()

        }

        function handleDelete(i) {
            const updatedTags = tags.filter((tag, index) => index !== i)
            setTags(updatedTags)
        }

        function handleAddition(tag) {
            setTags([...tags, tag])
        }


        function renderInfo(arr) {
            const address = arr?.find(f => f.is_default)
            if (address) {
                return `${address?.address1} ${address?.city}, ${address?.country}, ${address?.zip} `
            } else {
                return "N/A"
            }
        }

        return (
            <>

                <SaveModal
                    visible={modal}
                    title={`Are you sure you want to save these changes?`}
                    close={() => setModal(false)}
                    type="status"
                    call={() => save()}
                />
                {/* <EditModal
                    visible={edit}
                    close={() => setEdit(false)}
                    value={fee}
                    setter={(n, v) => {
                        setFees({ ...fee, [n]: v })
                    }}
                    call={() => {
                        setEdit(false)
                    }}
                /> */}
                <Row className='align-items-center'>
                    <Col sm xl={12}>
                        {

                            brandModal ?
                                <div className='modal-edit'>
                                    {userType === "brand" ?
                                        <UpdateBrand
                                            visible={brandModal}
                                            id={currentUser}
                                            close={() => setBrandModal(false)}
                                        /> :
                                        <EditUser
                                            visible={brandModal}
                                            id={currentUser}
                                            close={() => setBrandModal(false)}
                                            type={userDetails?.is_house_influencer ? "house" : "customer"}
                                        />
                                    }
                                </div>
                                :

                                <Card variant="outlined" className='users-card-main pb-0'>
                                    <div className='d-flex justify-content-between'>
                                        <Typography className='fw-normal' color="primary" variant="h6" gutterBottom component="div">
                                            User Information
                                        </Typography>
                                        <div className='user-status-style' style={{
                                            backgroundColor: check ? 'green' : 'red',
                                        }}>
                                            {check ? "Active" : "Deactive"}
                                        </div>
                                    </div>
                                    <Divider className='' />

                                    <CardContent>
                                        <Row className=''>
                                            <Col sm={12} lg={12} xl={6} className='new mb-1'>
                                                <h6 className='titleVie'>Basic Information</h6>
                                                <div className='infoSection new mb-3' style={{ minHeight: '206px' }}>
                                                    <Row className=''>

                                                        <Col sm={11} lg={11} xl={11} className='user-info-column new mb-1'>
                                                            <span className='info-hd'> PID:</span> {agency?.pixel_id}</Col>
                                                    </Row>
                                                    <Row className=''>
                                                        <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'><span className='info-hd'>Date Created:</span> {moment(agency?.created_at).format('lll')}</Col>
                                                    </Row>
                                                    <Row className=''>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Account Type:</span> Agency </Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Company:</span> <span style={{ color: 'green' }}>{agency?.company ? agency?.company : '-'}</span> </Col>
                                                    </Row>
                                                    <Row className=''>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Agency Name:</span> <span style={{ color: 'green' }}>{agency?.company_name ? agency?.company_name : '-'}</span> </Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Contact Person:</span> <span style={{ color: 'green' }}>{agency?.contact_name ? agency?.contact_name : '-'}</span> </Col>
                                                    </Row>
                                                    <Row className=''>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Email: </span>{agency?.email ? agency?.email : '-'} {agency?.email_verified && <VerifiedIcon style={{ fontSize: "1rem", color: "#3ccfff" }} />} </Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Phone: </span>{agency?.phone ? agency.phone : "-"}</Col>
                                                    </Row>
                                                    <Row className=''>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Website: </span> <a href={agency?.website_address?.includes('http') ? agency?.website_address : `//${agency?.website_address}`} target='_blank'>{agency?.website_address}</a> </Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Country: </span>{agency?.country}</Col>
                                                    </Row>
                                                    <Row className=''>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Signed Agreement: </span>{
                                                            agency?.agreement_status === "accepted" ? <a href={agency?.agreement_url} target='_blank' style={{ color: "green", textDecoration: 'underline' }}>Signed</a> : <a style={{ color: "red" }} href={agency?.agreement_url} target='_blank'>Not Signed</a>
                                                        }</Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col sm={12} lg={12} xl={6} className='new mb-1'>
                                                <h6 className='titleVie'>Connection Setup</h6>
                                                <div className='infoSection new mb-3' style={{ minHeight: '206px' }}>
                                                    <Row className=''>
                                                        <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'><span className='info-hd'> Paypal Status: {agency?.paypal_email?.length > 1 ? <span className='info-hd' style={{ color: 'green' }}>Connected</span> : <span className='info-hd' style={{ color: 'red' }}>Not Connected</span>} </span></Col>
                                                        <Col sm={12} lg={12} xl={12} className='user-info-column new mb-1'><span className='info-hd'>Paypal Email: {agency?.paypal_email ? agency?.paypal_email : 'N/A'}</span></Col>
                                                    </Row>
                                                </div>
                                            </Col>

                                            <Col sm={12} lg={12} xl={6} className='new mb-1'>
                                                <h6 className='titleVie'>Operations</h6>
                                                <div className='infoSection new mb-3' style={userType === "brand" ? { minHeight: '83px' } : { minHeight: '190px' }}>
                                                    <Row className=''>

                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-2'>
                                                            <FormControl className='field-style pid-fields' size="small">
                                                                <span className='info-hd mb-1'>Account Status:</span>
                                                                <Select
                                                                    sx={{ color: "black" }}
                                                                    value={check}
                                                                    // label="Account Status"
                                                                    onChange={(e) => setCheck(e.target.value)}
                                                                >
                                                                    <MenuItem value={true}>Active</MenuItem>
                                                                    <MenuItem value={false}>Deactive</MenuItem>
                                                                </Select>
                                                            </FormControl>

                                                        </Col>
                                                    </Row>
                                                </div>

                                            </Col>
                                            {/* <Col sm={12} lg={12} xl={6} className='new mb-1'>
                                                <h6 className='titleVie'>Settings</h6>
                                                <div className='infoSection new mb-3' style={{ minHeight: '140px' }}>
                                                    <Row className=''>
                                                        <Col sm={11} lg={11} xl={11} className='user-info-column new mb-1'>
                                                            <span className='info-hd'>E-Commerce Platform :</span>
                                                            {
                                                                userDetails?.shopify ?
                                                                    <span style={{ fontWeight: "600" }}>Shopify <span style={{ color: 'green' }}>Connected</span></span> :
                                                                    userDetails?.salesforce ?
                                                                        <span style={{ fontWeight: "600" }}>Salesforce <span style={{ color: 'green' }}>Connected</span></span> :
                                                                        userDetails?.magento ? <span style={{ fontWeight: "600" }}>Magento <span style={{ color: 'green' }}>Connected</span></span> :
                                                                            <span style={{ color: 'red' }}>Not Connected</span>
                                                            }
                                                        </Col>
                                                        <Col sm={1} lg={1} xl={1} className='user-info-column new mb-1'>
                                                            <EditIcon sx={{ cursor: "pointer", color: "#052977" }} fontSize='small' onClick={() => { setEdit(true) }} />
                                                        </Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>ORME Fee:</span> {userDetails?.contract?.fee}% </Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Shopper Cashback:</span> {fee?.shopper_cashback}% </Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Referral Fee: </span>{fee?.referral_fee}% </Col>
                                                        <Col sm={12} lg={6} xl={6} className='user-info-column new mb-1'><span className='info-hd'>Creator Fee: </span>{fee?.content_creator_fee}% </Col>

                                                    </Row>
                                                </div>
                                            </Col> */}

                                        </Row>
                                        <Button
                                            className=" btn-gen-margin ml-0  btn-width btn-gen-margin grey-btn-outline h-100 resetBTN"
                                            size="medium"
                                            variant="outlined"
                                            // style={{ marginTop: "10px !important" }}
                                            sx={{ color: 'gray', }}

                                            onClick={clear}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="btn-width btn-gen-margin ml-0 h-100 webBTN"
                                            size="medium"
                                            variant="contained"
                                            onClick={() => {
                                                setModal(true)
                                            }}
                                        // disabled={url === ""}
                                        >
                                            Save
                                        </Button>
                                    </CardContent>
                                </Card>


                        }

                    </Col>

                </Row >


            </>
        )
    }

    return (
        <React.Fragment>
            {loading ? (
                <SkeletonLoader />
            ) : renderData()}
        </React.Fragment>
    );
}

function mapStateToProps({ accountDetail, fees, agencyDetail }) {
    return { accountDetail, fees, agencyDetail }
}

export default connect(mapStateToProps, { ...accountActions, ...feesActions, ...feedActions, ...salesActions })(ViewInfo)