import konnect from "./axios"
import Axios from "axios"
import config from "../../Configs/Config"
import { LOGIN } from "./type"
import micro from "./axiosMicro"

function getPublicIP() {
    return new Promise((resolve, reject) => {
        const pc = new RTCPeerConnection({
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] // STUN server URL
        });

        pc.createDataChannel('');
        pc.createOffer()
            .then(sdp => {
                pc.setLocalDescription(sdp);
            })
            .catch(error => {
                reject(error);
            });

        pc.onicecandidate = event => {
            if (event.candidate) {
                const candidate = event.candidate.candidate;
                const regex = /([0-9]{1,3}(\.[0-9]{1,3}){3})/;
                const match = regex.exec(candidate);
                if (match && match.length >= 1) {
                    resolve(match[0]);
                    pc.onicecandidate = null;
                    pc.close();
                }
            }
        };
    });
}


export const login = (data, success, offLoader) => async (dispatch) => {
    const publicIP = await getPublicIP()
    konnect.defaults.headers.common['public-ip'] = publicIP;
    konnect.post(`/v1/admin/auth/login`, data)
        .then((res) => {
            // localStorage.setItem('token', res.data.message.token)
            // konnect.defaults.headers.common['Authorization'] = "Bearer " + res.data.message.token;
            // micro.defaults.headers.common['Authorization'] = "Bearer " + res.data.message.token;
            // dispatch({
            //     type: LOGIN,
            //     payload: res.data
            // })
            success(res?.data)
            // offLoader()
        })
        .catch(({ response }) => {
            dispatch({
                type: LOGIN,
                payload: response?.data
            })
            offLoader()
        })
}

export const optVerification = (data, success, offLoader) => async (dispatch) => {
    const publicIP = await getPublicIP()
    konnect.defaults.headers.common['public-ip'] = publicIP;
    konnect.post(`/v1/admin/auth/adminOtpVerify`, data)
        .then((res) => {
            localStorage.setItem('token', res.data.message.token)
            konnect.defaults.headers.common['Authorization'] = "Bearer " + res.data.message.token;
            micro.defaults.headers.common['Authorization'] = "Bearer " + res.data.message.token;
            dispatch({
                type: LOGIN,
                payload: res.data
            })
            success()
            offLoader()
        })
        .catch(({ response }) => {
            // dispatch({
            //     type: LOGIN,
            //     payload: response?.data
            // })
            offLoader(response.data)
        })
}

export const setUser = (cb, loadingOff, showMessage) => async (dispatch) => {

    // konnect.interceptors.response.use((res)=>{
    //     return res
    // },(error)=>{
    //     if(error.response.status == 401){
    //         showMessage()
    //         cb()
    //     }
    // })

    konnect.interceptors.response.use((response) => {
        if (response.status === 200) {
            return response
        }
    }, (error) => {
        if (error.response && error.response.status === 401) {
            showMessage()
            cb()
        }
        return Promise.reject(error)
    })

    const token = localStorage.getItem('token')

    if (token) {
        const publicIP = await getPublicIP()
        konnect.defaults.headers.common['public-ip'] = publicIP;
        konnect.defaults.headers.common['Authorization'] = "Bearer " + token;
        micro.defaults.headers.common['Authorization'] = "Bearer " + token;
        return konnect.post('/v1/admin/auth/adminTokenValidate')
            .then((res) => {
                dispatch({
                    type: LOGIN,
                    payload: { ...res.data, message: { ...res.data.Data, token } }
                })
                loadingOff()
            })
            .catch((err) => {
                loadingOff()
                
            })
    } else {
        loadingOff()
    }
}

export const logOut = () => async (dispatch) => {
    localStorage.removeItem('token')
    dispatch({
        type: LOGIN,
        payload: {}
    })
}
