import konnect from "./axios"
import { GET_LEDGER } from "./type"

export const getLedger = (fields, date, page, type, extra) => async (dispatch) => {
    let url = "/v1/admin/report/getLedgerTransactions"
    let updatedData = { ...fields, startDate: date[0], endDate: date[1] }
    if (fields.account_id === "all") updatedData.account_id = ""
    if (fields.name !== "") {
        updatedData.user_id = fields.name
        updatedData.name = ""
    }
    if (type === "download") {
        try {
            const res = await konnect.post(url,
                updatedData,
                {
                    params: {
                        page: page,
                        limit: extra,
                    }
                })
            return res.data.data
        } catch (err) {
            console.log(err)
            return err
        }
    } else {
        try {
            const res = await konnect.post(url,
                updatedData,
                {
                    params: {
                        page: page,
                        limit: 15,
                    }
                })
            dispatch({
                type: GET_LEDGER,
                payload: res?.data
            })
            return res?.data

        } catch (err) {
            console.log(err.response)
            return err
        }
    }
}

export const getDeposit = (fields, date, page, type) => async (dispatch) => {
    let url = "/v1/admin/report/getLedgerTransactions"
    let updatedData = { ...fields, startDate: date[0], endDate: date[1], account_id: type === "brand" ? "3" : "4" }
    try {
        const res = await konnect.post(url,
            updatedData,
            {
                params: {
                    page: page,
                    limit: 10,
                }
            })
        dispatch({
            type: GET_LEDGER,
            payload: res?.data
        })

    } catch (err) {
        console.log(err.response)
    }
}