import React, { useEffect, useState } from 'react'
import { connect, } from 'react-redux'
import { InputLabel, FormControl, Select, TextField, Button, MenuItem, Typography, Divider, Box } from '@mui/material'
import * as salesActions from "../../../store/actions/sales"

import moment from "moment";
import DateButton from "../../../reUsable/DateButtons"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO } from 'date-fns';
import { Col, Row } from "react-bootstrap";
import { purchaseCol } from "./cols"
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from "@mui/x-data-grid";
import NoRows from "../../../reUsable/NoRows"
import Skeleton from '../../../reUsable/skeleton';
import { singleShopperPurchase } from "../../sales/cols"

function Purchase({ currentUser, accountDetail, userType, getAccountingPurchase, allSales, }) {
    const [date, setDate] = useState(false)
    const [value, setValue] = useState([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [fields, setFields] = useState({ orderBy: -1, sort: "created_date", _id: currentUser, source: "all" })
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState(false);

    useEffect(() => {
        getAccountingPurchase(1, value, fields, '', '').then((res) => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (search || date) {
            setLoading(true)
            getAccountingPurchase(1, value, fields, '', '').then((res) => {
                setLoading(false)
                setDate(false)
                setSearch(false)
            })
        }
    }, [search, date])
    const handleSearch = (type) => {
        setPage(1)
        setSearch(true)
        if (type === "reset") {
            setValue([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
            setFields({ orderBy: -1, sort: "created_date", _id: currentUser, source: "all" })
        }
    }

    return (
        <div className='saleContainer'>
            <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                Purchase
            </Typography>
            <div className='body'>
                <div style={{ margin: "10px 10px 10px -15px" }} >
                    <DateButton value={value} setValue={setValue} setDate={setDate} />
                </div>
                <Row className='align-items-center' >
                    <Col xl={3} lg={12} className="mb-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className='dateBox'>
                                <DatePicker
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format('YYYY-MM-DD'), value[1]]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format('YYYY-MM-DD')]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Field</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Field"
                                labelId="field"
                                id="field"
                                value={fields.sort}
                                label="Field"
                                onChange={(e) => setFields({ ...fields, sort: e.target.value })}
                            >
                                {purchaseCol.map((m, i) => {
                                    if (m.field !== "id") return <MenuItem value={m.field} key={i}> {m.headerName}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Order By</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="order by"
                                labelId="orderBy"
                                id="orderBy"
                                value={fields.orderBy}
                                label="Order By"
                                onChange={(e) => setFields({ ...fields, orderBy: e.target.value })}
                            >
                                <MenuItem value={1}>Ascending</MenuItem>
                                <MenuItem value={-1}>Descending</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => handleSearch()}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width"
                            color="success"
                            disableElevation
                            onClick={() => handleSearch('reset')}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
                <Divider className="separater-sale" />
                <div style={{ height: 460, width: '100%' }}>
                    {
                        <>
                            <DataGrid
                                density='compact'
                                components={{ NoRowsOverlay: NoRows, }}
                                disableSelectionOnClick
                                className="bg-white"
                                loading={loading}
                                rows={Object.keys(allSales).length !== 0 ? allSales?.data?.map((single, index) => ({
                                    ...single,
                                    id: index + (page == 1 ? 1 : (page * 10) - 9),
                                })) : []}
                                columns={singleShopperPurchase}
                                pagination
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                paginationMode="server"
                                onPageChange={(newPage) => {
                                    setPage(newPage + 1);
                                    setLoading(true)
                                    getAccountingPurchase(newPage + 1, value, fields, '', '').then(() => {
                                        setLoading(false)
                                    })
                                }}
                            />
                        </>
                    }
                </div>
            </div>
        </div >
    )
}

function mapStateToProps({ allSales }) {
    return { allSales }
}
export default connect(mapStateToProps, { ...salesActions })(Purchase)
