import React, { useState, useEffect } from 'react';
import { Col } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActions } from '@mui/material';
import DeleteModal from 'reUsable/DeleteModal';
import CardHeader from '@mui/material/CardHeader';
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import UpdateCategory from "../pages/MainCategories/GeneralCategory/UpdateCategory"
import Box from '@mui/material/Box';

import * as catActions from "../store/actions/Categories/categories"


function Category({ image, name = "", verify, item, isFeatured, deleteGenderCategory, reloadData, changeStatusGeneral, changeFeatureStatus }) {
    const [currentData, setCurrentData] = useState({})
    const [check, setCheck] = useState(true)
    const [isPopular, setIsPopular] = useState(false)
    const [loading, setLoading] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)
    const [modal, setModal] = useState(false)

    useEffect(() => {
        setCheck(verify)
        setIsPopular(isFeatured)
    }, [])
    useEffect(() => {
        if (!check) {
            setIsPopular(check)
        }
    })
    function onDelete() {
        setLoading(true)
        deleteGenderCategory(currentData._id)
            .then((res) => {
                setLoading(false)
                reloadData()
                setModal(false)
                toast.success(res.data.message)
            })
            .catch(err => {
                setLoading(false)
                setModal(false)
                toast.error(err.response?.message)
            })
    }

    function onShowModal(item) {
        setCurrentData(item)
        setModal(true)
    }
    function onShowUpdateModal(item) {
        setCurrentData(item)
        setUpdateModal(true)
    }

    return (
        <>
            {
                <UpdateCategory
                    visible={updateModal}
                    data={currentData}
                    close={() => setUpdateModal(false)}
                    reloadData={reloadData}
                />
            }
            <DeleteModal
                visible={modal}
                title="Are you sure you want to delete this category? This will delete all your categories and sub categories related to this category."
                close={() => setModal(false)}
                call={onDelete}
            />
            <Card sx={{ display: 'flex' }} key={item?._id} >
                <CardHeader
                    className='category-header'
                    title={
                        <div className='titleCard' style={{ display: "flex", justifyContent: "space-between", alignItems: "stretch" }}>
                            <Typography className='categories-hd' variant="h5" component="div">
                                {name}
                            </Typography>
                            <div className='test' style={{ display: "flex" }}>
                                <span style={{ fontSize: "1rem" }} className='mb-0'>
                                    Status
                                </span>
                                <Switch
                                    size="small"
                                    color="success"
                                    checked={check}
                                    onChange={(e) => {
                                        setCheck(e.target.checked)
                                        changeStatusGeneral(item._id).then(() => { reloadData() })
                                    }} />
                            </div>
                        </div>
                    }
                />
                <Box sx={{ width: '100%' }}>
                    <CardActions className='categories-box-buttons'>
                        <Button
                            onClick={() => onShowUpdateModal(item)}
                            variant="contained"
                            size="small"
                            color="primary"
                            disableElevation
                            startIcon={<EditIcon />}
                        >
                            Edit
                        </Button>
                        <Button
                            style={{ marginLeft: 10 }}
                            onClick={() => onShowModal(item)}
                            variant="outlined"
                            size="small"
                            color="primary"
                            disableElevation
                            startIcon={<DeleteIcon />}
                        >
                            Delete
                        </Button>
                    </CardActions>
                </Box>
            </Card>
            
        </>
    )
}
export default connect(null, catActions)(Category)