import React, { useEffect, useMemo, useState } from 'react'
import * as salesActions from "../../../store/actions/sales"
import * as categoryActions from "../../../store/actions/Categories/categories"
import { connect, } from 'react-redux'
import { InputLabel, FormControl, Select, TextField, Button, MenuItem, Typography, Divider, Box } from '@mui/material'

import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import numeral from "numeral";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Col, Row } from "react-bootstrap";
import NoRows from "../../../reUsable/NoRows"
import Skeleton from '../../../reUsable/skeleton';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PercentIcon from '@mui/icons-material/Percent';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO } from 'date-fns';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faPercent } from "@fortawesome/free-solid-svg-icons";
import "./sale.css"


function MarketFee({ currentUser, marketfee, marketFees,filterCategories }) {
    const [value, setValue] = useState([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [search, setSearch] = useState(true)
    const [loading, setLoading] = useState(true)
    const [fields, setFields] = useState({
        generalCategory: "all",
        category: "all",
        subCategory: "all",
    })
    const [gen, setGen] = useState([])
    const [cat, setCat] = useState([])
    const [sub, setSub] = useState([])

    useEffect(() => {
        filterCategories({ brand_id: currentUser, source: "genCategory" }).then((res) => {
            if (res.success) {
                setGen(res?.message)
            } else {
                setGen([])
            }
        })
    }, [])

    // useEffect(() => {
    //     getData()
    // }, [])

    useEffect(() => {
        if (search) { getData() }
    }, [search]);


    const getData = () => {
        marketFees(currentUser, fields, value).then(() => {
            setSearch(false)
            setLoading(false)
        })
    }

    const handleSearch = (type) => {
        if (type === "refresh") {
            setValue([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
            setFields({ generalCategory: "all", category: "all", subCategory: "all" })
            setCat([])
            setSub([])
        } else {
            setLoading(true)
            setSearch(true)
        }
    }

    const fees = useMemo(() => {
        if (marketfee.success) {
            return marketfee?.message
        }
    }, [marketfee])

    return (
        <div className='saleContainer'>
            <Typography className='fw-normal' color="primary" variant="h6" gutterBottom component="div">
                Market Fees
            </Typography>
            <Divider className='' />
            <div className='body'>
                <Row className='mt-4'>
                    <Col xl={3} lg={12} className='mb-3 '>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className='dateBox'>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            helperText: 'YYYY / MM / DD',
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format('YYYY-MM-DD'), value[1]]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            helperText: 'YYYY / MM / DD',
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format('YYYY-MM-DD')]);
                                    }}
                                    renderInput={(params) => <TextField className="field-style" size="small" style={{ backgroundColor: 'white', width: '50%', }} {...params} />}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel id="demo-simple-select-label1">General Category</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="General Category"
                                labelId="generalCategory"
                                id="generalCategory"
                                value={fields.generalCategory}
                                label="General Category"
                                onChange={(e) => {
                                    setFields({ ...fields, generalCategory: e.target.value, category: 'all', subCategory: 'all' })
                                    setCat([])
                                    setSub([])
                                    if (e.target.value !== "all") {
                                        filterCategories({ brand_id: currentUser, source: "category", gen_category_id: e.target.value }).then((res) => {
                                            if (res.success) {
                                                setCat(res?.message)
                                            } else {
                                                setCat([])
                                                setSub([])
                                            }
                                        })
                                    }
                                    
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {gen?.map((item) => (<MenuItem value={item._id} >{item.name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel id="demo-simple-select-label1">Category</InputLabel>
                            <Select
                                style={fields.generalCategory !== "" ? {} : { background: "#ebebeb" }}
                                disabled={fields.generalCategory === ""}
                                sx={{ color: "black" }}
                                placeholder="Category"
                                labelId="category"
                                id="category"
                                value={fields.category}
                                label="Category"
                                onChange={(e) => {
                                    setFields({ ...fields, category: e.target.value, subCategory: 'all' })
                                    setSub([])
                                    if (e.target.value !== "all") {
                                        filterCategories({ brand_id: currentUser, source: "subCategory", gen_category_id: fields.generalCategory, category_id: e.target.value }).then((res) => {
                                            if (res.success) {
                                                setSub(res?.message)
                                            } else {
                                                setSub([])
                                            }
                                        })
                                    }
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {cat?.map((item) => (<MenuItem value={item._id} >{item.category_name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className='w-100' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Sub Category</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Sub Category"
                                labelId="subCategory"
                                id="subCategory"
                                value={fields.subCategory}
                                label="Sub Category"
                                onChange={(e) => setFields({ ...fields, subCategory: e.target.value })}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {sub?.map((item) => (<MenuItem value={item._id} >{item.sub_category_name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xl={5} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => { handleSearch() }}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('refresh') }}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
                <Divider className="separater-sale" />


                <div className='container ml-0 p-0 mt-3'>
                    <div class="row">
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : fees?.fee_due?.[0]?.cashback_amount).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">CASHBACK DUE</div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faPercent}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : fees?.fee_due?.[0]?.customer_commission).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">COMMISSION DUE</div>
                            </div>
                        </div>
                        {/* <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : fees?.fee_due?.[0]?.customer_commission).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">REFERRAL FEE DUE</div>
                            </div>
                        </div> */}
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="money-check-dollar" class="svg-inline--fa fa-money-check-dollar info-icon-main" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path fill="currentColor" d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zM272 192H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16s7.2-16 16-16zM256 304c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zM164.1 160v6.3c6.6 1.2 16.6 3.2 21 4.4c10.7 2.8 17 13.8 14.2 24.5s-13.8 17-24.5 14.2c-3.8-1-17.4-3.7-21.7-4.3c-12.2-1.9-22.2-.3-28.6 2.6c-6.3 2.9-7.9 6.2-8.2 8.1c-.6 3.4 0 4.7 .1 5c.3 .5 1 1.8 3.6 3.5c6.1 4.2 15.7 7.2 29.9 11.4l.8 .2c12.1 3.7 28.3 8.5 40.4 17.4c6.7 4.9 13 11.4 16.9 20.5c4 9.1 4.8 19.1 3 29.4c-3.3 19-15.9 32-31.6 38.7c-4.9 2.1-10 3.6-15.4 4.6V352c0 11.1-9 20.1-20.1 20.1s-20.1-9-20.1-20.1v-6.4c-9.5-2.2-21.9-6.4-29.8-9.1c-1.7-.6-3.2-1.1-4.4-1.5c-10.5-3.5-16.1-14.8-12.7-25.3s14.8-16.1 25.3-12.7c2 .7 4.1 1.4 6.4 2.1l0 0 0 0c9.5 3.2 20.2 6.9 26.2 7.9c12.8 2 22.7 .7 28.8-1.9c5.5-2.3 7.4-5.3 8-8.8c.7-4 .1-5.9-.2-6.7c-.4-.9-1.3-2.2-3.7-4c-5.9-4.3-15.3-7.5-29.3-11.7l-2.2-.7c-11.7-3.5-27-8.1-38.6-16c-6.6-4.5-13.2-10.7-17.3-19.5c-4.2-9-5.2-18.8-3.4-29c3.2-18.3 16.2-30.9 31.1-37.7c5-2.3 10.3-4 15.9-5.1v-6c0-11.1 9-20.1 20.1-20.1s20.1 9 20.1 20.1z"></path>
                                </svg>
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : fees?.fee_due?.[0]?.creator_commission).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">CREATOR FEE DUE</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                <div>{numeral(loading ? 0 : fees?.fee_paid?.[0]?.customer_commission).format("$0,0.00", Math.floor)}</div>
                                   
                                </div>
                                <div class="total-info-hd">CASHBACK PAID</div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faPercent}
                                />
                                <div class="value-info-hd">
                                <div>{numeral(loading ? 0 : fees?.fee_paid?.[0]?.customer_commission).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">COMMISSION PAID</div>
                            </div>
                        </div>
                        {/* <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <FontAwesomeIcon
                                    className="info-icon-main"
                                    icon={faDollarSign}
                                />
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : fees?.fee_paid?.[0]?.customer_commission).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">REFERRAL FEE PAID</div>
                            </div>
                        </div> */}
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="dashboard-info-box">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="money-check-dollar" class="svg-inline--fa fa-money-check-dollar info-icon-main" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path fill="currentColor" d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zM272 192H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16s7.2-16 16-16zM256 304c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zM164.1 160v6.3c6.6 1.2 16.6 3.2 21 4.4c10.7 2.8 17 13.8 14.2 24.5s-13.8 17-24.5 14.2c-3.8-1-17.4-3.7-21.7-4.3c-12.2-1.9-22.2-.3-28.6 2.6c-6.3 2.9-7.9 6.2-8.2 8.1c-.6 3.4 0 4.7 .1 5c.3 .5 1 1.8 3.6 3.5c6.1 4.2 15.7 7.2 29.9 11.4l.8 .2c12.1 3.7 28.3 8.5 40.4 17.4c6.7 4.9 13 11.4 16.9 20.5c4 9.1 4.8 19.1 3 29.4c-3.3 19-15.9 32-31.6 38.7c-4.9 2.1-10 3.6-15.4 4.6V352c0 11.1-9 20.1-20.1 20.1s-20.1-9-20.1-20.1v-6.4c-9.5-2.2-21.9-6.4-29.8-9.1c-1.7-.6-3.2-1.1-4.4-1.5c-10.5-3.5-16.1-14.8-12.7-25.3s14.8-16.1 25.3-12.7c2 .7 4.1 1.4 6.4 2.1l0 0 0 0c9.5 3.2 20.2 6.9 26.2 7.9c12.8 2 22.7 .7 28.8-1.9c5.5-2.3 7.4-5.3 8-8.8c.7-4 .1-5.9-.2-6.7c-.4-.9-1.3-2.2-3.7-4c-5.9-4.3-15.3-7.5-29.3-11.7l-2.2-.7c-11.7-3.5-27-8.1-38.6-16c-6.6-4.5-13.2-10.7-17.3-19.5c-4.2-9-5.2-18.8-3.4-29c3.2-18.3 16.2-30.9 31.1-37.7c5-2.3 10.3-4 15.9-5.1v-6c0-11.1 9-20.1 20.1-20.1s20.1 9 20.1 20.1z"></path>
                                </svg>
                                <div class="value-info-hd">
                                    <div>{numeral(loading ? 0 : fees?.fee_paid?.[0]?.creator_commission).format("$0,0.00", Math.floor)}</div>
                                </div>
                                <div class="total-info-hd">CREATOR FEE PAID</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

function mapStateToProps({ sales, accountDetail, genderCategories, ordersales, marketfee }) {
    return { sales, accountDetail, genderCategories, ordersales, marketfee }
}
export default connect(mapStateToProps, { ...salesActions, ...categoryActions })(MarketFee)

