import konnect from "./axios"
import { DISCOUNTS } from "./type"


export const getDiscounts = (data, page) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/promotion/getDiscounts`, data, {
            params: { page, limit: 15 }
        })
        dispatch({
            type: DISCOUNTS,
            payload: res?.data
        })
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
    }
}
export const addDiscount = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/promotion/createDiscount`, data)
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
    }
}
export const deleteDiscount = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/promotion/deleteDiscount`, data)
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
    }
}
export const updateDiscountStatus = (data) => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/promotion/discountActivation`, data)
        return res?.data
    } catch (err) {
        console.log('333', err?.response)
    }
}
// export const banUser = (data) => async (dispatch) => {
//     try {
//         const res = await konnect.post(`/v1/admin/chat/banUser`, data)
//         return res?.data
//     } catch (err) {
//         console.log('333', err?.response)
//     }
// }
// export const unbanUser = (data) => async (dispatch) => {
//     try {
//         const res = await konnect.post(`/v1/admin/chat/unbanUser`, data)
//         return res?.data
//     } catch (err) {
//         console.log('333', err?.response)
//         return err.response.data
//     }
// }
// export const getBanUsers = (data, page) => async (dispatch) => {
//     try {
//         const res = await konnect.post(`/v1/admin/chat/getBanUserList`, data, {
//             params: { page, limit: 20 }
//         })
//         dispatch({
//             type: BANUSERS,
//             payload: res?.data
//         })
//         return res?.data
//     } catch (err) {
//         console.log('333', err?.response)
//     }
// }