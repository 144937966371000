import React, { useState, useEffect, useCallback, useMemo } from 'react'
import DataTable from "./Table"
import { Col, Row } from 'react-bootstrap'
import { Autocomplete, Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'


import moment from 'moment';
import { connect } from 'react-redux';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";

import * as promotionActions from "../../store/actions/promotions"
import SkeletonLoader from "reUsable/skeleton";

import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import DateBtn from 'reUsable/DateBtn';
import SearchBrand from 'reUsable/SearchBrand';

function Returns({ getPromotionSales, promotionSales }) {
    const [value, setValue] = useState([
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
    ]);
    const [fields, setFields] = useState({
        brand_id: ''
    });
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [load, setLoad] = useState(true);
    const [date, setDate] = useState(false)
    useEffect(() => {
        getData()
    }, []);

    function getData(type) {
        setLoading(true)
        if (type === "reset") {
            setFields({
                brand_id: ''
            })

            setPage(1)
            setValue([moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),])

            getPromotionSales({
                brand_id: ''
            }, [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),], 1).then(() => {
                setDate(false)
                setLoading(false)
                setLoad(false)
            })
        } else {
            getPromotionSales(fields, value, 1).then(() => {
                setDate(false)
                setLoading(false)
                setLoad(false)
            })
        }
    }

    const returnsData = useMemo(() => {
        if (promotionSales?.success) {
            return promotionSales?.data?.[0]
        } else {
            return null
        }
    }, [promotionSales])
    // console.log("returnsData", returnsData)


    useEffect(() => {
        if (date) {
            getData()
        }
    }, [date])
    return (
        <div className='container-fluid mt-3 mb-3 pl-0'>


            <Row className='pl-15'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Promotion Sales
                    </Typography>
                </Col>
            </Row>
            {/* <DateBtn value={value} setValue={(data) => {
                setValue(data)
            }} setDate={setDate} /> */}
            <Row className="account-main-row pl-15">
                {/* <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="dateBox">
                            <DatePicker
                                minDate={new Date(2023, 0, 1)}
                                slotProps={{
                                    textField: {
                                        helperText: "YYYY / MM / DD",
                                    },
                                }}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="Start Date"
                                value={parseISO(value[0])}
                                onChange={(newValue) => {
                                    setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style"
                                        size="small"
                                        style={{ backgroundColor: "white", width: "50%" }}
                                        {...params}
                                    />
                                )}
                            />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <DatePicker
                                minDate={new Date(2023, 0, 1)}
                                slotProps={{
                                    textField: {
                                        helperText: "YYYY / MM / DD",
                                    },
                                }}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="End Date"
                                value={parseISO(value[1])}
                                onChange={(newValue) => {
                                    setValue([value[0], moment(newValue).format("YYYY-MM-DD")]);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style"
                                        size="small"
                                        style={{ backgroundColor: "white", width: "50%" }}
                                        {...params}
                                    />
                                )}
                            />
                        </div>
                    </LocalizationProvider>
                </Col> */}

                {/* <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <TextField
                        size="small"
                        value={fields.order_id}
                        onChange={e => setFields({ ...fields, order_id: e.target.value })}
                        id="outlined-basic"
                        type='number'
                        label="Order ID"
                        variant="outlined"
                        className="field-style"
                        autoComplete={false}
                    />
                </Col> */}
                {/* <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <FormControl className="field-style" size="small">
                        <InputLabel id="demo-simple-select-label1">
                            Source
                        </InputLabel>
                        <Select
                            sx={{ color: "black" }}
                            labelId="Source"
                            id="Source"
                            value={fields.source}
                            label="Source"
                            onChange={(e) =>
                                setFields({ ...fields, source: e.target.value })
                            }
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="review_sales">Review</MenuItem>
                            <MenuItem value="event_sales">Show</MenuItem>

                        </Select>
                    </FormControl>
                </Col> */}
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <SearchBrand setter={(value) => setFields({ ...fields, brand_id: value })} brand={fields.brand_id} />

                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                    <Button
                        variant="contained"
                        size="medium"
                        className="btn-gen-margin btn-width laptop"
                        color="primary"
                        startIcon={<SearchIcon />}
                        disableElevation
                        onClick={getData}
                    >
                        Search
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        startIcon={<RefreshIcon />}
                        className="btn-gen-margin btn-width laptop"
                        color="success"
                        disableElevation
                        onClick={() => {
                            getData('reset')
                        }}
                    >
                        Refresh
                    </Button>
                </Col>
            </Row >
            <Divider className="separater-sale" />
            {
                loading ?
                    <div className="pl-15" style={{ marginTop: 16 }}>
                        <SkeletonLoader />
                    </div>
                    :
                    <DataTable
                        page={page}
                        data={returnsData}
                        load={load}
                        nowGet={(page) => {
                            setLoad(true)
                            getPromotionSales(fields, value, page).then(() => {
                                setPage(page)
                                setLoad(false)
                            })
                        }}
                    />
            }
        </div >
    )
}
const mapStateToProps = ({ promotionSales }) => ({ promotionSales })
export default connect(mapStateToProps, { ...promotionActions })(Returns)