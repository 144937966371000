import { GET_STATUS } from "../../actions/type";

// const initialState = {
//     instances: [],
//     message: ''
// };

// export default function setStaus(state=initialState,action){
//     switch (action.type) {
//         case GET_STATUS:
//             return {
//                 ...state,
//                 instances: action.payload.instances,
//                 message: action.payload.message
//             };
//         default:
//             return state;
//     }
// }

export default function (state = [], action) {
  switch (action.type) {
    case GET_STATUS:
        console.log(action.payload, "testingggg")
      return action.payload;
    default:
      return state;
  }
}
