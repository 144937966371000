import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";

import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { Col, Row } from 'react-bootstrap';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import * as discountAction from "../../store/actions/discount.action"
import moment from 'moment';
import SearchBrandMultiple from 'reUsable/SearchBrandMutile';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};
function DiscountModalAdd({ clear, visible, data, create }) {
    const [fields, setFields] = useState({
        brand_ids: [],
        name: "",
        discount: 0,
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        discounted_product_marked: false
    })
    const [submit, setSubmit] = useState(false)
    function setter(k, v) {
        setFields({ ...fields, [k]: v })
    }
    function handleSubmit() {
        setSubmit(true)
        if (fields.name === "" || fields.discount < 1) {
            return
        }
        const data = {
            ...fields,
            brand_ids: fields.brand_ids?.map(brand => brand._id)
        }
        create(data)
        close()
    }
    useEffect(() => {
        if (data) {
            setFields({
                name: data?.name,
                min: data?.min,
                max: data?.max,
                is_active: data?.is_active,
            })
        }
    }, [data])

    function close(type) {
        setSubmit(false)
        if (data) {
            setFields({
                name: data?.name,
                min: data?.min,
                max: data?.max,
                is_active: data?.is_active,
            })
        } else {
            setFields({
                brand_ids: [],
                name: "",
                discount: 0,
                start_date: moment().format("YYYY-MM-DD"),
                end_date: moment().format("YYYY-MM-DD"),
                discounted_product_marked: false
            })
        }
        if (type !== "reset") {
            setFields({
                brand_ids: [],
                name: "",
                discount: 0,
                start_date: moment().format("YYYY-MM-DD"),
                end_date: moment().format("YYYY-MM-DD"),
                discounted_product_marked: false
            })
            clear()
        }
    }

    return (
        <Modal
            open={visible}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className={"deleteModal modal-generic-style modal-spacing-new"} style={{ width: "30%" }}>
                <Row className='heading-name-area-modal '>
                    <Col xl={12} lg={12} className="d-flex align-items-center justify-content-between">
                        <Box className="d-flex align-items-center">
                            <Typography className='text-center w-100 m-0' id="modal-modal-title" variant="h6" component="h2">
                                {data ? "Update" : "Add"} Discount
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                className='custom-close-btn-moda'
                                onClick={close}
                                aria-label="delete" sx={{ color: "grey" }}>
                                <CancelIcon color="error" />
                            </IconButton>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} lg={12} className="">
                        <Divider className='mb-15' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-left mb-3 " >
                        <SearchBrandMultiple setter={(value) => setFields({ ...fields, brand_ids: value })} brand={fields.brand_ids} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-left" >
                        <TextField
                            error={submit && fields.name === "" ? true : false}
                            size="small"
                            label="Discount Code"
                            name='name'
                            value={fields.name}
                            onChange={(e) => setter(e.target.name, e.target.value)}
                            variant="outlined"
                            className="field-style mb-3 "
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-left" >
                        <TextField
                            error={submit && fields.discount < 1 ? true : false}
                            size="small"
                            label="Discount Percent"
                            type='number'
                            name='discount'
                            InputProps={{
                                inputProps: {
                                    min: 1
                                },
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            value={fields.discount}
                            onChange={(e) => {
                                let val = e.target.value;
                                if (val > 0) {
                                    setter(e.target.name, Number(val))
                                }
                            }}
                            variant="outlined"
                            className="field-style mb-3 "
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-left" >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                minDate={new Date(2023, 0, 1)}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="Start Date"
                                value={fields.start_date}
                                onChange={(newValue) => {
                                    setter('start_date', moment(newValue).format('YYYY-MM-DD'))
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style mb-3"
                                        size="small"
                                        style={{ backgroundColor: "white", }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-left" >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                minDate={new Date(2023, 0, 1)}
                                allowSameDateSelection
                                sx={{ color: "black" }}
                                label="End Date"
                                value={fields.end_date}
                                onChange={(newValue) => {
                                    setter('end_date', moment(newValue).format('YYYY-MM-DD'))
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="field-style mb-3"
                                        size="small"
                                        style={{ backgroundColor: "white", }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} >
                        <FormControl
                            className="field-style mb-3" size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Markdown</InputLabel>
                            <Select
                                value={fields.discounted_product_marked}
                                label="Markdown"
                                name='discounted_product_marked'
                                onChange={(e) => setter(e.target.name, e.target.value)}
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                <div className='modal-buttons-row' style={{ marginTop: "5px" }}>
                    <LoadingButton
                        onClick={handleSubmit}
                        variant="contained"
                        size="medium"
                        color="primary"
                        className="btn-gen-margin ml-0 btn-width"
                        disableElevation
                    >
                        <SaveIcon className="mr-2" />  {data ? "Update" : "Save"}
                    </LoadingButton>
                    <Button
                        className='btn-gen-margin btn-width grey-btn-outline'
                        variant="outlined"
                        component="label"
                        size="large"
                        onClick={() => close('reset')}
                    >
                        Reset
                    </Button>
                </div>
            </Box>
        </Modal >
    )
}
const mapStateToProps = ({ }) => {
    return {};
};
export default connect(mapStateToProps, { ...discountAction })(DiscountModalAdd)

