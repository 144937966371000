import { Box, Button, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import numeral from 'numeral';
import NoRows from 'reUsable/NoRows';
import React, { useMemo } from 'react'
import { useState } from 'react';
// import SaveModal from "./Modal";

export default function Table({ data, load, page, nowGet }) {
    const orderCol = [
        {
            field: "id",
            headerName: "S.#",
            width: 50,
            renderCell: ({ value }) => {
                return (
                    <span className="d-flex justify-content-center w-100">{value}</span>
                );
            },
            sortable: false,
        },
        {
            field: "pixel_id",
            headerName: "PID",
            width: 100,
            sortable: false,
        },
        {
            field: "name",
            headerName: "Brand",
            width: 200,
            sortable: false,
        },
        {
            field: "email",
            headerName: "Email",
            width: 200,
            sortable: false,
        },
      
        {
            field: "total_estimate_amount",
            headerName: "Total Ad Spent",
            width: 200,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "total_paid_amount",
            headerName: "Total Paid Amount",
            width: 200,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        {
            field: "difference_amount",
            headerName: "Difference Amount",
            width: 200,
            renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor),
            sortable: false,
        },
        
    ];

    return (
        <div style={{ height: "445px" }}>


            <DataGrid
                // rowHeight={40}
                density="compact"
                disableColumnFilter={true}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                className="bg-white ml-15"
                components={{ NoRowsOverlay: NoRows, }}
                loading={load}
                rows={

                    data?.data?.map((item, index) => ({
                        ...item,
                        id: index + (page == 1 ? 1 : page * 12 - 11),
                    }))
                }

                columns={orderCol}
                pagination
                pageSize={12}
                rowsPerPageOptions={[12]}
                rowCount={data?.total ? data?.total : 0}
                paginationMode="server"
                onPageChange={(newPage) => nowGet(newPage + 1)}
            />
        </div>
    )
}
