
import React, { useEffect, useState } from 'react'
import { Typography, Divider, Box, Modal, IconButton } from '@mui/material'
import { Col, Row } from 'react-bootstrap';
import CancelIcon from '@mui/icons-material/Cancel';
import { connect } from "react-redux"
import * as salesActions from "../../../store/actions/sales"
import moment from 'moment';
import { setDate } from 'date-fns';
import numeral from 'numeral';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

function OrderModal({ visible, close, data, type, user, orderDetail }) {
    const [fields, setFields] = useState(null);
    const [load, setLoad] = useState(true)
    useEffect(() => {
        if (data) {
            let newData = {}
            setLoad(true)
            if (type === "referral") {
                if (data.sales_tracker_id) {
                    newData.id = data?.sales_tracker_id
                } else {
                    newData.id = data?._id
                }
                newData.event = data?.event
                newData.brand_id = user
            }
            if (type === "orders") {
                if (data.sales_tracker_id) {
                    newData.sales_tracker_id = data?.sales_tracker_id
                } else {
                    newData.sales_id = data?._id
                }
                newData.brand_id = user
            }
            if (type === "purchase") {
                if (data.sales_tracker_id) {
                    newData.sales_tracker_id = data?.sales_tracker_id
                } else {
                    newData.sales_id = data?._id
                }
                newData.brand_id = user
            }
            orderDetail(newData, type).then((res) => {
                if (res?.success) {
                    if (res?.message) {
                        setFields({ order_detail: res?.message?.order_detail, order_return_detail: res?.message?.order_return_detail })
                        setLoad(false)
                    } else {
                        setLoad(false)
                    }
                } else {
                    setFields(null)
                    setLoad(false)
                }
            })
        }

        return () => {
            setFields(null)
            setLoad(false)
        }

    }, [data])


    const computeTotalRefund = () => {
        return fields.order_return_detail.reduce((total, orderReturnDetail) => {
            const subtotalSum = orderReturnDetail.refund_line_items.reduce((subtotalSum, item) => subtotalSum + item.subtotal, 0);
            const totalTaxSum = orderReturnDetail.refund_line_items.reduce((totalTaxSum, item) => totalTaxSum + (item.total_tax || 0), 0);
            return total + subtotalSum + totalTaxSum;
        }, 0);
    };

    return (
        <Modal
            open={visible}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="deleteModal modal-spacing-new" style={{ width: '52rem', height: "initial" }}>
                <Row className='heading-name-area-modal '>
                    <Col xl={12} lg={12} className="d-flex align-items-center justify-content-between">
                        <Box className="d-flex align-items-center">
                            <Typography className='text-center w-100 m-0' id="modal-modal-title" variant="h6" component="h2">
                                Order Detail
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                className='custom-close-btn-moda'
                                onClick={close}
                                aria-label="delete" sx={{ color: "grey" }}>
                                <CancelIcon color="error" />
                            </IconButton>
                        </Box>
                    </Col>
                </Row>
                <Row><Col xl={12} lg={12} className=""><Divider className='' /></Col></Row>

                <Row>
                    <Col xl={12} lg={12} className="">
                        <div class="order-detail-title-area">
                            <div class="d-flex flex-column">
                                <div class="order-num">
                                    Order #
                                    <div class="order-name">{load ? "..." : fields && fields?.order_detail?.order_number}</div>
                                    {
                                        !load &&
                                        <>
                                            {fields?.order_detail?.cancel_reason && <div className='order-status status-error'>Cancelled</div>}
                                            {fields?.order_detail?.financial_status && <div className='order-status '>{fields?.order_detail?.financial_status}</div>}
                                            {fields?.order_detail?.fulfillment_status === null ? <div className='order-status '>Unfulfilled</div> : fields?.order_detail?.fulfillment_status === "partial" ? <div className='order-status status-success'>Partially Fulfilled</div> : <div className='order-status status-success'>Fulfilled</div>}
                                            {fields?.order_detail?.cancel_reason === null && fields?.order_return_detail?.length > 0 && <div className='order-status status-error'>Returned</div>}
                                        </>
                                    }

                                </div>
                                <duv class="order-num order-name-main">Customer Name: <div class="order-name">{load ? "..." : fields && `${fields?.order_detail?.customer?.first_name} ${fields?.order_detail?.customer?.last_name}`}</div>
                                </duv>
                            </div>
                            <span class="order-date">{load ? "..." : fields && `${moment(fields?.order_detail?.created_at).format('LLL')} from Online Store`} </span>
                        </div>
                        {
                            fields?.order_detail?.fulfillment_status &&
                            <>
                                <div className='orderdetail-title'>Fulfillment Status</div>
                                <div class="table-responsive">
                                    <table class="transactions-box table table-sm">
                                        <thead class="table_heading">
                                            <tr>
                                                <th>Tracking Company</th>
                                                <th>Tracking Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                load ? "..." :
                                                    fields &&
                                                    fields?.order_detail?.fulfillments?.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{item?.tracking_company ? item?.tracking_company : '-'}</td>
                                                                <td>{item?.tracking_number ? item?.tracking_number : '-'}</td>
                                                            </tr>
                                                        );
                                                    })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }

                        <div class="table-responsive">
                            <table class="transactions-box table table-sm">
                                <thead class="table_heading">
                                    <tr>
                                        <th class="td-width-60">S.#</th>
                                        <th class="td-width-120">Order Date</th>
                                        <th class="td-width-100">SKU #</th>
                                        <th class="td-width-200">Description</th>
                                        <th class="td-width-80">Qty</th>
                                        <th class="td-width-100 text-right">Price</th>
                                        <th class="td-width-100 text-right">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        load ? "..." :
                                            fields &&
                                            fields?.order_detail?.line_items?.map((item, ind) => (
                                                <tr>
                                                    <td>{ind + 1}</td>
                                                    <td>{moment(fields?.order_detail?.created_at).format("MM-DD-YYYY")}</td>
                                                    <td>{item?.sku}</td>
                                                    <td>{item?.title}</td>
                                                    <td>{item?.quantity}</td>
                                                    <td class="text-right">{numeral(item?.price).format("$0,0.0'")}</td>
                                                    <td class="text-right">{numeral(item?.price * item?.quantity).format("$0,0.0'")}</td>
                                                </tr>
                                            ))
                                    }

                                </tbody>
                            </table>
                        </div>
                        <div class="order-sum mb-2">
                            <div class="paid-mid-row">
                                <div class="left-txt multi-content">
                                    <div class="left-hd">Subtotal</div>
                                    <div class="left-info">{fields?.order_detail?.line_items?.length}</div>
                                </div>
                                <div class="right-txt">{numeral(fields?.order_detail?.total_line_items_price).format("$0,0.0'")}</div>
                            </div>
                            <div class="paid-mid-row">
                                <div class="left-txt multi-content">
                                    <div class="left-hd">Discount</div>
                                    {fields?.order_detail?.total_discounts ? numeral(fields?.order_detail?.total_discounts / fields?.order_detail?.total_line_items_price).format("0%") : ""}
                                </div>
                                {fields?.order_detail?.total_discounts ?
                                    <div className="right-txt">-{numeral(fields?.order_detail?.total_discounts).format("$0,0.0'")}</div>
                                    : <div className="right-txt">{numeral(0).format("$0,0.0'")}</div>
                                }
                                <div class="right-txt">-{numeral(fields?.order_detail?.total_discounts).format("$0,0.0'")}</div>
                            </div>
                            <div class="paid-mid-row">
                                <div class="left-txt multi-content">
                                    <div class="left-hd">Shipping</div>
                                </div>
                                <div class="right-txt"> {numeral(fields?.order_detail?.total_shipping_price_set?.presentment_money?.amount).format("$0,0.0'")}</div>
                            </div>
                            <div class="paid-mid-row">
                                <div class="left-txt multi-content">
                                    <div class="left-hd">Tax</div>
                                </div>
                                <div class="right-txt">  {numeral(fields?.order_detail?.total_tax).format("$0,0.0'")}</div>
                            </div>
                            <div class="paid-mid-row">
                                <div class="left-txt multi-content">
                                    <div class="left-hd fw-bold">Total Amount</div>
                                </div>
                                <div class="right-txt fw-bold"> {numeral(fields?.order_detail?.total_price).format("$0,0.0'")}</div>
                            </div>
                        </div>

                        {
                            fields?.order_return_detail?.length > 0 &&
                            <>
                                <div className='orderdetail-title'>Refund Detail</div>
                                <div class="table-responsive">
                                    <table class="transactions-box table table-sm">
                                        <thead class="table_heading">
                                            <tr>
                                                <th class="td-width-60">S.#</th>
                                                <th class="td-width-120">Order Date</th>
                                                <th class="td-width-100">SKU #</th>
                                                <th class="td-width-200">Description</th>
                                                <th class="td-width-80">Qty</th>
                                                <th class="td-width-100 text-right">Price</th>
                                                <th class="td-width-100 text-right">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                load ? "..." :
                                                    fields &&
                                                    fields?.order_return_detail?.map((item, ind) => {
                                                        return (
                                                            item?.refund_line_items?.map((item2, key2) => {
                                                                return (
                                                                    <tr key={key2}>
                                                                        <td>{key2 + 1}</td>
                                                                        <td>{moment(fields?.order_detail?.created_at).format("MM-DD-YYYY")}</td>
                                                                        <td>{item2?.line_item.sku}</td>
                                                                        <td>{item2?.line_item?.title}</td>
                                                                        <td>{item2?.quantity}</td>
                                                                        <td>{numeral(item2?.line_item?.price).format("$0,0.0'")}</td>
                                                                        <td>{numeral(item2?.subtotal).format("$0,0.0'")}</td>
                                                                    </tr>
                                                                );
                                                            })
                                                        )
                                                    })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                <div class="order-sum">
                                    <div class="paid-mid-row">
                                        <div class="left-txt multi-content">
                                            <div class="left-hd">Subtotal </div>
                                            <div class="left-info">{fields?.order_return_detail?.[0]?.refund_line_items?.length}</div>
                                        </div>
                                        <div class="right-txt">{numeral(fields?.order_return_detail
                                            .map(orderReturnDetail =>
                                                orderReturnDetail.refund_line_items.reduce((subtotalSum, item) => subtotalSum + item.subtotal, 0)
                                            )
                                            .reduce((total, subtotal) => total + subtotal, 0)).format("$0,0.0'")}</div>
                                    </div>
                                    <div class="paid-mid-row">
                                        <div class="left-txt multi-content">
                                            <div class="left-hd">Tax</div>
                                        </div>
                                        <div class="right-txt">  {numeral(
                                            fields?.order_return_detail
                                                .map(orderReturnDetail =>
                                                    orderReturnDetail.refund_line_items.reduce((totaltax, item) => totaltax + item?.total_tax, 0)
                                                )
                                                .reduce((total, subtotal) => total + subtotal, 0)
                                        ).format("$0,0.0'")}</div>
                                    </div>
                                    <div class="paid-mid-row">
                                        <div class="left-txt multi-content">
                                            <div class="left-hd fw-bold">Total Amount</div>
                                        </div>
                                        <div class="right-txt fw-bold">{numeral(computeTotalRefund()).format("$0,0.00", Math.floor)}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                    </Col>
                </Row>
            </Box>
        </Modal>
    )
}

function mapStateToProps({ }) {
    return {}
};
export default connect(mapStateToProps, salesActions)(OrderModal)
