import konnect from "./axios"
import { REVIEWS } from "./type"


export const getReviews = (limit, page, flag, data) => async (dispatch) => {
    try {
        let updated = {
            ...data,
            content_type: "influencer",
        }
        const res = await konnect.post('/v1/admin/review/getAllReviews', updated, { params: { page, limit } })
        dispatch({
            type: REVIEWS,
            payload: res.data,
            flag
        })
    } catch (err) {
        console.log(err.response)
    }
}

export const updateReview = (data) => async (dispatch) => {
    const res = await konnect.post('/v1/admin/review/enabledAndDisabled', data)
    return res.data
}

export const getModerationLevel = () => async (dispatch) => {
    try {
        const res = await konnect.post('/v1/admin/appSetting/getContentModerationSetting')
        return res.data
    } catch (err) {
        return err.response.data
    }
}
export const setModerationLevel = (data) => async (dispatch) => {
    let updated = {
        alcohol: Number(data.alcohol),
        gambling: Number(data.gambling),
        hate_speech: Number(data.hate_speech),
        nudity: Number(data.nudity),
        violence: Number(data.violence),
        suggestive: Number(data.suggestive),
        visually_disturbing: Number(data.visually_disturbing),
        rude_gestures: Number(data.rude_gestures),
        drugs: Number(data.drugs),
        tobacco: Number(data.tobacco),
        content_moderation: data.content_moderation,
        adult: Number(data.adult),
    }
    try {
        const res = await konnect.post('/v1/admin/appSetting/contentModerationSetting', updated)
        return res.data
    } catch (err) {
        return err.response.data
    }
}


