import { toast } from "react-toastify";
import konnect from "./axios";
import { GET_NOTIFICATIONS_LIST, GET_NOTIFICATIONSMSG_LIST } from "./type";

export const getNotifications = (page) => async (dispatch) => {
  try {
    const res = await konnect.post(
      "/v1/mobile/private/admin/getPushNotification",
      {},
      {
        params: {
          limit: 10,
          page,
        },
      }
    );
    dispatch({
      type: GET_NOTIFICATIONS_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err.response);
  }
};



export const createNotifications = (data) => async (dispatch) => {
  try {
    const res = await konnect.post(
      "/v1/mobile/private/addPushNotification",
      data
    );
    toast.success(res.data.message);
    return res;
  } catch (err) {
    console.log(err.response, "errr");
  }
};
export const getNotificationsMSG = (page, data) => async (dispatch) => {
  try {
    const res = await konnect.post(
      "/v1/admin/appSetting/getNotificationMsg",
      data,
      {
        params: {
          limit: 15,
          page,
        },
      }
    );
    dispatch({
      type: GET_NOTIFICATIONSMSG_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err.response);
  }
};
export const createNotificationsMSG = (data) => async (dispatch) => {
  try {
    let arr = data?.userIds?.map(m => m._id)
    const formBody = new FormData();
    formBody.append('title', data.title)
    formBody.append('desc', data.body)
    formBody.append('image', data.image)
    formBody.append('userIds', JSON.stringify(arr))
    const res = await konnect.post(
      "/v1/admin/appSetting/createNotificationMsg",
      formBody
    );
    console.log("1", res)
    toast.success(res.data.message);
    return res;
  } catch (err) {
    console.log(err.response, "errr");
  }
};
