import konnect from "./axios";
import {
  GET_SALE,
  GET_ALL_SALES,
  WITHDRAWAL,
  DEPOSIT,
  REFERRAL_SALES,
  ORDER_SALES,
  MARKET_FEE,
  PURCHASES,
  REFERRAL,
  EARNING,
  REVIEW_SALES,
  TOTAL_SALES,
} from "./type";

export const referralSales =
  (brand_id, fields, value, page = 1, limit = 10) =>
    async (dispatch) => {
      try {
        let obj = {
          from_date: value[0],
          to_date: value[1],
          gen_category: fields.generalCategory,
          category: fields.category,
          sub_category: fields.subCategory,
          source: fields.source,
          brand_id: brand_id,
          order: fields.order,
          group_by: fields.group_by,
          purchasedBy: fields.purchasedBy,
          ref_user: fields.ref_user,
          user_type: fields.user_type
        };
        const res = await konnect.post(
          "/v1/admin/crm/getreferralstatssummary",
          obj,
          { params: { page, limit } }
        );
        dispatch({
          type: REFERRAL_SALES,
          payload: res?.data,
        });
        return res?.data;
      } catch (err) {
        console.log(err.response);
      }
    };
export const reviewSales = (brand_id, fields, value, page = 1, limit = 10) => async (dispatch) => {
  try {
    let obj = {
      from_date: value[0],
      to_date: value[1],
      gen_category: fields.generalCategory,
      category: fields.category,
      sub_category: fields.subCategory,
      source: fields.source,
      brand_id,
      order: fields.order,
      group_by: fields.group_by,
      purchasedBy: fields.purchasedBy,
      ref_user: fields.ref_user,
      user_type: fields.user_type,
      user_id: brand_id
    };
    const res = await konnect.post("/v1/admin/crm/getcontentsalessummary", obj, { params: { page, limit } });
    dispatch({
      type: REVIEW_SALES,
      payload: res?.data,
    });
    return res?.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const orderSales =
  (brand_id, fields, value, page = 1, limit = 10) =>
    async (dispatch) => {
      try {
        let obj = {
          from_date: value[0],
          to_date: value[1],
          gen_category: fields.generalCategory,
          category: fields.category,
          sub_category: fields.subCategory,
          source: fields.source,
          brand_id,
          group_by: fields.group_by,
          order: fields.order,
          purchasedBy: fields.purchasedBy
        };
        const res = await konnect.post("/v1/admin/crm/getsalesreport", obj, {
          params: { page, limit },
        });
        dispatch({
          type: ORDER_SALES,
          payload: res?.data,
        });
        return res?.data;
      } catch (err) {
        console.log(err.response);
      }
    };
export const marketFees = (brand_id, fields, value) => async (dispatch) => {
  try {
    let obj = {
      from_date: value[0],
      to_date: value[1],
      gen_category: fields.generalCategory,
      category: fields.category,
      sub_category: fields.subCategory,
      brand_id,


    };
    const res = await konnect.post("/v1/admin/crm/getfeesummary", obj);
    dispatch({
      type: MARKET_FEE,
      payload: res?.data,
    });
    return res?.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const orderDetail = (data, type) => async (dispatch) => {
  try {
    let url = "/v1/admin/crm/getsalesdetails";
    if (type === "referral") url = "/v1/admin/crm/getreferralstatsdetail";
    if (type === "purchase") url = "/v1/admin/crm/getpurchasedetail";
    const res = await konnect.post(url, data);
    return res?.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const getPurchases =
  (user_id, fields, value, page = 1, limit = 10) =>
    async (dispatch) => {
      try {
        let obj = {
          source: fields.source,
          from_date: value[0],
          to_date: value[1],
          category: "all",
          gen_category: "all",
          sub_category: "all",
          brand: "all",
          group_by: fields.group_by,
          user_id,
          status: fields.status
        };
        if (fields.generalCategory !== "")
          obj.gen_category = fields.generalCategory;
        if (fields.category !== "") obj.category = fields.category;
        if (fields.subCategory !== "") obj.sub_category = fields.subCategory;
        if (fields.brand !== "") obj.brand = fields.brand;
        if (fields.brand === "All") obj.brand = "all";

        const res = await konnect.post("/v1/admin/crm/getpurchases", obj, {
          params: { page, limit },
        });
        dispatch({
          type: PURCHASES,
          payload: res?.data,
        });
        return res?.data;
      } catch (err) {
        console.log(err.response);
      }
    };
export const getEarning = (user_id, fields, value) => async (dispatch) => {
  try {
    let obj = {
      category: "all",
      gen_category: "all",
      sub_category: "all",
      brand: "all",
      group_by: fields.group_by,
      user_id,
    };
    if (value) {
      obj.from_date = value[0]
      obj.to_date = value[1]

    }
    if (fields.generalCategory !== "")
      obj.gen_category = fields.generalCategory;
    if (fields.category !== "") obj.category = fields.category;
    if (fields.subCategory !== "") obj.sub_category = fields.subCategory;
    if (fields.brand !== "") obj.brand = fields.brand;
    if (fields.brand === "All") obj.brand = "all";

    const res = await konnect.post("/v1/admin/crm/getearningsummary", obj);
    dispatch({
      type: EARNING,
      payload: res?.data,
    });
    return res?.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getReferral =
  (user_id, fields, value, page = 1, limit = 10) =>
    async (dispatch) => {
      try {
        let obj = {
          source: fields.source,
          from_date: value[0],
          to_date: value[1],
          category: "all",
          gen_category: "all",
          sub_category: "all",
          brand: "all",
          group_by: fields.group_by,
          user_id,
          status: fields.status
        };
        if (fields.generalCategory !== "")
          obj.gen_category = fields.generalCategory;
        if (fields.category !== "") obj.category = fields.category;
        if (fields.subCategory !== "") obj.sub_category = fields.subCategory;
        if (fields.brand !== "") obj.brand = fields.brand;
        if (fields.brand === "All") obj.brand = "all";

        const res = await konnect.post(
          "/v1/admin/crm/getreferralstatscustomersummary",
          obj,
          { params: { page, limit } }
        );
        dispatch({
          type: REFERRAL,
          payload: res?.data,
        });
        return res?.data;
      } catch (err) {
        console.log(err.response);
      }
    };

export const getSales =
  (userId, type, groupBy, page, value, fields, count) => async (dispatch) => {
    let url =
      type === "influencer"
        ? "/v1/admin/report/getInfluncerEarningByAdmin"
        : "v1/admin/report/getSalesReportsByAdmin";
    let id = `${type === "influencer"
      ? "62421495a368f8961c70941a"
      : "61d373de54c7db79889ecd2e"
      }`;
    let filter;
    if (groupBy === "summary") filter = type;
    if (groupBy === "detail") filter = "";
    let data = { id: userId };
    if (fields) {
      data.orderBy = parseInt(fields.orderBy);
      data.sort = fields.sort;
    }
    try {
      if (count) {
        const res = await konnect.post(url, data, {
          params: {
            group_by:
              groupBy === "" || groupBy === "summary" ? filter : groupBy,
            page: 1,
            limit: count,
            from_date: value[0],
            to_date: value[1],
          },
        });
        return res?.data?.message;
      } else {
        const res = await konnect.post(url, data, {
          params: {
            group_by:
              groupBy === "" || groupBy === "summary" ? filter : groupBy,
            page: page,
            limit: 10,
            from_date: value[0],
            to_date: value[1],
          },
        });
        dispatch({
          type: GET_SALE,
          payload: res?.data?.message,
        });
        return res?.data?.message;
      }
    } catch (err) {
      console.log(err.response);
    }
  };

export const getUserSales =
  (userId, type, groupBy, page, value, fields, count) => async (dispatch) => {
    let url = "/v1/admin/report/getCustomerSalesReportsByAdmin";
    let filter;
    if (groupBy === "summary") filter = "detail";
    if (groupBy === "detail") filter = "";
    let data = { id: userId };
    if (fields) {
      data.orderBy = parseInt(fields.orderBy);
      data.sort = fields.sort;
    }
    try {
      if (count) {
        const res = await konnect.post(url, data, {
          params: {
            group_by: groupBy === "summary" ? filter : groupBy,
            page: 1,
            limit: count,
            from_date: value[0],
            to_date: value[1],
          },
        });
        return res?.data?.message;
      } else {
        const res = await konnect.post(url, data, {
          params: {
            group_by: groupBy === "summary" ? filter : groupBy,
            page: page,
            limit: 10,
            from_date: value[0],
            to_date: value[1],
          },
        });
        dispatch({
          type: GET_SALE,
          payload: res?.data?.message,
        });
        return res?.data?.message;
      }
    } catch (err) {
      console.log(err.response);
    }
  };

export const getAllSales =
  (type, groupBy, page, value, fields, typeCheck, extra, brandId, from) =>
    async (dispatch) => {
      const url =
        from === "accounting"
          ? "/v1/admin/report/getBrandSales"
          : "/v1/admin/report/salesReportBrandWise";
      let filter;
      if (groupBy === "detail") filter = "";
      if (groupBy === "summary") {
        type === "Brand"
          ? (filter = "brand")
          : type === "Customer"
            ? (filter = "customer")
            : (filter = "influencer");
      }
      let body = {
        orderBy: parseInt(fields.orderBy),
        sort: fields.sort,
        user_type: type === "All" ? "" : type.toLowerCase(),
        brand_id: brandId,
      };
      try {
        if (typeCheck === "download") {
          const res = await konnect.post(url, body, {
            params: {
              group_by:
                groupBy === "" || groupBy === "summary" ? filter : groupBy,
              page: page,
              limit: extra,
              from_date: value[0],
              to_date: value[1],
            },
          });
          return res.data.message.data;
        } else {
          try {
            const res = await konnect.post(url, body, {
              params: {
                group_by:
                  groupBy === "" || groupBy === "summary" ? filter : groupBy,
                page: page,
                limit: 10,
                from_date: value[0],
                to_date: value[1],
              },
            });
            dispatch({
              type: GET_ALL_SALES,
              payload: res?.data?.message,
            });
            return res?.data?.message;
          } catch (err) {
            console.log(err.response);
          }
        }
      } catch (err) {
        return err;
      }
    };

export const getAccountingSale =
  (page, value, fields, typeCheck, extra) => async (dispatch) => {
    const url = "/v1/admin/report/getBrandSales";
    let res;
    let body = {
      ...fields,
      source: fields.source === "all" ? "" : fields.source,
    };
    try {
      if (typeCheck === "download") {
        res = await konnect.post(url, body, {
          params: {
            group_by: "detail",
            page: page,
            limit: extra,
            from_date: value[0],
            to_date: value[1],
          },
        });
        return res.data.message.data;
      } else {
        res = await konnect.post(url, body, {
          params: {
            group_by: "detail",
            page: page,
            limit: 10,
            from_date: value[0],
            to_date: value[1],
          },
        });
        dispatch({
          type: GET_ALL_SALES,
          payload: res?.data?.message,
        });
        return res?.data?.message;
      }
    } catch (err) {
      return err;
    }
  };
export const getAccountingPurchase =
  (page, value, fields, typeCheck, extra) => async (dispatch) => {
    const url = "/v1/admin/report/getPurchaseSales";
    let res;
    let body = {
      ...fields,
      source: fields.source === "all" ? "" : fields.source,
    };
    try {
      if (typeCheck === "download") {
        res = await konnect.post(url, body, {
          params: {
            group_by: "detail",
            page: page,
            limit: extra,
            from_date: value[0],
            to_date: value[1],
          },
        });
        return res.data.message.data;
      } else {
        res = await konnect.post(url, body, {
          params: {
            group_by: "detail",
            page: page,
            limit: 10,
            from_date: value[0],
            to_date: value[1],
          },
        });
        dispatch({
          type: GET_ALL_SALES,
          payload: res?.data?.message,
        });
        return res?.data?.message;
      }
    } catch (err) {
      return err;
    }
  };
export const getWithdrawals =
  (page, value, fields, typeCheck, extra) => async (dispatch) => {
    const url = "/v1/admin/report/getWithdrawList";
    let res;
    let body = {
      ...fields,
      source: fields.source === "all" ? "" : fields.source,
      account_type: fields.account_type === "all" ? "" : fields.account_type,
    };
    try {
      if (typeCheck === "download") {
        res = await konnect.post(url, body, {
          params: {
            group_by: "detail",
            page: page,
            limit: extra,
            from_date: value[0],
            to_date: value[1],
          },
        });
        return res.data.message.data;
      } else {
        res = await konnect.post(url, body, {
          params: {
            group_by: "detail",
            page: page,
            limit: 10,
            from_date: value[0],
            to_date: value[1],
          },
        });
        dispatch({
          type: WITHDRAWAL,
          payload: res?.data?.message,
        });
        return res?.data?.message;
      }
    } catch (err) {
      return err;
    }
  };
export const getDeposits =
  (page, value, fields, typeCheck, extra) => async (dispatch) => {
    const url = "/v1/admin/report/getBrandDeposit";
    let res;
    let body = {
      ...fields,
      source: fields.source === "all" ? "" : fields.source,
      account_type: fields.account_type === "all" ? "" : fields.account_type,
    };
    try {
      if (typeCheck === "download") {
        res = await konnect.post(url, body, {
          params: {
            page: page,
            limit: extra,
            from_date: value[0],
            to_date: value[1],
          },
        });
        return res.data.message.data;
      } else {
        res = await konnect.post(url, body, {
          params: {
            page: page,
            limit: 10,
            from_date: value[0],
            to_date: value[1],
          },
        });
        dispatch({
          type: DEPOSIT,
          payload: res?.data?.message,
        });
        return res?.data?.message;
      }
    } catch (err) {
      return err;
    }
  };

export const getCommissionAccounting =
  (page, value, fields, typeCheck, extra) => async (dispatch) => {
    const url = "/v1/admin/report/salesReportBrandWise";
    let body = {
      ...fields,
      user_type: fields.user_type === "All" ? "" : fields.user_type,
    };
    try {
      if (typeCheck === "download") {
        const res = await konnect.post(url, body, {
          params: {
            group_by: "detail",
            page: page,
            limit: extra,
            from_date: value[0],
            to_date: value[1],
          },
        });
        return res.data.message.data;
      } else {
        try {
          const res = await konnect.post(url, body, {
            params: {
              group_by: "detail",
              page: page,
              limit: 10,
              from_date: value[0],
              to_date: value[1],
            },
          });
          dispatch({
            type: GET_ALL_SALES,
            payload: res?.data?.message,
          });
          return res?.data?.message;
        } catch (err) {
          console.log(err.response);
        }
      }
    } catch (err) {
      return err;
    }
  };


export const reviewSalesInf = (brand_id, fields, value, page = 1, limit = 10) => async (dispatch) => {
  try {
    let obj = {
      from_date: value[0],
      to_date: value[1],
      gen_category: fields.generalCategory,
      category: fields.category,
      sub_category: fields.subCategory,
      source: fields.source,
      brand_id,
      order: fields.order,
      group_by: fields.group_by,
      purchasedBy: fields.purchasedBy,
      ref_user: fields.ref_user,
      user_type: fields.user_type,
      user_id: brand_id,
      status: fields.status
    };
    const res = await konnect.post("/v1/admin/crm/creatorSales", obj, { params: { page, limit } });
    dispatch({
      type: REVIEW_SALES,
      payload: res?.data,
    });
    return res?.data;
  } catch (err) {
    console.log(err.response);
    return err.response.data
  }
};

export const getEarnings = (user_id, type) => async (dispatch) => {
  try {
    const url = type === "agency" ? "/v1/admin/agency/adminSummary" : "/v1/withdraw/adminSummary"
    const res = await konnect.post(url, { user_id });
    return res?.data?.message?.earnings
  } catch (err) {
    console.log(err.response);
    return err.response
  }
};

export const getTotalSales = (fields, value, page = 1, limit = 12) => async (dispatch) => {
  try {
    let obj = {
      from_date: value[0],
      to_date: value[1],
      gen_category: fields.generalCategory,
      category: fields.category,
      sub_category: fields.subCategory,
      source: fields.source,
      group_by: fields.group_by,
      channel: fields.channel,
      content: fields.content,
      creator: fields.creator,
      referral: fields.referrer,
      brand_id: fields.brand_id,
      order_id: fields.order_id,
      status: fields.status === "all" ? "" : fields.status
    };
    const res = await konnect.post("/v1/admin/crm/getSalesNewReport", obj, {
      params: { page, limit },
    });
    dispatch({
      type: TOTAL_SALES,
      payload: res?.data,
    });
    return res?.data;
  } catch (err) {
    return err.response.data
  }
};
export const getTotalSalesAgency = (fields, value, page = 1, limit = 12) => async (dispatch) => {
  try {
    let obj = {
      from_date: value[0],
      to_date: value[1],
      gen_category: fields.generalCategory,
      category: fields.category,
      sub_category: fields.subCategory,
      source: fields.source,
      group_by: fields.group_by,
      channel: fields.channel,
      content: fields.content,
      creator: fields.creator,
      referral: fields.referrer,
      brand_id: fields.brand_id,
      order_id: fields.order_id,
      status: fields.status === "all" ? "" : fields.status,
      agency_id: fields.agency_id
    };
    const res = await konnect.post("/v1/admin/agency/getIndivisualAgencySalesReport", obj, {
      params: { page, limit },
    });
    dispatch({
      type: TOTAL_SALES,
      payload: res?.data,
    });
    return res?.data;
  } catch (err) {
    return err.response.data
  }
};
export const getTotalSalesCSV = (fields, value, page = 1, limit = 12) => async (dispatch) => {
  try {
    let obj = {
      from_date: value[0],
      to_date: value[1],
      gen_category: fields.generalCategory,
      category: fields.category,
      sub_category: fields.subCategory,
      source: fields.source,
      group_by: fields.group_by,
      channel: fields.channel,
      content: fields.content,
      creator: fields.creator,
      referral: fields.referrer,
      brand_id: fields.brand_id,
      order_id: fields.order_id,
      status: fields.status === "all" ? "" : fields.status,
      agency_id: fields.agency_id
    };
    const res = await konnect.post("/v1/admin/crm/getSalesNewReport", obj, {
      params: { page, limit },
    });

    return res?.data;
  } catch (err) {
    return err.response.data
  }
};